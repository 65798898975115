var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "documnetGalleryUpload" },
    [
      _c(
        "div",
        { staticClass: "list-box" },
        [
          _vm._l(_vm.list, function (item) {
            return _c("div", { key: item.id, staticClass: "upload" }, [
              _c(
                "div",
                { staticClass: "img" },
                [
                  _c("el-image", {
                    staticStyle: { width: "140px", height: "200px" },
                    attrs: { src: item.thumbUrl, fit: "cover" },
                    on: {
                      click: function ($event) {
                        return _vm.openImg(item.docurl)
                      },
                    },
                  }),
                  _vm.getCanDelete(item.id)
                    ? _c("i", {
                        staticClass: "el-icon-error",
                        on: {
                          click: function ($event) {
                            return _vm.removeDocuments(item)
                          },
                        },
                      })
                    : _vm._e(),
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(
                      _vm._s(item.fileName) + " (" + _vm._s(item.subtitle) + ")"
                    ),
                  ]),
                ],
                1
              ),
            ])
          }),
          _c("div", { staticClass: "upload" }, [
            _c(
              "div",
              { staticClass: "img" },
              [
                _c("el-image", {
                  staticStyle: { width: "140px", height: "200px" },
                  attrs: { src: _vm.addDocument, fit: "cover" },
                  on: {
                    click: function ($event) {
                      _vm.Show = true
                    },
                  },
                }),
                _c("p", { staticClass: "msg" }, [_vm._v("Add New Document")]),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.Show, "close-on-click-modal": false },
          on: { close: _vm.modalClose },
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("Add New Document"),
          ]),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("span", [_vm._v("Name of document")]),
              _c("el-input", {
                model: {
                  value: _vm.subtitle,
                  callback: function ($$v) {
                    _vm.subtitle = $$v
                  },
                  expression: "subtitle",
                },
              }),
              _vm.subtitle
                ? _c(
                    "div",
                    [
                      _vm.defaultImg
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { xs: 24, sm: 24, lg: 24, xl: 24 } },
                                [
                                  _vm.defaultImg
                                    ? _c("div", { staticClass: "img-box" }, [
                                        _c("img", {
                                          staticStyle: { "max-width": "200px" },
                                          attrs: {
                                            src: _vm.defaultImg,
                                            alt: "",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "upload-test" },
                                          [
                                            _c("div", [
                                              _c(
                                                "p",
                                                { staticClass: "color-white" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.notPassFileName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "color-white" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.notPassFileSize
                                                    ) + "KB"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("div", [
                                              _c(
                                                "p",
                                                { staticClass: "color-white" },
                                                [
                                                  _vm._v(
                                                    "Uploading " +
                                                      _vm._s(_vm.processLen) +
                                                      "% "
                                                  ),
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-circle-o-notch fa-pulse",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm.fileTypePass
                                ? _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      staticClass: "upload-demo",
                                      attrs: {
                                        drag: "",
                                        action: "#",
                                        "http-request": _vm.uploadFile,
                                        "before-remove": _vm.cancelUpload,
                                        "show-file-list": false,
                                        "on-change": _vm.uploadChange,
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Drag and Drop your files or "),
                                        _c("span", { staticClass: "browse" }, [
                                          _vm._v("Browse"),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _c("div", [
                                    _c("div", { staticClass: "not-pass-box" }, [
                                      _c(
                                        "div",
                                        { staticClass: "not-pass-content" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "content1" },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-error",
                                                staticStyle: { color: "white" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.fileTypePass =
                                                      !_vm.fileTypePass
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass: "content2",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "content3" },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "color-white" },
                                                [
                                                  _vm._v(
                                                    "File size exceed 20MB or file type is invalid"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "color-pink" },
                                                [
                                                  _vm._v(
                                                    "Expects jpeg, jpg, png, pdf, or heic"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "tip-box" }, [
                    _c("span", [
                      _vm._v("Enter a document name to enable uploading"),
                    ]),
                  ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }