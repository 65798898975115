var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "ComplianceReference",
    },
    [
      _c("div", [
        _vm.previousAddresses.length > 0
          ? _c("h3", [_vm._v("Current Address")])
          : _vm._e(),
        _c("div", [
          _c("div", { staticStyle: { "margin-top": "20px" } }, [
            _vm._v("Full Address"),
          ]),
          _c("div", { staticClass: "box" }, [
            _c("p", [_vm._v(_vm._s(_vm.applicant.fullAddress))]),
          ]),
        ]),
        _c("div", [
          _c("div", { staticStyle: { "margin-top": "20px" } }, [
            _vm._v("From Date"),
          ]),
          _c(
            "div",
            [
              _c("el-date-picker", {
                attrs: {
                  disabled: "",
                  value: _vm.applicant.currentAddressFromDate,
                  type: "date",
                  format: "dd/MM/yyyy",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm.previousAddresses.length
        ? _c("div", [
            _c(
              "div",
              [
                _c("h3", [_vm._v("Previous Addresses")]),
                _vm._l(_vm.previousAddresses, function (item, index) {
                  return _c("div", { key: index, staticClass: "box" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(item.fullAddress) +
                          " (" +
                          _vm._s(
                            _vm._f("parseDefaultTime")(
                              item.fromDate,
                              "{d}/{m}/{y}"
                            )
                          ) +
                          " - " +
                          _vm._s(
                            _vm._f("parseDefaultTime")(
                              item.toDate,
                              "{d}/{m}/{y}"
                            )
                          ) +
                          ")"
                      ),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }