<script>
import { medicalDeclarationApi } from '@/api/pattern'
export default {
  name: 'MedicalDeclarationView',
  props: {
    applicantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: {},
      moduleLoading: false
    }
  },
  computed: {
    HasConditionAffectingRoleShow() {
      return this.ruleForm.conditionAffectingRole === 'Yes'
    },
    HasConditionAffectingAttendenceshow() {
      return this.ruleForm.conditionAffectingAttendence === 'Yes'
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }
      await medicalDeclarationApi(params).then((res) => {
        const { data } = res
        this.ruleForm = data.getApplicantDeclarationByApplicantId
        this.ruleForm.conditionAffectingRole = data.getApplicantDeclarationByApplicantId.conditionAffectingRole ? 'Yes' : 'No'
        this.ruleForm.conditionAffectingAttendence = data.getApplicantDeclarationByApplicantId.conditionAffectingAttendence ? 'Yes' : 'No'
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>
<template>
  <div v-loading="moduleLoading" class="MedicalDeclarationView">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
      disabled
    >
      <el-form-item
        label="Do you have any pre-existing illnesses, injuries or medical conditions* that may impact your ability to undertake the duties of the role?"
        prop="conditionAffectingRole"
      >
        <el-radio-group v-model="ruleForm.conditionAffectingRole" disabled>
          <el-radio label="Yes" />
          <el-radio label="No" />
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="HasConditionAffectingRoleShow"
        label="If you answer ‘Yes’, Please provide further details of the condition in the space provided below including details of any treatment requirements and any adjustments that may be required in the workplace to accommodate that condition."
        prop="roleConditionDetail"
      >
        <el-input
          v-model="ruleForm.roleConditionDetail"
          type="textarea"
          maxlength="1000"
          disabled
        />
      </el-form-item>
      <el-form-item
        label="Do you have any pre-existing illnesses, injuries or medical conditions that may impact your ability to maintain your attendance at work?"
        prop="conditionAffectingAttendence"
      >
        <el-radio-group
          v-model="ruleForm.conditionAffectingAttendence"
          disabled
        >
          <el-radio label="Yes" />
          <el-radio label="No" />
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="HasConditionAffectingAttendenceshow"
        label="If you answer ‘Yes’, Please provide further details of the condition in the space provided below including details of any treatment requirements and any adjustments that may be required in the workplace to accommodate that condition."
        prop="attendenceConditionDetail"
      >
        <el-input
          v-model="ruleForm.attendenceConditionDetail"
          type="textarea"
          maxlength="1000"
          disabled
        />
      </el-form-item>
      <el-form-item
        label="Please provide any further information or workplace modifications you believe may be necessary for you to perform the duties of the role applied for"
        prop="otherMedicalInformation"
      >
        <el-input
          v-model="ruleForm.otherMedicalInformation"
          type="textarea"
          maxlength="1000"
          disabled
        />
      </el-form-item>
    </el-form>
  </div>
</template>
<style lang="scss">
.MedicalDeclarationView {
  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
