<script>
import { textEllipsis, parseDefaultTime } from '@/utils/index'
export default {
  name: 'EmploymentHistoryItemView',
  props: {
    getCompliancesByApplicantId: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      openIndex: 0, // 当前打开的index
      index: 0, // 持续递增的index
      activeNames: 0
    }
  },
  computed: {
    formData: {
      get() {
        const template = this.getCompliancesByApplicantId
        template.forEach(item => {
          item.collapseTitle = item.isGap
            ? 'Gap: ' + item.gapReason
            : (
              'Employment: ' + textEllipsis(item.businessFullName)
            )
          item.collapseTitle += ' (' + parseDefaultTime(item.dateCommenced, '{g} {y}') + ' - ' +
            (this.$isNullDate(item.dateEnded, '<>') ? parseDefaultTime(item.dateEnded, '{g} {y}') : 'Present') +
            ')'
          item.payrollHrEmail = item.isPayrollHREmailUnknown ? 'UNKNOWN' : item.payrollHrEmail
          item.recruitmentAgencyEmail = item.isRecruitmentAgencyEmailUnknown ? 'UNKNOWN' : item.recruitmentAgencyEmail
        })
        return template
      },
      set(val) {
        return val
      }
    }
  },
  methods: {
    dateEndedShow(val) {
      return this.$isNullDate(val, '<>')
    }
  }
}
</script>

<template>
  <div class="employmentOrGapForm">
    <el-collapse v-model="activeNames">
      <el-collapse-item
        v-for="(item,_index) in formData"
        :key="_index"
        :title="item.collapseTitle"
      >
        <el-form
          :ref="`addForm${item.index}`"
          :model="item"
          label-width="80px"
          label-position="top"
          disabled
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                :label="item.isGap ? 'When did your gap commence?' : 'When did you start working for this employer?'"
                style="margin-bottom: 0"
              >
                <el-date-picker
                  :value="item.dateCommenced" type="month" format="MM/yyyy" value-format="yyyy-MM-dd"
                  disabled
                />
              </el-form-item>
            </el-col>
            <el-col v-if="dateEndedShow(item.dateEnded)" :span="12">
              <el-form-item
                :label="item.isGap ? 'When did your gap conclude?' : 'When did your employment conclude?'"
                disabled
                style="margin-bottom: 0"
              >
                <el-date-picker
                  :value="item.dateEnded" type="month" format="MM/yyyy" value-format="yyyy-MM-dd"
                  disabled
                />
              </el-form-item>
            </el-col>
          </el-row>

          <div v-if="item.isGap">
            <el-form-item label="Gap Reason">
              <el-input v-model="item.gapReason" disabled />
            </el-form-item>
          </div>

          <div v-else>
            <el-form-item label="Employer / Business Name (Full Name).">
              <el-input v-model="item.businessFullName" disabled />
            </el-form-item>
            <el-form-item label="Type of Employment:">
              <el-input v-model="item.typeOfEmployment" disabled />
            </el-form-item>

            <el-form-item>
              <span slot="label">Are you still employed at this company?</span>
              <el-radio-group v-model="item.isStillEmployed" disabled>
                <el-radio :label="true">Yes</el-radio>
                <el-radio :label="false">No</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <span slot="label">{{ item.isStillEmployed ? 'Is ': 'Was ' }} the Employment Paid?</span>
              <el-radio-group v-model="item.wasPaidEmployment" disabled>
                <el-radio :label="true">Yes</el-radio>
                <el-radio :label="false">No</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="!item.isStillEmployed">
              <span slot="label">Is the Employer still in business?</span>
              <el-radio-group v-model="item.isEmployerStillinBusiness" disabled>
                <el-radio :label="true">Yes</el-radio>
                <el-radio :label="false">No</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="item.isStillEmployed">
              <span slot="label">Can we contact this Employer to verify your Employment?</span>
              <el-radio-group v-model="item.canContactEmployer" disabled>
                <el-radio :label="true">Yes</el-radio>
                <el-radio :label="false">No</el-radio>
              </el-radio-group>
            </el-form-item>

            <h3>Employer / Business Address Details</h3>
            <el-row :gutter="20">
              <el-col
                :xs="24" :sm="24" :md="12" :lg="12"
                :xl="12"
              >
                <el-form-item label="City / Suburb">
                  <el-input v-model="item.businessLocality" disabled />
                </el-form-item>
              </el-col>
              <el-col
                :xs="24" :sm="24" :md="12" :lg="12"
                :xl="12"
              >
                <el-form-item label="State">
                  <el-input v-model="item.businessState" disabled />
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Country">
              <el-input v-model="item.businessCountry" disabled />
            </el-form-item>
            <el-form-item label="Employer Phone Number (Business, landline or mobile)">
              <el-input v-model="item.employerPhone" disabled />
            </el-form-item>
            <el-form-item label="Email Address (Payroll / Human Resources)">
              <el-input v-model="item.payrollHrEmail" disabled />
            </el-form-item>
            <el-form-item>
              <span slot="label">What {{ item.isStillEmployed ? 'is' : 'was' }} the title of your position under your Employer?</span>
              <el-input v-model="item.positionTitle" disabled />
            </el-form-item>
            <el-form-item>
              <span slot="label">{{ item.typeOfEmployment !== 'Self-Employed' ? ('What '+ (item.isStillEmployed ? 'is ' : 'was ') + 'your Employee Number / ID?') : 'What is the ABN of your business?' }}</span>
              <el-input v-model="item.employeeId" disabled />
            </el-form-item>
            <el-form-item v-if="item.reasonForLeaving!==''" label="Please select your reason for leaving:">
              <el-input v-model="item.reasonForLeaving" disabled />
            </el-form-item>

            <el-form-item>
              <span slot="label">{{ item.isStillEmployed ? 'Are you ' : 'Were you ' }} paid by a Recruitment Agency?</span>
              <el-radio-group v-model="item.isPaidByRecruitment" disabled>
                <el-radio :label="true">Yes</el-radio>
                <el-radio :label="false">No</el-radio>
              </el-radio-group>
            </el-form-item>

            <div v-if="item.isPaidByRecruitment">
              <el-form-item label="Recruitment Agency Name">
                <el-input v-model="item.recruitmentAgencyName" disabled />
              </el-form-item>
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item label="Recruitment Agency Phone">
                    <el-input v-model="item.recruitmentAgencyPhone" disabled />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Recruitment Agency Email">
                    <el-input v-model="item.recruitmentAgencyEmail" disabled />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-form>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<style lang="scss" scoped>
.employmentOrGapForm {
  .typeEmp {
    .el-radio {
      width: 45%;
      margin: 10px 0;
    }
  }
  ::v-deep .el-collapse-item__wrap{
    background-color:transparent!important;
  }
  ::v-deep .el-collapse-item__header{
    padding:0;
    font-weight: bold;
    line-height: 1.5;
    background-color:transparent!important;
  }
}
.action-box {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  color: #206caa;
  .action-item {
    cursor: pointer;
  }
  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }
  .action-value {
    margin-left: 10px;
  }
}
</style>
