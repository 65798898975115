<script>
import { mapState } from 'vuex'
import { proofOfIdentityApi } from '@/api/pattern'
import ImageShow from '../components/ImageShow/index.vue'
export default {
  name: 'ProofOfIdentity',
  components: { ImageShow },
  props: {
    applicantId: {
      type: Number,
      default: 0
    },
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      commencementOrderApplicantDocuments: [],
      getChangeOfNameOrderApplicantDocuments: [],
      primaryOrderApplicantDocuments: [],
      secondaryOrderApplicantDocuments: [],
      listFilterSelfieDocuments: [],
      moduleLoading: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }
      await proofOfIdentityApi(params)
        .then((res) => {
          const { data } = res
          this.commencementOrderApplicantDocuments =
            data.commencementOrderApplicantDocuments
          this.getChangeOfNameOrderApplicantDocuments =
            data.getChangeOfNameOrderApplicantDocuments
          this.primaryOrderApplicantDocuments =
            data.primaryOrderApplicantDocuments
          this.secondaryOrderApplicantDocuments =
            data.secondaryOrderApplicantDocuments
          this.listFilterSelfieDocuments = data.listFilterSelfieDocuments
          this.moduleLoading = false
        })
        .catch(() => {
          this.moduleLoading = false
        })
    }
  }
}
</script>
<template>
  <div v-loading="moduleLoading" class="ProofOfIdentity">
    <el-row :gutter="10">
      <el-col
        :xs="24" :sm="12" :md="12" :lg="12"
        :xl="12"
      ><div>
        <h3>Commencement Document</h3>
        <div v-if="commencementOrderApplicantDocuments.length">
          <ImageShow
            :img-list="[commencementOrderApplicantDocuments[0]]"
          />
        </div>
      </div></el-col>
      <el-col
        :xs="24" :sm="12" :md="12" :lg="12"
        :xl="12"
      ><div v-if="getChangeOfNameOrderApplicantDocuments.length">
        <h3>Proof of Name Change Document</h3>
        <div>
          <ImageShow
            :img-list="getChangeOfNameOrderApplicantDocuments"
          />
        </div>
      </div></el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col
        :xs="24" :sm="12" :md="12" :lg="12"
        :xl="12"
      ><div>
        <h3>Primary Document</h3>
        <div v-if="primaryOrderApplicantDocuments.length">
          <ImageShow
            :img-list="[primaryOrderApplicantDocuments[0]]"
          />
        </div>
      </div></el-col>
      <el-col
        :xs="24" :sm="12" :md="12" :lg="12"
        :xl="12"
      ><div>
        <h3>Secondary Documents</h3>
        <div v-if="secondaryOrderApplicantDocuments.length">
          <ImageShow
            :img-list="secondaryOrderApplicantDocuments"
          />
        </div>
      </div></el-col>
    </el-row>

    <div
      v-if="listFilterSelfieDocuments.length && from === 'applicant'"
    >
      <h3>Selfie with Photo ID Document</h3>
      <div>
        <ImageShow
          :img-list="listFilterSelfieDocuments"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>

</style>
