<script>
import { mapState } from 'vuex'
import OtherCountriesItem from './AddForm/index.vue'
import { preparationApi, saveFormDataApi, continueApi } from '@/api/OtherCountries'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'OtherCountriesView',
  components: { OtherCountriesItem },
  inject: ['OnFormDataSave', 'ContinueSetSession', 'SaveFormDataSetSession', 'OnApplicantChange'],
  provide() {
    return {
      Continue: this.Continue,
      SaveFormTempData: this.SaveFormTempData,
      NewComplianceReference: this.NewComplianceReference,
      OnComplianceRemoved: this.OnComplianceRemoved
    }
  },
  data() {
    return {
      previousAddresses: [],
      editItemIndex: 0,
      hasOverseasAddressHistory: '',
      minQty: 0,
      historyList: [],
      ruleForm: {
        livedInOtherCountries: '',
        inAddressHistory: ''
      },
      removedOtherCountries: [],
      rules: {
        livedInOtherCountries: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        inAddressHistory: [{ required: true, message: 'Please select a value', trigger: 'change' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    Applicant() {
      return this.preparation.applicant
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    inAddressHistoryShow() {
      return this.ruleForm.livedInOtherCountries === 'Yes' && this.hasOverseasAddressHistory
    },
    CompletePercent() {
      return this.Session.current.formSection.completePercent === 100
    },
    FormShow() {
      return this.ruleForm.livedInOtherCountries === 'Yes' && this.ruleForm.inAddressHistory === 'No'
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
     this.Session.current.formSection.completePercent < 100 ||
     this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'OtherCountries')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.ruleForm.livedInOtherCountries = data.livedInOtherCountries
        this.ruleForm.inAddressHistory = data.inAddressHistory
        this.editItemIndex = data.editItemIndex
        this.hasOverseasAddressHistory = data.hasOverseasAddressHistory
        this.previousAddresses = data.previousAddresses
        this.historyList = data.previousAddresses
        this.minQty = data.currentFormSection.minQty
        this.removedOtherCountries = data.removedOtherCountries || []
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async NewComplianceReference(list) {
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        formData: {
          livedInOtherCountries: this.ruleForm.livedInOtherCountries,
          hasOverseasAddressHistory: this.hasOverseasAddressHistory,
          inAddressHistory: this.ruleForm.inAddressHistory,
          previousAddresses: list,
          removedOtherCountries: []
        },
        editItemIndex: list.length
      }
      params.formData.previousAddresses.forEach(item => {
        item.applicantId = this.ApplicantId
      })
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
      })
    },
    async OnComplianceRemoved(item, list) {
      if (!item.toDate) return
      const removeItem = item
      this.removedOtherCountries.push(removeItem)
      removeItem.applicantId = this.ApplicantId
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        formData: {
          livedInOtherCountries: this.ruleForm.livedInOtherCountries,
          hasOverseasAddressHistory: this.hasOverseasAddressHistory,
          inAddressHistory: this.ruleForm.inAddressHistory,
          previousAddresses: list,
          removedOtherCountries: this.removedOtherCountries
        },
        editItemIndex: -1
      }
      params.formData.previousAddresses.forEach(item => {
        item.applicantId = this.ApplicantId
      })

      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
      })
    },

    OnHasOtherCountriesChange() {
      const applicant = this.Applicant
      applicant.hasLivedOverseas = this.ruleForm.livedInOtherCountries === 'Yes'
      applicant.overseasAddressInHistory = !applicant.hasLivedOverseas ? false : applicant.overseasAddressInHistory
      const params = {
        session: this.Session,
        applicant: applicant
      }
      if (this.ruleForm.livedInOtherCountries === 'No') {
        this.removedOtherCountries = this.historyList
        this.previousAddresses = []
      }
      this.OnApplicantChange(params)
      this.SaveFormTempData('SaveFormTempData')
    },

    OnIsInHistoryChange(val) {
      const applicant = this.Applicant
      applicant.overseasAddressInHistory = this.ruleForm.inAddressHistory === 'Yes'
      const params = {
        session: this.Session,
        applicant: applicant
      }
      if (val === 'Yes') {
        this.removedOtherCountries = this.historyList
        this.previousAddresses = []
      }
      this.OnApplicantChange(params)
      this.SaveFormTempData('SaveFormTempData')
    },

    async SaveFormTempData(list) {
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        formData: {
          livedInOtherCountries: this.ruleForm.livedInOtherCountries,
          hasOverseasAddressHistory: this.hasOverseasAddressHistory,
          inAddressHistory: this.ruleForm.inAddressHistory,
          previousAddresses: list === 'SaveFormTempData' ? [] : list,
          removedOtherCountries: []
        },
        editItemIndex: list === 'SaveFormTempData' ? 0 : list.length
      }
      list !== 'SaveFormTempData' && params.formData.previousAddresses.forEach(item => {
        item.applicantId = this.ApplicantId
      })
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
      })
    },

    async Continue(list) {
      this.$refs['Form'].validate(async(valid) => {
        if (valid) {
          const applicant = JSON.parse(JSON.stringify(this.Applicant))
          applicant.hasLivedOverseas = this.ruleForm.livedInOtherCountries === 'Yes'
          const params = {
            session: this.Session,
            ApplicantId: this.ApplicantId,
            Applicant: applicant,
            previousAddresses: list === 'Continue' ? [] : list,
            // removedOtherCountries: !this.FormShow ? this.previousAddresses : this.removedOtherCountries
            removedOtherCountries: this.removedOtherCountries
          }
          list !== 'Continue' && params.previousAddresses.forEach(item => {
            item.applicantId = this.ApplicantId
            if (item.isManual) {
              item.fullAddress = `${item.addressLine1}, ${item.addressLine2}, ${item.suburb}, ${item.country}`
              if (item.displayLine1) {
                delete item.displayLine1
              }
            }
          })
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    }
  }
}
</script>
<template>
  <div v-loading="allLoading" class="OtherCountriesView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-divider />
    <el-form
      ref="Form"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      :disabled="allDisabled"
    >

      <el-form-item
        prop="livedInOtherCountries"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> In the last {{ minQty }} years have you lived in another country for a period of 6 months or greater?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.livedInOtherCountries" @change="OnHasOtherCountriesChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="inAddressHistoryShow"
        prop="inAddressHistory"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Has this information been recorded in your address history?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.inAddressHistory" @change="OnIsInHistoryChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <template v-if="FormShow">
        <OtherCountriesItem :continue-btn="ContinueBtn" :previous-addresses="previousAddresses" :complete-percent="CompletePercent" />
      </template>
      <template v-if="!FormShow">
        <!-- <el-button type="primary" @click="SaveFormTempData('SaveFormTempData')">Save form data</el-button> -->
        <el-button
          v-if="ContinueBtn" class="mt-30" :loading="endAllRequests" type="primary"
          @click="Continue('Continue')"
        >Save and Continue</el-button>
      </template>
    </el-form>
    <!--  -->
  </div>
</template>
<style lang="scss" scoped>
.OtherCountriesView {
  .label {
    line-height: 1.5;
    font-size: 14px;
    color: #606266;
  }
  .comp {
    margin-top: 20px;
  }
}
</style>
