<script>
import { mapState } from 'vuex'
import OnlyUpload from '@/components/OnlyUpload'
import { preparationApi, uploadApi, removeDocumentsApi, continueApi } from '@/api/CVUpload'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'CVUpload',
  components: { OnlyUpload },
  inject: ['ContinueSetSession', 'SaveFormDataSetSession'],
  data() {
    return {
      cvOrderApplicantDocuments: [],
      currentFormSection: {},
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    BlockName() {
      return this.preparation.session.current.formSection.blockName
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    Intro() {
      return this.preparation.intro
    },
    imgcanDelete() {
      return !this.Session.current.isSubmitted || this.Session.current.isAdmin
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'CVUpload')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        intro: this.Intro
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.cvOrderApplicantDocuments = data.cvOrderApplicantDocuments
        this.currentFormSection = data.currentFormSection
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async CloudinaryUpload_UploadResult_CV(file) {
      const formData = new FormData()
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)
      await uploadApi(formData).then(res => {
        const { data } = res
        this.cvOrderApplicantDocuments = data.cvOrderApplicantDocuments
      })
    },

    async Document_Remove(item) {
      const params = {
        orderApplicantDocumentId: item.id,
        session: this.Session
      }
      await removeDocumentsApi(params).then(res => {
        this.cvOrderApplicantDocuments = []
        this.SaveFormDataSetSession(res.data.session)
      })
    },

    async Continue() {
      if (this.cvOrderApplicantDocuments.length < 1) return this.$message.error('Please upload your CV/Resume before continuing.')
      const params = {
        session: this.Session,
        currentFormSection: this.currentFormSection,
        cvOrderApplicantDocuments: this.cvOrderApplicantDocuments
      }
      await continueApi(params).then(res => {
        this.ContinueSetSession(res.data.session)
      })
    },

    getImgcanDelete(item) {
      // (CVDocuments.List.Current.UploadedBlockName = Session.Current.FormSection.BlockName
      // and not Session.Current.IsSubmitted) or Session.Current.IsAdmin
      return (item.uploadedBlockName === this.Session.current.formSection.blockName &&
      !this.Session.current.isSubmitted) || this.Session.current.isAdmin
    }

  }
}
</script>

<template>
  <div v-loading="allLoading">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <OnlyUpload v-if="cvOrderApplicantDocuments.length<1 && imgcanDelete" @Document_Upload="CloudinaryUpload_UploadResult_CV" />
    <OnlyUpload
      v-for="item in cvOrderApplicantDocuments"
      :key="item.id"
      :img-item="item"
      :can-delete="getImgcanDelete(item)"
      @Document_Upload="CloudinaryUpload_UploadResult_CV"
      @Document_Remove="Document_Remove"
    />
    <el-button
      v-if="ContinueBtn" class="mt-30" :loading="endAllRequests" type="primary"
      @click="Continue()"
    >Save and Continue</el-button>
  </div>
</template>
