<script>
import { cvUploadApi } from '@/api/pattern'
import ImageShow from '../components/ImageShow/index.vue'
export default {
  name: 'CVUpload',
  components: { ImageShow },
  props: {
    orderApplicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      cvOrderApplicantDocuments: []
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      await cvUploadApi({ orderApplicantId: this.orderApplicantId }).then(res => {
        this.cvOrderApplicantDocuments = res.data.cvOrderApplicantDocuments
      })
    }
  }
}
</script>

<template>
  <div v-if="cvOrderApplicantDocuments.length" class="CVUpload-box">
    <ImageShow :img-list="cvOrderApplicantDocuments" />
  </div>
</template>
<style lang="scss" scoped>
</style>
