<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import AddressAutocomplete from '@/components/AddressAutocomplete'
import { getAllGeoCountriesApi } from '@/api/application'

export default {
  name: 'EmploymentHistoryItemView',
  components: { AddressAutocomplete },
  inject: ['GetAllCompliancesList', 'removeItem', 'SaveFormData'],
  props: {
    employments: {
      type: Array,
      default: () => []
    },
    editItemIndex: {
      type: Number,
      default: 0
    },
    maxDate: {
      type: String,
      default: ''
    },
    targetMinDate: {
      type: String,
      default: ''
    },
    notes: {
      type: String,
      default: ''
    }
  },
  data() {
    const validateSearch = (rule, value, callback) => {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      if (this.compliancesList[spliceIndex].isBusinessAddressManual === true) {
        callback()
      }
      if (value !== '') {
        if (value === this.compliancesList[spliceIndex].displayLine1) {
          callback()
        } else {
          callback(new Error('Please select an address from the list.'))
        }
      } else {
        callback(new Error('Required field!'))
      }
    }
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      form: {},
      formConfig: {
        employmentOrGap: ''
      },
      gapFormConfig: {
        gapReason: '',
        dateCommenced: null,
        dateEnded: null
      },
      employmentFormConfig: {
        businessFullName: '',
        typeOfEmployment: '',
        isEmploymentPaid: '',
        isStillEmployed: '',
        dateCommenced: null,
        dateEnded: null,
        isStillInBusiness: '',
        canContactEmployer: '',
        businessfullAddress: '',
        employerPhone: '',
        positionTitle: '',
        employeeId: '',
        reasonForLeaving: '',
        isPaidByRecruitmentAgency: '',
        recruitmentAgencyName: '',
        recruitmentAgencyPhone: '',
        recruitmentAgencyEmail: '',
        payrollHrEmail: '',
        isPayrollHREmailUnknown: false,
        isRecruitmentAgencyEmailUnknown: false,
        businessLocality: '',
        businessState: '',
        businessCountry: ''
      },
      countrySelectionOptions: [],
      rules: {
        businessfullAddress: [
          { required: true, validator: validateSearch, trigger: 'blur' }
        ],
        businessLocality: [
          { required: true, validator: this.validateBusinessLocality, trigger: 'blur' }
        ],
        businessCountry: [
          { required: true, validator: this.validateBusinessCountry, trigger: 'blur' }
        ],
        employmentOrGap: [
          { required: true, message: 'Required field!', trigger: 'change' }
        ],
        gapReason: [
          { required: true, message: 'Please select a reason for you gap', trigger: 'change' }
        ],
        dateCommenced: [
          { required: true, message: 'Required field!', trigger: 'change' }
        ],
        dateEnded: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        businessFullName: [
          { required: true, message: 'Required field!', trigger: 'change' }
        ],
        typeOfEmployment: [
          { required: true, message: 'Please select your type of employment', trigger: 'change' }
        ],
        isEmploymentPaid: [
          { required: true, message: 'Please select Yes or No', trigger: 'change' }
        ],
        isStillEmployed: [
          { required: true, message: 'Please select Yes or No', trigger: 'change' }
        ],
        isStillInBusiness: [
          { required: true, message: 'Required field!', trigger: 'change' }
        ],
        canContactEmployer: [
          { required: true, message: 'Required field!', trigger: 'change' }
        ],
        employerPhone: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        positionTitle: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        employeeId: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        reasonForLeaving: [
          { required: true, message: 'Please select your reason for leaving', trigger: 'change' }
        ],
        isPaidByRecruitmentAgency: [
          { required: true, message: 'Please select Yes or No', trigger: 'change' }
        ],
        recruitmentAgencyName: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        recruitmentAgencyPhone: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        recruitmentAgencyEmail: [
          { validator: this.validateRecruitmentAgencyEmail, trigger: 'blur' },
          { type: 'email', message: 'Email expected!', trigger: ['blur', 'change'] }
        ],
        payrollHrEmail: [
          { validator: this.validatePayrollHrEmail, trigger: 'blur' },
          { type: 'email', message: 'Email expected!', trigger: ['blur', 'change'] }
        ]
      },
      compliancesList: [
        // {
        //   employmentOrGap: '',
        //   index: 0
        // }
      ],
      openIndex: 0, // 当前打开的index
      index: 0 // 持续递增的index
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device,
      preparation: state => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ReopenNotes: {
      get() {
        return this.notes
      },
      set(val) {
        this.$emit('update:notes', val)
      }
    },
    isMobile() {
      return this.device === 'mobile'
    },
    isPayrollHREmailUnknownOpen() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      return this.compliancesList[spliceIndex].isPayrollHREmailUnknown === true
    },
    isRecruitmentAgencyEmailUnknownOpen() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      return this.compliancesList[spliceIndex].isRecruitmentAgencyEmailUnknown === true
    },
    outAddBtnShow() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      return spliceIndex === -1
    }
  },
  watch: {
    compliancesList: {
      handler(data) {
        if (data) {
          const oldData = JSON.parse(JSON.stringify(data))
          const lastData = []
          oldData.forEach((el, i) => {
            // console.log(79, el)
            if (el.employmentOrGap === '') {
              lastData.push({
                employmentOrGap: el.employmentOrGap,
                index: el.index
              })
              // this.SaveFormData('', el.index)
            }
            if (el.employmentOrGap === 'Gap') {
              lastData.push({
                employmentOrGap: el.employmentOrGap,
                index: el.index,
                id: (el.id || el.id === 0) ? el.id : null,
                gapReason: el.gapReason ? el.gapReason : '',
                dateCommenced: el.dateCommenced ? el.dateCommenced : null,
                dateEnded: el.dateEnded ? el.dateEnded : null
              })
            } else if (el.employmentOrGap === 'Employment') {
              lastData.push({
                employmentOrGap: el.employmentOrGap,
                index: el.index,
                id: (el.id || el.id === 0) ? el.id : null,
                businessFullName: el.businessFullName
                  ? el.businessFullName
                  : '',
                typeOfEmployment: el.typeOfEmployment
                  ? el.typeOfEmployment
                  : '',
                isEmploymentPaid: el.isEmploymentPaid
                  ? el.isEmploymentPaid
                  : '',
                isStillEmployed: el.isStillEmployed ? el.isStillEmployed : '',
                isStillInBusiness: el.isStillInBusiness ? el.isStillInBusiness : '',
                canContactEmployer: el.canContactEmployer ? el.canContactEmployer : '',
                dateCommenced: el.dateCommenced ? el.dateCommenced : null,
                dateEnded: el.dateEnded ? el.dateEnded : null,
                businessfullAddress: el.businessfullAddress ? el.businessfullAddress : '',
                employerPhone: el.employerPhone ? el.employerPhone : '',
                positionTitle: el.positionTitle ? el.positionTitle : '',
                employeeId: el.employeeId ? el.employeeId : '',
                reasonForLeaving: el.reasonForLeaving
                  ? el.reasonForLeaving
                  : '',
                isPaidByRecruitmentAgency: el.isPaidByRecruitmentAgency
                  ? el.isPaidByRecruitmentAgency
                  : '',
                recruitmentAgencyName: el.recruitmentAgencyName
                  ? el.recruitmentAgencyName
                  : '',
                recruitmentAgencyPhone: el.recruitmentAgencyPhone
                  ? el.recruitmentAgencyPhone
                  : '',
                recruitmentAgencyEmail: el.recruitmentAgencyEmail
                  ? el.recruitmentAgencyEmail
                  : '',
                payrollHrEmail: el.payrollHrEmail
                  ? el.payrollHrEmail
                  : '',
                isPayrollHREmailUnknown: el.isPayrollHREmailUnknown,
                isRecruitmentAgencyEmailUnknown: el.isRecruitmentAgencyEmailUnknown,
                businessLocality: el.businessLocality ? el.businessLocality : '',
                businessState: el.businessState ? el.businessState : '',
                businessCountry: el.businessCountry ? el.businessCountry : '',
                isBusinessAddressManual: el.isBusinessAddressManual ? el.isBusinessAddressManual : false
              })
            } else {
              lastData.push({
                employmentOrGap: el.employmentOrGap,
                index: el.index
              })
            }
          })
          // console.log(92, lastData)
          this.GetAllCompliancesList(lastData, '', this.openIndex)
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (this.employments.length === 1 && !this.employments[0].employmentOrGap) {
      this.compliancesList = []
    } else if (this.employments.length > 0) {
      this.compliancesList = []
      this.employments.forEach(item => {
        const obj = {
          ...item.employment,
          canContactEmployer: item.canContactEmployer,
          employmentOrGap: item.employmentOrGap,
          isEmploymentPaid: item.isEmploymentPaid,
          isPaidByRecruitmentAgency: item.isPaidByRecruitmentAgency,

          isStillEmployed: item.isStillEmployed,
          isStillInBusiness: item.isStillInBusiness,
          isPayrollHREmailUnknown: item.isPayrollHREmailUnknown,
          isRecruitmentAgencyEmailUnknown: item.isRecruitmentAgencyEmailUnknown
        }

        // obj.isPayrollHREmailUnknown = obj.payrollHrEmail === 'UNKNOWN'
        // obj.isRecruitmentAgencyEmailUnknown = obj.recruitmentAgencyEmail === 'UNKNOWN'

        obj.payrollHrEmail = obj.payrollHrEmail === 'UNKNOWN' ? '' : obj.payrollHrEmail
        obj.recruitmentAgencyEmail = obj.recruitmentAgencyEmail === 'UNKNOWN' ? '' : obj.recruitmentAgencyEmail

        obj.dateEnded = obj.isStillEmployed === 'Yes' ? this.maxDate : obj.dateEnded
        obj.reasonForLeaving = obj.isStillEmployed === 'Yes' ? '' : obj.reasonForLeaving
        obj.displayLine1 = obj.businessfullAddress

        obj.status = 'edit'
        obj.index = obj.index ? obj.index : 0
        this.compliancesList.push(obj)
      })
      this.compliancesList = this.$isRepeat(this.compliancesList)
      this.index = Math.max.apply(Math, this.compliancesList.map(item => {
        return item.index
      }))
      this.index = this.index ? this.index : 0
      this.openIndex = this.editItemIndex
      this.employments.length > 1 && this.bubbleSort()
    }
    this.getCountriesOptions()
  },
  methods: {
    validatePayrollHrEmail(rule, value, callback) {
      if (this.isPayrollHREmailUnknownOpen) {
        callback()
      } else {
        if (value === '') {
          callback(new Error('Required field!'))
        } else {
          callback()
        }
      }
    },
    validateRecruitmentAgencyEmail(rule, value, callback) {
      if (this.isRecruitmentAgencyEmailUnknownOpen) {
        callback()
      } else {
        if (value === '') {
          callback(new Error('Required field!'))
        } else {
          callback()
        }
      }
    },
    validateBusinessLocality(rule, value, callback) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      if (this.compliancesList[spliceIndex].isBusinessAddressManual !== true) {
        callback()
      }
      if (value === '') {
        callback(new Error('Required field!'))
      } else {
        callback()
      }
    },
    validateBusinessCountry(rule, value, callback) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      if (this.compliancesList[spliceIndex].isBusinessAddressManual !== true) {
        callback()
      }
      if (value === '') {
        callback(new Error('Required field!'))
      } else {
        callback()
      }
    },

    bubbleSort() {
      for (let i = 0; i < this.compliancesList.length - 1; i += 1) {
        // 通过 this.length 次把第一位放到最后,完成排序
        // -i是因为最后的位置是会动态改变的，当完成一次后,最后一位会变成倒数第二位
        for (let j = 0; j < this.compliancesList.length - 1 - i; j += 1) {
          if (dayjs(this.compliancesList[j].dateCommenced).isAfter(dayjs(this.compliancesList[j + 1].dateCommenced))) {
            const temp = this.compliancesList[j]
            this.compliancesList[j] = this.compliancesList[j + 1]
            this.compliancesList[j + 1] = temp
          }
        }
      }
    },

    AddressAutocompleteOnDetailReady(AddressDetail, displayLine1) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].displayLine1 = displayLine1
      this.compliancesList[spliceIndex].businessfullAddress = displayLine1
      this.compliancesList[spliceIndex].businessLocality = AddressDetail.suburb ? AddressDetail.suburb : AddressDetail.addressLine1
      this.compliancesList[spliceIndex].businessState = AddressDetail.state
      this.compliancesList[spliceIndex].businessCountry = AddressDetail.country

      this.SaveFormData('', this.openIndex)
      this.$refs[`addForm${this.openIndex}`][0].validateField('businessfullAddress')
    },
    AddressAutocompleteOnClear() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].displayLine1 = ''
      this.compliancesList[spliceIndex].businessLocality = ''
      this.compliancesList[spliceIndex].businessState = ''
      this.compliancesList[spliceIndex].businessCountry = ''

      this.SaveFormData('', this.openIndex)
    },
    AddressAutocompleteOnNoSelectionMade() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].businessLocality = ''
      this.compliancesList[spliceIndex].businessState = ''
      this.compliancesList[spliceIndex].businessCountry = ''

      this.SaveFormData('', this.openIndex)
    },
    async getCountriesOptions() {
      await getAllGeoCountriesApi().then((res) => {
        const { data } = res
        this.countrySelectionOptions = data.geoCountries
      })
    },
    AddressAutocompleteOnManualEntry() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].businessfullAddress = ''
      this.compliancesList[spliceIndex].businessLocality = ''
      this.compliancesList[spliceIndex].businessState = ''
      this.compliancesList[spliceIndex].businessCountry = ''
      this.compliancesList[spliceIndex].isBusinessAddressManual = true
    },

    isPaidByRecruitmentAgencyChange(itemIndex) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === itemIndex
      )
      if (this.compliancesList[spliceIndex].isPaidByRecruitmentAgency === 'No') {
        this.compliancesList[spliceIndex].recruitmentAgencyName = ''
        this.compliancesList[spliceIndex].recruitmentAgencyPhone = ''
        this.compliancesList[spliceIndex].recruitmentAgencyEmail = ''
        this.compliancesList[spliceIndex].isRecruitmentAgencyEmailUnknown = null
      }
    },

    handleRemove(itemIndex, item) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === itemIndex
      )
      // if (this.compliancesList.length > 1) {
      this.compliancesList.splice(spliceIndex, 1)
      if (item.employmentOrGap !== '') {
        this.GetAllCompliancesList(this.compliancesList, '', this.openIndex)
        this.removeItem(item, this.openIndex)
      }
      // }
    },
    addItem() {
      this.bubbleSort()
      this.compliancesList.push(
        JSON.parse(
          JSON.stringify({
            ...this.formConfig,
            ...this.gapFormConfig,
            ...this.employmentFormConfig,
            index: ++this.index
          })
        )
      )
      this.openIndex = this.index
    },
    handleAdd(formName, type, item) {
      if (type === 1) {
        // 表单内
        this.$refs[formName][0].validate(async(valid) => {
          if (valid) {
            this.addItem()
          }
        })
      } else {
        // 全局下
        if (
          this.$refs[`addForm${this.index}`] &&
          this.$refs[`addForm${this.index}`][0]
        ) {
          // 当前存在打开的表单
          this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
            if (valid) {
              this.addItem()
            }
          })
        } else {
          //  不存在打开的表单
          this.addItem()
        }
      }
    },
    open(itemIndex) {
      if (
        this.$refs[`addForm${this.openIndex}`] &&
        this.$refs[`addForm${this.openIndex}`][0]
      ) {
        this.$refs[`addForm${this.openIndex}`][0].validate(async(valid) => {
          if (valid) {
            this.openIndex = itemIndex
            this.ReopenNotes = ''
          }
        })
      } else {
        this.openIndex = itemIndex
        this.ReopenNotes = ''
      }
    },
    ValidateForm(index) {
      if (this.$refs[`addForm${index}`] && this.$refs[`addForm${index}`][0]) {
        this.$refs[`addForm${index}`][0].validate(async(valid) => {
          if (valid) {
            // 单纯地验证表单 不用做任何操作
          }
        })
      }
    },
    isPayrollHREmailUnknownChange(index, val) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].isPayrollHREmailUnknown = val
      if (this.compliancesList[spliceIndex].isPayrollHREmailUnknown) {
        this.compliancesList[spliceIndex].payrollHrEmail = ''
      }
      this.GetAllCompliancesList(this.compliancesList, '', this.openIndex)
      this.SaveFormData('', index)
      this.$refs[`addForm${this.openIndex}`][0].validateField('payrollHrEmail')
    },
    isRecruitmentAgencyEmailUnknownChange(index, val) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].isRecruitmentAgencyEmailUnknown = val
      if (this.compliancesList[spliceIndex].isRecruitmentAgencyEmailUnknown) {
        this.compliancesList[spliceIndex].recruitmentAgencyEmail = ''
      }
      this.GetAllCompliancesList(this.compliancesList, '', this.openIndex)
      this.SaveFormData('', index)
      this.$refs[`addForm${this.openIndex}`][0].validateField('recruitmentAgencyEmail')
    },

    isStillEmployedChange(index) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === index
      )
      if (this.compliancesList[spliceIndex].isStillEmployed === 'Yes') {
        this.compliancesList[spliceIndex].dateEnded = this.maxDate
      } else {
        this.compliancesList[spliceIndex].dateEnded = null
      }
      this.SaveFormData('', index)
    },

    refreshBusinessFullAddress(index) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === index
      )
      if (this.compliancesList[spliceIndex].businessLocality !== '') {
        this.compliancesList[spliceIndex].businessfullAddress = this.compliancesList[spliceIndex].businessLocality
        if (this.compliancesList[spliceIndex].businessState !== '') {
          this.compliancesList[spliceIndex].businessfullAddress = this.compliancesList[spliceIndex].businessfullAddress + ', ' + this.compliancesList[spliceIndex].businessState
        }
        if (this.compliancesList[spliceIndex].businessCountry !== 'Australia') {
          this.compliancesList[spliceIndex].businessfullAddress = this.compliancesList[spliceIndex].businessfullAddress + ' (' + this.compliancesList[spliceIndex].businessCountry + ')'
        }
      }
    }

  }
}
</script>

<template>
  <div class="employmentOrGapForm">
    <div v-for="item in compliancesList" :key="item.index">
      <div
        v-if="item.index != openIndex"
        class="history-box"
        @click="open(item.index)"
      >
        <p>{{ item.employmentOrGap }}:
          {{ item.employmentOrGap === 'Gap' ? item.gapReason : item.businessFullName }}
          ( {{ item.dateCommenced | parseDefaultTime('{g} {y}') }} -
          <span v-if="item.employmentOrGap!=='Gap' && item.isStillEmployed==='Yes'">Present</span>
          <span v-else>{{ item.dateEnded | parseDefaultTime('{g} {y}') }}</span>
          )
        </p>
      </div>
      <div v-if="item.index == openIndex">
        <div v-if="ReopenNotes" class="ReopenNotes" style="color: #0e1011; font-weight: bolder;">
          <div class="expression-left-box" style="display: flex; align-items: center; color: #0e1011; font-weight: bolder;">
            <i style="margin-left: 10px;" class="el-icon-warning" />
            <span style="margin-left: 10px; color: #0e1011;">&nbsp;Please provide your employment history up to {{ ReopenNotes === 'start' ? targetMinDate : maxDate | parseDefaultTime('{g} {y}')
            }}</span>
          </div>
          <i class="el-icon-close" @click="ReopenNotes=''" />
        </div>
        <div class="section-header">{{ item.status === 'edit' ? 'Editing' : 'New' }} {{ item.employmentOrGap || 'Item'
        }}
        </div>
        <el-form
          :ref="`addForm${item.index}`"
          :model="item"
          :rules="rules"
          label-width="80px"
          label-position="top"
          :disabled="allDisabled"
        >
          <el-form-item
            v-if="item.status !== 'edit'"
            label=""
            prop="employmentOrGap"
            style="margin-bottom: 0"
          >
            <label class="label">
              <div style="margin-bottom: 5px;">* What are you adding to your Employment History?</div>
              <el-radio-group v-model="item.employmentOrGap" @change="SaveFormData('',item.index)">
                <el-radio label="Employment" />
                <el-radio label="Gap" />
              </el-radio-group>
            </label>
          </el-form-item>

          <template v-if="item.employmentOrGap == 'Employment'">
            <el-form-item
              prop="businessFullName"
            >
              <label class="label">* Employer / Business Name (Full Name)
                <el-input v-model="item.businessFullName" @input="SaveFormData('',item.index)" />
              </label>
            </el-form-item>

            <el-form-item
              prop="typeOfEmployment"
              style="margin-bottom: 0"
            >
              <label class="label">
                <div style="margin-bottom: 5px;">* Type of Employment:</div>
                <el-radio-group v-model="item.typeOfEmployment" class="typeEmp" @change="SaveFormData('',item.index)">
                  <el-radio label="Employment" />
                  <el-radio label="Volunteer" />
                  <el-radio label="Traineeship" />
                  <el-radio label="Internship / Cadet" />
                  <el-radio label="Placement / Work Experience" />
                  <br v-if="isMobile">
                  <el-radio label="Self-Employed" />
                </el-radio-group>
              </label>
            </el-form-item>
            <br>
            <el-form-item
              prop="isEmploymentPaid"
            >
              <label class="label">
                <div style="margin-bottom: 5px;">* Was the Employment Paid?</div>
                <el-radio-group v-model="item.isEmploymentPaid" @change="SaveFormData('',item.index)">
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </label>
            </el-form-item>

            <el-form-item
              prop="isStillEmployed"
            >
              <label class="label">
                <div style="margin-bottom: 5px;">* Are you still employed at this company?</div>
                <el-radio-group v-model="item.isStillEmployed" @change="isStillEmployedChange(item.index)">
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </label>
            </el-form-item>

            <el-row :gutter="20">
              <el-col
                :xs="24" :sm="24" :md="12" :lg="12"
                :xl="12"
              >
                <el-form-item
                  prop="dateCommenced"
                >
                  <label class="label">
                    <div style="margin-bottom: 5px;">* When did you start working for this employer?</div>
                    <el-date-picker
                      v-model="item.dateCommenced" :picker-options="pickerOptions" type="month" format="MM/yyyy"
                      value-format="yyyy-MM-dd"
                      @change="SaveFormData('',item.index)"
                    />
                  </label>
                </el-form-item>
              </el-col>
              <el-col
                v-if="item.isStillEmployed!=='Yes'"
                :xs="24" :sm="24" :md="12" :lg="12"
                :xl="12"
              >
                <el-form-item
                  prop="dateEnded"
                >
                  <label class="label">
                    <div style="margin-bottom: 5px;">* Are you still employed at this company?</div>
                    <el-date-picker
                      v-model="item.dateEnded" type="month" :picker-options="pickerOptions" format="MM/yyyy"
                      value-format="yyyy-MM-dd"
                      @change="SaveFormData('',item.index)"
                    />
                  </label>
                </el-form-item>
              </el-col>
            </el-row>

            <div v-if="item.isStillEmployed==='No'">
              <el-form-item
                prop="isStillInBusiness"
              >
                <label class="label">
                  <div style="margin-bottom: 5px;">* Is the Employer still in business?</div>
                  <el-radio-group v-model="item.isStillInBusiness" @change="SaveFormData('',item.index)">
                    <el-radio label="Yes" />
                    <el-radio label="No" />
                  </el-radio-group>
                </label>
              </el-form-item>
            </div>

            <div v-if="item.isStillEmployed==='Yes'">
              <el-form-item
                prop="canContactEmployer"
              >
                <label class="label">
                  <div style="margin-bottom: 5px;">* Can we contact this Employer to verify your Employment?</div>
                  <el-radio-group v-model="item.canContactEmployer" @change="SaveFormData('',item.index)">
                    <el-radio label="Yes" />
                    <el-radio label="No" />
                  </el-radio-group>
                </label>
              </el-form-item>
            </div>

            <el-divider />

            <div class="section-header">Employer / Business Address Details</div>

            <el-row v-if="!item.isBusinessAddressManual" :gutter="20">
              <el-col
                :xs="24" :sm="24" :md="12" :lg="12"
                :xl="12"
              >
                <el-form-item prop="businessfullAddress">
                  <label class="label">
                    <div style="margin-bottom: 5px;">* City / Suburb</div>
                    <AddressAutocomplete
                      :initialaddress.sync="item.businessfullAddress"
                      :placeholder="'Start typing and pause to select from the list'"
                      :use-google="true"
                      :google-types="'(cities)'"
                      from="EmploymentHistory"
                      @OnDetailReady="AddressAutocompleteOnDetailReady"
                      @OnClear="AddressAutocompleteOnClear"
                      @OnManualEntry="AddressAutocompleteOnManualEntry"
                      @OnNoSelectionMade="AddressAutocompleteOnNoSelectionMade"
                    />
                  </label>
                </el-form-item>
              </el-col>
            </el-row>

            <div v-else>
              <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                  <el-form-item prop="businessLocality">
                    <label class="label">
                      <div style="margin-bottom: 5px;">* City / Suburb</div>
                      <el-input
                        v-model="item.businessLocality" @input="SaveFormData('',item.index)"
                        @change="refreshBusinessFullAddress(item.index)"
                      />
                    </label>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                  <el-form-item prop="businessState">
                    <label class="label">
                      <div style="margin-bottom: 5px;">* State</div>
                      <el-input
                        v-model="item.businessState" @input="SaveFormData('',item.index)"
                        @change="refreshBusinessFullAddress(item.index)"
                      />
                    </label>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                  <el-form-item rop="businessCountry">
                    <label class="label">
                      <div style="margin-bottom: 5px;">* Employer / Business Country</div>
                      <el-select
                        v-model="item.businessCountry" popper-class="dropdownClass"
                        @change="refreshBusinessFullAddress(item.index)" @input="SaveFormData('',item.index)"
                      >
                        <el-option
                          v-for="(item,index) in countrySelectionOptions" :key="index" :value="item.countryName"
                          :label="item.countryName"
                        />
                      </el-select>
                    </label>
                  </el-form-item>
                </el-col>
              </el-row>

            </div>

            <el-form-item prop="employerPhone">
              <label class="label">
                <div style="margin-bottom: 5px;">* Employer Phone Number (Business, landline or mobile)
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="If you are unsure of your Employer Phone Number please enter &quot;N/A&quot;"
                    :placement="isMobile ? 'top' : 'right'"
                  >
                    <i class="el-icon-info" />
                  </el-tooltip>
                </div>
                <el-input v-model="item.employerPhone" maxlength="20" @input="SaveFormData('',item.index)" />
              </label>
            </el-form-item>

            <el-form-item
              prop="payrollHrEmail"
            >
              <label class="label">
                <div style="margin-bottom: 5px;"><span v-if="!item.isPayrollHREmailUnknown" class="color-red">*</span>
                  Email Address
                  (Payroll / Human Resources)
                </div>
                <el-input
                  v-model="item.payrollHrEmail"
                  :disabled="item.isPayrollHREmailUnknown"
                  @input="SaveFormData('',item.index)"
                />
              </label>
            </el-form-item>

            <div class="email-check">
              <label class="label">
                <el-checkbox
                  v-model="item.isPayrollHREmailUnknown"
                  @change="isPayrollHREmailUnknownChange(item.index,$event)"
                >Email Unknown?
                </el-checkbox>
              </label>
            </div>

            <el-form-item prop="positionTitle">
              <label class="label">
                <div style="margin-bottom: 5px;">* What was the title of your position under your Employer?
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="If you are unsure of your position please enter &quot;N/A&quot;"
                    :placement="isMobile ? 'top' : 'right'"
                  >
                    <i class="el-icon-info" />
                  </el-tooltip>
                </div>
                <el-input v-model="item.positionTitle" maxlength="150" @input="SaveFormData('',item.index)" />
              </label>
            </el-form-item>

            <el-form-item prop="employeeId">
              <label class="label">
                <div style="margin-bottom: 5px;">*
                  {{ item.typeOfEmployment !== 'Self-Employed' ? ('What ' + (item.isStillEmployed === 'Yes' ? 'is ' : 'was ') + 'your Employee Number / ID?') : 'What is the ABN of your business?'
                  }}
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="If you are unsure of your Employee Number/ID please enter &quot;N/A&quot;"
                    :placement="isMobile ? 'top' : 'right'"
                  >
                    <i class="el-icon-info" />
                  </el-tooltip>
                </div>
                <el-input v-model="item.employeeId" :maxlength="50" @input="SaveFormData('',item.index)" />
              </label>
            </el-form-item>

            <el-form-item
              v-if="item.isStillEmployed!=='Yes'"
              prop="reasonForLeaving"
              style="margin-bottom: 0"
            >
              <label class="label">
                <div style="margin-bottom: 5px;">* Please select your reason for leaving:</div>
                <el-radio-group v-model="item.reasonForLeaving" @change="SaveFormData('',item.index)">
                  <el-radio label="Contract Ended" />
                  <el-radio label="Resignation" />
                  <!-- If(UseEmploymentDismissal, "Dismissal", "Termination") -->
                  <el-radio label="Dismissal" />
                  <el-radio label="Redundancy" />
                </el-radio-group>
              </label>
            </el-form-item>

            <el-divider />

            <el-form-item
              prop="isPaidByRecruitmentAgency"
            >
              <label class="label">
                <div style="margin-bottom: 5px;">* Were you paid by a Recruitment Agency?</div>
                <el-radio-group
                  v-model="item.isPaidByRecruitmentAgency"
                  @change="isPaidByRecruitmentAgencyChange(item.index)"
                >
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </label>
            </el-form-item>

            <template v-if="item.isPaidByRecruitmentAgency === 'Yes'">
              <el-form-item
                prop="recruitmentAgencyName"
              >
                <label class="label">
                  <div style="margin-bottom: 5px;">* Recruitment Agency Name</div>
                  <el-input v-model="item.recruitmentAgencyName" @input="SaveFormData('',item.index)" />
                </label>
              </el-form-item>

              <el-row :gutter="20">
                <el-col
                  :xs="24" :sm="24" :md="12" :lg="12"
                  :xl="12"
                >
                  <el-form-item
                    prop="recruitmentAgencyPhone"
                  >
                    <label class="label">
                      <div style="margin-bottom: 5px;">* Recruitment Agency Phone</div>
                      <el-input
                        v-model="item.recruitmentAgencyPhone" maxlength="20"
                        @input="SaveFormData('',item.index)"
                      />
                    </label>
                  </el-form-item>
                </el-col>
                <el-col
                  :xs="24" :sm="24" :md="12" :lg="12"
                  :xl="12"
                >
                  <el-form-item
                    prop="recruitmentAgencyEmail"
                  >
                    <label class="label">
                      <div style="margin-bottom: 5px;"><span
                        v-if="!item.isRecruitmentAgencyEmailUnknown"
                        class="color-red"
                      >*</span>
                        Recruitment Agency Email
                      </div>
                      <el-input
                        v-model="item.recruitmentAgencyEmail"
                        :disabled="item.isRecruitmentAgencyEmailUnknown"
                        @input="SaveFormData('',item.index)"
                      />
                    </label>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" type="flex" justify="end">
                <el-col
                  :xs="24" :sm="24" :md="12" :lg="12"
                  :xl="12"
                >
                  <div class="email-check">
                    <label class="label">
                      <el-checkbox
                        v-model="item.isRecruitmentAgencyEmailUnknown"
                        @change="isRecruitmentAgencyEmailUnknownChange(item.index,$event)"
                      >
                        Email Unknown?
                      </el-checkbox>
                    </label>
                  </div>
                </el-col>
              </el-row>
            </template>
          </template>

          <template v-if="item.employmentOrGap == 'Gap'">
            <el-form-item
              prop="gapReason"
              style="margin-bottom: 0"
            >
              <label class="label">
                <div style="margin-bottom: 5px;">* Explanation for the gap:</div>
                <el-radio-group v-model="item.gapReason" class="typeEmp" @change="SaveFormData('',item.index)">
                  <el-radio label="Parental Leave" />
                  <el-radio label="Career Break" />
                  <el-radio label="Travel" />
                  <el-radio label="Unemployed" />
                  <el-radio label="Caring for Family" />
                  <el-radio label="Study" />
                </el-radio-group>
              </label>
            </el-form-item>
            <br>
            <el-row :gutter="20">
              <el-col
                :xs="24" :sm="24" :md="12" :lg="12"
                :xl="12"
              >
                <el-form-item
                  prop="dateCommenced"
                >
                  <label class="label">
                    <div style="margin-bottom: 5px;">* When did your gap commence?</div>
                    <el-date-picker
                      v-model="item.dateCommenced" :picker-options="pickerOptions" type="month" format="MM/yyyy"
                      value-format="yyyy-MM-dd"
                      @change="SaveFormData('',item.index)"
                    />
                  </label>
                </el-form-item>
              </el-col>
              <el-col
                :xs="24" :sm="24" :md="12" :lg="12"
                :xl="12"
              >
                <el-form-item
                  prop="dateEnded"
                >
                  <label class="label">
                    <div style="margin-bottom: 5px;">* When did your gap conclude?</div>
                    <el-date-picker
                      v-model="item.dateEnded" :picker-options="pickerOptions" type="month" format="MM/yyyy"
                      value-format="yyyy-MM-dd"
                      @change="SaveFormData('',item.index)"
                    />
                  </label>
                </el-form-item>
              </el-col>
            </el-row>
          </template>

          <el-form-item v-if="!allDisabled">
            <div class="action-box">
              <div
                class="action-item"
                @click="handleAdd(`addForm${item.index}`, 1, item)"
              >
                <i class="el-icon-circle-plus" />
                <span class="action-value">Add New Item</span>
              </div>
              <div class="action-item" @click="handleRemove(item.index, item)">
                <i class="el-icon-delete-solid" />
                <span class="action-value">Remove {{ item.employmentOrGap || 'Item' }}</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <p v-if="compliancesList.length === 0">Please select "Add New Item" to begin</p>
    <div v-if="outAddBtnShow && !allDisabled" class="action-box" @click="handleAdd('', 2)">
      <div class="action-item">
        <i class="el-icon-circle-plus" />
        <span class="action-value f-s-14">Add New Item</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ReopenNotes {
  color: #fff;
  background-color: #e9a100 !important;
  padding: 20px 10px !important;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin: 10px 0;

  .el-icon-warning {
    font-size: 24px;
  }
}

.label {
  font-size: 14px;
  color: #606266;
  margin: 0 0 10px;
  line-height: 1.5;
}

.employmentOrGapForm {
  .typeEmp {
    .el-radio {
      width: 45%;
      margin: 10px 0;
    }
  }
}

.email-check {
  margin: 0 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .label {
    margin: 0 0 0 6px;
  }
}

.action-box {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  color: #206caa;

  .action-item {
    cursor: pointer;
  }

  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }

  .action-value {
    margin-left: 10px;
  }
}

.dropdownClass .el-select-dropdown__item {
  width: 90vw !important;
  max-width: 500px;
}
</style>
