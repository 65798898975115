var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "EntitlementToWorkView",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "50%",
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "label",
                  attrs: { xs: 24, sm: 24, md: 24, lg: 19, xl: 19 },
                },
                [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _vm._v(" Are you an Australian Citizen? "),
                ]
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 5, xl: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { prop: "isAusCitizen" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.isAusCitizenChange },
                          model: {
                            value: _vm.ruleForm.isAusCitizen,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "isAusCitizen", $$v)
                            },
                            expression: "ruleForm.isAusCitizen",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.CitizenshipShow
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "selectedCitizenshipCountryCode" } },
                        [
                          _c(
                            "label",
                            { staticClass: "label" },
                            [
                              _vm._v("* What is your Country of Citizenship? "),
                              _c("GetAllGeoCountries", {
                                attrs: {
                                  disabled: _vm.allDisabled,
                                  "defalut-value":
                                    _vm.ruleForm.selectedCitizenshipCountryCode,
                                },
                                on: {
                                  "update:defalutValue": function ($event) {
                                    return _vm.$set(
                                      _vm.ruleForm,
                                      "selectedCitizenshipCountryCode",
                                      $event
                                    )
                                  },
                                  "update:defalut-value": function ($event) {
                                    return _vm.$set(
                                      _vm.ruleForm,
                                      "selectedCitizenshipCountryCode",
                                      $event
                                    )
                                  },
                                  getCountryData:
                                    _vm.OnCitizenshipCountryChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.PassportBirthCertShow
            ? _c(
                "div",
                [
                  _c("el-row", { attrs: { gutter: 20 } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "label",
                            attrs: { xs: 24, sm: 24, md: 24, lg: 19, xl: 19 },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "* Do you hold a current Australian Passport?"
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 24, lg: 5, xl: 5 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "0" },
                                attrs: { prop: "hasPassport" },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: { change: _vm.hasPassportOnChange },
                                    model: {
                                      value: _vm.ruleForm.hasPassport,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "hasPassport",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.hasPassport",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "Yes" } }),
                                    _c("el-radio", { attrs: { label: "No" } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.hasPassportTrueShow
                    ? _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Passport Number",
                                    prop: "passportNumber",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    on: { input: _vm.passportNumberChange },
                                    model: {
                                      value: _vm.ruleForm.passportNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "passportNumber",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.passportNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Passport Country",
                                    prop: "selectedPassportCountryCode",
                                  },
                                },
                                [
                                  _c("GetAllGeoCountries", {
                                    attrs: {
                                      disabled: _vm.allDisabled,
                                      "defalut-value":
                                        _vm.ruleForm
                                          .selectedPassportCountryCode,
                                    },
                                    on: {
                                      "update:defalutValue": function ($event) {
                                        return _vm.$set(
                                          _vm.ruleForm,
                                          "selectedPassportCountryCode",
                                          $event
                                        )
                                      },
                                      "update:defalut-value": function (
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.ruleForm,
                                          "selectedPassportCountryCode",
                                          $event
                                        )
                                      },
                                      getCountryData:
                                        _vm.OnPassportCountryChange,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasPassportFalseShow
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "f-w-600",
                          attrs: {
                            label:
                              "Please attach a legible copy of your Birth Certificate or Citizenship Certificate",
                            prop: "selectedCertificateDocumentType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.OnSelectCertificateType },
                              model: {
                                value:
                                  _vm.ruleForm.selectedCertificateDocumentType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "selectedCertificateDocumentType",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.selectedCertificateDocumentType",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "Birth" } },
                                [_vm._v("Birth Certificate")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "Citizenship" } },
                                [_vm._v("Citizenship Certificate")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.uploadShow
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label f-w-600",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    },
                    [
                      _vm._v(
                        " Please attach a legible copy of your Australian Passport "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    [
                      _vm.orderApplicantDocuments.length < 1
                        ? _c("OnlyUpload", {
                            on: { Document_Upload: _vm.Document_Upload },
                          })
                        : _vm._e(),
                      _vm._l(_vm.orderApplicantDocuments, function (item) {
                        return _c("OnlyUpload", {
                          key: item.id,
                          attrs: { "img-item": item },
                          on: {
                            Document_Upload: _vm.Document_Upload,
                            Document_Remove: _vm.Document_Remove,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _vm.ContinueBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.endAllRequests, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.Continue("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.buttonText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }