import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/auth/applicant' : '/proxy/api/auth/applicant'
const refreshToken = process.env.NODE_ENV !== 'development' ? '/api/auth' : '/proxy/api/auth'

export function refreshTokenApi(data) {
  return service({
    url: `${refreshToken}/refresh-token`,
    method: 'post'
  })
}

export function loginApi(data) {
  return service({
    url: `${api}/login`,
    method: 'post',
    data
  })
}

export function passwordLoginApi(data) {
  return service({
    url: `${api}/password-login`,
    method: 'post',
    data
  })
}

export function registerApi(data) {
  return service({
    url: `${api}/register`,
    method: 'post',
    data
  })
}

export function tokenLoginApi(data) {
  return service({
    url: `${api}/token-login?token=${data.token}`,
    method: 'post',
    data
  })
}

export function loginPreparationApi(data) {
  return service({
    url: `${api}/login/preparation`,
    method: 'post',
    data
  })
}
