<script>
import Header from './components/Header/index.vue'
import Sider from './components/Sider/index.vue'
import ResizeMixin from './mixin/ResizeHandler'
import Select from '@/views/Select/index.vue'
import { mapState } from 'vuex'
export default {
  name: 'LayoutView',
  components: { Header, Select, Sider },
  mixins: [ResizeMixin],
  provide() {
    return {
      closeNav: this.closeNav
    }
  },
  data() {
    return {
      showNav: false,
      Intro: ''
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device,
      ApplicantId: (state) => state.preparation.ApplicantId
    }),
    classObj() {
      return {
        mobile: this.device === 'mobile'
      }
    },
    Session() {
      return this.$getPreparation().session
    }
  },
  methods: {
    navMobile(val) {
      this.showNav = val
    },
    closeNav() {
      this.showNav = false
    }
  }
}
</script>

<template>
  <div :class="classObj" class="app-wrapper">
    <Header class="app-header" @navMobile="navMobile" />
    <div class="app-main">
      <div class="app-title">
        <h1>Background Check</h1>
      </div>
      <section class="container">
        <div class="checks">
          <Select />
        </div>
      </section>
      <footer class="app-footer">
        Copyright 2024 PreciseBackground.com. All rights reserved.
      </footer>
    </div>

    <div :class="showNav ? 'block' : 'none'">
      <div class="nav-box">
        <div class="nav">
          <Sider from-page="select" />
        </div>
        <div class="blank" @click="closeNav" />
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.block {
  display: block !important;
}
.none {
  display: none;
}
.app-wrapper {
  height: 100%;
  width: 100%;
  background-color: #ededed;
  position: relative;
  .app-header {
    position: fixed;
    z-index: 1;
  }
  .app-main {
    background-color: #ededed;
    padding-top: 130px;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    .app-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .checks {
        width: 100%;
      }
      .base-nav {
        width: 28%;
        .sider {
          background-color: #fff;
          padding: 20px 10px;
        }
        .Previous-Next {
          margin: 6px 0;
          display: flex;
          justify-content: space-between;
          color: #206caa;
          span {
            cursor: pointer;
          }
        }
      }
    }
  }
  .app-footer {
    text-align: center;
    font-size: 14px;
    padding: 30px;
  }
}
.mobile {
  .app-main {
    padding: 120px 20px 0 20px;
    .app-title {
      display: block;
      .ref {
        text-align: right;
      }
    }
    .container {
      .checks {
        width: 95% !important;
      }
      .base-nav {
        display: none;
      }
    }
  }
  .nav-box {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    .nav {
      overflow: auto;
      width: 70%;
      background-color: #fff;
      padding: 30px 0;
    }
    .blank {
      width: 30%;
      background-color: gray;
      opacity: 0.5;
    }
  }
}
</style>
