import { getToken, setToken, removeToken, setTokenTime, removeTokenTime } from '@/utils/auth'
import { passwordLoginApi, refreshTokenApi } from '@/api/user'
import store from '@/store'
const state = {
  id: '',
  token: getToken()
}

const mutations = {
  SET_TOKEN: (state, token) => {
    setTokenTime(new Date().getTime())
    state.token = token
  },

  SET_ID: (state, id) => {
    state.id = id
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      passwordLoginApi(userInfo).then(res => {
        const { data } = res
        setToken(data.accessToken)
        commit('SET_TOKEN', data.accessToken)
        window.sessionStorage.setItem('lastTime', new Date().getTime())
        resolve()
      }).catch(() => {
        reject()
      })
    })
  },

  // resresh token time
  resreshTokentime({ commit }) {
    refreshTokenApi().then(res => {
      const { data } = res
      if (data.accessToken) {
        setTokenTime(new Date().getTime())
        setToken(data.accessToken)
        commit('SET_TOKEN', data.accessToken)
      }
    })
  },

  setToken({ commit }, accessToken) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', accessToken)
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeTokenTime()
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },

  logOut({ commit }) {
    return new Promise((resolve) => {
      store.dispatch('user/resetToken')
      store.dispatch('preparation/resetPreparation')
      store.dispatch('section/reset_section')
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
