<script>
import { addressHistoryApi } from '@/api/pattern'
export default {
  name: 'AddressHistory',
  props: {
    applicant: {
      type: Object,
      default: () => {
        return {
          fullAddress: '',
          currentAddressFromDate: ''
        }
      }
    },
    applicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      previousAddresses: [],
      moduleLoading: false
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      await addressHistoryApi({ applicantId: this.applicantId }).then(res => {
        this.previousAddresses = res.data.getPreviousAddressesByApplicantId
        this.previousAddresses.reverse()
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="ComplianceReference">
    <div>
      <h3 v-if="previousAddresses.length > 0">Current Address</h3>
      <div>
        <div style="margin-top:20px">Full Address</div>
        <div class="box"> <p>{{ applicant.fullAddress }}</p></div>
      </div>
      <div>
        <div style="margin-top:20px">From Date</div>
        <div>
          <el-date-picker
            disabled
            :value="applicant.currentAddressFromDate"
            type="date"
            format="dd/MM/yyyy"
          />
        </div>
      </div>
    </div>
    <div v-if="previousAddresses.length">
      <div>
        <h3>Previous Addresses</h3>
        <div v-for="(item,index) in previousAddresses" :key="index" class="box">
          <p>{{ item.fullAddress }} ({{ item.fromDate | parseDefaultTime("{d}/{m}/{y}") }} - {{ item.toDate | parseDefaultTime("{d}/{m}/{y}") }})</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  min-height: 40px;
  padding: 0 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  cursor: no-drop;
  p {
    font-size: 14px;
    margin: 0;
  }
}
.blod {
  font-weight: bold;
}
.selectWidth {
  display: block;
}
</style>
