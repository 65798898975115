var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "ConfirmationView",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "div",
        { staticClass: "mb-20 Confirmation" },
        [
          _c(
            "el-collapse",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.preparationData, function (item) {
              return _c(
                "el-collapse-item",
                { key: item.index, attrs: { name: item.blockName } },
                [
                  _c("template", { slot: "title" }, [
                    _vm.isComplete(item.blockName)
                      ? _c("i", {
                          staticClass: "fa fa-check-circle completePercent100",
                        })
                      : _c("i", {
                          staticClass: "fa fa-circle-o completePercent0",
                        }),
                    _c("span", { staticClass: "ml-10" }, [
                      _vm._v(_vm._s(_vm.getTitle(item.blockName))),
                    ]),
                  ]),
                  _vm.isComplete(item.blockName)
                    ? _c(item.blockName, {
                        tag: "component",
                        attrs: {
                          applicant: _vm.Applicant,
                          "applicant-id": _vm.ApplicantId,
                          "order-applicant-id": _vm.orderApplicantId,
                          "section-id": item.id,
                          "minimum-quantity": _vm.minimumQuantity,
                          from: "applicant",
                        },
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "cursor-pointer color-blue",
                          on: {
                            click: function ($event) {
                              return _vm.getPath(item.blockName)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " This section is not yet complete. Please complete it before continuing. "
                          ),
                        ]
                      ),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "electronic-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "left",
            "label-width": "70%",
            "hide-required-asterisk": "",
            disabled: _vm.allDisabled,
          },
        },
        [
          _vm.ShowLodgement
            ? _c(
                "el-form-item",
                { attrs: { prop: "electronicLodgementAgreed" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v(
                      "I agree to the electronic lodgement of my personal information"
                    ),
                  ]),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.electronicLodgementAgreed,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "electronicLodgementAgreed",
                            $$v
                          )
                        },
                        expression: "ruleForm.electronicLodgementAgreed",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "Yes" } }),
                      _c("el-radio", { attrs: { label: "No" } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "lodgement-confirmation" }, [
                _vm._v(
                  "Please check above and finish all the sections before continuing."
                ),
              ]),
        ],
        1
      ),
      _vm.ShowContinueBtn
        ? _c(
            "el-button",
            {
              staticClass: "mt-30",
              attrs: { type: "primary" },
              on: { click: _vm.openDialog },
            },
            [_vm._v(_vm._s(_vm.buttonText))]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Confirm and Continue",
            visible: _vm.dialogVisible,
            width: _vm.device === "mobile" ? "90%" : "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              "You will be unable to modify this information. Are you sure you want to CONFIRM and CONTINUE?"
            ),
          ]),
          _vm.device === "mobile" ? _c("el-divider") : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  class: _vm.device === "mobile" ? "width100" : "",
                  on: { click: _vm.Cancel },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  class: _vm.device === "mobile" ? "width100" : "",
                  attrs: { type: "primary" },
                  on: { click: _vm.Continue },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }