import { setSection, removeSection, getSection } from '@/utils/section'
import { getPreparation } from '@/utils/preparation'

const state = {
  section: '',
  introduction: '',
  sectionTitle: ''
}

const mutations = {
  SET_SECTION: (state, section) => {
    state.section = section
  },
  SET_INTRODUCTION: (state) => {
    state.introduction = getPreparation().session.current.formSection.introduction.replace(/\n/g, '<br />')
  },
  SET_SECTIONTITLE: (state) => {
    state.sectionTitle = getPreparation().session.current.formSection.title
  }
}

const actions = {
  set_section({ commit }, data) {
    if (data.type === 'Initialize' && getSection()) {
      commit('SET_SECTION', getSection())
    } else {
      setSection(data.section)
      commit('SET_SECTION', data.section)
    }
    commit('SET_INTRODUCTION')
    commit('SET_SECTIONTITLE')
  },

  reset_section({ commit }) {
    return new Promise((resolve) => {
      commit('SET_SECTION', '')
      removeSection()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
