var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "welcome",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introductionShow) },
      }),
      _c(
        "div",
        { staticClass: "documnet-box" },
        [
          _vm.adobeDocumentList
            ? _c(
                "div",
                { staticClass: "documnet-box" },
                [
                  _c("el-divider"),
                  _c("h4", [_vm._v("Signed Documents")]),
                  _vm._l(_vm.adobeDocumentList, function (item, index) {
                    return _c("div", { key: index }, [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _c("span", {
                          staticClass: "fa fa-download",
                          staticStyle: {
                            margin: "8px 10px 8px 5px",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.downloadFile(item)
                            },
                          },
                        }),
                        _c("span", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                      ]),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _c("el-divider"),
          _c("h4", [_vm._v("Additional Documents")]),
          _c("DocumnetGalleryUpload", {
            attrs: {
              "modal-show": _vm.modalShow,
              "img-list": _vm.getOrderApplicantDocumentsByOrderApplicantId,
              "thank-you-document-id-list": _vm.thankYouDocumentIdList,
            },
            on: {
              "update:modalShow": function ($event) {
                _vm.modalShow = $event
              },
              "update:modal-show": function ($event) {
                _vm.modalShow = $event
              },
              Document_Upload: _vm.Document_Upload,
              Document_Remove: _vm.Document_Remove,
            },
          }),
        ],
        1
      ),
      _vm.IsShowRating
        ? _c("div", { staticClass: "img-box" }, [
            _c("div", { staticClass: "evaluate-box" }, [
              _vm.IsSubmitted
                ? _c("p", { staticClass: "title text-style f-w-600" }, [
                    _vm._v(
                      " Was this online experience user friendly? Rate your experience! "
                    ),
                  ])
                : _c("p", { staticClass: "title text-style f-w-600" }, [
                    _vm._v(" Thank you for your feedback! "),
                  ]),
              _vm.IsSubmitted
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "evaluate-box-2" },
                      _vm._l(_vm.evaluateImg, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.title,
                            class:
                              _vm.device === "mobile"
                                ? "evaluate-item-mobile"
                                : "evaluate-item",
                          },
                          [
                            !_vm.isShow(item.clickedRating)
                              ? _c("img", {
                                  staticClass: "img",
                                  attrs: { src: item.src, alt: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handle(item.clickedRating)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.isShow(item.clickedRating)
                              ? _c("img", {
                                  staticClass: "img",
                                  attrs: { src: item.color, alt: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handle(item.clickedRating)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("p", [_vm._v(_vm._s(item.title))]),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.Rating
                      ? _c("div", [
                          _c("p", { staticClass: "f-w-600" }, [
                            _vm._v("Thank you!"),
                          ]),
                          _vm.IsNotesEnabled
                            ? _c(
                                "div",
                                [
                                  _c("p", { staticClass: "f-w-600" }, [
                                    _vm._v(
                                      " Your feedback is valuable, tell us how we can improve? "
                                    ),
                                  ]),
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 7,
                                      placeholder: "Leave a comment here...",
                                    },
                                    model: {
                                      value: _vm.CSATNotes,
                                      callback: function ($$v) {
                                        _vm.CSATNotes = $$v
                                      },
                                      expression: "CSATNotes",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-top": "20px" },
                                      attrs: { type: "primary" },
                                      on: { click: _vm.Continue },
                                    },
                                    [_vm._v("Submit")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.Logout } },
        [_vm._v("Logout")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }