<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import OnlyUpload from '@/components/OnlyUpload'
import GetAllGeoCountries from '@/components/GetAllGeoCountries'
import { getAllGeoCountriesApi } from '@/api/application'
import { preparationApi, onCitizenShipCountryChangeApi, onDualCitizenChangeApi,
  onCitizenShipDualCountryChangeApi, hasPassportOnChangeApi, onPassportCountryChangeApi,
  onCertificateDocumentTypeChangeApi, uploadApi, uploadOtherNamApi,
  onOtherNameDocumentTypeChangeApi, removeDocumentApi, continueApi } from '@/api/EntitlementToWork2'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'EntitlementToWorkView',
  components: { OnlyUpload, GetAllGeoCountries },
  inject: ['ContinueSetSession', 'OnApplicantChange', 'SetApplicant', 'SaveFormDataSetSession'],
  data() {
    const validateOrderApplicantDocuments = (rule, value, callback) => {
      if (this.orderApplicantDocuments && this.orderApplicantDocuments.length) {
        callback()
      } else {
        callback(new Error('Please upload the relevant Document before proceeding'))
      }
    }
    const validateOtherNameDocuments = (rule, value, callback) => {
      if (this.ruleForm.selectedOtherNameDocumentType && !this.otherNameDocuments.length) {
        callback(new Error('Please upload supporting documents for your name change before continuing'))
      } else {
        callback()
      }
    }
    return {
      dualCitizen: '',

      isCertificateTypeSelected: false,

      ausCitizen: '',
      showOtherNameDocument: false,
      uploadCertificateDocumentTypeId: '',
      otherNameDocumentTypeId: '',
      otherNameDocuments: [],
      orderApplicantDocuments: [],
      birthCertificateDocumentTypeId: '',
      citizenshipCertDocumentTypeId: '',
      selectedPassportCountryCode: '',

      ruleForm: {
        selectedCitizenshipCountryCode: '',
        dualCitizen: '',
        selectedCitizenshipDualCountryCode: '',
        hasPassport: '',
        passportNumber: '',
        selectedPassportCountryCode: '',
        selectedCertificateDocumentType: '',
        selectedOtherNameDocumentType: ''
      },
      rules: {
        selectedCitizenshipCountryCode: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        dualCitizen: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        selectedCitizenshipDualCountryCode: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        hasPassport: [{ required: true, message: 'Please select Yes or No', trigger: 'change' }],
        passportNumber: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        selectedPassportCountryCode: [{ validator: this.validateSelectedPassportCountryCode, message: 'Please select a value', trigger: 'change' }],
        selectedCertificateDocumentType: [{ required: true, message: 'Please select the Certificate type', trigger: 'change' }],
        orderApplicantDocuments: [{ type: 'array', required: true, validator: validateOrderApplicantDocuments, trigger: 'change' }],
        otherNameDocuments: [{ type: 'array', required: true, validator: validateOtherNameDocuments, trigger: 'change' }]
      },
      allLoading: false,
      geoCountries: []
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    BlockName() {
      return this.preparation.session.current.formSection.blockName
    },
    PassportCountryShow() {
      // AusCitizen = "No"
      return this.ausCitizen === 'No'
    },
    PassportShow() {
      // SelectedCitizenshipCountryCode <> "" and (DualCitizen = "No" or (DualCitizen = "Yes" and SelectedCitizenshipDualCountryCode <> ""))
      return this.ruleForm.selectedCitizenshipCountryCode !== '' && (this.ruleForm.dualCitizen === 'No' || (this.ruleForm.dualCitizen === 'Yes' && this.ruleForm.selectedCitizenshipDualCountryCode))
    },
    BirthOrCitizenshipCertShow() {
      // DualCitizen = "No" or (DualCitizen = "Yes" and SelectedCitizenshipDualCountryCode <> "")
      return this.ruleForm.dualCitizen === 'No' || (this.ruleForm.dualCitizen === 'Yes' && this.ruleForm.selectedCitizenshipDualCountryCode)
    },
    DocumentShow() {
    // SelectedCitizenshipCountryCode <> "" and (DualCitizen = "No" or (DualCitizen = "Yes" and SelectedCitizenshipDualCountryCode <> ""))
      return this.ruleForm.selectedCitizenshipCountryCode && (this.ruleForm.dualCitizen === 'No' || (this.ruleForm.dualCitizen === 'Yes' && this.ruleForm.selectedCitizenshipDualCountryCode))
    },
    DocumentShow2() {
      // HasPassport = "Yes" or (HasPassport = "No" and SelectedCertificateDocumentType <> "")
      return (this.ruleForm.hasPassport === 'Yes') || (this.ruleForm.hasPassport === 'No' && this.ruleForm.selectedCertificateDocumentType)
    },
    OtherNameDocumentShow() {
      return this.showOtherNameDocument
    },
    hasPassportDisabled() {
      // OrderApplicantDocuments.Length = 0 or (OrderApplicantDocuments.Length <> 0 and HasPassport = "")
      return !(this.orderApplicantDocuments.length === 0 || (this.orderApplicantDocuments.length !== 0 && !this.ruleForm.hasPassport))
    },
    passportNumberDisabled() {
      return this.orderApplicantDocuments.length > 0
    },
    passportDocumentShow() {
      return this.ruleForm.passportNumber && this.ruleForm.selectedPassportCountryCode
    },
    selectedCertificateDocumentTypeDisabled() {
      return this.orderApplicantDocuments.length !== 0
    },
    selectedOtherNameDocumentTypeDisabled() {
      return this.otherNameDocuments.length !== 0
    },
    buttonText() {
      // If(Session.Current.FormSection.Index < (Session.FormSections.Length - 1), "Save and Continue", "Submit")
      return this.Session.current.formSection.index <
        this.Session.formSections.length - 1
        ? 'Save and Continue'
        : 'Submit'
    },
    imgcanDelete() {
      // (not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100) or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100) || this.Session.current.isAdmin
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    validateSelectedPassportCountryCode(rule, value, callback) {
      if (this.ruleForm.hasPassport === 'Yes' && this.ausCitizen === 'No') {
        if (this.ruleForm.selectedPassportCountryCode === '') {
          callback(new Error('Required'))
        }
      }
      callback()
    },
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'EntitlementToWork2')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        applicant: this.Applicant
      }

      await preparationApi(params).then(async(res) => {
        const { data } = res
        this.showOtherNameDocument = data.showOtherNameDocument
        this.ausCitizen = data.ausCitizen
        this.otherNameDocuments = data.otherNameDocuments ? data.otherNameDocuments : []
        this.birthCertificateDocumentTypeId = data.birthCertificateDocumentTypeId
        this.citizenshipCertDocumentTypeId = data.citizenshipCertDocumentTypeId

        this.ruleForm.hasPassport = data.hasPassport
        this.ruleForm.passportNumber = this.Applicant.passportNumber || ''
        this.orderApplicantDocuments = data.orderApplicantDocuments ? data.orderApplicantDocuments : []
        this.ruleForm.selectedCitizenshipCountryCode = data.selectedCitizenshipCountryCode
        this.ruleForm.dualCitizen = data.dualCitizen
        this.ruleForm.selectedCitizenshipDualCountryCode = data.selectedCitizenshipDualCountryCode
        this.ruleForm.selectedPassportCountryCode = data.selectedPassportCountryCode
        this.selectedPassportCountryCode = data.selectedPassportCountryCode
        this.ruleForm.selectedCertificateDocumentType = data.selectedCertificateDocumentType
        this.ruleForm.selectedOtherNameDocumentType = data.selectedOtherNameDocumentType
        this.geoCountries = data.internationalCountries
        this.otherNameDocumentTypeId = data.otherNameDocumentTypeId
        if (!this.geoCountries.length) {
          await getAllGeoCountriesApi().then((res) => {
            const { data } = res
            this.geoCountries = data.geoCountries
          })
        }
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async OnCitizenshipCountryChange(data) {
      this.ruleForm.selectedCitizenshipCountryCode = data.countryCode

      this.ruleForm.hasPassport = ((this.ausCitizen === 'No' && this.ruleForm.selectedPassportCountryCode === 'AU') ||
      (this.ausCitizen === 'Yes' && this.ruleForm.selectedPassportCountryCode !== 'AU')) ? '' : this.ruleForm.hasPassport

      const applicant = this.Applicant

      applicant.citizenshipCountry = data.countryName
      applicant.citizenshipCountryCode = data.countryCode
      applicant.citizenshipCountryCode3 = data.isoAlpha3

      const params = {
        session: this.Session,
        applicant: applicant,
        selectedCitizenshipCountryCode: this.ruleForm.selectedCitizenshipCountryCode,
        selectedCitizenshipDualCountryCode: this.ruleForm.selectedCitizenshipDualCountryCode,
        selectedPassportCountryCode: this.ruleForm.selectedPassportCountryCode,
        hasPassport: this.ruleForm.hasPassport,
        isPassportValid: this.ruleForm.hasPassport !== ''
      }
      await onCitizenShipCountryChangeApi(params).then(res => {
        const { data } = res
        this.ausCitizen = data.ausCitizen
        this.ruleForm.hasPassport = data.hasPassport
        this.showOtherNameDocument = true
        this.SetApplicant(data.applicant)
      })
    },

    async OnDualCitizenChange() {
      const params = {
        session: this.Session,
        applicant: this.Applicant,
        dualCitizen: this.ruleForm.dualCitizen
      }
      params.applicant.isDualCitizenship = this.ruleForm.dualCitizen === 'Yes'
      params.applicant.isNotDualCitizenship = this.ruleForm.dualCitizen === 'No'
      if (this.ruleForm.dualCitizen === 'No') {
        params.applicant.citizenshipDualCountry = ''
        params.applicant.citizenshipDualCountryCode = ''
        params.applicant.citizenshipDualCountryCode3 = ''
        this.ruleForm.selectedCitizenshipDualCountryCode = ''
      }
      await onDualCitizenChangeApi(params).then(res => {
        const { data } = res
        // this.ruleForm.selectedCertificateDocumentType = data.selectedCertificateDocumentType
        this.SetApplicant(data.applicant)
        // this.OnApplicantChange({
        //   session: this.Session,
        //   applicant: data.applicant
        // })
      })
    },

    async OnCitizenshipDualCountryChange(data) {
      this.ruleForm.selectedCitizenshipDualCountryCode = data.countryCode

      this.ausCitizen = (this.ruleForm.selectedCitizenshipCountryCode === 'AU' || this.ruleForm.selectedCitizenshipDualCountryCode === 'AU') ? 'Yes' : 'No'

      this.ruleForm.hasPassport = ((this.ausCitizen === 'No' && this.ruleForm.selectedPassportCountryCode === 'AU') ||
      this.ausCitizen === 'Yes' && this.ruleForm.selectedPassportCountryCode !== 'AU') ? '' : this.ruleForm.hasPassport

      const params = {
        session: this.Session,
        applicant: this.Applicant,
        selectedCitizenshipCountryCode: this.ruleForm.selectedCitizenshipCountryCode,
        selectedCitizenshipDualCountryCode: this.ruleForm.selectedCitizenshipDualCountryCode,
        selectedPassportCountryCode: this.ruleForm.selectedPassportCountryCode,
        hasPassport: this.ruleForm.hasPassport,
        isPassportValid: this.ruleForm.hasPassport !== ''
      }

      await onCitizenShipDualCountryChangeApi(params).then(res => {
        const { data } = res
        this.ausCitizen = data.ausCitizen
        this.ruleForm.hasPassport = data.hasPassport
        this.SetApplicant(data.applicant)
        // this.OnApplicantChange({
        //   session: this.Session,
        //   applicant: data.applicant
        // })
      })
    },

    async OnHasPassportChange() {
      const Applicant = this.Applicant
      // if (this.ruleForm.hasPassport === 'Yes') {
      //   if (this.ruleForm.selectedPassportCountryCode !== '' && ((this.ausCitizen === 'No' && this.ruleForm.selectedPassportCountryCode === 'AU') ||
      //   this.ausCitizen === 'Yes' && this.ruleForm.selectedPassportCountryCode !== 'AU')) {
      //     Applicant.passportNumber = ''
      //     Applicant.passportCountry = ''
      //     Applicant.passportCountryCode = ''
      //     Applicant.passportCountryCode3 = ''
      //   } else {
      //     // If(HasPassport = "Yes" and AusCitizen = "Yes", "Australia", ApplicantForm.Record.PassportCountry)
      //     Applicant.passportCountry = (this.hasPassport === 'Yes' && this.ausCitizen === 'Yes') ? 'Australia' : Applicant.passportCountry
      //     Applicant.passportCountryCode = (this.hasPassport === 'Yes' && this.ausCitizen === 'Yes') ? 'AU' : Applicant.passportCountryCode
      //     Applicant.passportCountryCode3 = (this.hasPassport === 'Yes' && this.ausCitizen === 'Yes') ? 'AUS' : Applicant.passportCountryCode3
      //   }
      // } else {
      //   if (this.ruleForm.selectedPassportCountryCode !== '' && ((this.ausCitizen === 'No' && this.ruleForm.selectedPassportCountryCode === 'AU') ||
      //   this.ausCitizen === 'Yes' && this.ruleForm.selectedPassportCountryCode !== 'AU')) {
      //     Applicant.passportNumber = ''
      //     Applicant.passportCountry = ''
      //     Applicant.passportCountryCode = ''
      //     Applicant.passportCountryCode3 = ''
      //   }
      // }
      // this.ruleForm.passportNumber = Applicant.passportNumber
      // this.ruleForm.selectedPassportCountryCode = Applicant.passportCountryCode
      const params = {
        session: this.Session,
        applicant: Applicant,
        hasPassport: this.ruleForm.hasPassport,
        ausCitizen: this.ausCitizen,
        selectedCertificateDocumentType: this.ruleForm.selectedCertificateDocumentType,
        showOtherNameDocument: this.showOtherNameDocument,
        selectedOtherNameDocumentType: this.ruleForm.selectedOtherNameDocumentType,
        selectedPassportCountryCode: this.selectedPassportCountryCode,
        selectedCitizenshipDualCountryCode: this.ruleForm.selectedCitizenshipDualCountryCode
      }

      await hasPassportOnChangeApi(params).then(res => {
        const { data } = res
        this.isCertificateTypeSelected = data.isCertificateTypeSelected
        this.ruleForm.selectedCertificateDocumentType = data.selectedCertificateDocumentType
        this.ruleForm.selectedPassportCountryCode = data.selectedPassportCountryCode
        this.SetApplicant(data.applicant)
        this.SaveFormDataSetSession(data.session)
        if (this.ruleForm.hasPassport === 'Yes') {
          if (data.applicant.passportCountryCode === 'AU') {
            if (this.ausCitizen === 'Yes') {
              this.ruleForm.passportNumber = data.applicant.passportNumber
              this.ruleForm.selectedPassportCountryCode = 'AU'
              this.orderApplicantDocuments = data.orderApplicantDocuments || []
            } else {
              this.ruleForm.passportNumber = data.applicant.passportNumber
              this.ruleForm.selectedPassportCountryCode = ''
              this.orderApplicantDocuments = []
            }
          } else {
            if (this.ausCitizen === 'No') {
              this.ruleForm.passportNumber = data.applicant.passportNumber
              this.orderApplicantDocuments = data.orderApplicantDocuments || []
            } else {
              this.ruleForm.passportNumber = data.applicant.passportNumber
              this.ruleForm.selectedPassportCountryCode = ''
              this.orderApplicantDocuments = []
            }
          }
        } else {
          this.ruleForm.selectedCertificateDocumentType = data.selectedCertificateDocumentType
          this.ruleForm.selectedPassportCountryCode = data.selectedPassportCountryCode
          this.orderApplicantDocuments = data.orderApplicantDocuments
        }
      })
    },

    passportNumberChange: _.debounce(
      function() {
        const params = {
          session: this.Session,
          applicant: this.Applicant
        }
        params.applicant.passportNumber = this.ruleForm.passportNumber
        this.OnApplicantChange(params)
      }, global.debounceTime),

    async OnPassportCountryChange(data) {
      this.ruleForm.selectedPassportCountryCode = data.countryCode

      const params = {
        session: this.Session,
        applicant: this.Applicant,
        selectedPassportCountryCode: this.ruleForm.selectedPassportCountryCode,
        dualCitizen: this.ruleForm.dualCitizen
      }
      this.ruleForm.selectedCertificateDocumentType = ''
      await onPassportCountryChangeApi(params).then(res => {
        // this.OnApplicantChange(params)
        const { data } = res
        this.SetApplicant(data.applicant)
      })
    },

    async OnCertificateDocumentTypeChange() {
      // console.log(this.ruleForm.selectedCertificateDocumentType)
      if (this.ruleForm.selectedCertificateDocumentType === 'Birth') {
        this.uploadCertificateDocumentTypeId = this.birthCertificateDocumentTypeId + ''
      } else if (this.ruleForm.selectedCertificateDocumentType === 'Citizenship') {
        this.uploadCertificateDocumentTypeId = this.citizenshipCertDocumentTypeId + ''
      }

      const params = {
        session: this.Session,
        applicant: this.Applicant,
        uploadCertificateDocumentTypeId: this.uploadCertificateDocumentTypeId,
        selectedCertificateDocumentType: this.ruleForm.selectedCertificateDocumentType
      }

      await onCertificateDocumentTypeChangeApi(params).then(res => {
        const { data } = res
        this.isCertificateTypeSelected = data.isCertificateTypeSelected
        this.orderApplicantDocuments = data.orderApplicantDocuments || []
        this.showOtherNameDocument = true
      })
    },

    async CloudinaryUpload_UploadResult(file) {
      const formData = new FormData()
      formData.append('UploadCertificateDocumentTypeId', this.uploadCertificateDocumentTypeId)
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('IsAusCitizen', this.ausCitizen)
      formData.append('HasPassport', this.ruleForm.hasPassport)
      formData.append('file', file)

      await uploadApi(formData).then(res => {
        const { data } = res
        this.orderApplicantDocuments = data.getOrderApplicantDocumentsByOrderApplicantId2
        this.$refs.ruleForm.validateField('orderApplicantDocuments')
      })
    },

    async CloudinaryUpload_UploadResult_OtherName(file) {
      const formData = new FormData()
      formData.append('OtherNameDocumentTypeId', this.otherNameDocumentTypeId)
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('IsAusCitizen', this.ausCitizen)
      formData.append('HasPassport', this.ruleForm.hasPassport)
      formData.append('file', file)

      await uploadOtherNamApi(formData).then(res => {
        const { data } = res
        this.otherNameDocuments = data.otherNameDocuments || []
      })
    },

    async Document_Remove(type, index, item) {
      const params = {
        session: this.Session,
        orderApplicantDocumentId: item.id
      }
      await removeDocumentApi(params).then(res => {
        if (type === 'otherName') {
          this.otherNameDocuments = []
          this.ruleForm.selectedOtherNameDocumentType = ''
        } else {
          this.orderApplicantDocuments.splice(index, 1)
          this.showOtherNameDocument = true
        }
        this.SaveFormDataSetSession(res.data.session)
      })
    },

    async OnOtherNameDocumentTypeChange() {
      const params = {
        selectedOtherNameDocumentType: this.ruleForm.selectedOtherNameDocumentType
      }
      await onOtherNameDocumentTypeChangeApi(params).then(res => {
        this.otherNameDocumentTypeId = res.data.otherNameDocumentTypeId
      })
    },

    async Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const applicant = this.Applicant
          applicant.passportNumber = this.ruleForm.passportNumber
          const params = {
            session: this.Session,
            applicant: applicant,
            orderApplicantId: this.Session.current.orderApplicantId,
            hasPassport: this.ruleForm.hasPassport,
            ausCitizen: this.ausCitizen,
            dualCitizen: this.ruleForm.dualCitizen,
            selectedCertificateDocumentType: this.ruleForm.selectedCertificateDocumentType,
            showOtherNameDocument: this.showOtherNameDocument,
            selectedOtherNameDocumentType: this.ruleForm.selectedOtherNameDocumentType,
            selectedPassportCountryCode: this.ruleForm.selectedPassportCountryCode,
            selectedCitizenshipDualCountryCode: this.ruleForm.selectedCitizenshipDualCountryCode,
            orderApplicantDocuments: this.orderApplicantDocuments,
            otherNameDocumentTypeId: this.otherNameDocumentTypeId,
            otherNameDocuments: this.otherNameDocuments,
            selectedCitizenshipCountryCode: this.ruleForm.selectedCitizenshipCountryCode
          }
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    getImgcanDelete(item) {
      // (ListRecords_OtherNameDocuments.List.Current.UploadedBlockName = Session.Current.FormSection.BlockName and
      //  (not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100)) or Session.Current.IsAdmin
      return ((item.uploadedBlockName === this.Session.current.formSection.blockName) &&
      (!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100)) || this.Session.current.isAdmin
    }

  }
}
</script>

<template>
  <div v-loading="allLoading" class="EntitlementToWork2View">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item
            prop="selectedCitizenshipCountryCode"
          >
            <label class="label">* What is your Country of Citizenship?
              <GetAllGeoCountries
                :defalut-value.sync="ruleForm.selectedCitizenshipCountryCode"
                :disabled="allDisabled"
                :geo-countries="geoCountries"
                @getCountryData="OnCitizenshipCountryChange"
              />
            </label>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-form-item prop="dualCitizen" class="dualCitizen">
          <el-col
            :xs="24" :sm="24" :md="16" :lg="15"
            :xl="19" class="label"
          >
            <span class="color-red">*</span> Do you hold dual Citizenship?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="6" :lg="7"
            :xl="5"
          >
            <el-radio-group v-model="ruleForm.dualCitizen" @change="OnDualCitizenChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-form-item>
      </el-row>

      <el-row v-if="ruleForm.dualCitizen ==='Yes'" :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item
            prop="selectedCitizenshipDualCountryCode"
          >
            <label class="label">* What is your second Country of Citizenship?
              <GetAllGeoCountries
                :defalut-value.sync="ruleForm.selectedCitizenshipDualCountryCode"
                :geo-countries="geoCountries"
                @getCountryData="OnCitizenshipDualCountryChange"
              />
            </label>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="PassportShow">
        <el-form-item prop="hasPassport" class="dualCitizen">
          <el-row :gutter="20">
            <el-col
              :xs="24" :sm="24" :md="24" :lg="15"
              :xl="19" class="label"
            >
              <span class="color-red">*</span> Do you hold a current {{ ausCitizen ==="Yes"?"Australian ": "International" }} Passport?
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="24" :lg="7"
              :xl="5"
            >
              <el-radio-group v-model="ruleForm.hasPassport" :disabled="hasPassportDisabled" @change="OnHasPassportChange">
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-col>
          </el-row>
        </el-form-item>

        <el-row v-if="ruleForm.hasPassport==='Yes'" :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="passportNumber">
              <label class="label">* Passport Number
                <el-input v-model="ruleForm.passportNumber" placeholder="Enter Passport Number" :disabled="passportNumberDisabled" @input="passportNumberChange" />
              </label>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item
              v-if="PassportCountryShow"
              label="Passport Country"
              prop="selectedPassportCountryCode"
            >
              <GetAllGeoCountries
                :defalut-value.sync="ruleForm.selectedPassportCountryCode"
                :disabled="passportNumberDisabled || allDisabled"
                :geo-countries="geoCountries"
                @getCountryData="OnPassportCountryChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div v-if="BirthOrCitizenshipCertShow">
        <el-form-item
          v-if="ruleForm.hasPassport==='No'"
          class="f-w-600"
          label="Please attach a legible copy of your Birth Certificate or Citizenship Certificate"
          prop="selectedCertificateDocumentType"
        >
          <el-radio-group v-model="ruleForm.selectedCertificateDocumentType" :disabled="selectedCertificateDocumentTypeDisabled" @change="OnCertificateDocumentTypeChange">
            <el-radio-button label="Birth">Birth Certificate</el-radio-button>
            <el-radio-button label="Citizenship">Citizenship Certificate</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <div v-if="DocumentShow">
          <div v-if="DocumentShow2">
            <p v-if="ruleForm.hasPassport==='Yes'" class="f-w-600">Please attach a legible copy of your {{ ausCitizen === "Yes"? "Australian ": "International " }} Passport <span class="color-red">*</span></p>
            <el-form-item
              prop="orderApplicantDocuments"
            >
              <OnlyUpload v-if="orderApplicantDocuments.length < 1" @Document_Upload="CloudinaryUpload_UploadResult" />
              <OnlyUpload
                v-for="(item,index) in orderApplicantDocuments"
                :key="item.id"
                :img-item="item"
                icon-color="#206caa"
                @Document_Upload="CloudinaryUpload_UploadResult"
                @Document_Remove="Document_Remove('order',index,$event)"
              />
            </el-form-item>

          </div>
        </div>
      </div>

      <div v-if="OtherNameDocumentShow">
        <el-form-item
          label="Please provide either a Change of Name Certificate or Marriage Certificate issued by a government agency or regulator."
          prop="selectedOtherNameDocumentType"
        >
          <el-radio-group v-model="ruleForm.selectedOtherNameDocumentType" :disabled="selectedOtherNameDocumentTypeDisabled" @change="OnOtherNameDocumentTypeChange">
            <el-radio-button label="ChangeOfName">Change of Name Certificate</el-radio-button>
            <el-radio-button label="Marriage">Marriage Certificate</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <div v-if="ruleForm.selectedOtherNameDocumentType">
          <el-form-item
            prop="otherNameDocuments"
          >
            <OnlyUpload v-if="otherNameDocuments.length < 1 && imgcanDelete" @Document_Upload="CloudinaryUpload_UploadResult_OtherName" />
            <OnlyUpload
              v-for="item in otherNameDocuments"
              :key="item.id"
              :img-item="item"
              :can-delete="getImgcanDelete(item)"
              @Document_Upload="CloudinaryUpload_UploadResult_OtherName"
              @Document_Remove="Document_Remove('otherName',$event,item)"
            />
          </el-form-item>
        </div>

      </div>

      <el-form-item>
        <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">{{ buttonText }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss">
.EntitlementToWork2View {
  .label {
    line-height: 40px;
    font-size: 14px;
    color: #606266;
  }

   .dualCitizen{
    .el-form-item__error{
      left: 15px !important;
    }
  }
}
</style>
