<script>
import { mapState } from 'vuex'
export default {
  name: 'EYQuestionG13Item',
  inject: ['OnItemAddNew', 'OnItemRemoved', 'getAddFormList', 'Question_DataChange'],
  props: {
    jobs: {
      type: Array,
      default: () => []
    },
    editItemIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      formConfig: {
        jobTitle: '',
        dateCommenced: null,
        dateEnded: null
      },
      rules: {
        jobTitle: [{ required: true, message: 'Job Title Required!', trigger: 'blur' }],
        dateCommenced: [{ required: true, message: 'Month and Year Required!', trigger: 'blur' }],
        dateEnded: [{ required: true, message: 'Month and Year Required!', trigger: 'blur' }]
      },
      compliancesList: [
        // {
        //   jobTitle: '',
        //   dateCommenced: null,
        //   dateEnded: null,
        //   index: 0
        // }
      ],
      openIndex: 0, // 当前打开的index
      index: 0 // 持续递增的index
    }
  },
  computed: {
    ...mapState({
      preparation: state => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    outAddBtnShow() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      return spliceIndex === -1
    }
  },
  watch: {
    compliancesList: {
      handler(data) {
        if (data) {
          this.getAddFormList(this.compliancesList)
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (this.jobs.length > 0) {
      this.compliancesList = this.jobs
      this.compliancesList = this.$isRepeat(this.compliancesList)
      this.index = Math.max.apply(Math, this.compliancesList.map(item => { return item.index }))
      this.index = this.index ? this.index : 0
      this.openIndex = this.editItemIndex
    }
  },
  methods: {
    handleRemove(itemIndex, item) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === itemIndex
      )
      this.compliancesList.splice(spliceIndex, 1)
      this.OnItemRemoved(item, this.compliancesList)
    },
    addItem() {
      this.OnItemAddNew(this.compliancesList)
      this.compliancesList.push(
        JSON.parse(
          JSON.stringify({
            ...this.formConfig,
            index: ++this.index
          })
        )
      )
      this.openIndex = this.index
    },
    handleAdd(formName, type, item) {
      if (type === 1) {
        // 表单内
        this.$refs[formName][0].validate(async(valid) => {
          if (valid) {
            this.addItem()
          }
        })
      } else {
        // 全局下
        if (
          this.$refs[`addForm${this.index}`] &&
          this.$refs[`addForm${this.index}`][0]
        ) {
          // 当前存在打开的表单
          this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
            if (valid) {
              this.addItem()
            }
          })
        } else {
          //  不存在打开的表单
          this.addItem()
        }
      }
    },
    open(itemIndex) {
      if (
        this.$refs[`addForm${this.index}`] &&
        this.$refs[`addForm${this.index}`][0]
      ) {
        this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
          if (valid) {
            this.openIndex = itemIndex
          }
        })
      } else {
        this.openIndex = itemIndex
      }
    },
    handleSure() {
      if (
        this.$refs[`addForm${this.openIndex}`] &&
        this.$refs[`addForm${this.openIndex}`][0]
      ) {
        this.$refs[`addForm${this.openIndex}`][0].validate(async(valid) => {
          if (valid) {
            this.getAddFormList(this.compliancesList, true)
          }
        })
      } else {
        this.getAddFormList(this.compliancesList, true)
      }
    }

  }
}
</script>

<template>
  <div>
    <p v-if="compliancesList.length === 0" class="f-s-14">please select "Add New Item" to begin</p>
    <div v-for="item in compliancesList" :key="item.index">
      <div
        v-if="item.index != openIndex"
        class="history-box"
        @click="open(item.index)"
      >
        <p>
          {{ item.jobTitle }}
          ({{ item.dateCommenced | parseDefaultTime('{g} {y}') }} - {{ item.dateEnded | parseDefaultTime('{g} {y}') }})
        </p>
      </div>
      <div v-if="item.index == openIndex">
        <h4>{{ item.id?'Editing':'New' }} Job Title</h4>
        <el-form
          :ref="`addForm${item.index}`"
          :model="item"
          :rules="rules"
          class="demo-ruleForm"
          label-width="50%"
          label-position="top"
          :disabled="allDisabled"
        >
          <el-form-item label="Job Title" prop="jobTitle">
            <el-input v-model="item.jobTitle" @input="Question_DataChange" />
          </el-form-item>
          <el-row :gutter="20">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item label="When did the employment commence?" prop="dateCommenced">
                <el-date-picker
                  v-model="item.dateCommenced"
                  value-format="yyyy-MM-dd"
                  format="MM/yyyy"
                  type="month"
                  :picker-options="pickerOptions"
                  @change="Question_DataChange"
                />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item label="When did the employment conclude?" prop="dateEnded">
                <el-date-picker
                  v-model="item.dateEnded"
                  value-format="yyyy-MM-dd"
                  format="MM/yyyy"
                  type="month"
                  :picker-options="pickerOptions"
                  @change="Question_DataChange"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item v-if="!allDisabled">
            <div class="action-box">
              <div
                class="action-item"
                @click="handleAdd(`addForm${item.index}`, 1, item)"
              >
                <i class="el-icon-circle-plus" />
                <span class="action-value">Add New Item</span>
              </div>
              <div class="action-item" @click="handleRemove(item.index, item)">
                <i class="el-icon-delete-solid" />
                <span class="action-value">Remove Item</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-divider v-if="!outAddBtnShow && !allDisabled" />
    <div v-if="outAddBtnShow" class="action-box" @click="handleAdd('', 2)">
      <div class="action-item">
        <i class="el-icon-circle-plus" />
        <span class="action-value f-s-14">Add New Item</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action-box {
  display: flex;
  justify-content: space-between;
  color: #206caa;
  margin-bottom:10px ;
  .action-item {
    cursor: pointer;
  }
  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }
  .action-value {
    margin-left: 10px;
  }
}
</style>

