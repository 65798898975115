var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddressForm" },
    [
      _vm._l(_vm.compliancesList, function (item) {
        return _c("div", { key: item.index }, [
          item.index != _vm.openIndex
            ? _c(
                "div",
                {
                  staticClass: "history-box",
                  on: {
                    click: function ($event) {
                      return _vm.open(item.index)
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(item.fullAddress || item.addressLine1) +
                        " (" +
                        _vm._s(_vm.timeChange(item.fromDate)) +
                        " - " +
                        _vm._s(_vm.timeChange(item.toDate)) +
                        ") "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          item.index == _vm.openIndex
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "Where did you live prior to " +
                        _vm._s(_vm.priortimeChange(item.toDate)) +
                        "?"
                    ),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: `addForm${item.index}`,
                      refInFor: true,
                      attrs: {
                        model: item,
                        rules: _vm.rules,
                        "label-width": "80px",
                        "label-position": "top",
                        disabled: _vm.allDisabled,
                      },
                    },
                    [
                      !item.isManual
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { xs: 24, sm: 24, lg: 24, xl: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "fullAddress",
                                        label: "Full Address",
                                      },
                                    },
                                    [
                                      _c("AddressAutocomplete", {
                                        attrs: {
                                          initialaddress: item.fullAddress,
                                          placeholder:
                                            "Start typing and pause to select the address",
                                          "use-kleber": true,
                                          "use-google": true,
                                          from: "AddressHistory",
                                        },
                                        on: {
                                          "update:initialaddress": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              item,
                                              "fullAddress",
                                              $event
                                            )
                                          },
                                          OnDetailReady:
                                            _vm.AddressAutocompleteOnDetailReady,
                                          OnClear:
                                            _vm.AutocompleteAddressOnClear,
                                          OnManualEntry:
                                            _vm.AddressAutocompleteOnManualEntry,
                                          OnNoSelectionMade:
                                            _vm.AddressAutocompleteOnNoSelectionMade,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { xs: 24, sm: 12, lg: 12, xl: 12 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "countryCode",
                                            label: "Country",
                                          },
                                        },
                                        [
                                          _c("GetAllGeoCountries", {
                                            attrs: {
                                              "defalut-value": item.countryCode,
                                            },
                                            on: {
                                              "update:defalutValue": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  item,
                                                  "countryCode",
                                                  $event
                                                )
                                              },
                                              "update:defalut-value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  item,
                                                  "countryCode",
                                                  $event
                                                )
                                              },
                                              getCountryData: function (
                                                $event
                                              ) {
                                                return _vm.onCountryChage(
                                                  item,
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { xs: 24, sm: 12, lg: 12, xl: 12 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "addressLine1",
                                            label: "Address Line 1",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            on: {
                                              input: function ($event) {
                                                return _vm.OnManualAddressChange(
                                                  item.index,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.addressLine1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "addressLine1",
                                                  $$v
                                                )
                                              },
                                              expression: "item.addressLine1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { xs: 24, sm: 12, lg: 12, xl: 12 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "addressLine2",
                                            label: "Address Line 2",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            on: {
                                              input: function ($event) {
                                                return _vm.OnManualAddressChange(
                                                  item.index,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.addressLine2,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "addressLine2",
                                                  $$v
                                                )
                                              },
                                              expression: "item.addressLine2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: { xs: 24, sm: 12, lg: 12, xl: 12 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "suburb",
                                            label: "Suburb",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            on: {
                                              input: function ($event) {
                                                return _vm.OnManualAddressChange(
                                                  item.index,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.suburb,
                                              callback: function ($$v) {
                                                _vm.$set(item, "suburb", $$v)
                                              },
                                              expression: "item.suburb",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  item.countryCode === "AU"
                                    ? _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 6,
                                            lg: 6,
                                            xl: 6,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "state",
                                                label: "State",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "selectWidth",
                                                  attrs: {
                                                    "popper-class":
                                                      "dropdownClass",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.OnManualAddressChange(
                                                        item.index,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.state,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "state",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.state",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.australianStates,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.adminCodes1
                                                        .ISO3166_2,
                                                      attrs: {
                                                        label:
                                                          item.adminCodes1
                                                            .ISO3166_2,
                                                        value:
                                                          item.adminCodes1
                                                            .ISO3166_2,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-col",
                                    { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "postCode",
                                            rules: {
                                              required:
                                                item.countryCode === "AU",
                                              message: "Required field!",
                                              trigger: "change",
                                            },
                                            label: "Postcode",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            on: {
                                              input: function ($event) {
                                                return _vm.OnManualAddressChange(
                                                  item.index,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.postCode,
                                              callback: function ($$v) {
                                                _vm.$set(item, "postCode", $$v)
                                              },
                                              expression: "item.postCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "fromDate",
                                    label: "From Date",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      "value-format": "yyyy-MM-dd",
                                      format: "dd/MM/yyyy",
                                      "picker-options": _vm.pickerOptions,
                                      placeholder: "DD/MM/YYYY",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.DatePickerOnSelect(
                                          item.index,
                                          $event
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.fromDate,
                                      callback: function ($$v) {
                                        _vm.$set(item, "fromDate", $$v)
                                      },
                                      expression: "item.fromDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "toDate", label: "To Date" } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      disabled: "",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd",
                                      format: "dd/MM/yyyy",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.DatePickerOnSelect2(
                                          item.index,
                                          $event
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.toDate,
                                      callback: function ($$v) {
                                        _vm.$set(item, "toDate", $$v)
                                      },
                                      expression: "item.toDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-form-item", [
                        _c("div", { staticClass: "action-box" }, [
                          item.isManual
                            ? _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: { click: _vm.ValidateForm },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Add Another Address"),
                                  ]),
                                ]
                              )
                            : _c("div"),
                          _vm.compliancesList[_vm.compliancesList.length - 1]
                            .index === item.index && !_vm.allDisabled
                            ? _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item.index, item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete-solid",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Remove Address"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      _vm.passStatus
        ? _c("p", { staticStyle: { color: "#37b24d" } }, [
            _vm._v(
              "You have provided enough information. Please Save and Continue."
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }