<script>
import PersonalDetailsItem from './AddForm/index.vue'
import { personalDetailsApi } from '@/api/pattern'
import ImageShow from '../components/ImageShow/index.vue'
export default {
  name: 'PersonalDetailsView',
  components: { PersonalDetailsItem, ImageShow },
  props: {
    applicantId: {
      type: Number,
      default: 0
    },
    applicant: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {
        passportCountry: '',
        placeOfBirth: '',
        firstName: '',
        lastName: '',
        middleNames: '',
        isSingleNameOnly: false
      },
      getOtherNamesByApplicantId: [],
      listFilter_Passport: [],
      listFilter_DriversLicence: [],
      listFilter_DriversLicenceBack: [],
      listFilter_FirearmsLicence: [],
      moduleLoading: false,
      showDriverLicenseAdditionalInfo: false
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }
      await personalDetailsApi(params).then(res => {
        const { data } = res
        this.ruleForm = Object.assign({}, this.applicant)
        this.ruleForm.passportCountry = this.ruleForm.passportCountry ? this.ruleForm.passportCountry.toUpperCase() : ''
        this.ruleForm.placeOfBirth = data.placeOfBirth
        this.listFilter_DriversLicence = data.listFilter_DriversLicence
        this.listFilter_DriversLicenceBack = data.listFilter_DriversLicenceBack
        this.listFilter_Passport = data.listFilter_Passport
        this.listFilter_FirearmsLicence = data.listFilter_FirearmsLicence
        this.getOtherNamesByApplicantId = data.getOtherNamesByApplicantId
        this.ruleForm.firstName = this.ruleForm.firstName ? this.ruleForm.firstName.toUpperCase() : ''
        this.ruleForm.lastName = this.ruleForm.lastName ? this.ruleForm.lastName.toUpperCase() : ''
        this.ruleForm.middleNames = this.ruleForm.middleNames ? this.ruleForm.middleNames.toUpperCase() : ''
        this.showDriverLicenseAdditionalInfo = data.showDriverLicenseAdditionalInfo
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="PersonalDetailsView">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
      disabled
    >
      <div class="mt-10">
        <div class="label"><el-checkbox v-model="ruleForm.isSingleNameOnly" @change="isSingleNameOnlyChange($event)" /> <span class="ml-4"> I am legally known by single name only</span></div>
      </div>
      <el-row v-if="!ruleForm.isSingleNameOnly" :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Given Name (current legal name)" prop="firstName">
            <el-input v-model="ruleForm.firstName" :maxlength="100" @keyup.native="ruleForm.firstName = ruleForm.firstName.replace(/[^a-zA-Z\s'-]/g,'')" />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Family Name (current legal name)" prop="lastName">
            <el-input v-model="ruleForm.lastName" :maxlength="100" @keyup.native="ruleForm.lastName = ruleForm.lastName.replace(/[^a-zA-Z\s'-]/g,'')" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-else :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Current legal name" prop="lastName">
            <el-input v-model="ruleForm.lastName" :maxlength="100" @keyup.native="ruleForm.lastName = ruleForm.lastName.replace(/[^a-zA-Z\s'-]/g,'')" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item v-if="!ruleForm.isSingleNameOnly" label="Middle Name(s) (current legal names)" prop="middleNames">
        <el-input v-model="ruleForm.middleNames" :maxlength="100" @keyup.native="ruleForm.middleNames = ruleForm.middleNames.replace(/[^a-zA-Z\s'-]/g,'')" />
      </el-form-item>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Email" prop="email">
            <el-input v-model="ruleForm.email" />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Contact Phone Number" prop="phone">
            <el-input
              v-model="ruleForm.phone"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Date of Birth" prop="dateOfBirth">
            <el-date-picker
              v-model="ruleForm.dateOfBirth"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              type="date"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Place of Birth" prop="placeOfBirth">
            <el-input v-model="ruleForm.placeOfBirth" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="Gender" prop="gender">
        <el-input v-model="ruleForm.gender" />
      </el-form-item>

      <el-divider />

      <h3>Australian Driver's Licence</h3>

      <template v-if="ruleForm.driversLicenceNumber !==''">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Driver's Licence State" prop="driversLicenceState">
              <el-input v-model="ruleForm.driversLicenceState" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Driver's Licence Number" prop="driversLicenceNumber">
              <el-input v-model="ruleForm.driversLicenceNumber" />
            </el-form-item>
          </el-col>
          <el-col
            v-if="showDriverLicenseAdditionalInfo" :xs="24" :sm="24" :md="12"
            :lg="12"
            :xl="12"
          >
            <el-form-item label="Driver's Licence Card Number" prop="driversLicenceCardNumber">
              <el-input v-model="ruleForm.driversLicenceCardNumber" />
            </el-form-item>
          </el-col>
        </el-row>

      </template>
      <template v-else>
        <p>No Driver's Licence</p>
      </template>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <div v-if="listFilter_DriversLicence.length" class="PersonalDetails-box">
            <ImageShow :img-list="listFilter_DriversLicence" />
          </div>
        </el-col>
        <el-col
          v-if="showDriverLicenseAdditionalInfo" :xs="24" :sm="24" :md="12"
          :lg="12"
          :xl="12"
        >
          <div v-if="listFilter_DriversLicenceBack.length" class="PersonalDetails-box">
            <ImageShow :img-list="listFilter_DriversLicenceBack" />
          </div>
        </el-col>
      </el-row>

      <h3>Current Passport</h3>
      <template v-if="ruleForm.passportNumber !== ''">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Passport Number" prop="passportNumber">
              <el-input v-model="ruleForm.passportNumber" />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Passport Country" prop="passportCountryCode">
              <el-input v-model="ruleForm.passportCountry" />
            </el-form-item>
          </el-col>
        </el-row>

      </template>
      <template v-else>
        <p>No Passport</p>
      </template>

      <div v-if="listFilter_Passport.length" class="PersonalDetails-box">
        <ImageShow :img-list="listFilter_Passport" />
      </div>

      <h3>Australian Firearms Licence </h3>

      <template v-if="ruleForm.firearmsLicenceNumber !==''">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Firearms Licence Number" prop="firearmsLicenceNumber">
              <el-input v-model="ruleForm.firearmsLicenceNumber" />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Firearms Licence State" prop="firearmsLicenceState">
              <el-input v-model="ruleForm.firearmsLicenceState" />
            </el-form-item>
          </el-col>
        </el-row>

      </template>
      <template v-else>
        <p>No Firearms Licence</p>
      </template>

      <div v-if="listFilter_FirearmsLicence.length" class="PersonalDetails-box">
        <ImageShow :img-list="listFilter_FirearmsLicence" />
      </div>

      <h3>Other Names </h3>
      <PersonalDetailsItem v-if="getOtherNamesByApplicantId.length" ref="PersonalDetailsItem" :other-names="getOtherNamesByApplicantId" />
      <p v-else>No other names provided</p>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.PersonalDetailsView{
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
  }
  .PersonalDetails-item {
    width: 156px;
    height: 242px;
    background: #fff;
    margin: 10px 20px 10px 0;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    .img {
      width: 140px;
      height: 205px;
      padding: 8px;
    }
    .msg {
      font-size: 12px;
      padding: 8px;
      height: 21px;
      margin-top: -16px;
      text-align: center;
      overflow: hidden;
    }
  }
}
</style>
