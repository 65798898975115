var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "ComplianceReference",
    },
    [
      _c(
        "el-form",
        {
          ref: "topForm",
          attrs: { "label-width": "80px", "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "hasBeenAuthorisedRepresentative" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { xs: 24, sm: 16, lg: 16, xl: 16 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "In the last " +
                          _vm._s(_vm.minimumQuantity) +
                          " years have you been an Authorised Representative?"
                      ),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.HasBeenAuthorisedRepresentative,
                            callback: function ($$v) {
                              _vm.HasBeenAuthorisedRepresentative = $$v
                            },
                            expression: "HasBeenAuthorisedRepresentative",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.getCompliancesByApplicantId.length
        ? _c(
            "div",
            [
              _c("AddForm", {
                ref: "AddForm",
                attrs: { compliances: _vm.getCompliancesByApplicantId },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }