var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "ElectronicLodgement",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c("el-divider"),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "electronicLodgementAgreed" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " I agree to the electronic lodgement of my personal information "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.electronicLodgementAgreed,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "electronicLodgementAgreed",
                                $$v
                              )
                            },
                            expression: "ruleForm.electronicLodgementAgreed",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ContinueBtn
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.endAllRequests, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.Continue("ruleForm")
                    },
                  },
                },
                [_vm._v("Save and Continue")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }