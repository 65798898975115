var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SessionExpiredView" },
    [
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "center" } },
        [
          _c("el-col", { attrs: { xs: 22, sm: 21, md: 8, lg: 8, xl: 8 } }, [
            _c(
              "div",
              { staticClass: "content-box" },
              [
                _c("div", { staticClass: "logo" }),
                _c("h2", { staticClass: "text-c" }, [
                  _vm._v("Session Expired"),
                ]),
                _c("h4", [_vm._v("Your session has expired.")]),
                _c("h4", [_vm._v("Please log in again to continue")]),
                _c(
                  "el-button",
                  { staticClass: "btn", attrs: { type: "primary" } },
                  [_vm._v("Resume Session")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }