var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "OtherCountriesView",
    },
    [
      _vm.getPreviousAddressesByApplicantId.length
        ? _c("OtherCountriesItem", {
            attrs: {
              "previous-addresses": _vm.getPreviousAddressesByApplicantId,
            },
          })
        : _c("p", { staticClass: "comp" }, [
            _vm._v("No other countries provided"),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }