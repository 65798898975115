<script>
import { getNameApi } from '@/api/documentTypes'
export default {
  name: 'DocumnetGalleryUpload',
  props: {
    imgList: {
      type: Array,
      default: () => []
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    modalShow: {
      type: Boolean,
      default: false
    },
    thankYouDocumentIdList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      addDocument: require('@/assets/imgs/AddDocument.png'),
      typeList: ['png', 'jpeg', 'jpg', 'pdf', 'heic'],
      fileTypePass: true,
      defaultImg: '',
      subtitle: '',
      list: [],
      source: null, // cnacel upload
      notPassFileName: '',
      notPassFileSize: '',
      processLen: 0
    }
  },
  computed: {
    Show: {
      get() {
        return this.modalShow
      },
      set(val) {
        this.$emit('update:modalShow', val)
      }
    }
  },
  watch: {
    imgList: function(newData, oldData) {
      this.list = newData
      this.getFileName(newData)
    },
    defaultImg(newValue, oldValue) {
      if (!newValue) {
        // false  close loading
        this.loadingCover = ''
      }
    }
  },
  methods: {
    async getFileName(val) {
      this.list = val
      await this.list.length > 0 && this.list.forEach(async(item) => {
        await getNameApi({ id: item.documentTypeId }).then(res => {
          item.fileName = res.data.name
        })
      })
      this.$forceUpdate()
    },
    uploadChange(res, file) {
      if (!(file[0].size > this.maxFileSize) && !this.fileTypeVerdict(file[0])) {
        this.loadingCover = URL.createObjectURL(res.raw)
      }
    },
    uploadFile(file) {
      this.notPassFileName = file.file.name
      this.notPassFileSize = (file.file.size / 1024).toFixed(2)
      if (this.fileTypeVerdict(file.file)) {
        this.fileTypePass = false
      } else {
        const interval = setInterval(() => {
          if (this.processLen >= 99) {
            clearInterval(interval)
            return
          }
          this.processLen += 1
        }, 80)
        const params = {
          file: file.file,
          subtitle: this.subtitle
        }
        this.$emit('Document_Upload', params)
      }
    },
    fileTypeVerdict(file) { // false--file type not allowed to updoad
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isSuffix = this.typeList.indexOf(fileSuffix) === -1
      return isSuffix
    },
    removeDocuments(imgItem) {
      this.removeLoding = true
      this.$emit('Document_Remove', imgItem)
    },
    modalClose() {
      this.source = null
      this.percentage = 0
      this.subtitle = ''
      this.Show = false
      this.defaultImg = ''
    },
    openImg(url) {
      window.open(url)
    },
    cancelUpload() {
      if (this.source) {
        this.source.cancel('cnacel upload')
        this.percentage = 0
        this.show = false
        this.defaultImg = ''
      }
    },
    getCanDelete(id) {
      return this.thankYouDocumentIdList.indexOf(id) === -1
    }
  }
}
</script>

<template>
  <div class="documnetGalleryUpload">
    <div class="list-box">
      <div v-for="item in list" :key="item.id" class="upload">
        <div class="img">
          <el-image
            style="width: 140px; height: 200px"
            :src="item.thumbUrl"
            fit="cover"
            @click="openImg(item.docurl)"
          />
          <i
            v-if="getCanDelete(item.id)"
            class="el-icon-error"
            @click="removeDocuments(item)"
          />
          <!-- <p class="msg">{{ item.documentTypeName }} ({{ item.subtitle }})</p> -->
          <p class="msg">{{ item.fileName }} ({{ item.subtitle }})</p>
        </div>
      </div>

      <div class="upload">
        <div class="img">
          <el-image
            style="width: 140px; height: 200px"
            :src="addDocument"
            fit="cover"
            @click="Show = true"
          />
          <p class="msg">Add New Document</p>
        </div>
      </div>
    </div>

    <el-dialog
      :visible="Show"
      :close-on-click-modal="false"
      @close="modalClose"
    >
      <span slot="title">Add New Document</span>
      <div class="modal-body">
        <span>Name of document</span>
        <el-input v-model="subtitle" />

        <div v-if="subtitle">
          <!-- <div v-if="defaultImg" class="DocumentGalleryBox">
            <div class="DocumentGalleryItem">
              <div
                v-loading="defaultImg ? true : false"
                class="img"
                element-loading-text="Uploading"
              >
                <img :src="defaultImg" alt="" width="140px" height="200px">
              </div>
            </div>
          </div> -->
          <el-row v-if="defaultImg">
            <el-col :xs="24" :sm="24" :lg="24" :xl="24">
              <div v-if="defaultImg" class="img-box">
                <img
                  :src="defaultImg" alt="" style="max-width: 200px"
                >
                <div class="upload-test">
                  <div>
                    <p class="color-white">{{ notPassFileName }}</p>
                    <p class="color-white">{{ notPassFileSize }}KB</p>
                  </div>
                  <div>
                    <p class="color-white">Uploading {{ processLen }}% <i class="fa fa-circle-o-notch fa-pulse" /></p>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div v-else>
            <el-upload
              v-if="fileTypePass"
              ref="upload"
              class="upload-demo"
              drag
              action="#"
              :http-request="uploadFile"
              :before-remove="cancelUpload"
              :show-file-list="false"
              :on-change="uploadChange"
            >
              <span>Drag and Drop your files or
                <span class="browse">Browse</span></span>
            </el-upload>

            <div v-else>
              <div class="not-pass-box">
                <div class="not-pass-content">
                  <div class="content1"><i style="color: white;" class="el-icon-error" @click="fileTypePass = !fileTypePass" /></div>
                  <div class="content2">
                    <!--                  <p class="color-white">{{ notPassFileName }}</p>-->
                    <!--                  <p class="color-pink">{{ notPassFileSize }}KB</p>-->
                  </div>
                  <div class="content3">
                    <p class="color-white">File size exceed 20MB or file type is invalid</p>
                    <p class="color-pink">Expects jpeg, jpg, png, pdf, or heic</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="tip-box">
          <span>Enter a document name to enable uploading</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
.img-box {
  border-radius: 5px;
  width: 95%;
  background: #dee2e6;
  padding: 4px;
  display: flex;
  justify-content: center;
  position: relative;
  .upload-test{
    border-radius: 5px;
    position: absolute;
    width: 100%;
    top: 0;
    left:0;
    padding: 4px 0;
    background:linear-gradient(black,transparent);
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    p {
      font-size: 12px;
      line-height: 1.5;
      margin: 0;
      padding: 0 4px;
    }
  }
}
.color-white{
  color: #fff;
}
.documnetGalleryUpload {
  ::v-deep .el-upload {
    width: 100%;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 80px;
    background: #f1f0ef;
    border: 1px solid #f1f0ef;
  }
}

.list-box {
  display: flex;
  flex-wrap: wrap;
}

.upload-demo {
  margin: 10px 0;
  height: 80px;
  line-height: 80px;
  .browse {
    text-decoration: underline;
  }
}
.DocumentGalleryBox {
  display: flex;
  flex-wrap: wrap;
}
.DocumentGalleryItem {
  width: 156px;
  height: 242px;
  background: #fff;
  margin: 10px 20px 10px 0;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.tip-box {
  height: 80px;
  outline: 1px solid #d9d9d9;
  text-align: center;
  line-height: 80px;
  margin-top: 20px;
}

.upload {
  width: 156px;
  height: 242px;
  background: #fff;
  margin: 10px 20px 10px 0;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  .msg {
    font-size: 12px;
    padding: 8px;
    height: 21px;
    margin-top: 0;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.img {
  width: 140px;
  height: 205px;
  padding: 8px;
  position: relative;
  cursor: pointer;
  .el-icon-error {
    font-size: 25px;
    position: absolute;
    right: 8px;
    color: red;
  }

  .hight-light {
    color: #2d74af;
  }
  .action {
    font-size: 12px;
    color: #272b30;
    margin-top: -7px;
  }
}
.not-pass-box{
  width: 100%;
  margin: 10px 0;
  height: 80px;
  line-height: 80px;
  background-color: #f1f0ef;
  border-radius: 4px;
  padding: 10px 8px;
  box-sizing: border-box;
  .not-pass-content{
    background-color: #c44f47;
    border-radius: 4px;
    height: 60px;
    padding: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
      line-height: 1.5;
      margin: 0;
    }
    .content1{
      font-size: 30px;
      .el-icon-error{
        cursor: pointer;
      }
    }
    .content2{
      width: 20%;
      p{
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .content3{
      text-align: right;
    }
  }
}
.color-white{
  color: #fff !important;
}
.color-pink{
  color: #db918c;
}
</style>
