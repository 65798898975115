const getters = {
  device: state => state.setting.device,
  session_timeout: state => state.setting.session_timeout,
  token: state => state.user.token,
  section: state => state.section.section,
  introduction: state => state.section.introduction,
  sectionTitle: state => state.section.sectionTitle,
  ReferenceNumber: state => state.preparation.ReferenceNumber,
  preparation: state => state.preparation.preparation,
  endAllRequests: state => state.portCount.endAllRequests
}

export default getters
