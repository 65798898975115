var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "EYQuestionG13View",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "50%",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "Part 1", name: "1" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "q1",
                        label:
                          "1. Are you applying for an Accounting or Financial Reporting Oversight Role,\n     a secretary role on any NAB Group company board, or a role on the Executive Committee AND have you worked for\n      EY (NAB external auditor) at any time since 1 October 2004?",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.ruleForm.q1,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "q1", $$v)
                            },
                            expression: "ruleForm.q1",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "q2",
                        label:
                          "2. In past three years, has your spouse, spousal equivalent, parent,\n       child (dependent or otherwise) or sibling been a partner, principal,\n        shareholder or professional employee of Ernst & Young (NAB's external auditor) AND have they worked on the NAB Audit?",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.ruleForm.q2,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "q2", $$v)
                            },
                            expression: "ruleForm.q2",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "Part 2", name: "2" } },
                [
                  _vm.ruleForm.q1 === "Yes" || _vm.ruleForm.q2 === "Yes"
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "q3",
                              label:
                                "3. Have you been a professional employee, partner, principal or shareholder of Ernst & Young at any time since 1 October 2004?",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.ruleForm.q3,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "q3", $$v)
                                  },
                                  expression: "ruleForm.q3",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "Yes" } }),
                                _c("el-radio", { attrs: { label: "No" } }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.ruleForm.q3 === "Yes"
                          ? _c(
                              "div",
                              [
                                _c("p", { staticClass: "label mb-10" }, [
                                  _vm._v(
                                    " If yes, what was your job title and during what time period(s)? (If you had more than one role at Ernst & Young, please list each job title and period in that role separately. "
                                  ),
                                ]),
                                _c("EYQuestionG13Item", {
                                  ref: "EYQuestionG13Item",
                                  attrs: { jobs: _vm.jobs },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                "4. Please provide a brief description of your role and responsibilities at Ernst & Young:",
                              prop: "q4",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", disabled: "" },
                              model: {
                                value: _vm.ruleForm.q4,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "q4", $$v)
                                },
                                expression: "ruleForm.q4",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                "5. During your time at Ernst & Young, were you ever a member of the NAB Group Audit Engagement Team?",
                              prop: "q5",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.ruleForm.q5,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "q5", $$v)
                                  },
                                  expression: "ruleForm.q5",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "Yes" } }),
                                _c("el-radio", { attrs: { label: "No" } }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.ruleForm.q5 === "Yes"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label:
                                    "If yes, please list the financial years you were a member of the NAB Group Audit Engagement Team.",
                                  prop: "q5_Details",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", disabled: "" },
                                  model: {
                                    value: _vm.ruleForm.q5_Details,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "q5_Details", $$v)
                                    },
                                    expression: "ruleForm.q5_Details",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                "6. During your time at Ernst & Young, were you ever involved in providing non-audit services to the NAB Group?",
                              prop: "q5_Q1",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.ruleForm.q5_Q1,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "q5_Q1", $$v)
                                  },
                                  expression: "ruleForm.q5_Q1",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "Yes" } }),
                                _c("el-radio", { attrs: { label: "No" } }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.ruleForm.q5_Q1 === "Yes"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label:
                                    "If yes, please list what services you provided and during which financial year/s",
                                  prop: "q5_Q1_Details",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", disabled: "" },
                                  model: {
                                    value: _vm.ruleForm.q5_Q1_Details,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "q5_Q1_Details",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.q5_Q1_Details",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "q6" } },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _vm._v(
                                  "7. During your time at Ernst & Young did you serve in a professional capacity in a Prudential Audit "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: { effect: "dark", placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " “Prudential Audit” means an audit in relation "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " to the Banking Act 1959 (Cth) or in "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "relation to the Prudential Standards "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " or Reporting Standards within the "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " meaning of Prudential Standard CPS 510 of "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " the Australian Prudential Regulation Authority) "
                                        ),
                                      ]
                                    ),
                                    _c("i", { staticClass: "el-icon-info" }),
                                  ]
                                ),
                                _vm._v("of NAB? "),
                              ],
                              1
                            ),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.ruleForm.q6,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "q6", $$v)
                                  },
                                  expression: "ruleForm.q6",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "Yes" } }),
                                _c("el-radio", { attrs: { label: "No" } }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.ruleForm.q6 === "Yes"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label:
                                    "If yes, please list the financial years you served in a professional capacity in a Prudential Audit of NAB.",
                                  prop: "name",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", disabled: "" },
                                  model: {
                                    value: _vm.ruleForm.q6_Details,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "q6_Details", $$v)
                                    },
                                    expression: "ruleForm.q6_Details",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("p", { staticClass: "label" }, [
                          _vm._v(
                            "8. If you accept this position with NAB, will:"
                          ),
                        ]),
                        _vm._l(_vm.listOf8, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: item.title, prop: item.key },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.ruleForm[item.key],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, item.key, $$v)
                                        },
                                        expression: "ruleForm[item.key]",
                                      },
                                    },
                                    [
                                      _c("el-radio", {
                                        attrs: { label: "Yes" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "No" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.ruleForm[item.key] === "Yes"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "If yes, please provide details",
                                        prop: item.details,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.ruleForm[item.details],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              item.details,
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm[item.details]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        _vm._l(_vm.listAfter8, function (item, index) {
                          return _c(
                            "div",
                            { key: "9" + index },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: item.title, prop: item.key },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.ruleForm[item.key],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, item.key, $$v)
                                        },
                                        expression: "ruleForm[item.key]",
                                      },
                                    },
                                    [
                                      _c("el-radio", {
                                        attrs: { label: "Yes" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "No" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.ruleForm[item.key] === "Yes" &&
                              item.key === "q8_A"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          "Type of Close Family Member (i.e. spouse, sibling etc)",
                                        prop: "q8_A_FamilyMemberType",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value:
                                            _vm.ruleForm.q8_A_FamilyMemberType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "q8_A_FamilyMemberType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.q8_A_FamilyMemberType",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.ruleForm[item.key] === "Yes" &&
                              item.key === "q8_A"
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Job Title",
                                        prop: "q8_A_JobTitle",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: _vm.ruleForm.q8_A_JobTitle,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "q8_A_JobTitle",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.q8_A_JobTitle",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.ruleForm[item.key] === "Yes"
                                ? _c(
                                    "div",
                                    [
                                      _c("p", { staticClass: "label" }, [
                                        _vm._v(
                                          "If yes, please provide period worked"
                                        ),
                                      ]),
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 20 } },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              attrs: {
                                                xs: 24,
                                                sm: 24,
                                                md: 12,
                                                lg: 12,
                                                xl: 12,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "Period start?",
                                                    prop: item.periodStart,
                                                  },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    attrs: {
                                                      disabled: "",
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      format: "MM/yyyy",
                                                      type: "month",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm[
                                                          item.periodStart
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm,
                                                          item.periodStart,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm[item.periodStart]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              attrs: {
                                                xs: 24,
                                                sm: 24,
                                                md: 12,
                                                lg: 12,
                                                xl: 12,
                                              },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "Period conclude?",
                                                    prop: item.periodConclude,
                                                  },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    attrs: {
                                                      disabled: "",
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      format: "MM/yyyy",
                                                      type: "month",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm[
                                                          item.periodConclude
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm,
                                                          item.periodConclude,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm[item.periodConclude]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "q8_E" } },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _vm._v(
                                  "is (or was) the relevant Close Family Member a partner, principal or shareholder from an Ernst & Young “office” "
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: { effect: "dark", placement: "top" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          " In this question, “office” means a distinct "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " sub-group within Ernst & Young, whether "
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " distinguished along geographic or practice lines. "
                                        ),
                                      ]
                                    ),
                                    _c("i", { staticClass: "el-icon-info" }),
                                  ]
                                ),
                                _vm._v(
                                  " where the lead Ernst & Young NAB audit engagement partner primarily practices? "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.ruleForm.q8_E,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "q8_E", $$v)
                                  },
                                  expression: "ruleForm.q8_E",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "Yes" } }),
                                _c("el-radio", { attrs: { label: "No" } }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.ruleForm.q8_E === "Yes"
                          ? _c(
                              "div",
                              [
                                _c("p", { staticClass: "label" }, [
                                  _vm._v(
                                    "If yes, please provide period worked"
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 12,
                                          lg: 12,
                                          xl: 12,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Period start?",
                                              prop: "q8_E_PeriodStart",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              attrs: {
                                                disabled: "",
                                                "value-format": "yyyy-MM-dd",
                                                format: "MM/yyyy",
                                                type: "month",
                                              },
                                              model: {
                                                value:
                                                  _vm.ruleForm.q8_E_PeriodStart,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "q8_E_PeriodStart",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.q8_E_PeriodStart",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 12,
                                          lg: 12,
                                          xl: 12,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "Period conclude?",
                                              prop: "q8_E_PeriodConclude",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              attrs: {
                                                disabled: "",
                                                "value-format": "yyyy-MM-dd",
                                                format: "MM/yyyy",
                                                type: "month",
                                              },
                                              model: {
                                                value:
                                                  _vm.ruleForm
                                                    .q8_E_PeriodConclude,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "q8_E_PeriodConclude",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.q8_E_PeriodConclude",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _c("p", [_vm._v("No Part 2 questions required.")]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }