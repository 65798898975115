var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "EYQuestionView",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "50%",
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          _c("el-form-item", { attrs: { prop: "q0" } }, [
            _c(
              "label",
              { staticClass: "label" },
              [
                _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                  _vm._v(
                    "* 1. Are you applying for an Accounting or Financial Reporting Oversight Role, a secretary role on any NAB Group company board, or a role on the Executive Committee AND have you worked for EY (NAB external auditor) at any time since 1 October 2004? "
                  ),
                ]),
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.Question_DataChange },
                    model: {
                      value: _vm.ruleForm.q0,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "q0", $$v)
                      },
                      expression: "ruleForm.q0",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "Yes" } }),
                    _c("el-radio", { attrs: { label: "No" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-form-item", { attrs: { prop: "q1" } }, [
            _c(
              "label",
              { staticClass: "label" },
              [
                _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                  _vm._v(
                    "* 2. If applying for other NAB Group roles, have you worked for EY (NAB's external auditor) at any time in the past three years? "
                  ),
                ]),
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.Question_DataChange },
                    model: {
                      value: _vm.ruleForm.q1,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "q1", $$v)
                      },
                      expression: "ruleForm.q1",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "Yes" } }),
                    _c("el-radio", { attrs: { label: "No" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-form-item", { attrs: { prop: "q2" } }, [
            _c(
              "label",
              { staticClass: "label" },
              [
                _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                  _vm._v(
                    "* 3. In past three years, has your spouse, spousal equivalent, parent, child (dependent or otherwise) or sibling been a partner, principal, shareholder or professional employee of Ernst & Young (NAB's external auditor) AND have they worked on the NAB Audit? "
                  ),
                ]),
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.Question_DataChange },
                    model: {
                      value: _vm.ruleForm.q2,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "q2", $$v)
                      },
                      expression: "ruleForm.q2",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "Yes" } }),
                    _c("el-radio", { attrs: { label: "No" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.PartTwoShow
            ? _c(
                "div",
                [
                  _c("el-form-item", { attrs: { prop: "q3" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            "* 4. Have you been a professional employee, partner, principal, or shareholder of Ernst & Young at any time since 1 October 2004? "
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.Question_DataChange },
                            model: {
                              value: _vm.ruleForm.q3,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "q3", $$v)
                              },
                              expression: "ruleForm.q3",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "Yes" } }),
                            _c("el-radio", { attrs: { label: "No" } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.ruleForm.q3 === "Yes"
                    ? [
                        _c("p", { staticClass: "label mb-10" }, [
                          _vm._v(
                            " If yes, what was your job title and during what time period(s)? (If you had more than one role at Ernst & Young, please list each job title and period in that role separately. "
                          ),
                        ]),
                        _c("EYQuestionItem", {
                          ref: "EYQuestionItem",
                          attrs: {
                            "jobs-list": _vm.jobsList,
                            "edit-item-index": _vm.editItemIndex,
                          },
                        }),
                      ]
                    : _vm._e(),
                  _c("el-form-item", { attrs: { prop: "q4" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            "* 5. Please provide a brief description of your role and responsibilities at Ernst & Young: "
                          ),
                        ]),
                        _c("el-input", {
                          attrs: { type: "textarea" },
                          on: { input: _vm.Question_DataChange },
                          model: {
                            value: _vm.ruleForm.q4,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "q4", $$v)
                            },
                            expression: "ruleForm.q4",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-form-item", { attrs: { prop: "q5" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            "* 6. During your time at Ernst & Young, were you ever a member of the NAB Group Audit Engagement Team? "
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.Question_DataChange },
                            model: {
                              value: _vm.ruleForm.q5,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "q5", $$v)
                              },
                              expression: "ruleForm.q5",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "Yes" } }),
                            _c("el-radio", { attrs: { label: "No" } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.ruleForm.q5 === "Yes"
                    ? _c("el-form-item", { attrs: { prop: "q5_Details" } }, [
                        _c(
                          "label",
                          { staticClass: "label" },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  "* If yes, please list the financial years you were a member of the NAB Group Audit Engagement Team. "
                                ),
                              ]
                            ),
                            _c("el-input", {
                              attrs: { type: "textarea" },
                              on: { input: _vm.Question_DataChange },
                              model: {
                                value: _vm.ruleForm.q5_Details,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "q5_Details", $$v)
                                },
                                expression: "ruleForm.q5_Details",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("el-form-item", { attrs: { prop: "q5_Q1" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            "* 7. During your time at Ernst & Young, were you ever involved in providing non-audit services to the NAB Group? "
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.Question_DataChange },
                            model: {
                              value: _vm.ruleForm.q5_Q1,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "q5_Q1", $$v)
                              },
                              expression: "ruleForm.q5_Q1",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "Yes" } }),
                            _c("el-radio", { attrs: { label: "No" } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.ruleForm.q5_Q1 === "Yes"
                    ? _c("el-form-item", { attrs: { prop: "q5_Q1_Details" } }, [
                        _c(
                          "label",
                          { staticClass: "label" },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  "* If yes, please list what services you provided and during which financial year/s "
                                ),
                              ]
                            ),
                            _c("el-input", {
                              attrs: { type: "textarea" },
                              on: { input: _vm.Question_DataChange },
                              model: {
                                value: _vm.ruleForm.q5_Q1_Details,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "q5_Q1_Details", $$v)
                                },
                                expression: "ruleForm.q5_Q1_Details",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("el-form-item", { attrs: { prop: "q6" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "5px" } },
                          [
                            _vm._v(
                              "* 8. During your time at Ernst & Young did you serve in a professional capacity in a Prudential Audit "
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: { effect: "dark", placement: "top" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm._v(
                                      " “Prudential Audit” means an audit in relation to "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " the Banking Act 1959 (Cth) or in relation to the "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " Prudential Standards or Reporting Standards "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " within the meaning of Prudential Standard CPS "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " 510 of the Australian Prudential Regulation Authority) "
                                    ),
                                  ]
                                ),
                                _c("i", { staticClass: "el-icon-info" }),
                              ]
                            ),
                            _vm._v(" of NAB? "),
                          ],
                          1
                        ),
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.Question_DataChange },
                            model: {
                              value: _vm.ruleForm.q6,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "q6", $$v)
                              },
                              expression: "ruleForm.q6",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "Yes" } }),
                            _c("el-radio", { attrs: { label: "No" } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.ruleForm.q6 === "Yes"
                    ? _c("el-form-item", { attrs: { prop: "q6_Details" } }, [
                        _c(
                          "label",
                          { staticClass: "label" },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "5px" } },
                              [
                                _vm._v(
                                  "* If yes, please list the financial years you served in a professional capacity in a Prudential Audit of NAB. "
                                ),
                              ]
                            ),
                            _c("el-input", {
                              attrs: { type: "textarea" },
                              on: { input: _vm.Question_DataChange },
                              model: {
                                value: _vm.ruleForm.q6_Details,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "q6_Details", $$v)
                                },
                                expression: "ruleForm.q6_Details",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "label" }, [
                    _vm._v("9. If you accept this position with NAB, will:"),
                  ]),
                  _vm._l(_vm.listOf9, function (item) {
                    return _c(
                      "div",
                      { key: item.key },
                      [
                        _c("el-form-item", { attrs: { prop: item.key } }, [
                          _c(
                            "label",
                            { staticClass: "label" },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "5px" } },
                                [_vm._v("* " + _vm._s(item.label))]
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.Question_DataChange },
                                  model: {
                                    value: _vm.ruleForm[item.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, item.key, $$v)
                                    },
                                    expression: "ruleForm[item.key]",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "Yes" } }),
                                  _c("el-radio", { attrs: { label: "No" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.ruleForm[item.key] === "Yes"
                          ? _c(
                              "el-form-item",
                              { attrs: { prop: item.detail } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "* If yes, please provide details"
                                        ),
                                      ]
                                    ),
                                    _c("el-input", {
                                      attrs: { type: "textarea" },
                                      on: { input: _vm.Question_DataChange },
                                      model: {
                                        value: _vm.ruleForm[item.detail],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            item.detail,
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm[item.detail]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _c("el-form-item", { attrs: { prop: "q8_A" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                          _vm._v(
                            "* 10. Is your spouse, spousal equivalent, parent, child (dependent or not) or sibling (a Close Family Member) currently, or have they been at any time since 1 October 2004, a partner, principal, shareholder or employee of Ernst & Young? "
                          ),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.Question_DataChange },
                            model: {
                              value: _vm.ruleForm.q8_A,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "q8_A", $$v)
                              },
                              expression: "ruleForm.q8_A",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "Yes" } }),
                            _c("el-radio", { attrs: { label: "No" } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.ruleForm.q8_A === "Yes"
                    ? [
                        _c("p", { staticClass: "label mb-10" }, [
                          _vm._v("If yes, please provide details"),
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "q8_A_FamilyMemberType" } },
                          [
                            _c(
                              "label",
                              { staticClass: "label" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "5px" } },
                                  [
                                    _vm._v(
                                      "* Type of Close Family Member (i.e. spouse, sibling etc)"
                                    ),
                                  ]
                                ),
                                _c("el-input", {
                                  on: { input: _vm.Question_DataChange },
                                  model: {
                                    value: _vm.ruleForm.q8_A_FamilyMemberType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "q8_A_FamilyMemberType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.q8_A_FamilyMemberType",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "q8_A_JobTitle" } },
                          [
                            _c(
                              "label",
                              { staticClass: "label" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "5px" } },
                                  [_vm._v("* Job Title")]
                                ),
                                _c("el-input", {
                                  on: { input: _vm.Question_DataChange },
                                  model: {
                                    value: _vm.ruleForm.q8_A_JobTitle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "q8_A_JobTitle",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.q8_A_JobTitle",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  xs: 12,
                                  sm: 12,
                                  md: 12,
                                  lg: 12,
                                  xl: 12,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "q8_A_PeriodStart" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "label" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                            },
                                          },
                                          [_vm._v("Period start?")]
                                        ),
                                        _c("el-date-picker", {
                                          attrs: {
                                            "picker-options": _vm.pickerOptions,
                                            "value-format": "yyyy-MM-dd",
                                            format: "MM/yyyy",
                                            type: "month",
                                          },
                                          on: {
                                            change: _vm.Question_DataChange,
                                          },
                                          model: {
                                            value:
                                              _vm.ruleForm.q8_A_PeriodStart,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "q8_A_PeriodStart",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.q8_A_PeriodStart",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  xs: 12,
                                  sm: 12,
                                  md: 12,
                                  lg: 12,
                                  xl: 12,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "q8_A_PeriodConclude" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "label" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                            },
                                          },
                                          [_vm._v("Period conclude?")]
                                        ),
                                        _c("el-date-picker", {
                                          attrs: {
                                            "picker-options": _vm.pickerOptions,
                                            "value-format": "yyyy-MM-dd",
                                            format: "MM/yyyy",
                                            type: "month",
                                          },
                                          on: {
                                            change: _vm.Question_DataChange,
                                          },
                                          model: {
                                            value:
                                              _vm.ruleForm.q8_A_PeriodConclude,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "q8_A_PeriodConclude",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.q8_A_PeriodConclude",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm._l(_vm.listOf10, function (item) {
                    return _c(
                      "div",
                      { key: item.key },
                      [
                        _c("el-form-item", { attrs: { prop: item.key } }, [
                          _c(
                            "label",
                            { staticClass: "label" },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "5px" } },
                                [_vm._v("* " + _vm._s(item.label))]
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.Question_DataChange },
                                  model: {
                                    value: _vm.ruleForm[item.key],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, item.key, $$v)
                                    },
                                    expression: "ruleForm[item.key]",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "Yes" } }),
                                  _c("el-radio", { attrs: { label: "No" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.ruleForm[item.key] === "Yes"
                          ? [
                              _c("p", { staticClass: "label mb-10" }, [
                                _vm._v("If yes, please provide period worked"),
                              ]),
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: item.periodStart } },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "5px",
                                                  },
                                                },
                                                [_vm._v("Period start?")]
                                              ),
                                              _c("el-date-picker", {
                                                attrs: {
                                                  "picker-options":
                                                    _vm.pickerOptions,
                                                  "value-format": "yyyy-MM-dd",
                                                  format: "MM/yyyy",
                                                  type: "month",
                                                },
                                                on: {
                                                  change:
                                                    _vm.Question_DataChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.ruleForm[
                                                      item.periodStart
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm,
                                                      item.periodStart,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleForm[item.periodStart]",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { prop: item.periodConclude },
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "label" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "5px",
                                                  },
                                                },
                                                [_vm._v("Period conclude?")]
                                              ),
                                              _c("el-date-picker", {
                                                attrs: {
                                                  "picker-options":
                                                    _vm.pickerOptions,
                                                  "value-format": "yyyy-MM-dd",
                                                  format: "MM/yyyy",
                                                  type: "month",
                                                },
                                                on: {
                                                  change:
                                                    _vm.Question_DataChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.ruleForm[
                                                      item.periodConclude
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm,
                                                      item.periodConclude,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleForm[item.periodConclude]",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  _c("el-form-item", { attrs: { prop: "q8_E" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "5px" } },
                          [
                            _vm._v(
                              "is (or was) the relevant Close Family Member a partner, principal or shareholder from an Ernst & Young “office” "
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: { effect: "dark", placement: "top" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _vm._v(
                                      " In this question, “office” means a distinct sub-group "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " within Ernst & Young, whether distinguished "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " along geographic or practice lines. "
                                    ),
                                  ]
                                ),
                                _c("i", { staticClass: "el-icon-info" }),
                              ]
                            ),
                            _vm._v(
                              ' where the lead Ernst & Young NAB audit engagement partner primarily practices?" '
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.Question_DataChange },
                            model: {
                              value: _vm.ruleForm.q8_E,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "q8_E", $$v)
                              },
                              expression: "ruleForm.q8_E",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "Yes" } }),
                            _c("el-radio", { attrs: { label: "No" } }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.ruleForm.q8_E === "Yes"
                    ? [
                        _c("p", { staticClass: "label mb-10" }, [
                          _vm._v("If yes, please provide period worked"),
                        ]),
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  xs: 12,
                                  sm: 12,
                                  md: 12,
                                  lg: 12,
                                  xl: 12,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "q8_E_PeriodStart" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "label" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                            },
                                          },
                                          [_vm._v("Period start?")]
                                        ),
                                        _c("el-date-picker", {
                                          attrs: {
                                            "picker-options": _vm.pickerOptions,
                                            "value-format": "yyyy-MM-dd",
                                            format: "MM/yyyy",
                                            type: "month",
                                          },
                                          on: {
                                            change: _vm.Question_DataChange,
                                          },
                                          model: {
                                            value:
                                              _vm.ruleForm.q8_E_PeriodStart,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "q8_E_PeriodStart",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.q8_E_PeriodStart",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  xs: 12,
                                  sm: 12,
                                  md: 12,
                                  lg: 12,
                                  xl: 12,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "q8_E_PeriodConclude" } },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "label" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                            },
                                          },
                                          [_vm._v("Period conclude?")]
                                        ),
                                        _c("el-date-picker", {
                                          attrs: {
                                            "picker-options": _vm.pickerOptions,
                                            "value-format": "yyyy-MM-dd",
                                            format: "MM/yyyy",
                                            type: "month",
                                          },
                                          on: {
                                            change: _vm.Question_DataChange,
                                          },
                                          model: {
                                            value:
                                              _vm.ruleForm.q8_E_PeriodConclude,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "q8_E_PeriodConclude",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.q8_E_PeriodConclude",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.ContinueBtn
        ? _c(
            "el-button",
            {
              attrs: { loading: _vm.endAllRequests, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.Continue("ruleForm")
                },
              },
            },
            [_vm._v("Save and Continue ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }