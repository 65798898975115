<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import { preparationApi, saveFormDataApi, continueApi } from '@/api/CoIDeclaration'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'ComplianceReference',
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  data() {
    return {
      applicantDeclaration: {},
      currentFormSection: {},
      id: 0,
      ruleForm: {
        hasAssociation: '',
        coiAssociationDetail: '',
        hasRelativeWithAssociation: '',
        coiRelativeAssociationDetail: ''
      },
      rules: {
        hasAssociation: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        coiAssociationDetail: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        hasRelativeWithAssociation: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        coiRelativeAssociationDetail: [{ required: true, message: 'Required field!', trigger: 'blur' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      device: state => state.setting.device,
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'CoIDeclaration')
      // }
      this.allLoading = true
      const params = {
        Session: this.Session,
        ApplicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        this.allLoading = false
        const { data } = res
        this.currentFormSection = data.currentFormSection
        this.ruleForm.hasAssociation = data.hasAssociation
        this.ruleForm.hasRelativeWithAssociation = data.hasRelativeWithAssociation
        this.applicantDeclaration = data.applicantDeclaration || {}
        this.id = data.applicantDeclaration ? data.applicantDeclaration.id : 0
        this.ruleForm.coiAssociationDetail = data.applicantDeclaration ? data.applicantDeclaration.coiAssociationDetail : ''
        this.ruleForm.coiRelativeAssociationDetail = data.applicantDeclaration ? data.applicantDeclaration.coiRelativeAssociationDetail : ''
      }).catch(() => {
        this.allLoading = false
      })
    },
    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (this.ruleForm.hasAssociation === 'No') {
            this.ruleForm.coiAssociationDetail = ''
          }
          if (this.ruleForm.hasRelativeWithAssociation === 'No') {
            this.ruleForm.coiRelativeAssociationDetail = ''
          }
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            currentFormSection: this.currentFormSection,
            hasAssociation: this.ruleForm.hasAssociation,
            hasRelativeWithAssociation: this.ruleForm.hasRelativeWithAssociation
          }
          params.applicantDeclaration = this.applicantDeclaration
          params.applicantDeclaration.id = this.id
          params.applicantDeclaration.coiAssociationDetail = this.ruleForm.coiAssociationDetail
          params.applicantDeclaration.coiRelativeAssociationDetail = this.ruleForm.coiRelativeAssociationDetail
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },
    SaveFormData: _.debounce(
      function() {
        if (this.ruleForm.hasAssociation === 'No') {
          this.ruleForm.coiAssociationDetail = ''
        }
        if (this.ruleForm.hasRelativeWithAssociation === 'No') {
          this.ruleForm.coiRelativeAssociationDetail = ''
        }
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId,
          currentFormSection: this.currentFormSection,
          hasAssociation: this.ruleForm.hasAssociation,
          hasRelativeWithAssociation: this.ruleForm.hasRelativeWithAssociation
        }
        params.applicantDeclaration = this.applicantDeclaration
        params.applicantDeclaration.coiAssociationDetail = this.ruleForm.coiAssociationDetail
        params.applicantDeclaration.coiRelativeAssociationDetail = this.ruleForm.coiRelativeAssociationDetail
        saveFormDataApi(params).then(res => {
          if (res.data && res.data.session) {
            this.SaveFormDataSetSession(res.data.session)
          }
        })
      }, global.debounceTime)
  }
}
</script>

<template>
  <div v-loading="allLoading" class="ComplianceReference">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      :disabled="allDisabled"
    >

      <el-form-item
        prop="hasAssociation"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Are you aware of any positions, relationships or any other matters that could give rise to real or apparent conflicts of interest if you obtain the position you have applied for?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.hasAssociation" @change="SaveFormData">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-row v-if="ruleForm.hasAssociation==='Yes'" :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="16"
          :xl="16" class="label"
        >
          <span class="color-red">*</span> Please provide details
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="24" :lg="8"
          :xl="8"
        >
          <el-form-item
            prop="coiAssociationDetail"
          >
            <el-input
              v-model="ruleForm.coiAssociationDetail"
              type="textarea"
              maxlength="1000"
              @input="SaveFormData"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
        prop="hasRelativeWithAssociation"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> Do you have any relatives or associates that have any prior or present association with any organisation or individual that could be considered a real or apparent conflict of interest if you are successful in obtaining the position you have applied for?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.hasRelativeWithAssociation" @change="SaveFormData">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-row v-if="ruleForm.hasRelativeWithAssociation==='Yes'" :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="16"
          :xl="16" class="label"
        >
          <span class="color-red">*</span> Please provide details
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="24" :lg="8"
          :xl="8"
        >
          <el-form-item
            prop="coiRelativeAssociationDetail"
          >
            <el-input
              v-model="ruleForm.coiRelativeAssociationDetail"
              type="textarea"
              maxlength="1000"
              @input="SaveFormData"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-button type="primary" @click="SaveFormData('ruleForm')">Save form data</el-button> -->
      <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and Continue</el-button>
    </el-form>
  </div>
</template>

<style lang="scss" >
.ComplianceReference {
  .label {
    line-height: 1.5;
    font-size: 14px;
    color: #606266;
  }
  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
