<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import { preparationApi, continueApi, saveFormDataApi } from '@/api/RegisteredTaxAgent'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'RegisteredTaxAgent',
  inject: ['OnFormDataSave', 'ContinueSetSession', 'OnApplicantChange', 'SaveFormDataSetSession'],
  data() {
    return {
      ruleForm: {
        isRegisteredTaxAgent: '',
        TPBRegistrationNumber: ''
      },
      rules: {
        isRegisteredTaxAgent: [
          { required: true, message: 'Please select a value', trigger: 'blur' }
        ],
        TPBRegistrationNumber: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    }
  },
  watch: {
    'ruleForm.isRegisteredTaxAgent': {
      handler(newVal) {
        if (newVal === 'No') {
          this.ruleForm.TPBRegistrationNumber = ''
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'RegisteredTaxAgent')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        Applicant: this.Applicant
      }

      await preparationApi(params).then((res) => {
        const { data } = res
        this.ruleForm.isRegisteredTaxAgent = data.isRegisteredTaxAgent
        this.ruleForm.TPBRegistrationNumber = this.Applicant.tpbRegistrationNumber
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    OnTPBRegistrationNumberChange: _.debounce(
      function() {
        const params = {
          session: this.Session,
          applicant: this.Applicant
        }
        params.applicant.tpbRegistrationNumber = this.ruleForm.TPBRegistrationNumber
        this.OnApplicantChange(params)
        this.OnFormDataSave({ session: this.Session })
      }, global.debounceTime),

    async OnIsRegisteredTaxAgentChange() {
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        isRegisteredTaxAgent: this.ruleForm.isRegisteredTaxAgent
      }
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
      })
    },

    Continue() {
      this.$refs['ruleForm'].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            applicant: this.Applicant,
            isRegisteredTaxAgent: this.ruleForm.isRegisteredTaxAgent,
            TPBRegistrationNumber: this.ruleForm.TPBRegistrationNumber
          }
          params.applicant.tpbRegistrationNumber = this.ruleForm.TPBRegistrationNumber
          await continueApi(params).then((res) => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="RegisteredTaxAgent">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-form-item prop="isRegisteredTaxAgent">
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="24" :lg="16"
              :xl="16" class="label"
            >
              <span class="color-red">*</span> Are you a Registered Tax Agent?
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="24" :lg="8"
              :xl="8"
            >
              <el-radio-group v-model="ruleForm.isRegisteredTaxAgent" @change="OnIsRegisteredTaxAgentChange">
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-row v-if="ruleForm.isRegisteredTaxAgent === 'Yes'">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="12"
          :xl="12"
        >
          <el-form-item
            prop="TPBRegistrationNumber"
          >
            <label class="label">
              * TPB Registration Number
              <el-input v-model="ruleForm.TPBRegistrationNumber" @input="OnTPBRegistrationNumberChange" />
            </label>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and Continue</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.label {
  margin: 0;
  font-size: 14px;
  color: #606266;
}
</style>
