var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "ComplianceReference",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "hasAssociation" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " Are you aware of any positions, relationships or any other matters that could give rise to real or apparent conflicts of interest if you obtain the position you have applied for? "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.SaveFormData },
                          model: {
                            value: _vm.ruleForm.hasAssociation,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "hasAssociation", $$v)
                            },
                            expression: "ruleForm.hasAssociation",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.hasAssociation === "Yes"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" Please provide details "),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "coiAssociationDetail" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", maxlength: "1000" },
                            on: { input: _vm.SaveFormData },
                            model: {
                              value: _vm.ruleForm.coiAssociationDetail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "coiAssociationDetail",
                                  $$v
                                )
                              },
                              expression: "ruleForm.coiAssociationDetail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "hasRelativeWithAssociation" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " Do you have any relatives or associates that have any prior or present association with any organisation or individual that could be considered a real or apparent conflict of interest if you are successful in obtaining the position you have applied for? "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.SaveFormData },
                          model: {
                            value: _vm.ruleForm.hasRelativeWithAssociation,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "hasRelativeWithAssociation",
                                $$v
                              )
                            },
                            expression: "ruleForm.hasRelativeWithAssociation",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.hasRelativeWithAssociation === "Yes"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" Please provide details "),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "coiRelativeAssociationDetail" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", maxlength: "1000" },
                            on: { input: _vm.SaveFormData },
                            model: {
                              value: _vm.ruleForm.coiRelativeAssociationDetail,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "coiRelativeAssociationDetail",
                                  $$v
                                )
                              },
                              expression:
                                "ruleForm.coiRelativeAssociationDetail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ContinueBtn
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.endAllRequests, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.Continue("ruleForm")
                    },
                  },
                },
                [_vm._v("Save and Continue")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }