var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c("Header", {
        staticClass: "app-header",
        on: { navMobile: _vm.navMobile },
      }),
      _c("div", { staticClass: "app-main" }, [
        _vm._m(0),
        _c("section", { staticClass: "container" }, [
          _c("div", { staticClass: "checks" }, [_c("Select")], 1),
        ]),
        _c("footer", { staticClass: "app-footer" }, [
          _vm._v(
            " Copyright 2024 PreciseBackground.com. All rights reserved. "
          ),
        ]),
      ]),
      _c("div", { class: _vm.showNav ? "block" : "none" }, [
        _c("div", { staticClass: "nav-box" }, [
          _c(
            "div",
            { staticClass: "nav" },
            [_c("Sider", { attrs: { "from-page": "select" } })],
            1
          ),
          _c("div", { staticClass: "blank", on: { click: _vm.closeNav } }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "app-title" }, [
      _c("h1", [_vm._v("Background Check")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }