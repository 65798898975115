const state = {
  device: 'desktop',
  session_timeout: false
}

const mutations = {
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SESSION_TIMEOUT: (state, timeout) => {
    state.session_timeout = timeout
  }
}

const actions = {
  // remove token
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  // session timeout
  set_session_timeout({ commit }, timeout) {
    commit('SET_SESSION_TIMEOUT', timeout)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
