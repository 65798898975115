<script>
import { mapState } from 'vuex'
import { preparationApi } from '@/api/ElectronicLodgement'
export default {
  name: 'ElectronicLodgement',
  data() {
    return {
      currentFormSection: {},
      ruleForm: {
        electronicLodgementAgreed: ''
      },
      rules: {},
      moduleLoading: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.$getPreparation().session
    },
    Applicant() {
      return this.$getPreparation().applicant
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        session: this.Session
      }
      await preparationApi(params).then((res) => {
        const { data } = res
        this.ruleForm.electronicLodgementAgreed = data.electronicLodgementAgreed
        this.currentFormSection = data.currentFormSection
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="ElectronicLodgement">
    <!-- <h3>Acknowledgement of Electronic Lodgement of Personal Information</h3>
    <div class="mb-10 f-s-14" v-html="introduction" /> -->
    <el-divider />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item prop="electronicLodgementAgreed">
        <span slot="label">
          I agree to the electronic lodgement of my personal information
        </span>
        <el-radio-group v-model="ruleForm.electronicLodgementAgreed" disabled>
          <el-radio label="Yes" />
          <el-radio label="No" />
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss">
.ElectronicLodgement {
  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
