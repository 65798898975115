var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddressForm" },
    _vm._l(_vm.compliancesList, function (item) {
      return _c("div", { key: item.index, staticClass: "box" }, [
        _c("div", [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(item.fullAddress) +
                " (" +
                _vm._s(
                  _vm._f("parseDefaultTime")(item.fromDate, "{d}/{m}/{y}")
                ) +
                " - " +
                _vm._s(_vm._f("parseDefaultTime")(item.toDate, "{d}/{m}/{y}")) +
                ") "
            ),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }