import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth' // get token from cookie
import { Message } from 'element-ui'
import { resetTokenFunc } from '@/utils/resetTokenTime'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
  // baseURL: process.env.NODE_ENV !== 'development' ? 'https://dev-api.precisebackground.com' : '' // dev
  // baseURL: process.env.NODE_ENV !== 'development' ? 'https://uat-api.precisebackground.com' : '' // uat
  // baseURL: process.env.NODE_ENV !== 'development' ? 'https://api.precisebackground.com' : '' // pro

})

let errCount = false
const countUrlArr = ['change', 'save-form-data', 'continue']

service.interceptors.request.use(
  config => {
    // console.log('config------------', config)
    if (store.getters.token) {
      config.headers['Authorization'] = 'bearer ' + getToken()
      let countUrl = false
      for (let i = 0; i < countUrlArr.length; i++) {
        if (config.url.indexOf(countUrlArr[i]) !== -1) {
          // console.log(config.url, countUrlArr[i])
          countUrl = true
          errCount = true
          break
        }
      }
      if (countUrl) {
        store.dispatch('portCount/add_startPort')
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    // console.log('response-------------', response)
    let countUrl = false
    for (let i = 0; i < countUrlArr.length; i++) {
      if (response.request.responseURL.indexOf(countUrlArr[i]) !== -1) {
        countUrl = true
        break
      }
    }
    if (countUrl) {
      store.dispatch('portCount/add_endPort')
    }
    countUrl = false
    if (response.status === 200) {
      if (response.data.code === 200) {
        if (store.getters.token && !response.config.url.includes('refresh-token')) {
          resetTokenFunc()
        }
        // if continue port's applicant not equal to null,applicant needs to be overwritten
        if (response.request.responseURL.indexOf('continue') !== -1) {
          if (response.data.data.applicant) {
            const preparation = store.state.preparation.preparation
            preparation.applicant = response.data.data.applicant
            store.dispatch('preparation/setPreparation', preparation)
          }
        }
        return response.data
      } else if (response.data.code === 500 || response.data.code === 400) {
        if (!response.config.url.includes('/adobe-sign/preparation')) {
          Message({
            message: response.data.message,
            type: 'error:::',
            duration: 5 * 1000
          })
        }
        return Promise.reject(response.data.message)
      }
    }
  },
  error => {
    if (errCount) {
      store.dispatch('portCount/add_endPort')
    }
    errCount = false
    if (error.response.status === 401 || error.response.status === 403) {
      Message({
        message: 'Session expired. Please log in again.',
        type: 'error:::',
        duration: 5 * 1000
      })
      store.dispatch('user/resetToken')
      store.dispatch('preparation/resetPreparation')
      store.dispatch('section/reset_section')
      location.reload()
    } else {
      Message({
        message: error.message,
        type: 'error:::',
        duration: 2 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
