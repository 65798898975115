var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "ComplianceReference",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c("p", { staticClass: "bold" }, [_vm._v("Current Address")]),
      _c(
        "el-form",
        {
          ref: "mainForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          _vm.IsManualAddressEntryShow
            ? _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, lg: 24, xl: 24 } },
                    [
                      _c("el-form-item", { attrs: { prop: "fullAddress" } }, [
                        _c(
                          "label",
                          { staticClass: "label" },
                          [
                            _vm._v("Full Address "),
                            _c("AddressAutocomplete", {
                              attrs: {
                                initialaddress: _vm.form.fullAddress,
                                placeholder:
                                  "Start typing and pause to select the address",
                                "use-kleber": true,
                                "use-google": true,
                                from: "AddressHistory",
                                "search-after-characters": 5,
                              },
                              on: {
                                "update:initialaddress": function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "fullAddress",
                                    $event
                                  )
                                },
                                OnDetailReady:
                                  _vm.AddressAutocompleteOnDetailReady,
                                OnClear: _vm.AutocompleteAddressOnClear,
                                OnManualEntry:
                                  _vm.AddressAutocompleteOnManualEntry,
                                OnNoSelectionMade:
                                  _vm.AddressAutocompleteOnNoSelectionMade,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.IsManualAddressEntryShow
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, lg: 24, xl: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "selectedCountryCode",
                                label: "Country",
                              },
                            },
                            [
                              _c("GetAllGeoCountries", {
                                attrs: {
                                  "defalut-value": _vm.form.selectedCountryCode,
                                },
                                on: {
                                  "update:defalutValue": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "selectedCountryCode",
                                      $event
                                    )
                                  },
                                  "update:defalut-value": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "selectedCountryCode",
                                      $event
                                    )
                                  },
                                  getCountryData: _vm.OnManualCountryChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, lg: 24, xl: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "addressLine1",
                                label: "Address Line 1",
                              },
                            },
                            [
                              _c("el-input", {
                                on: { input: _vm.OnManualAddressChange },
                                model: {
                                  value: _vm.form.addressLine1,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "addressLine1", $$v)
                                  },
                                  expression: "form.addressLine1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, lg: 24, xl: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "addressLine2",
                                label: "Address Line 2",
                              },
                            },
                            [
                              _c("el-input", {
                                on: { input: _vm.OnManualAddressChange },
                                model: {
                                  value: _vm.form.addressLine2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "addressLine2", $$v)
                                  },
                                  expression: "form.addressLine2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "suburb", label: "Suburb" } },
                            [
                              _c("el-input", {
                                on: { input: _vm.OnManualAddressChange },
                                model: {
                                  value: _vm.form.suburb,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "suburb", $$v)
                                  },
                                  expression: "form.suburb",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.StateShow
                        ? _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "state", label: "State" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "popper-class": "dropdownClass",
                                      },
                                      on: { change: _vm.OnManualAddressChange },
                                      model: {
                                        value: _vm.form.state,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "state", $$v)
                                        },
                                        expression: "form.state",
                                      },
                                    },
                                    _vm._l(
                                      _vm.australianStates,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.adminCodes1.ISO3166_2,
                                          attrs: {
                                            label: item.adminCodes1.ISO3166_2,
                                            value: item.adminCodes1.ISO3166_2,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 6, lg: 6, xl: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "postCode", label: "Postcode" } },
                            [
                              _c("el-input", {
                                on: { input: _vm.OnManualAddressChange },
                                model: {
                                  value: _vm.form.postCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "postCode", $$v)
                                  },
                                  expression: "form.postCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "currentAddressFromDate" } },
                    [
                      _c(
                        "label",
                        { staticClass: "label" },
                        [
                          _vm._v("*From Date "),
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              disabled: _vm.currentAddressFromDateDisabled,
                              format: "dd/MM/yyyy",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "DD/MM/YYYY",
                              type: "date",
                            },
                            on: { change: _vm.CurrentAddressFromDateChange },
                            model: {
                              value: _vm.form.currentAddressFromDate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "currentAddressFromDate",
                                  $$v
                                )
                              },
                              expression: "form.currentAddressFromDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.PreviousAddressesShow
        ? _c(
            "div",
            [
              _c("p", { staticClass: "bold" }, [_vm._v("Previous Addresses")]),
              _c(
                "p",
                { staticStyle: { color: "#c92a50", "font-weight": "bold" } },
                [
                  _vm._v(
                    " Your address history must include all your residential addresses over the last " +
                      _vm._s(_vm.MinimumYears) +
                      " years. "
                  ),
                ]
              ),
              _c("AddressForm", {
                ref: "AddressFormRef",
                attrs: {
                  "current-address-from-date": _vm.form.currentAddressFromDate,
                  "minimum-years": _vm.MinimumYears,
                  "target-min-date": _vm.targetMinDate,
                  "australian-states": _vm.australianStates,
                  "previous-addresses": _vm.previousAddresses,
                },
                on: {
                  "update:currentAddressFromDate": function ($event) {
                    return _vm.$set(_vm.form, "currentAddressFromDate", $event)
                  },
                  "update:current-address-from-date": function ($event) {
                    return _vm.$set(_vm.form, "currentAddressFromDate", $event)
                  },
                  getPassinfo: _vm.getPassinfo,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.ContinueBtn
        ? _c(
            "el-button",
            {
              attrs: { loading: _vm.endAllRequests, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.Continue("mainForm")
                },
              },
            },
            [_vm._v("Save and Continue")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }