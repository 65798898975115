var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "welcome",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", { staticClass: "mb-20 f-s-14" }, [
        _vm._v("Dear " + _vm._s(_vm.ApplicantFirstName) + ","),
      ]),
      _c("div", {
        staticClass: "mb-20 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introductionShow) },
      }),
      _vm.ContinueBtn
        ? _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.Continue } },
            [_vm._v("Continue")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }