<script>
import { mapState } from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    isSingleName: {
      type: Boolean,
      default: () => false
    },
    allDisabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      enableSingleNameOption: false
    }
  },

  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    visible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    enableSingleName: {
      get() {
        return this.isSingleName
      },
      set(val) {
        console.log('in enableSingleName set(val)')
        this.$emit('update:isSingleName', val)
      }
    }
  },
  watch: {
    isSingleName() {
      this.enableSingleNameOption = this.isSingleName
    }
  },
  created() {
    this.enableSingleNameOption = this.isSingleName
  },
  methods: {
    saveSingleNameOption() {
      console.log('in saveSingleNameOption')
      this.enableSingleName = this.enableSingleNameOption
      this.visible = false
    }
  }
}
</script>

<template>
  <el-dialog
    ref="dialogEntity"
    class="dialog"
    :lock-scroll="false"
    :append-to-body="true"
    :visible.sync="visible"
    :width="device === 'mobile'?'90%':'800px'"
  >
    <div slot="title" class="dialog-title">What is legally known by single name only?</div>
    <div class="modal-content">
      <p><b>A single name only</b> is when your name comprises:</p>
      <ul>
        <li>only one word, or</li>
        <li>several words not using Western naming conventions (first name, middle name/s and family name).</li>
      </ul>
    </div>

    <div class="dialog-footer">
      <div style="margin-bottom: 20px;">
        <el-switch
          v-model="enableSingleNameOption"
          :disabled="allDisabled"
          off-text=""
        />
        <span style="font-weight: bold" class="ml-4"> I am legally known by single name only</span>
      </div>
      <div style="margin-top: 20px;">
        <el-button type="primary" @click="saveSingleNameOption">Confirm</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
.dialog-title {
  font-weight: bold;
  font-size: large;
  color: #206BAA;
}
.section-title {
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word;
}
.title {
  font-size: 15px;
  margin-top: 10px;
  word-wrap: normal;
  font-weight: bold;
}
.content {
  margin-bottom: 20px;
  word-wrap: normal;
}
.popup-img {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}
.imageListItem {
  min-width: 300px;
}
.modal-content{
  margin-bottom: 40px;
}
.dialog-footer{
  margin-up: 40px;
  position: relative;
}
</style>
