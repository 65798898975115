var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "ElectronicLodgement",
    },
    [
      _c("el-divider"),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "electronicLodgementAgreed" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(
                  " I agree to the electronic lodgement of my personal information "
                ),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.electronicLodgementAgreed,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "electronicLodgementAgreed", $$v)
                    },
                    expression: "ruleForm.electronicLodgementAgreed",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Yes" } }),
                  _c("el-radio", { attrs: { label: "No" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }