<script>
import * as VuePhoneNumberInput from './vue-phone-number-input/vue-phone-number-input.umd'
import './vue-phone-number-input/vue-phone-number-input.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
export default {
  name: 'PhoneNumberInput',
  components: { VuePhoneNumberInput },
  props: {
    number: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: () => false
    },
    error: {
      // 校验失败
      type: Boolean,
      default: false
    },
    disabled: {
      // 禁用
      type: Boolean,
      default: false
    },
    clearable: {
      // 是否可清空
      type: Boolean,
      default: false
    },
    validColor: {
      // 验证通过的颜色
      type: String,
      default: '#dddfe6'
    },
    color: {
      // 默认外观
      type: String,
      default: 'dodgerblue'
    },
    translations: {
      // 自定义文案
      type: Object,
      default: function() {
        return {
          countrySelectorLabel: '',
          countrySelectorError: '',
          phoneNumberLabel: '',
          example: ''
        }
      }
    },
    noCountrySelector: {
      // 是否不使用国家选择器，默认使用
      type: Boolean,
      default: false
    },
    defaultCountryCode: {
      type: String,
      default: 'AU'
    },
    preferredCountries: {
      // 默认展示在前面的国家，数组格式，值是国家代码的集合,如中国“CN”
      type: Array,
      default: () => ['AU', 'NZ']
    },
    borderRadius: {
      type: Number,
      default: 0
    },
    showCodeOnList: {
      // 是否在国家选择器内展示国际号
      type: Boolean,
      default: false
    },
    noFlags: {
      // 是否在国家选择器内展示国家的国旗
      type: Boolean,
      default: false
    },
    noExample: {
      // 是否展示输入示例
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      phone: ''
    }
  },
  mounted() {
    this.setDefaults()
  },
  methods: {
    setDefaults() {
      const parsedPhoneNumber = parsePhoneNumberFromString(this.number, 'NO')
      // console.log(parsedPhoneNumber)
      if (parsedPhoneNumber) {
        // console.log(this.$refs.vuePhoneNumberInput)
        // this.$nextTick(() => {
        // this.$refs.vuePhoneNumberInput.emitValues({
        //   countryCode: parsedPhoneNumber.country,
        //   phoneNumber: parsedPhoneNumber.nationalNumber
        // })
        this.$refs.vuePhoneNumberInput.countryCode = parsedPhoneNumber.country
        this.$refs.vuePhoneNumberInput.phoneNumber = parsedPhoneNumber.nationalNumber

        //   this.$forceUpdate()
        // })
      }
    },
    blur(formName) {
      this.$emit('phoneBlur', '')
    },
    update(allValue) {
      if (allValue.formatNational) {
        this.$nextTick(() => {
          this.phone = allValue.formatNational
        })
      } else {
        this.$nextTick(() => {
          this.phone = allValue.phoneNumber
        })
      }
      this.$emit('update', allValue)
    }
  }
}
</script>

<template>
  <div>
    <VuePhoneNumberInput
      ref="vuePhoneNumberInput"
      v-model="phone"
      :error="error"
      :valid-color="validColor"
      :color="color"
      :no-country-selector="noCountrySelector"
      :default-country-code="defaultCountryCode"
      :preferred-countries="preferredCountries"
      :disabled="disabled"
      :clearable="clearable"
      :show-code-on-list="showCodeOnList"
      :no-flags="noFlags"
      :border-radius="borderRadius"
      :no-example="noExample"
      :translations="translations"
      aria-label="Phone number"
      @update="update"
      @phone-number-blur="blur"
    />
  </div>

</template>

 <style lang="scss" scoped></style>
