<script>
import RefereesItem from './AddForm/index.vue'
import { refereesApi } from '@/api/pattern'
export default {
  name: 'EmploymentReferenceView',
  components: { RefereesItem },
  props: {
    applicantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      referees: [],
      moduleLoading: false
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }

      await refereesApi(params).then(res => {
        const { data } = res
        this.referees = data.getRefereesByApplicantId || []
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
      this.$refs.RefereesItem.getRefereesList()
    }
  }
}
</script>
<template>
  <div v-loading="moduleLoading" class="EmploymentReferenceView">
    <RefereesItem ref="RefereesItem" :referees="referees" />
  </div>
</template>

<style lang="scss" scoped>
.EmploymentReferenceView{
    .comp{
        margin: 20px 0;
        color:#206caa ;
    }
}
</style>
