const Section = 'section'

export function getSection() {
  return JSON.parse(sessionStorage.getItem(Section))
}

export function setSection(data) {
  sessionStorage.setItem(Section, JSON.stringify(data))
}

export function removeSection() {
  sessionStorage.removeItem(Section)
}

