var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "AuthorisedRepresentative",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "60%",
            "label-position": _vm.isMobile ? "top" : "left",
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "isRepresentative" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(
                  " Do you currently or have you previously had an AFS Representative Number? "
                ),
              ]),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.OnIsRepresentativeChange },
                  model: {
                    value: _vm.ruleForm.isRepresentative,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "isRepresentative", $$v)
                    },
                    expression: "ruleForm.isRepresentative",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Yes" } }),
                  _c("el-radio", { attrs: { label: "No" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showItem
            ? _c(
                "el-form-item",
                { attrs: { prop: "afsRepresentativeNumber" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 14, lg: 14, xl: 14 } },
                        [
                          _c(
                            "label",
                            { staticClass: "label" },
                            [
                              _vm._v(
                                "* Please provide your AFS Representative Number "
                              ),
                              _c("el-input", {
                                staticStyle: { "margin-top": "10px" },
                                on: {
                                  change: _vm.afsRepresentativeNumberOnchange,
                                },
                                model: {
                                  value: _vm.ruleForm.afsRepresentativeNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "afsRepresentativeNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.afsRepresentativeNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.ContinueBtn
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.endAllRequests, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.Continue("ruleForm")
                    },
                  },
                },
                [_vm._v("Save and Continue")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }