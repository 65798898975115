const Preparation = 'preparation'

export function getPreparation() {
  return JSON.parse(sessionStorage.getItem(Preparation))
}

export function setPreparation(data) {
  sessionStorage.setItem(Preparation, JSON.stringify(data))
}

export function removePreparation() {
  sessionStorage.removeItem(Preparation)
}
