var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "PersonalDetailsInternational",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c("el-divider"),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "50%",
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          !_vm.ruleForm.isSingleNameOnly
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "firstName" } },
                        [
                          _c("p", { staticClass: "label" }, [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(" Given Name (current legal name)"),
                          ]),
                          _c("el-input", {
                            attrs: {
                              label: "Given Name",
                              maxlength: 100,
                              disabled: _vm.ruleForm.isSingleNameOnly,
                            },
                            on: { input: _vm.applicantChange },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.ruleForm.firstName =
                                  _vm.ruleForm.firstName.replace(
                                    /[^a-zA-Z\s'-]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.firstName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "firstName", $$v)
                              },
                              expression: "ruleForm.firstName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "lastName" } },
                        [
                          _c("p", { staticClass: "label" }, [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(" Family Name (current legal name)"),
                          ]),
                          _c("el-input", {
                            attrs: { label: "Family Name", maxlength: 100 },
                            on: { input: _vm.applicantChange },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.ruleForm.lastName =
                                  _vm.ruleForm.lastName.replace(
                                    /[^a-zA-Z\s'-]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.lastName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "lastName", $$v)
                              },
                              expression: "ruleForm.lastName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "lastName" } },
                        [
                          _c("p", { staticClass: "label" }, [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(" Current legal name"),
                          ]),
                          _c("el-input", {
                            attrs: {
                              label: "Current legal name",
                              maxlength: 100,
                            },
                            on: { input: _vm.applicantChange },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.ruleForm.lastName =
                                  _vm.ruleForm.lastName.replace(
                                    /[^a-zA-Z\s'-]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.lastName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "lastName", $$v)
                              },
                              expression: "ruleForm.lastName",
                            },
                          }),
                          _c(
                            "a",
                            {
                              staticStyle: {
                                color: "#206CAA",
                                "font-size": "13px",
                                "font-weight": "bold",
                                cursor: "help",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showSingleNameTips = true
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-question-circle" }),
                              _vm._v(
                                " I am not legally known by single name only? "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          !_vm.ruleForm.isSingleNameOnly
            ? _c(
                "el-form-item",
                { attrs: { prop: "middleNames" } },
                [
                  _c("p", { staticClass: "label" }, [
                    _vm._v("Middle Name(s) (current legal names)"),
                  ]),
                  _c("el-input", {
                    attrs: {
                      label: "Middle Name(s) (current legal names)",
                      maxlength: 100,
                      disabled: _vm.ruleForm.isSingleNameOnly,
                    },
                    on: { input: _vm.applicantChange },
                    nativeOn: {
                      keyup: function ($event) {
                        _vm.ruleForm.middleNames =
                          _vm.ruleForm.middleNames.replace(/[^a-zA-Z\s'-]/g, "")
                      },
                    },
                    model: {
                      value: _vm.ruleForm.middleNames,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "middleNames", $$v)
                      },
                      expression: "ruleForm.middleNames",
                    },
                  }),
                  _c(
                    "a",
                    {
                      staticStyle: {
                        color: "#206CAA",
                        "font-size": "13px",
                        "font-weight": "bold",
                        cursor: "help",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showSingleNameTips = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-question-circle" }),
                      _vm._v(" I am legally known by single name only? "),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c("el-form-item", { attrs: { prop: "email" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _vm._v("* Email "),
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.ruleForm.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "email", $$v)
                            },
                            expression: "ruleForm.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c("el-form-item", { attrs: { prop: "phone" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _vm._v("* Contact Phone Number "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "Select the country and enter the phone number",
                              placement: "top",
                              "aria-describedby": "phone-input",
                            },
                          },
                          [_c("i", { staticClass: "el-icon-info" })]
                        ),
                        _vm.numberShow
                          ? _c("VuePhoneNumberInput", {
                              attrs: {
                                id: "phone-input",
                                number: _vm.ruleForm.phone,
                                disabled: _vm.allDisabled,
                              },
                              on: {
                                update: _vm.update,
                                phoneBlur: _vm.phoneBlur,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c("el-form-item", { attrs: { prop: "dateOfBirth" } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _vm._v("* Date of Birth "),
                        _c("el-date-picker", {
                          attrs: {
                            "value-format": "yyyy-MM-dd",
                            format: "dd/MM/yyyy",
                            "picker-options": _vm.pickerOptions,
                            placeholder: "DD/MM/YYYY",
                            type: "date",
                          },
                          on: { change: _vm.applicantChange },
                          model: {
                            value: _vm.ruleForm.dateOfBirth,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "dateOfBirth", $$v)
                            },
                            expression: "ruleForm.dateOfBirth",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _vm.IsManualAddressShow
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "initialAddress" } },
                        [
                          _c(
                            "label",
                            { staticClass: "label" },
                            [
                              _vm._v("* Place of Birth "),
                              _c("AddressAutocomplete", {
                                attrs: {
                                  initialaddress: _vm.ruleForm.initialAddress,
                                  placeholder:
                                    "Start typing and pause to select from the list correct",
                                  "use-google": true,
                                  "google-types": "(cities)",
                                  from: "PersonalDetailsInternational",
                                },
                                on: {
                                  "update:initialaddress": function ($event) {
                                    return _vm.$set(
                                      _vm.ruleForm,
                                      "initialAddress",
                                      $event
                                    )
                                  },
                                  OnDetailReady:
                                    _vm.OnBirthSuburbSearchDetailReady,
                                  OnManualEntry:
                                    _vm.OnBirthSuburbAutoCompleteManualEntry,
                                  OnNoSelectionMade:
                                    _vm.OnBirthSuburbNoSelectionMade,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.IsManualAddressShow
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                rules: [
                                  { required: true, message: "Required" },
                                ],
                              },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "label" },
                                [
                                  _vm._v("* Place of Birth Country "),
                                  _c("GetAllGeoCountries", {
                                    attrs: {
                                      "defalut-value":
                                        _vm.manualAddressEntry.countryCode,
                                      "geo-countries": _vm.geoCountries,
                                    },
                                    on: {
                                      "update:defalutValue": function ($event) {
                                        return _vm.$set(
                                          _vm.manualAddressEntry,
                                          "countryCode",
                                          $event
                                        )
                                      },
                                      "update:defalut-value": function (
                                        $event
                                      ) {
                                        return _vm.$set(
                                          _vm.manualAddressEntry,
                                          "countryCode",
                                          $event
                                        )
                                      },
                                      getCountryData: _vm.OnManualCountryChange,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.manualAddressEntry.countryCode === "AU"
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "birthState" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "label" },
                                    [
                                      _vm._v("* Place of Birth State "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "popper-class": "dropdownClass",
                                          },
                                          on: {
                                            change: _vm.OnManualStateChange,
                                          },
                                          model: {
                                            value: _vm.ruleForm.birthState,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "birthState",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.birthState",
                                          },
                                        },
                                        _vm._l(
                                          _vm.australianStates,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.adminCodes1.ISO3166_2,
                                              attrs: {
                                                label:
                                                  item.adminCodes1.ISO3166_2,
                                                value:
                                                  item.adminCodes1.ISO3166_2,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "birthSuburb" } },
                            [
                              _c(
                                "label",
                                { staticClass: "label" },
                                [
                                  _vm._v("* Place of Birth Suburb "),
                                  _c("el-input", {
                                    on: { input: _vm.OnManualSuburbChange },
                                    model: {
                                      value: _vm.ruleForm.birthSuburb,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "birthSuburb",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.birthSuburb",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { prop: "gender" } }, [
            _c(
              "label",
              { staticClass: "label" },
              [
                _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
                  _vm._v("* Gender"),
                ]),
                _c(
                  "el-radio-group",
                  {
                    on: { change: _vm.applicantChange },
                    model: {
                      value: _vm.ruleForm.gender,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "gender", $$v)
                      },
                      expression: "ruleForm.gender",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: "Male" } }, [
                      _vm._v("Male"),
                    ]),
                    _c("el-radio", { attrs: { label: "Female" } }, [
                      _vm._v("Female"),
                    ]),
                    _c("el-radio", { attrs: { label: "Other" } }, [
                      _vm._v("Indeterminate / Intersex / Unspecified"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-divider"),
          _c(
            "el-form-item",
            { attrs: { prop: "driversLicence" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" Do you have a Current Driver's Licence? "),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.OnShowDriversLicenceChange },
                          model: {
                            value: _vm.ruleForm.driversLicence,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "driversLicence", $$v)
                            },
                            expression: "ruleForm.driversLicence",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.driversLicence === "Yes"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "selectedDriversLicenseCountryCode",
                            },
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "label" },
                              [
                                _vm._v("* Driver's Licence State "),
                                _c("GetAllGeoCountries", {
                                  attrs: {
                                    "defalut-value":
                                      _vm.ruleForm
                                        .selectedDriversLicenseCountryCode,
                                    "geo-countries": _vm.geoCountries,
                                  },
                                  on: {
                                    "update:defalutValue": function ($event) {
                                      return _vm.$set(
                                        _vm.ruleForm,
                                        "selectedDriversLicenseCountryCode",
                                        $event
                                      )
                                    },
                                    "update:defalut-value": function ($event) {
                                      return _vm.$set(
                                        _vm.ruleForm,
                                        "selectedDriversLicenseCountryCode",
                                        $event
                                      )
                                    },
                                    getCountryData:
                                      _vm.OnDriversLicenseCountryChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _vm.ruleForm.selectedDriversLicenseCountryCode === "AU"
                          ? _c(
                              "el-form-item",
                              { attrs: { prop: "driversLicenceState" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _vm._v("* Driver's Licence Number "),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          "popper-class": "dropdownClass",
                                        },
                                        on: { change: _vm.applicantChange },
                                        model: {
                                          value:
                                            _vm.ruleForm.driversLicenceState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "driversLicenceState",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleForm.driversLicenceState",
                                        },
                                      },
                                      _vm._l(
                                        _vm.australianStates,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.adminCodes1.ISO3166_2,
                                            attrs: {
                                              label: item.adminCodes1.ISO3166_2,
                                              value: item.adminCodes1.ISO3166_2,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.driversLicenceState !== "" &&
                _vm.ruleForm.driversLicenceState !== null
                  ? _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "driversLicenceNumber" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _vm._v("* Driver's Licence Number "),
                                    _c("el-input", {
                                      on: { input: _vm.applicantChange },
                                      model: {
                                        value:
                                          _vm.ruleForm.driversLicenceNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "driversLicenceNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.driversLicenceNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                          [
                            _vm.ruleForm.selectedDriversLicenseCountryCode ===
                              "AU" && _vm.showDriverLicenseAdditionalInfo
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: { prop: "driversLicenceCardNumber" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "label" },
                                      [
                                        _vm._v(
                                          "* Driver's Licence Card Number "
                                        ),
                                        _c("el-input", {
                                          on: { input: _vm.applicantChange },
                                          model: {
                                            value:
                                              _vm.ruleForm
                                                .driversLicenceCardNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "driversLicenceCardNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.driversLicenceCardNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.ruleForm.selectedDriversLicenseCountryCode === "AU"
                          ? _c(
                              "el-col",
                              {
                                staticClass: "label",
                                staticStyle: {
                                  "margin-top": "-10px",
                                  "margin-bottom": "20px",
                                },
                                attrs: {
                                  xs: 24,
                                  sm: 24,
                                  md: 24,
                                  lg: 16,
                                  xl: 16,
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      color: "#206CAA",
                                      "font-weight": "bold",
                                      cursor: "help",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showDriversLicenceTips = true
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-question-circle",
                                    }),
                                    _vm._v(
                                      " Where can I find my Driver Licence Number and Card Number?"
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c("p", { staticClass: "label f-w-600" }, [
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            " Please attach a legible copy of the FRONT of your Driver’s Licence"
                          ),
                        ]),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "driversLicenceOrderApplicantDocuments",
                            },
                          },
                          [
                            _vm.driversLicenceOrderApplicantDocuments.length <
                              1 && _vm.imgcanDelete
                              ? _c("OnlyUpload", {
                                  on: {
                                    Document_Upload:
                                      _vm.CloudinaryUpload_UploadResult_DriversLicence,
                                  },
                                })
                              : _vm._e(),
                            _vm._l(
                              _vm.driversLicenceOrderApplicantDocuments,
                              function (item) {
                                return _c("OnlyUpload", {
                                  key: item.id,
                                  attrs: {
                                    "img-item": item,
                                    "can-delete": _vm.imgcanDelete,
                                  },
                                  on: {
                                    Document_Upload:
                                      _vm.CloudinaryUpload_UploadResult_DriversLicence,
                                    Document_Remove: _vm.Document_Remove,
                                  },
                                })
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm.showDriverLicenseAdditionalInfo
                      ? _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                          [
                            _c("p", { staticClass: "label f-w-600" }, [
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                " Please attach a legible copy of the BACK of your Driver’s Licence"
                              ),
                            ]),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "driversLicenceBackOrderApplicantDocuments",
                                },
                              },
                              [
                                _vm.driversLicenceBackOrderApplicantDocuments
                                  .length < 1 && _vm.imgcanDelete
                                  ? _c("OnlyUpload", {
                                      on: {
                                        Document_Upload:
                                          _vm.CloudinaryUpload_UploadResult_DriversLicenceBack,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._l(
                                  _vm.driversLicenceBackOrderApplicantDocuments,
                                  function (item) {
                                    return _c("OnlyUpload", {
                                      key: item.id,
                                      attrs: {
                                        "img-item": item,
                                        "can-delete": _vm.imgcanDelete,
                                      },
                                      on: {
                                        Document_Upload:
                                          _vm.CloudinaryUpload_UploadResult_DriversLicenceBack,
                                        Document_Remove: _vm.Document_Remove,
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "passport" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" Do you have a Current Passport? "),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.OnShowPassportChange },
                          model: {
                            value: _vm.ruleForm.passport,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "passport", $$v)
                            },
                            expression: "ruleForm.passport",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.passport === "Yes"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "passportNumber" } },
                          [
                            _c(
                              "label",
                              { staticClass: "label" },
                              [
                                _vm._v(" Passport Number "),
                                _c("el-input", {
                                  on: { input: _vm.applicantChange },
                                  model: {
                                    value: _vm.ruleForm.passportNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "passportNumber",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.passportNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "passportCountryCode" } },
                          [
                            _c(
                              "label",
                              { staticClass: "label" },
                              [
                                _vm._v(" Passport Country "),
                                _c("GetAllGeoCountries", {
                                  attrs: {
                                    disabled:
                                      _vm.allDisabled ||
                                      _vm.passportOrderApplicantDocuments
                                        .length > 0,
                                    "defalut-value":
                                      _vm.ruleForm.passportCountryCode,
                                    "geo-countries": _vm.geoCountries,
                                  },
                                  on: {
                                    "update:defalutValue": function ($event) {
                                      return _vm.$set(
                                        _vm.ruleForm,
                                        "passportCountryCode",
                                        $event
                                      )
                                    },
                                    "update:defalut-value": function ($event) {
                                      return _vm.$set(
                                        _vm.ruleForm,
                                        "passportCountryCode",
                                        $event
                                      )
                                    },
                                    getCountryData: _vm.OnPassportCountryChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.ruleForm.passportCountryCode
                  ? _c(
                      "div",
                      [
                        _vm.passportOrderApplicantDocuments.length < 1
                          ? _c("p", { staticClass: "f-w-600" }, [
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                " Please attach a legible copy of your Passport"
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: { prop: "passportOrderApplicantDocuments" },
                          },
                          [
                            _vm.passportOrderApplicantDocuments.length < 1 &&
                            _vm.imgcanDelete2
                              ? _c("OnlyUpload", {
                                  on: {
                                    Document_Upload:
                                      _vm.CloudinaryUpload_UploadResult_Passport,
                                  },
                                })
                              : _vm._e(),
                            _vm._l(
                              _vm.passportOrderApplicantDocuments,
                              function (item) {
                                return _c("OnlyUpload", {
                                  key: item.id,
                                  attrs: {
                                    "img-item": item,
                                    "can-delete": _vm.getImgcanDelete(item),
                                  },
                                  on: {
                                    Document_Upload:
                                      _vm.CloudinaryUpload_UploadResult_Passport,
                                    Document_Remove: _vm.Document_Remove,
                                  },
                                })
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.ruleForm.passport === "No"
            ? [
                _c("span", { staticClass: "label f-w-600" }, [
                  _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                  _vm._v(
                    " Please attach a legible copy of your Birth Certificate or Citizenship Certificate"
                  ),
                ]),
                _vm.birthCertificateOrderApplicantDocuments.length < 1
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "selectedCertificateType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.selectedCertificateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "selectedCertificateType",
                                  $$v
                                )
                              },
                              expression: "ruleForm.selectedCertificateType",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              { attrs: { label: "Birth" } },
                              [_vm._v("Birth Certificate")]
                            ),
                            _c(
                              "el-radio-button",
                              { attrs: { label: "Citizenship" } },
                              [_vm._v("Citizenship Certificate")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ruleForm.selectedCertificateType
                  ? _c("div", [
                      _vm.ruleForm.selectedCertificateType === "Birth"
                        ? _c(
                            "div",
                            [
                              _vm.birthCertificateOrderApplicantDocuments
                                .length < 1 && _vm.imgcanDelete2
                                ? _c("OnlyUpload", {
                                    on: {
                                      Document_Upload:
                                        _vm.CloudinaryUpload_UploadResult_BirthCertificate,
                                    },
                                  })
                                : _vm._e(),
                              _vm._l(
                                _vm.birthCertificateOrderApplicantDocuments,
                                function (item) {
                                  return _c("OnlyUpload", {
                                    key: item.id,
                                    attrs: {
                                      "img-item": item,
                                      "can-delete": _vm.getImgcanDelete(item),
                                    },
                                    on: {
                                      Document_Upload:
                                        _vm.CloudinaryUpload_UploadResult_BirthCertificate,
                                      Document_Remove: _vm.Document_Remove,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.ruleForm.selectedCertificateType === "Citizenship"
                        ? _c(
                            "div",
                            [
                              _vm.birthCertificateOrderApplicantDocuments
                                .length < 1
                                ? _c("OnlyUpload", {
                                    on: {
                                      Document_Upload:
                                        _vm.CloudinaryUpload_UploadResult_CitizenshipCert,
                                    },
                                  })
                                : _vm._e(),
                              _vm._l(
                                _vm.birthCertificateOrderApplicantDocuments,
                                function (item) {
                                  return _c("OnlyUpload", {
                                    key: item.id,
                                    attrs: {
                                      "img-item": item,
                                      "can-delete": _vm.getImgcanDelete(item),
                                    },
                                    on: {
                                      Document_Upload:
                                        _vm.CloudinaryUpload_UploadResult_CitizenshipCert,
                                      Document_Remove: _vm.Document_Remove,
                                    },
                                  })
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ])
                  : _c(
                      "div",
                      _vm._l(
                        _vm.birthCertificateOrderApplicantDocuments,
                        function (item) {
                          return _c("OnlyUpload", {
                            key: item.id,
                            attrs: {
                              "img-item": item,
                              "can-delete": _vm.getImgcanDelete(item),
                            },
                            on: {
                              Document_Upload:
                                _vm.CloudinaryUpload_UploadResult_CitizenshipCert,
                              Document_Remove: _vm.Document_Remove,
                            },
                          })
                        }
                      ),
                      1
                    ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "creditOrBankCard" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" Do you have a current credit/bank card? "),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.creditOrBankCardDisabled },
                          on: { change: _vm.OnShowCreditOrBankCardChange },
                          model: {
                            value: _vm.ruleForm.creditOrBankCard,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "creditOrBankCard", $$v)
                            },
                            expression: "ruleForm.creditOrBankCard",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.creditOrBankCard === "Yes"
            ? [
                _vm.creditOrBankCardOrderApplicantDocuments.length < 1
                  ? _c("div", [
                      !_vm.ruleForm.selectedCardType
                        ? _c("span", { staticClass: "label f-w-600" }, [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              " Please attach a legible copy of your Bank Card or Credit Card (Please cover card number)"
                            ),
                          ])
                        : _vm._e(),
                      _vm.ruleForm.selectedCardType === "Credit"
                        ? _c("span", { staticClass: "label f-w-600" }, [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              " Please attach a legible copy of your Credit Card (Please cover card number)"
                            ),
                          ])
                        : _vm._e(),
                      _vm.ruleForm.selectedCardType === "Bank"
                        ? _c("span", { staticClass: "label f-w-600" }, [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              " Please attach a legible copy of your Bank Card (Please cover card number)"
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.creditOrBankCardOrderApplicantDocuments.length < 1
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "selectedCardType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.selectedCardType,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "selectedCardType", $$v)
                              },
                              expression: "ruleForm.selectedCardType",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              { attrs: { label: "Credit" } },
                              [_vm._v("Credit Card")]
                            ),
                            _c(
                              "el-radio-button",
                              { attrs: { label: "Bank" } },
                              [_vm._v("Bank Card")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.ruleForm.selectedCardType ||
                _vm.creditOrBankCardOrderApplicantDocuments.length > 0
                  ? [
                      _vm.creditOrBankCardOrderApplicantDocuments.length < 1 &&
                      _vm.imgcanDelete2
                        ? _c("OnlyUpload", {
                            on: {
                              Document_Upload:
                                _vm.CloudinaryUpload_UploadResult_BankorCreditCard,
                            },
                          })
                        : _vm._e(),
                      _vm.creditOrBankCardOrderApplicantDocuments.length > 0
                        ? _c("span", { staticClass: "label f-w-600" }, [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              " Please attach a legible copy of your Bank Card or Credit Card (Please cover card number)"
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(
                        _vm.creditOrBankCardOrderApplicantDocuments,
                        function (item) {
                          return _c("OnlyUpload", {
                            key: item.id,
                            attrs: {
                              "img-item": item,
                              "can-delete": _vm.getImgcanDelete(item),
                            },
                            on: {
                              Document_Upload:
                                _vm.CloudinaryUpload_UploadResult_BankorCreditCard,
                              Document_Remove: _vm.Document_Remove,
                            },
                          })
                        }
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _c("el-divider"),
          _c(
            "el-form-item",
            { attrs: { prop: "otherNames" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " Have you ever been known by any other names including an Alias, Maiden or Previous Names? "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.OnShowOtherNamesChange },
                          model: {
                            value: _vm.ruleForm.otherNames,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "otherNames", $$v)
                            },
                            expression: "ruleForm.otherNames",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.otherNames === "Yes"
            ? _c(
                "div",
                [
                  _c("PersonalDetailsItem", {
                    ref: "PersonalDetailsItem",
                    attrs: { "other-names": _vm.otherNames },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.ContinueBtn
        ? _c(
            "el-button",
            {
              attrs: { loading: _vm.endAllRequests, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.Continue("ruleForm")
                },
              },
            },
            [_vm._v("Save and Continue ")]
          )
        : _vm._e(),
      _c("DriversLicenceTips", {
        attrs: {
          show: _vm.showDriversLicenceTips,
          "drivers-licence-state": _vm.ruleForm.driversLicenceState,
        },
        on: {
          "update:show": function ($event) {
            _vm.showDriversLicenceTips = $event
          },
        },
      }),
      _c("SingleNameTips", {
        attrs: {
          show: _vm.showSingleNameTips,
          "is-single-name": _vm.isSingleNameOnlyEnabled,
          "all-disabled": _vm.allDisabled,
        },
        on: {
          "update:show": function ($event) {
            _vm.showSingleNameTips = $event
          },
          "update:isSingleName": function ($event) {
            _vm.isSingleNameOnlyEnabled = $event
          },
          "update:is-single-name": function ($event) {
            _vm.isSingleNameOnlyEnabled = $event
          },
          "update:allDisabled": function ($event) {
            _vm.allDisabled = $event
          },
          "update:all-disabled": function ($event) {
            _vm.allDisabled = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }