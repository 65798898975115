<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import EYQuestionItem from './AddForm/index.vue'
import { preparationApi, saveFormDataApi, continueApi } from '@/api/EYQuestion'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'

export default {
  name: 'EYQuestionView',
  components: { EYQuestionItem },
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  provide() {
    return {
      OnItemAddNew: this.OnItemAddNew,
      OnItemRemoved: this.OnItemRemoved,
      getAddFormList: this.getAddFormList,
      OnItemChange: this.OnItemChange
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      ruleForm: {
        q0: '',
        q1: '',
        q2: '',
        q3: '',
        q4: '',
        q5: '',
        q5_Details: '',
        q5_Q1: '',
        q5_Q1_Details: '',
        q6: '',
        q6_Details: '',
        q7_A: '',
        q7_A_Details: '',
        q7_B: '',
        q7_B_Details: '',
        q7_C: '',
        q7_C_Details: '',
        q7_D: '',
        q7_D_Details: '',
        q7_E: '',
        q7_E_Details: '',
        q8_A: '',
        q8_A_FamilyMemberType: '',
        q8_A_JobTitle: '',
        q8_A_PeriodStart: null,
        q8_A_PeriodConclude: null,
        q8_B: '',
        q8_B_PeriodStart: null,
        q8_B_PeriodConclude: null,
        q8_C: '',
        q8_C_PeriodStart: null,
        q8_C_PeriodConclude: null,
        q8_D: '',
        q8_D_PeriodStart: null,
        q8_D_PeriodConclude: null,
        q8_E: '',
        q8_E_PeriodStart: null,
        q8_E_PeriodConclude: null
      },
      rules: {
        q0: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q1: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q2: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q3: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q4: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q5: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q5_Details: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q5_Q1: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q5_Q1_Details: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q6: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q7_A: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q7_A_Details: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q7_B: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q7_B_Details: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q7_C: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q7_C_Details: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q7_D: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q7_D_Details: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q7_E: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q7_E_Details: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q8_A: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q8_A_FamilyMemberType: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q8_A_JobTitle: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        q8_B: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q8_C: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q8_D: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        q8_E: [{ required: true, message: 'Please select a value', trigger: 'change' }]
      },
      index: 1,
      componentList: [],
      listOf9: [
        {
          key: 'q7_A',
          detail: 'q7_A_Details',
          label:
            'a) you continue to be a partner, principal, shareholder or professional employee of Ernst & Young after your engagement by NAB begins?'
        },
        {
          key: 'q7_B',
          detail: 'q7_B_Details',
          label:
            'b) you have actual ongoing influence over or participation in Ernst & Young’s business?'
        },
        {
          key: 'q7_C',
          detail: 'q7_C_Details',
          label:
            'c) there be a perception that you have ongoing influence over or participation in Ernst & Young’s business, even if in fact you have no such influence?'
        },
        {
          key: 'q7_D',
          detail: 'q7_D_Details',
          label: 'd) you have capital balances in Ernst & Young?'
        },
        {
          key: 'q7_E',
          detail: 'q7_E_Details',
          label:
            'e) you have an on-going financial arrangement with Ernst & Young?'
        }
      ],
      listOf10: [
        {
          key: 'q8_B',
          periodStart: 'q8_B_PeriodStart',
          periodConclude: 'q8_B_PeriodConclude',
          label:
            'is (or was) the relevant Close Family Member on the Ernst & Young NAB audit engagement team?'
        },
        {
          key: 'q8_C',
          periodStart: 'q8_C_PeriodStart',
          periodConclude: 'q8_C_PeriodConclude',
          label:
            'is (or was) the relevant Close Family Member a person who supervises or has direct management responsibility for the NAB audit, or who evaluates the performance or recommends the compensation of the audit engagement partner, or who provides quality control or other oversight of NAB’s audit?'
        },
        {
          key: 'q8_D',
          periodStart: 'q8_D_PeriodStart',
          periodConclude: 'q8_D_PeriodConclude',
          label:
            'is (or was) the relevant Close Family Member a partner, principal shareholder or managerial employee of Ernst & Young who  provided (or expects to provide) 10 or more hours of non-audit services to NAB during a financial year?'
        }
      ],
      currentFormSection: {},
      editItemIndex: null,
      eyQuestion_Id: null,
      jobsList: [],
      jobs: [],
      status: false,
      allLoading: false,
      id: 0
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Intro() {
      return this.preparation.intro
    },
    Applicant() {
      return this.preparation.applicant
    },
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    PartTwoShow() {
      return this.ruleForm.q0 === 'Yes' || this.ruleForm.q1 === 'Yes' || this.ruleForm.q2 === 'Yes'
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'EYQuestion')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.ruleForm.applicantId = this.ApplicantId
        this.currentFormSection = data.currentFormSection
        this.editItemIndex = data.editItemIndex
        this.eyQuestion_Id = data.eyQuestion.jobs.eyQuestion_Id || null
        this.ruleForm = data.eyQuestion.eyQuestion
        this.id = data.eyQuestion.eyQuestion.id
        this.jobsList = data.eyQuestion.jobs
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async OnItemChange() {
      console.log(this.jobsList)
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        formData: {
          editItemIndex: this.jobsList.length,
          eyQuestionExt: {
            jobs: this.jobsList,
            eyQuestion: {
              ...this.ruleForm,
              applicantId: this.ApplicantId,
              id: this.id
            },
            removedJobs: []
          }
        }
      }
      params.formData.eyQuestionExt.jobs.forEach(item => {
        item.eyQuestion_Id = this.eyQuestion_Id
      })
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    async OnItemAddNew(list) {
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        formData: {
          editItemIndex: list.length,
          eyQuestionExt: {
            jobs: list,
            eyQuestion: {
              ...this.ruleForm,
              applicantId: this.ApplicantId,
              id: this.id
            },
            removedJobs: []
          }
        }
      }
      params.formData.eyQuestionExt.jobs.forEach(item => {
        item.eyQuestion_Id = this.eyQuestion_Id
      })
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    async OnItemRemoved(item, list) {
      if (!item.jobTitle) return
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        formData: {
          editItemIndex: -1,
          eyQuestionExt: {
            jobs: list,
            eyQuestion: {
              ...this.ruleForm,
              applicantId: this.ApplicantId,
              id: this.id
            },
            removedJobs: [item]
          }
        }
      }
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    Question_DataChange: _.debounce(
      function() {
        if (!this.PartTwoShow) {
          this.jobsList = []
          this.ruleForm = {
            id: this.id,
            q0: this.ruleForm.q0,
            q1: this.ruleForm.q1,
            q2: this.ruleForm.q2,
            q3: '',
            q4: '',
            q5: '',
            q5_Details: '',
            q5_Q1: '',
            q5_Q1_Details: '',
            q6: '',
            q6_Details: '',
            q7_A: '',
            q7_A_Details: '',
            q7_B: '',
            q7_B_Details: '',
            q7_C: '',
            q7_C_Details: '',
            q7_D: '',
            q7_D_Details: '',
            q7_E: '',
            q7_E_Details: '',
            q8_A: '',
            q8_A_FamilyMemberType: '',
            q8_A_JobTitle: '',
            q8_A_PeriodStart: null,
            q8_A_PeriodConclude: null,
            q8_B: '',
            q8_B_PeriodStart: null,
            q8_B_PeriodConclude: null,
            q8_C: '',
            q8_C_PeriodStart: null,
            q8_C_PeriodConclude: null,
            q8_D: '',
            q8_D_PeriodStart: null,
            q8_D_PeriodConclude: null,
            q8_E: '',
            q8_E_PeriodStart: null,
            q8_E_PeriodConclude: null
          }
        }
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId,
          formData: {
            editItemIndex: this.jobsList.length,
            eyQuestionExt: {
              jobs: this.jobsList,
              eyQuestion: {
                ...this.ruleForm,
                applicantId: this.ApplicantId,
                id: this.id
              },
              removedJobs: []
            }
          }
        }
        params.formData.eyQuestionExt.jobs.forEach(item => {
          item.eyQuestion_Id = this.eyQuestion_Id
        })
        saveFormDataApi(params).then(res => {
          if (res.data && res.data.session) {
            this.SaveFormDataSetSession(res.data.session)
          }
        })
      }, global.debounceTime),

    async SaveFormData(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (this.ruleForm.q3 === 'Yes') {
            this.$refs.EYQuestionItem.handleSure()
            if (!this.status) {
              return
            } else {
              this.jobsList.forEach(item => {
                item.eyQuestion_Id = this.eyQuestion_Id
              })
            }
          }
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            formData: {
              editItemIndex: this.editItemIndex,
              eyQuestionExt: {
                jobs: this.jobsList,
                eyQuestion: {
                  ...this.ruleForm,
                  applicantId: this.ApplicantId,
                  id: this.id
                },
                removedJobs: []
              }
            }
          }
          await saveFormDataApi(params).then(res => {
            if (res.data && res.data.session) {
              this.SaveFormDataSetSession(res.data.session)
            }
          })
        }
      })
    },
    getAddFormList(list, status) {
      this.jobsList = list
      this.status = status
    },
    Continue(formName) {
      console.log(this.Session)
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (this.ruleForm.q3 === 'Yes') {
            this.$refs.EYQuestionItem.handleSure()
            if (!this.status) {
              return
            } else {
              this.jobsList.forEach(item => {
                item.eyQuestion_Id = this.eyQuestion_Id
              })
            }
          }
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            eyQuestion_Ext: {
              jobs: this.jobsList,
              eyQuestion: {
                ...this.ruleForm,
                applicantId: this.ApplicantId,
                id: this.id
              },
              removedJobs: []
            },
            currentFormSection: this.currentFormSection
          }
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="EYQuestionView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-form-item
        prop="q0"
      >
        <label class="label">
          <div style="margin-bottom: 5px;">* 1. Are you applying for an Accounting or Financial Reporting Oversight
            Role, a secretary role on any NAB Group company board, or a role on the Executive Committee AND have you
            worked for EY (NAB external auditor) at any time since 1 October 2004?
          </div>
          <el-radio-group v-model="ruleForm.q0" @change="Question_DataChange">
            <el-radio label="Yes" />
            <el-radio label="No" />
          </el-radio-group>
        </label>
      </el-form-item>
      <el-form-item
        prop="q1"
      >
        <label class="label">
          <div style="margin-bottom: 5px;">* 2. If applying for other NAB Group roles, have you worked for EY (NAB's
            external auditor) at any time in the past three years?
          </div>
          <el-radio-group v-model="ruleForm.q1" @change="Question_DataChange">
            <el-radio label="Yes" />
            <el-radio label="No" />
          </el-radio-group>
        </label>
      </el-form-item>
      <el-form-item
        prop="q2"
      >
        <label class="label">
          <div style="margin-bottom: 5px;">* 3. In past three years, has your spouse, spousal equivalent, parent, child
            (dependent or otherwise) or sibling been a partner, principal, shareholder or professional employee of Ernst
            & Young (NAB's external auditor) AND have they worked on the NAB Audit?
          </div>
          <el-radio-group v-model="ruleForm.q2" @change="Question_DataChange">
            <el-radio label="Yes" />
            <el-radio label="No" />
          </el-radio-group>
        </label>
      </el-form-item>

      <div v-if="PartTwoShow">

        <el-form-item
          prop="q3"
        >
          <label class="label">
            <div style="margin-bottom: 5px;">* 4. Have you been a professional employee, partner, principal, or
              shareholder of Ernst & Young at any time since 1 October 2004?
            </div>
            <el-radio-group v-model="ruleForm.q3" @change="Question_DataChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </label>
        </el-form-item>

        <template v-if="ruleForm.q3 === 'Yes'">
          <p class="label mb-10">
            If yes, what was your job title and during what time period(s)? (If
            you had more than one role at Ernst & Young, please list each job
            title and period in that role separately.
          </p>
          <EYQuestionItem ref="EYQuestionItem" :jobs-list="jobsList" :edit-item-index="editItemIndex" />
        </template>

        <el-form-item
          prop="q4"
        >
          <label class="label">
            <div style="margin-bottom: 5px;">* 5. Please provide a brief description of your role and responsibilities
              at Ernst & Young:
            </div>
            <el-input v-model="ruleForm.q4" type="textarea" @input="Question_DataChange" />
          </label>
        </el-form-item>

        <el-form-item
          prop="q5"
        >
          <label class="label">
            <div style="margin-bottom: 5px;">* 6. During your time at Ernst & Young, were you ever a member of the NAB
              Group Audit Engagement Team?
            </div>
            <el-radio-group v-model="ruleForm.q5" @change="Question_DataChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </label>
        </el-form-item>

        <el-form-item
          v-if="ruleForm.q5 === 'Yes'"
          prop="q5_Details"
        >
          <label class="label">
            <div style="margin-bottom: 5px;">* If yes, please list the financial years you were a member of the NAB
              Group Audit Engagement Team.
            </div>
            <el-input v-model="ruleForm.q5_Details" type="textarea" @input="Question_DataChange" />
          </label>
        </el-form-item>

        <el-form-item
          prop="q5_Q1"
        >
          <label class="label">
            <div style="margin-bottom: 5px;">* 7. During your time at Ernst & Young, were you ever involved in providing
              non-audit services to the NAB Group?
            </div>
            <el-radio-group v-model="ruleForm.q5_Q1" @change="Question_DataChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </label>
        </el-form-item>

        <el-form-item
          v-if="ruleForm.q5_Q1 === 'Yes'"
          prop="q5_Q1_Details"
        >
          <label class="label">
            <div style="margin-bottom: 5px;">* If yes, please list what services you provided and during which financial
              year/s
            </div>
            <el-input v-model="ruleForm.q5_Q1_Details" type="textarea" @input="Question_DataChange" />
          </label>
        </el-form-item>

        <el-form-item prop="q6">
          <label class="label">
            <div style="margin-bottom: 5px;">* 8. During your time at Ernst & Young did you serve in a professional
              capacity in a Prudential Audit
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  “Prudential Audit” means an audit in relation to <br>
                  the Banking Act 1959 (Cth) or in relation to the <br>
                  Prudential Standards or Reporting Standards <br>
                  within the meaning of Prudential Standard CPS <br>
                  510 of the Australian Prudential Regulation Authority)
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
              of NAB?
            </div>
            <el-radio-group v-model="ruleForm.q6" @change="Question_DataChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </label>
        </el-form-item>

        <el-form-item
          v-if="ruleForm.q6 === 'Yes'"
          prop="q6_Details"
        >
          <label class="label">
            <div style="margin-bottom: 5px;">* If yes, please list the financial years you served in a professional
              capacity in a Prudential Audit of NAB.
            </div>
            <el-input v-model="ruleForm.q6_Details" type="textarea" @input="Question_DataChange" />
          </label>
        </el-form-item>

        <p class="label">9. If you accept this position with NAB, will:</p>
        <div v-for="item in listOf9" :key="item.key">
          <el-form-item :prop="item.key">
            <label class="label">
              <div style="margin-bottom: 5px;">* {{ item.label }}</div>
              <el-radio-group v-model="ruleForm[item.key]" @change="Question_DataChange">
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </label>
          </el-form-item>

          <el-form-item
            v-if="ruleForm[item.key] === 'Yes'"
            :prop="item.detail"
          >
            <label class="label">
              <div style="margin-bottom: 5px;">* If yes, please provide details</div>
              <el-input v-model="ruleForm[item.detail]" type="textarea" @input="Question_DataChange" />
            </label>
          </el-form-item>
        </div>

        <el-form-item
          prop="q8_A"
        >
          <label class="label">
            <div style="margin-bottom: 5px;">* 10. Is your spouse, spousal equivalent, parent, child (dependent or not)
              or sibling (a Close Family Member) currently, or have they been at any time since 1 October 2004, a
              partner, principal, shareholder or employee of Ernst & Young?
            </div>
            <el-radio-group v-model="ruleForm.q8_A" @change="Question_DataChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </label>
        </el-form-item>

        <template v-if="ruleForm.q8_A === 'Yes'">
          <p class="label mb-10">If yes, please provide details</p>
          <el-form-item
            prop="q8_A_FamilyMemberType"
          >
            <label class="label">
              <div style="margin-bottom: 5px;">* Type of Close Family Member (i.e. spouse, sibling etc)</div>
              <el-input v-model="ruleForm.q8_A_FamilyMemberType" @input="Question_DataChange" />
            </label>
          </el-form-item>
          <el-form-item prop="q8_A_JobTitle">
            <label class="label">
              <div style="margin-bottom: 5px;">* Job Title</div>
              <el-input v-model="ruleForm.q8_A_JobTitle" @input="Question_DataChange" />
            </label>
          </el-form-item>
          <el-row :gutter="20">
            <el-col
              :xs="12" :sm="12" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item prop="q8_A_PeriodStart">
                <label class="label">
                  <div style="margin-bottom: 5px;">Period start?</div>
                  <el-date-picker
                    v-model="ruleForm.q8_A_PeriodStart" :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    format="MM/yyyy"
                    type="month"
                    @change="Question_DataChange"
                  />
                </label>
              </el-form-item>
            </el-col>
            <el-col
              :xs="12" :sm="12" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item prop="q8_A_PeriodConclude">
                <label class="label">
                  <div style="margin-bottom: 5px;">Period conclude?</div>
                  <el-date-picker
                    v-model="ruleForm.q8_A_PeriodConclude" :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    format="MM/yyyy"
                    type="month"
                    @change="Question_DataChange"
                  />
                </label>
              </el-form-item>
            </el-col>
          </el-row>
        </template>

        <div v-for="item in listOf10" :key="item.key">
          <el-form-item :prop="item.key">
            <label class="label">
              <div style="margin-bottom: 5px;">* {{ item.label }}</div>
              <el-radio-group v-model="ruleForm[item.key]" @change="Question_DataChange">
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </label>
          </el-form-item>

          <template v-if="ruleForm[item.key] === 'Yes'">
            <p class="label mb-10">If yes, please provide period worked</p>
            <el-row :gutter="20">
              <el-col
                :xs="12" :sm="12" :md="12" :lg="12"
                :xl="12"
              >
                <el-form-item :prop="item.periodStart">
                  <label class="label">
                    <div style="margin-bottom: 5px;">Period start?</div>
                    <el-date-picker
                      v-model="ruleForm[item.periodStart]" :picker-options="pickerOptions"
                      value-format="yyyy-MM-dd"
                      format="MM/yyyy"
                      type="month"
                      @change="Question_DataChange"
                    />
                  </label>
                </el-form-item>
              </el-col>
              <el-col
                :xs="12" :sm="12" :md="12" :lg="12"
                :xl="12"
              >
                <el-form-item :prop="item.periodConclude">
                  <label class="label">
                    <div style="margin-bottom: 5px;">Period conclude?</div>
                    <el-date-picker
                      v-model="ruleForm[item.periodConclude]" :picker-options="pickerOptions"
                      value-format="yyyy-MM-dd"
                      format="MM/yyyy"
                      type="month"
                      @change="Question_DataChange"
                    />
                  </label>
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </div>

        <el-form-item prop="q8_E">
          <label class="label">
            <div style="margin-bottom: 5px;">is (or was) the relevant Close Family Member a partner, principal or
              shareholder from an Ernst & Young “office”
              <el-tooltip class="item" effect="dark" placement="top">
                <div slot="content">
                  In this question, “office” means a distinct sub-group <br>
                  within Ernst & Young, whether distinguished <br>
                  along geographic or practice lines.
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
              where the lead Ernst & Young NAB audit engagement partner primarily
              practices?"
            </div>
            <el-radio-group v-model="ruleForm.q8_E" @change="Question_DataChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </label>
        </el-form-item>

        <template v-if="ruleForm.q8_E === 'Yes'">
          <p class="label mb-10">If yes, please provide period worked</p>
          <el-row :gutter="20">
            <el-col
              :xs="12" :sm="12" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item prop="q8_E_PeriodStart">
                <label class="label">
                  <div style="margin-bottom: 5px;">Period start?</div>
                  <el-date-picker
                    v-model="ruleForm.q8_E_PeriodStart" :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    format="MM/yyyy"
                    type="month"
                    @change="Question_DataChange"
                  />
                </label>
              </el-form-item>
            </el-col>
            <el-col
              :xs="12" :sm="12" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item prop="q8_E_PeriodConclude">
                <label class="label">
                  <div style="margin-bottom: 5px;">Period conclude?</div>
                  <el-date-picker
                    v-model="ruleForm.q8_E_PeriodConclude" :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd"
                    format="MM/yyyy"
                    type="month"
                    @change="Question_DataChange"
                  />
                </label>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </div>

    </el-form>

    <!-- <el-button type="primary" @click="SaveFormData('ruleForm')">Save form data</el-button> -->
    <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and
      Continue
    </el-button>
  </div>
</template>

<style lang="scss" scoped>
.EYQuestionView {
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
  }
}
</style>
