<script>
import { mapState } from 'vuex'
export default {
  name: 'ReopenNotesView',
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      preparation: state => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Notes() {
      return this.preparation.session.current.formSection.reopenNotes
    }
  }
}
</script>

<template>
  <div class="ReopenNotes">
    <div class="expression-left-box">
      <i class="el-icon-circle-check" />
      <span style="margin-left: 10px">&nbsp;{{ Notes }}</span>
    </div>
    <i class="el-icon-close" />
  </div>
</template>

<style lang="scss" scoped>
.ReopenNotes {
  color: #fff;
  background-color: #e9a100 !important;
  padding: 40px 10px !important;
  display: flex;
  justify-content: space-between;
}
</style>

