import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/entitlement-to-work2' : '/proxy/api/applicant/entitlement-to-work2'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}

export function onCitizenShipCountryChangeApi(data) {
  return service({
    url: `${api}/on-citizen-ship-country-change`,
    method: 'post',
    data: data
  })
}

export function onDualCitizenChangeApi(data) {
  return service({
    url: `${api}/on-dual-citizen-change`,
    method: 'post',
    data: data
  })
}

export function onCitizenShipDualCountryChangeApi(data) {
  return service({
    url: `${api}/on-citizen-ship-dual-country-change`,
    method: 'post',
    data: data
  })
}

export function hasPassportOnChangeApi(data) {
  return service({
    url: `${api}/has-passport-on-change`,
    method: 'post',
    data: data
  })
}

export function onPassportCountryChangeApi(data) {
  return service({
    url: `${api}/on-passport-country-change`,
    method: 'post',
    data: data
  })
}

export function onCertificateDocumentTypeChangeApi(data) {
  return service({
    url: `${api}/on-certificate-document-type-change`,
    method: 'post',
    data: data
  })
}

export function uploadApi(data) {
  return service({
    url: `${api}/upload`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadOtherNamApi(data) {
  return service({
    url: `${api}/upload-other-name`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function onOtherNameDocumentTypeChangeApi(data) {
  return service({
    url: `${api}/on-other-name-document-type-change`,
    method: 'post',
    data: data
  })
}

export function removeDocumentApi(data) {
  return service({
    url: `${api}/remove-document`,
    method: 'post',
    data: data
  })
}

export function continueApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}
