var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "modal",
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-content" }, [
      _c("h4", [
        _c("i", {
          staticClass: "fa fa-spin fa-circle-o-notch",
          staticStyle: { "margin-right": "10px" },
        }),
        _vm._v("Your application is currently being submitted, please wait..."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }