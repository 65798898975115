var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "ProofOfIdentity",
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c("div", [
              _c("h3", [_vm._v("Commencement Document")]),
              _vm.commencementOrderApplicantDocuments.length
                ? _c(
                    "div",
                    [
                      _c("ImageShow", {
                        attrs: {
                          "img-list": [
                            _vm.commencementOrderApplicantDocuments[0],
                          ],
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _vm.getChangeOfNameOrderApplicantDocuments.length
              ? _c("div", [
                  _c("h3", [_vm._v("Proof of Name Change Document")]),
                  _c(
                    "div",
                    [
                      _c("ImageShow", {
                        attrs: {
                          "img-list":
                            _vm.getChangeOfNameOrderApplicantDocuments,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c("div", [
              _c("h3", [_vm._v("Primary Document")]),
              _vm.primaryOrderApplicantDocuments.length
                ? _c(
                    "div",
                    [
                      _c("ImageShow", {
                        attrs: {
                          "img-list": [_vm.primaryOrderApplicantDocuments[0]],
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } }, [
            _c("div", [
              _c("h3", [_vm._v("Secondary Documents")]),
              _vm.secondaryOrderApplicantDocuments.length
                ? _c(
                    "div",
                    [
                      _c("ImageShow", {
                        attrs: {
                          "img-list": _vm.secondaryOrderApplicantDocuments,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
      _vm.listFilterSelfieDocuments.length && _vm.from === "applicant"
        ? _c("div", [
            _c("h3", [_vm._v("Selfie with Photo ID Document")]),
            _c(
              "div",
              [
                _c("ImageShow", {
                  attrs: { "img-list": _vm.listFilterSelfieDocuments },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }