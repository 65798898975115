var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addressAutocomplete" },
    [
      _c("el-autocomplete", {
        staticStyle: { width: "100%" },
        attrs: {
          placeholder: _vm.placeholderShow,
          "fetch-suggestions": _vm.getSearchResult,
          "popper-append-to-body": false,
          "value-key": "displayLine3",
          "trigger-on-focus": false,
          disabled: _vm.disabled,
        },
        on: {
          select: _vm.handleSelect,
          blur: _vm.handleBlur,
          focus: _vm.handleFocus,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item }) {
              return [
                _c(
                  "p",
                  {
                    class: [
                      item.displayLine1 == "can't find the address?"
                        ? "color-blue"
                        : "",
                      "name",
                    ],
                  },
                  [_vm._v(" " + _vm._s(item.displayLine1) + " ")]
                ),
                _c(
                  "p",
                  {
                    class: [
                      item.displayLine2 == "Enter it manually..."
                        ? "color-blue"
                        : "",
                      "addr",
                    ],
                  },
                  [_vm._v(" " + _vm._s(item.displayLine2) + " ")]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.Search,
          callback: function ($$v) {
            _vm.Search = $$v
          },
          expression: "Search",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }