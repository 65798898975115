var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "QualificationsTrade",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Organisation Name", prop: "schoolName" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.ruleForm.schoolName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "schoolName", $$v)
                  },
                  expression: "ruleForm.schoolName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "School Location", prop: "initialAddress" } },
            [
              _c("div", { staticClass: "box" }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.ruleForm.schoolLocality) +
                      " " +
                      _vm._s(_vm.ruleForm.schoolState) +
                      " " +
                      _vm._s(_vm.ruleForm.schoolCountry) +
                      " "
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Contact Phone", prop: "contactPhone" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.ruleForm.contactPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "contactPhone", $$v)
                          },
                          expression: "ruleForm.contactPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Name of Qualification/Trade",
                prop: "qualificationName",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.ruleForm.qualificationName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "qualificationName", $$v)
                  },
                  expression: "ruleForm.qualificationName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Date Completed", prop: "dateCompleted" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: { type: "date", disabled: "" },
                        model: {
                          value: _vm.ruleForm.dateCompleted,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "dateCompleted", $$v)
                          },
                          expression: "ruleForm.dateCompleted",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }