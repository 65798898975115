import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/entitlement-to-work' : '/proxy/api/applicant/entitlement-to-work'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}

export function getIntPassportDocumentsApi(data) {
  return service({
    url: `${api}/get-int-passport-documents`,
    method: 'get',
    params: data
  })
}

export function uploadApi(data) {
  return service({
    url: `${api}/upload`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function removeDocumentApi(data) {
  return service({
    url: `${api}/remove-document`,
    method: 'post',
    data: data
  })
}

export function hasPassportOnChangeApi(data) {
  return service({
    url: `${api}/has-passport-on-change`,
    method: 'post',
    data: data
  })
}

export function onSelectCertificateTypeApi(data) {
  return service({
    url: `${api}/on-select-certificate-type`,
    method: 'post',
    data: data
  })
}

export function isAusCitizenChangeApi(data) {
  return service({
    url: `${api}/is-aus-citizen-change`,
    method: 'post',
    data: data
  })
}

export function continueApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}
