<script>
import OtherCountriesItem from './AddForm/index.vue'
import { otherCountriesApi } from '@/api/pattern'
export default {
  name: 'OtherCountriesView',
  components: { OtherCountriesItem },
  props: {
    applicantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      getPreviousAddressesByApplicantId: [],
      moduleLoading: false
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }
      await otherCountriesApi(params).then(res => {
        const { data } = res
        this.getPreviousAddressesByApplicantId = data.getPreviousAddressesByApplicantId
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>
<template>
  <div v-loading="moduleLoading" class="OtherCountriesView">
    <OtherCountriesItem v-if="getPreviousAddressesByApplicantId.length" :previous-addresses="getPreviousAddressesByApplicantId" />
    <p v-else class="comp">No other countries provided</p>
  </div>
</template>
<style lang="scss" scoped>
.OtherCountriesView {
  .comp {
    margin: 20px 0;
  }
}
</style>
