<script>
import { mapState } from 'vuex'
import RefereesItem from './AddForm/index.vue'
import { preparationApi, saveFormDataApi, continueApi } from '@/api/EmploymentReference'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'EmploymentReferenceView',
  components: { RefereesItem },
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  provide() {
    return {
      AddAnotherReferee: this.AddAnotherReferee,
      OnRefereeRemoved: this.OnRefereeRemoved,
      Continue: this.Continue
    }
  },
  data() {
    return {
      EditRefereeIndex: 0,
      referees: [],
      currentFormSection: {},
      minQty: 0,
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: (state) => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'EmploymentReference')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId
      }

      await preparationApi(params).then(res => {
        const { data } = res
        this.EditRefereeIndex = data.editRefereeIndex
        this.currentFormSection = data.currentFormSection
        this.minQty = data.minQty
        this.referees = data.referees || []
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
      this.$refs.RefereesItem.getRefereesList()
    },

    async AddAnotherReferee(list) {
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        formData: {
          editRefereeIndex: list.length,
          referees: [],
          removedReferees: []
        }
      }
      list.forEach(item => {
        params.formData.referees.push({
          canContactImmediately: item.canContactImmediately,
          referee: {
            ...item,
            applicantId: this.ApplicantId
          }
        })
      })
      params.formData.referees.forEach(item => {
        item.referee.canContactImmediately = item.referee.canContactImmediately === 'Yes'
      })
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
      })
    },

    async OnRefereeRemoved(item, list) {
      if (list.length <= 1) return
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        formData: {
          editRefereeIndex: -1,
          referees: [],
          removedReferees: [
            {
              ...item,
              applicantId: this.ApplicantId
            }
          ]
        }
      }
      params.formData.removedReferees[0].canContactImmediately = params.formData.removedReferees[0].canContactImmediately === 'Yes'
      list.forEach(item => {
        params.formData.referees.push({
          canContactImmediately: item.canContactImmediately,
          referee: {
            ...item,
            applicantId: this.ApplicantId
          }
        })
      })
      params.formData.referees.forEach(item => {
        item.referee.canContactImmediately = item.referee.canContactImmediately === 'Yes'
      })
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
      })
    },

    async Continue(list) {
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        currentFormSection: this.currentFormSection,
        minQty: this.minQty,
        referees: []

      }
      list.forEach(item => {
        params.referees.push({
          canContactImmediately: item.canContactImmediately,
          referee: {
            ...item,
            applicantId: this.ApplicantId
          }
        })
      })
      params.referees.forEach(item => {
        item.referee.canContactImmediately = item.referee.canContactImmediately === 'Yes'
      })
      await continueApi(params).then(res => {
        this.ContinueSetSession(res.data.session)
      })
    }
  }
}
</script>
<template>
  <div v-loading="allLoading" class="EmploymentReferenceView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <RefereesItem
      ref="RefereesItem" :continue-btn="ContinueBtn" :min-qty="minQty" :referees="referees"
      :edit-referee-index="EditRefereeIndex"
    />
  </div>
</template>

<style lang="scss" scoped>
.EmploymentReferenceView{
    .comp{
        margin: 20px 0;
        color:#206caa ;
    }
}
</style>
