var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "MedicalDeclarationView",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c("el-divider"),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "hasRelWIthNABEmployee" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _vm._v(
                            "1. Do you have any close personal or financial relationships "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "A close personal relationship may include:"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " • A regular and ongoing relationship with someone who might be an intimate friend, partner or a family member."
                                  ),
                                  _c("br"),
                                  _vm._v(" Financial relationship includes:"),
                                  _c("br"),
                                  _vm._v(
                                    " • A person with whom you have an interest in a business, investment or other financial liability/commitment or obligation."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " • A person with whom you are regularly involved in monetary transactions with. "
                                  ),
                                ]
                              ),
                              _c("i", { staticClass: "el-icon-info" }),
                            ]
                          ),
                          _vm._v(" with another employee of NAB? "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.Change(
                                $event,
                                "colHasRelWithNabEmployeeDel"
                              )
                            },
                          },
                          model: {
                            value: _vm.ruleForm.hasRelWIthNABEmployee,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "hasRelWIthNABEmployee",
                                $$v
                              )
                            },
                            expression: "ruleForm.hasRelWIthNABEmployee",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.hasRelWIthNABEmployee === "Yes"
            ? _c(
                "el-form-item",
                { attrs: { prop: "colHasRelWithNabEmployeeDel" } },
                [
                  _c("el-row", { attrs: { gutter: 20 } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "label",
                            attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                          },
                          [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              " If yes, provide the name of the NAB employee(s) and the nature of each relationship. "
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", maxlength: "1000" },
                              on: { input: _vm.SaveFormData },
                              model: {
                                value: _vm.ruleForm.colHasRelWithNabEmployeeDel,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "colHasRelWithNabEmployeeDel",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.colHasRelWithNabEmployeeDel",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "hasRelWithOfficial" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _vm._v(
                            "2. Do you have a close personal or financial relationship "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "A close personal relationship may include: "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "• A regular and ongoing relationship with someone who might be an intimate friend, partner or a family member. "
                                  ),
                                  _c("br"),
                                  _vm._v("Financial relationship includes: "),
                                  _c("br"),
                                  _vm._v(
                                    "• A person with whom you have an interest in a business, investment or other financial liability/commitment or obligation. "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "• A person with whom you are regularly involved in monetary transactions with. "
                                  ),
                                ]
                              ),
                              _c("i", { staticClass: "el-icon-info" }),
                            ]
                          ),
                          _vm._v(" with any of the below persons? "),
                          _c("br"),
                          _vm._v(
                            "• An executive member of any public department of any Government, State or Territories in Australia or internationally. "
                          ),
                          _c("br"),
                          _vm._v(
                            "• An elected member of any government, state or local municipality in Australia or internationally. "
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.Change(
                                $event,
                                "colHasRelWithOfficialDetail"
                              )
                            },
                          },
                          model: {
                            value: _vm.ruleForm.hasRelWithOfficial,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "hasRelWithOfficial", $$v)
                            },
                            expression: "ruleForm.hasRelWithOfficial",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.hasRelWithOfficial === "Yes"
            ? _c(
                "el-form-item",
                { attrs: { prop: "colHasRelWithOfficialDetail" } },
                [
                  _c("el-row", { attrs: { gutter: 20 } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "label",
                            attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                          },
                          [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              " If yes, provide details including the name of the person, their position, name of the government body, location of their position and the nature of your relationship. "
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", maxlength: "1000" },
                              on: { input: _vm.SaveFormData },
                              model: {
                                value: _vm.ruleForm.colHasRelWithOfficialDetail,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "colHasRelWithOfficialDetail",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.colHasRelWithOfficialDetail",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "hasBizOrg" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _vm._v(
                            "3. In relation to any business or other organisation "
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    " Business or organisation include all incorporated and unincorporated government and non-government organisations, businesses, companies, partnerships, trusts, funds, charitable organisations, as well as committees and working groups thereof. "
                                  ),
                                ]
                              ),
                              _c("i", { staticClass: "el-icon-info" }),
                            ]
                          ),
                          _vm._v(" in Australia and internationally, do you: "),
                          _c("br"),
                          _c(
                            "span",
                            [
                              _vm._v(
                                "• hold an official directorship/officeholder position"
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        " Official position includes but is not limited to executive and non-executive board positions, advisory positions on committees, trusteeships and any other role conferring responsibility. "
                                      ),
                                    ]
                                  ),
                                  _c("i", { staticClass: "el-icon-info" }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("br"),
                          _vm._v("• have any financial interest "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    " Financial interest includes a significant shareholding, majority shareholding and other beneficial interest. "
                                  ),
                                ]
                              ),
                              _c("i", { staticClass: "el-icon-info" }),
                            ]
                          ),
                          _c("br"),
                          _vm._v(
                            "• have influence, including over the board or any committee or associated group thereof "
                          ),
                          _c("br"),
                          _vm._v("• provide advisory or other services "),
                          _c("br"),
                          _vm._v(
                            "Includes competitors of and suppliers to NAB as well as other interests external to NAB. "
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.Change(
                                $event,
                                "colHasBizOrgInAuDetail"
                              )
                            },
                          },
                          model: {
                            value: _vm.ruleForm.hasBizOrg,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "hasBizOrg", $$v)
                            },
                            expression: "ruleForm.hasBizOrg",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.hasBizOrg === "Yes"
            ? _c(
                "el-form-item",
                { attrs: { prop: "colHasBizOrgInAuDetail" } },
                [
                  _c("el-row", { attrs: { gutter: 20 } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "label",
                            attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                          },
                          [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              " If yes, provide details of the company/organisation, your involvement/interest and the country in which it is primarily registered in. "
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", maxlength: "1000" },
                              on: { input: _vm.SaveFormData },
                              model: {
                                value: _vm.ruleForm.colHasBizOrgInAuDetail,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "colHasBizOrgInAuDetail",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.colHasBizOrgInAuDetail",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "criminalInterestOrInvestigatedByState" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _vm._v("4. Are you the person of interest "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "A 'Person of interest' is a person who is suspected by police or other law enforcement agencies of committing a crime, or a person who is a subject of a criminal investigation but who has yet to be charged with the crime."
                                  ),
                                ]
                              ),
                              _c("i", { staticClass: "el-icon-info" }),
                            ]
                          ),
                          _vm._v(" in an ongoing criminal matter "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: { effect: "dark", placement: "bottom" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v(
                                    "A criminal matter is an offence (or crime) against the state. It is often called ‘breaking the law’ and if you are accused of a criminal offence, you will have a charge sheet that states the offence you have been charged with."
                                  ),
                                ]
                              ),
                              _c("i", { staticClass: "el-icon-info" }),
                            ]
                          ),
                          _vm._v(
                            " before the Courts or being investigated by a state or federal government body? "
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              return _vm.Change(
                                $event,
                                "colBeingInvestigatedDetail"
                              )
                            },
                          },
                          model: {
                            value:
                              _vm.ruleForm
                                .criminalInterestOrInvestigatedByState,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "criminalInterestOrInvestigatedByState",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.criminalInterestOrInvestigatedByState",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.criminalInterestOrInvestigatedByState === "Yes"
            ? _c(
                "el-form-item",
                { attrs: { prop: "colBeingInvestigatedDetail" } },
                [
                  _c("el-row", { attrs: { gutter: 20 } }, [
                    _c(
                      "label",
                      { staticClass: "label" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "label",
                            attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                          },
                          [
                            _c("span", { staticClass: "color-red" }, [
                              _vm._v("*"),
                            ]),
                            _vm._v(
                              " If yes, provide details of the offence, your involvement/interest in the criminal investigation. "
                            ),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", maxlength: "1000" },
                              on: { input: _vm.SaveFormData },
                              model: {
                                value: _vm.ruleForm.colBeingInvestigatedDetail,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "colBeingInvestigatedDetail",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.colBeingInvestigatedDetail",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.ContinueBtn
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.endAllRequests, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.Continue("ruleForm")
                    },
                  },
                },
                [_vm._v("Save and Continue ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }