import Vue from 'vue'
import Router from 'vue-router'
// import VueRouter from "vue-router";
import LayoutApplication from '@/layout/application'
import LayoutSelect from '@/layout/select'
import Login from '@/views/Login/login'
import Register from '@/views/Login/register'
import TokenLogin from '@/views/Login/token-login'
import SessionExpired from '@/views/Login/SessionExpired'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/token-login',
    name: 'TokenLogin',
    component: TokenLogin
  },
  {
    path: '/application',
    name: 'application',
    component: LayoutApplication
  },
  {
    path: '/select',
    name: 'select',
    component: LayoutSelect
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    redirect: 'token-login'
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/sessionExpired',
    name: 'SessionExpired',
    component: SessionExpired
  }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

export default router
