import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/application/document-type' : '/proxy/api/applicant/application/document-type'

export function detailApi(data) {
  return service({
    url: `${api}/${data.id}/detail`,
    method: 'get'
  })
}

export function getNameApi(data) {
  return service({
    url: `${api}/${data.id}/get-name`,
    method: 'get'
  })
}
