var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PersonalDetailsItem" },
    _vm._l(_vm.nameList, function (item) {
      return _c("div", { key: item.index }, [
        _c("div", { staticClass: "history-box" }, [
          _c("p", [
            _vm._v(_vm._s(item.type) + ": " + _vm._s(item.label) + " "),
            _vm.$isNullDate(item.nameChangeDate, "<>")
              ? _c("span", [
                  _vm._v(
                    "(" +
                      _vm._s(
                        _vm._f("parseDefaultTime")(
                          item.nameChangeDate,
                          "{d}/{m}/{y}"
                        )
                      ) +
                      ")"
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }