<script>
import { mapState } from 'vuex'

export default {
  name: 'PersonalDetailsItem',
  inject: ['OnItemAddNew', 'OnItemRemoved', 'getAddFormList'],
  props: {
    otherNames: {
      type: Array,
      default: () => []
    },
    completePercent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const validateFirstName = (rule, value, callback) => {
      console.log('this.otherNames', this.otherNames)
      console.log('this.openIndex', this.openIndex)
      const arrIndex = this.nameList.findIndex(element => element.index === this.openIndex)
      if (this.nameList[arrIndex].isSingleNameOnly) {
        callback()
      } else {
        if (value === '') {
          callback(new Error('Please fill in the first name'))
        } else {
          callback()
        }
      }
    }
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      formConfig: {
        type: '',
        nameChangeDate: null,
        firstName: '',
        lastName: '',
        middleNames: ''
      },
      contrast: ['type', 'nameChangeDate', 'firstName', 'lastName', 'middleNames'],
      rules: {
        type: [{ required: true, message: 'Please select a type', trigger: 'change' }],
        firstName: [{ validator: validateFirstName, trigger: 'blur' }],
        lastName: [{ required: true, message: 'Please fill in the last name', trigger: 'blur' }]
      },
      nameList: [
        {
          type: '',
          nameChangeDate: null,
          firstName: '',
          lastName: '',
          middleNames: '',
          index: 0,
          isSingleNameOnly: false
        }
      ],
      openIndex: 0, // 当前打开的index
      index: 0 // 持续递增的index
    }
  },
  computed: {
    ...mapState({
      preparation: state => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    outAddBtnShow() {
      const spliceIndex = this.otherNames.findIndex(
        (data) => data.index === this.openIndex
      )
      return spliceIndex === -1
    }
  },
  watch: {
    nameList: {
      handler(data) {
        if (data) {
          this.getAddFormList(this.nameList, false)
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (this.otherNames.length > 0) {
      this.nameList = this.otherNames
      this.nameList = this.$isRepeat(this.nameList)
      this.nameList.forEach(item => {
        if (item.isSingleNameOnly) {
          item.firstName = ''
          item.middleNames = ''
        }
      })
      this.index = Math.max.apply(Math, this.nameList.map(item => {
        return item.index
      }))
      this.index = this.index ? this.index : 0
      if (this.completePercent) { // continue后 不展开列表
        this.openIndex = null
      } else {
        this.openIndex = (this.index && this.index !== 0) ? this.index : 0
      }
    }
  },
  methods: {
    handleRemove(itemIndex, item) {
      const spliceIndex = this.nameList.findIndex(
        (data) => data.index === itemIndex
      )
      if (this.nameList.length > 1) {
        this.nameList.splice(spliceIndex, 1)
        this.OnItemRemoved(item, this.nameList)
      } else {
        this.OnItemRemoved(this.nameList[0], [])
        this.nameList = [
          {
            type: '',
            nameChangeDate: null,
            firstName: '',
            lastName: '',
            middleNames: '',
            index: 0
          }
        ]
        this.openIndex = 0
      }
    },
    addItem() {
      this.OnItemAddNew(this.nameList)
      this.nameList.push(
        JSON.parse(
          JSON.stringify({
            ...this.formConfig,
            index: ++this.index
          })
        )
      )
      this.openIndex = this.index
    },
    handleAdd(formName, type, item) {
      if (type === 1) {
        // 表单内
        this.$refs[formName][0].validate(async(valid) => {
          if (valid) {
            this.addItem()
          }
        })
      } else {
        // 全局下
        if (
          this.$refs[`addForm${this.index}`] &&
          this.$refs[`addForm${this.index}`][0]
        ) {
          // 当前存在打开的表单
          this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
            if (valid) {
              this.addItem()
            }
          })
        } else {
          //  不存在打开的表单
          this.addItem()
        }
      }
    },
    open(itemIndex) {
      if (
        this.$refs[`addForm${this.openIndex}`] &&
        this.$refs[`addForm${this.openIndex}`][0]
      ) {
        this.$refs[`addForm${this.openIndex}`][0].validate(async(valid) => {
          if (valid) {
            this.openIndex = itemIndex
          } else {
            const data = this.nameList.find((item) => item.index === this.openIndex)
            const spliceIndex = this.nameList.findIndex((item) => item.index === this.openIndex)
            for (let i = 0; i < this.contrast.length; i++) {
              if (data[this.contrast[i]]) {
                return false
              }
            }
            this.nameList.splice(spliceIndex, 1)
            this.openIndex = itemIndex
          }
        })
      } else {
        this.openIndex = itemIndex
      }
    },
    handleSure() {
      if (
        this.$refs[`addForm${this.openIndex}`] &&
        this.$refs[`addForm${this.openIndex}`][0]
      ) {
        this.$refs[`addForm${this.openIndex}`][0].validate(async(valid) => {
          if (valid) {
            this.getAddFormList(this.nameList, true)
          }
        })
      } else {
        this.getAddFormList(this.nameList, true)
      }
    },
    isSingleNameOnlyChange(val, index) {
      const arrIndex = this.nameList.findIndex(element => element.index === index)
      if (val) {
        this.nameList[arrIndex].firstName = this.nameList[arrIndex].lastName
        this.nameList[arrIndex].middleNames = ''
      } else {
        this.nameList[arrIndex].firstName = ''
        this.nameList[arrIndex].middleNames = ''
      }
      this.nameList[arrIndex].isSingleNameOnly = val
      this.$refs[`addForm${this.openIndex}`][0].clearValidate()
    }
  }
}
</script>

<template>
  <div class="PersonalDetailsItem mb-20">
    <div v-for="item in nameList" :key="item.index">
      <div
        v-if="item.index != openIndex"
        class="history-box"
        @click="open(item.index)"
      >
        <p v-if="item.isSingleNameOnly">
          {{ item.type }}: {{ item.lastName }} <span v-if="$isNullDate(item.nameChangeDate, '<>')">({{ item.nameChangeDate | parseDefaultTime('{d}/{m}/{y}') }})</span>
        </p>
        <p v-else>
          {{ item.type }}: {{ item.firstName }} {{ item.middleNames }} {{ item.lastName }} <span
            v-if="$isNullDate(item.nameChangeDate, '<>')"
          >({{ item.nameChangeDate | parseDefaultTime('{d}/{m}/{y}') }})</span>
        </p>
      </div>
      <div v-if="item.index == openIndex">
        <el-form
          :ref="`addForm${item.index}`"
          :model="item"
          :rules="rules"
          class="demo-ruleForm"
          label-width="50%"
          label-position="top"
          :disabled="allDisabled"
        >
          <el-row :gutter="20">
            <el-col
              :xs="24" :sm="12" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item prop="type">
                <label class="label">
                  <div style="margin-bottom: 5px;">* Type</div>
                  <el-radio-group v-model="item.type">
                    <el-radio label="Alias" />
                    <el-radio label="Maiden" />
                    <el-radio label="Previous" />
                  </el-radio-group>
                </label>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24" :sm="12" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item prop="nameChangeDate">
                <label class="label">
                  <div style="margin-bottom: 5px;">Date of Name Change</div>
                  <el-date-picker
                    v-model="item.nameChangeDate"
                    value-format="yyyy-MM-dd"
                    format="dd/MM/yyyy"
                    :picker-options="pickerOptions"
                    placeholder="DD/MM/YYYY"
                    type="date"
                  />
                </label>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <div class="mb-20">
                <label class="label">
                  <el-checkbox v-model="item.isSingleNameOnly" @change="isSingleNameOnlyChange($event, item.index)">
                    I was legally known by single name only
                  </el-checkbox>
                </label>
              </div>
            </el-col>
          </el-row>

          <el-row v-if="!item.isSingleNameOnly" :gutter="20">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item prop="firstName">
                <label class="label">
                  <div style="margin-bottom: 5px;">* Given Name</div>
                  <el-input v-model="item.firstName" :maxlength="100" :disabled="item.isSingleNameOnly" />
                </label>
              </el-form-item>
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item prop="lastName">
                <label class="label">
                  <div style="margin-bottom: 5px;">* Family Name</div>
                  <el-input v-model="item.lastName" :maxlength="100" />
                </label>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="item.isSingleNameOnly" :gutter="20">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item prop="lastName">
                <label class="label">
                  <div style="margin-bottom: 5px;">* Legal Name</div>
                  <el-input v-model="item.lastName" :maxlength="100" />
                </label>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item v-if="!item.isSingleNameOnly" prop="middleNames">
            <label class="label">
              <div style="margin-bottom: 5px;">Middle Name(s)</div>
              <el-input v-model="item.middleNames" :maxlength="100" />
            </label>
          </el-form-item>

          <el-form-item v-if="!allDisabled">
            <div class="action-box">
              <div
                class="action-item"
                @click="handleAdd(`addForm${item.index}`, 1, item)"
              >
                <i class="el-icon-circle-plus" />
                <span class="action-value">Add Name</span>
              </div>
              <div class="action-item" @click="handleRemove(item.index, item)">
                <i class="el-icon-delete-solid" />
                <span class="action-value">Remove Name</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div v-if="outAddBtnShow && !allDisabled" class="action-box" @click="handleAdd('', 2)">
      <div class="action-item">
        <i class="el-icon-circle-plus" />
        <span class="action-value f-s-14">Add Name</span>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.action-box {
  display: flex;
  justify-content: space-between;
  color: #206caa;

  .action-item {
    cursor: pointer;
  }

  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }

  .action-value {
    margin-left: 10px;
  }
}

.label {
  margin: 0;
  font-size: 14px;
  color: #606266;
  line-height: 1.5;
}
</style>
