import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/patterns' : '/proxy/api/patterns'

export function coiDeclarationApi(data) {
  return service({
    url: `${api}/${data.applicantId}/coi-declaration`,
    method: 'get'
  })
}

export function coiDeclarationNabApi(data) {
  return service({
    url: `${api}/${data.applicantId}/coi-declaration-nab`,
    method: 'get'
  })
}
export function requiredDeclarationNabApi(data) {
  return service({
    url: `${api}/${data.applicantId}/required-declaration-nab`,
    method: 'get'
  })
}
export function cvUploadApi(data) {
  return service({
    url: `${api}/${data.orderApplicantId}/cv-upload`,
    method: 'get'
  })
}
export function eyQuestionApi(data) {
  return service({
    url: `${api}/${data.applicantId}/ey-question`,
    method: 'get'
  })
}
export function eyQuestionG4Api(data) {
  return service({
    url: `${api}/${data.applicantId}/ey-question-g4`,
    method: 'get'
  })
}
export function eyQuestionG13Api(data) {
  return service({
    url: `${api}/${data.applicantId}/ey-question-g1-3`,
    method: 'get'
  })
}
export function identity100PointsApi(data) {
  return service({
    url: `${api}/${data.orderApplicantId}/identity-100-points`,
    method: 'get'
  })
}
export function addressHistoryApi(data) {
  return service({
    url: `${api}/${data.applicantId}/address-history`,
    method: 'get'
  })
}
export function complianceReferenceApi(data) {
  return service({
    url: `${api}/${data.applicantId}/compliance-reference`,
    method: 'get'
  })
}
export function covid19VaccinationApi(data) {
  return service({
    url: `${api}/${data.applicantId}/covid-19-vaccination`,
    method: 'get',
    params: { OrderApplicantId: data.orderApplicantId }
  })
}
export function employmentHistoryApi(data) {
  return service({
    url: `${api}/${data.applicantId}/employment-history`,
    method: 'get'
  })
}
export function entitlementToWorkApi(data) {
  return service({
    url: `${api}/entitlement-to-work`,
    method: 'get',
    params: data
  })
}
export function entitlementToWork2Api(data) {
  return service({
    url: `${api}/entitlement-to-work2`,
    method: 'get',
    params: data
  })
}
export function managementDirectorApi(data) {
  return service({
    url: `${api}/${data.applicantId}/management-director`,
    method: 'get'
  })
}
export function medicalDeclarationApi(data) {
  return service({
    url: `${api}/${data.applicantId}/medical-declaration`,
    method: 'get'
  })
}
export function otherCountriesApi(data) {
  return service({
    url: `${api}/${data.applicantId}/other-countries`,
    method: 'get'
  })
}
export function personalDetailsApi(data) {
  return service({
    url: `${api}/${data.applicantId}/personal-details`,
    method: 'get'
  })
}
export function personalDetailsInternationalApi(data) {
  return service({
    url: `${api}/${data.applicantId}/personal-details-international`,
    method: 'get'
  })
}
export function professionalMembershipsApi(data) {
  return service({
    url: `${api}/${data.applicantId}/professional-memberships`,
    method: 'get'
  })
}
export function proofOfIdentityApi(data) {
  return service({
    url: `${api}/${data.applicantId}/proof-of-identity`,
    method: 'get'
  })
}
export function qualificationsNabApi(data) {
  return service({
    url: `${api}/${data.applicantId}/qualifications-nab`,
    method: 'get'
  })
}
export function qualificationsTertiaryApi(data) {
  return service({
    url: `${api}/${data.applicantId}/qualifications-tertiary`,
    method: 'get'
  })
}
export function qualificationsTradeApi(data) {
  return service({
    url: `${api}/${data.applicantId}/qualifications-trade`,
    method: 'get'
  })
}
export function refereesApi(data) {
  return service({
    url: `${api}/${data.applicantId}/referees`,
    method: 'get'
  })
}
