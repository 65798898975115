var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("VuePhoneNumberInput", {
        ref: "vuePhoneNumberInput",
        attrs: {
          error: _vm.error,
          "valid-color": _vm.validColor,
          color: _vm.color,
          "no-country-selector": _vm.noCountrySelector,
          "default-country-code": _vm.defaultCountryCode,
          "preferred-countries": _vm.preferredCountries,
          disabled: _vm.disabled,
          clearable: _vm.clearable,
          "show-code-on-list": _vm.showCodeOnList,
          "no-flags": _vm.noFlags,
          "border-radius": _vm.borderRadius,
          "no-example": _vm.noExample,
          translations: _vm.translations,
          "aria-label": "Phone number",
        },
        on: { update: _vm.update, "phone-number-blur": _vm.blur },
        model: {
          value: _vm.phone,
          callback: function ($$v) {
            _vm.phone = $$v
          },
          expression: "phone",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }