var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "ProofOfIdentity",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "selectedCommencementDocumentTypeId" },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "label",
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "600",
                              },
                            },
                            [
                              _vm._v("Commencement Document (One Required) "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "popper-class": "dropdownClass",
                                    clearable: "",
                                    placeholder:
                                      "Select a Commencement Document Type",
                                  },
                                  on: { change: _vm.OnCommencementComboChange },
                                  model: {
                                    value:
                                      _vm.ruleForm
                                        .selectedCommencementDocumentTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "selectedCommencementDocumentTypeId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.selectedCommencementDocumentTypeId",
                                  },
                                },
                                _vm._l(
                                  _vm.listFilterCommencement,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.label,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.CommencementUploadShow
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "600",
                          },
                        },
                        [_vm._v(_vm._s(_vm.commencementDescription))]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "commencementOrderApplicantDocuments",
                          },
                        },
                        [
                          _vm.commencementOrderApplicantDocuments.length < 1 &&
                          _vm.imgcanDelete
                            ? _c("OnlyUpload", {
                                on: {
                                  Document_Upload: function ($event) {
                                    return _vm.CloudinaryUpload_UploadResult(
                                      "Commencement",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._l(
                            _vm.commencementOrderApplicantDocuments,
                            function (item, index) {
                              return _c("OnlyUpload", {
                                key: item.id,
                                attrs: {
                                  "img-item": item,
                                  "can-delete": _vm.imgcanDelete,
                                },
                                on: {
                                  Document_Upload: function ($event) {
                                    return _vm.CloudinaryUpload_UploadResult(
                                      "Commencement",
                                      $event
                                    )
                                  },
                                  Document_Remove: function ($event) {
                                    return _vm.Document_Remove(
                                      "commencementOrderApplicantDocuments",
                                      index,
                                      $event
                                    )
                                  },
                                },
                              })
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-divider"),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "selectedPrimaryDocumentTypeId" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "label",
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "600",
                              },
                            },
                            [
                              _vm._v("Primary Document (One Required) "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "popper-class": "dropdownClass",
                                    clearable: "",
                                    placeholder:
                                      "Select a Primary Document Type",
                                  },
                                  on: { change: _vm.OnPrimaryComboChange },
                                  model: {
                                    value:
                                      _vm.ruleForm
                                        .selectedPrimaryDocumentTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "selectedPrimaryDocumentTypeId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.selectedPrimaryDocumentTypeId",
                                  },
                                },
                                _vm._l(_vm.listFilterPrimary, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.PrimaryUploadShow
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "600",
                          },
                        },
                        [_vm._v(_vm._s(_vm.primaryDescription))]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "primaryOrderApplicantDocuments" } },
                        [
                          _vm.primaryOrderApplicantDocuments.length < 1 &&
                          _vm.imgcanDelete
                            ? _c("OnlyUpload", {
                                on: {
                                  Document_Upload: function ($event) {
                                    return _vm.CloudinaryUpload_UploadResult(
                                      "Primary",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._l(
                            _vm.primaryOrderApplicantDocuments,
                            function (item, index) {
                              return _c("OnlyUpload", {
                                key: item.id,
                                attrs: {
                                  "img-item": item,
                                  "can-delete": _vm.imgcanDelete,
                                },
                                on: {
                                  Document_Upload: function ($event) {
                                    return _vm.CloudinaryUpload_UploadResult(
                                      "Primary",
                                      $event
                                    )
                                  },
                                  Document_Remove: function ($event) {
                                    return _vm.Document_Remove(
                                      "primaryOrderApplicantDocuments",
                                      index,
                                      $event
                                    )
                                  },
                                },
                              })
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-divider"),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "selectedSecondary1DocumentTypeId" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "label",
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "600",
                              },
                            },
                            [
                              _vm._v(
                                "Secondary Documents (Two Required) - First Document "
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "popper-class": "dropdownClass",
                                    clearable: "",
                                    placeholder:
                                      "Select a Secondary Document Type",
                                  },
                                  on: { change: _vm.OnSecondary1ComboChange },
                                  model: {
                                    value:
                                      _vm.ruleForm
                                        .selectedSecondary1DocumentTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "selectedSecondary1DocumentTypeId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.selectedSecondary1DocumentTypeId",
                                  },
                                },
                                _vm._l(
                                  _vm.secondary1DocumentTypesShow,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.secondary1UploadShow
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "16px",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.secondary1Description))]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "secondary1OrderApplicantDocuments",
                                  },
                                },
                                [
                                  _vm.secondary1OrderApplicantDocuments.length <
                                    1 && _vm.imgcanDelete
                                    ? _c("OnlyUpload", {
                                        style:
                                          "width:" +
                                          (_vm.isMobile ? "100%" : "100%"),
                                        on: {
                                          Document_Upload: function ($event) {
                                            return _vm.CloudinaryUpload_UploadResult(
                                              "Secondary1",
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.secondary1OrderApplicantDocuments,
                                    function (item, index) {
                                      return _c("OnlyUpload", {
                                        key: item.id,
                                        style:
                                          "width:" +
                                          (_vm.isMobile ? "100%" : "50%"),
                                        attrs: {
                                          "img-item": item,
                                          "can-delete": _vm.imgcanDelete,
                                        },
                                        on: {
                                          Document_Upload: function ($event) {
                                            return _vm.CloudinaryUpload_UploadResult(
                                              "Secondary1",
                                              $event
                                            )
                                          },
                                          Document_Remove: function ($event) {
                                            return _vm.Document_Remove(
                                              "secondary1OrderApplicantDocuments",
                                              index,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "selectedSecondary2DocumentTypeId" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "label",
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "600",
                              },
                            },
                            [
                              _vm._v(
                                "Secondary Documents (Two Required) - Second Document "
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "popper-class": "dropdownClass",
                                    clearable: "",
                                    placeholder:
                                      "Select a Secondary Document Type",
                                  },
                                  on: { change: _vm.OnSecondary2ComboChange },
                                  model: {
                                    value:
                                      _vm.ruleForm
                                        .selectedSecondary2DocumentTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "selectedSecondary2DocumentTypeId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.selectedSecondary2DocumentTypeId",
                                  },
                                },
                                _vm._l(
                                  _vm.secondary2DocumentTypesShow,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.label,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.secondary2UploadShow
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "16px",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.secondary2Description))]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "secondary2OrderApplicantDocuments",
                                  },
                                },
                                [
                                  _vm.secondary2OrderApplicantDocuments.length <
                                    1 && _vm.imgcanDelete
                                    ? _c("OnlyUpload", {
                                        style:
                                          "width:" +
                                          (_vm.isMobile ? "100%" : "100%"),
                                        on: {
                                          Document_Upload: function ($event) {
                                            return _vm.CloudinaryUpload_UploadResult(
                                              "Secondary2",
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.secondary2OrderApplicantDocuments,
                                    function (item, index) {
                                      return _c("OnlyUpload", {
                                        key: item.id,
                                        style:
                                          "width:" +
                                          (_vm.isMobile ? "100%" : "50%"),
                                        attrs: {
                                          "img-item": item,
                                          "can-delete": _vm.imgcanDelete,
                                        },
                                        on: {
                                          Document_Upload: function ($event) {
                                            return _vm.CloudinaryUpload_UploadResult(
                                              "Secondary2",
                                              $event
                                            )
                                          },
                                          Document_Remove: function ($event) {
                                            return _vm.Document_Remove(
                                              "secondary2OrderApplicantDocuments",
                                              index,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider"),
            ],
            1
          ),
          _vm.getOtherNamesByApplicantIdShow
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "usesOtherName",
                        label:
                          "Do ANY of the uploaded Identity Documents use your former name?",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.hasOtherNameLoading,
                              expression: "hasOtherNameLoading",
                            },
                          ],
                          on: { change: _vm.OtherNameRadioButtonOnChange },
                          model: {
                            value: _vm.ruleForm.usesOtherName,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "usesOtherName", $$v)
                            },
                            expression: "ruleForm.usesOtherName",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.ruleForm.usesOtherName === "Yes"
                    ? _c(
                        "div",
                        { staticClass: "mb-20" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "selectedOtherNameDocumentType" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { "line-height": "1.5" },
                                  attrs: { slot: "label" },
                                  slot: "label",
                                },
                                [
                                  _vm._v(
                                    "Please provide either a Change of Name Certificate issued by the Australian Registry of Births, Deaths and Marriages or an Australian Marriage Certificate issued by a state or territory. Church or celebrant-issued certificates are not accepted."
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.selectedOtherNameDocumentTypeDisabled,
                                  },
                                  on: {
                                    change: _vm.OnOtherNameDocumentTypeChange,
                                  },
                                  model: {
                                    value:
                                      _vm.ruleForm
                                        .selectedOtherNameDocumentType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "selectedOtherNameDocumentType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.selectedOtherNameDocumentType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    {
                                      attrs: {
                                        label:
                                          _vm.changeOfNameCertDocumentTypeId,
                                      },
                                    },
                                    [_vm._v("Change of Name Certificate")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    {
                                      attrs: {
                                        label:
                                          _vm.ausMarriageCertDocumentTypeId,
                                      },
                                    },
                                    [_vm._v("Australian Marriage Certificate")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.ruleForm.selectedOtherNameDocumentType
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "otherNameOrderApplicantDocuments",
                                      },
                                    },
                                    [
                                      _vm.otherNameOrderApplicantDocuments
                                        .length < 1
                                        ? _c("OnlyUpload", {
                                            on: {
                                              Document_Upload: function (
                                                $event
                                              ) {
                                                return _vm.CloudinaryUpload_UploadResult(
                                                  "OtherName",
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.otherNameOrderApplicantDocuments,
                                        function (item, index) {
                                          return _c("OnlyUpload", {
                                            key: item.id,
                                            attrs: {
                                              "img-item": item,
                                              "can-delete":
                                                _vm.getImgcanDelete(item),
                                            },
                                            on: {
                                              Document_Upload: function (
                                                $event
                                              ) {
                                                return _vm.CloudinaryUpload_UploadResult(
                                                  "OtherName",
                                                  $event
                                                )
                                              },
                                              Document_Remove: function (
                                                $event
                                              ) {
                                                return _vm.Document_Remove(
                                                  "otherNameOrderApplicantDocuments",
                                                  index,
                                                  $event
                                                )
                                              },
                                            },
                                          })
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                { attrs: { prop: "selfieOrderApplicantDocuments" } },
                [
                  _c("p", { staticClass: "f-s-14 f-w-600 title" }, [
                    _vm._v(
                      "We also require you to upload a photo of yourself holding one of the above PHOTO identity documents (eg: passport or licence)"
                    ),
                  ]),
                ]
              ),
              _c("div", {
                staticClass: "mb-10 f-s-14",
                domProps: { innerHTML: _vm._s(_vm.selfieDocumentIntro) },
              }),
              _vm.selfieOrderApplicantDocuments.length < 1
                ? _c("OnlyUpload", {
                    on: {
                      Document_Upload: function ($event) {
                        return _vm.CloudinaryUpload_UploadResult(
                          "Selfie",
                          $event
                        )
                      },
                    },
                  })
                : _vm._e(),
              _vm._l(_vm.selfieOrderApplicantDocuments, function (item, index) {
                return _c("OnlyUpload", {
                  key: item.id,
                  attrs: {
                    "img-item": item,
                    "can-delete": _vm.getImgcanDelete(item),
                  },
                  on: {
                    Document_Upload: function ($event) {
                      return _vm.CloudinaryUpload_UploadResult("Selfie", $event)
                    },
                    Document_Remove: function ($event) {
                      return _vm.Document_Remove(
                        "selfieOrderApplicantDocuments",
                        index,
                        $event
                      )
                    },
                  },
                })
              }),
            ],
            2
          ),
        ]
      ),
      _vm.ContinueBtn
        ? _c(
            "el-button",
            {
              staticClass: "mt-30",
              attrs: { loading: _vm.endAllRequests, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.Continue("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.buttonText))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }