<script>
export default {
  name: 'AddForm',
  props: {
    compliances: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      openIndex: 0, // 当前打开的index
      index: 0, // 持续递增的index
      activeNames: ''
    }
  }
}
</script>

<template>
  <div class="AddressForm">
    <el-collapse v-model="activeNames">
      <el-collapse-item
        v-for="item in compliances"
        :key="item.index"
        :title="`${item.licenseeName}(${item.licenseeNumber}) - ${item.complianceTeamMemberName}`"
        :name="item.index"
      >
        <div>
          <el-form
            :ref="`addForm${item.index}`"
            :model="item"
            label-width="80px"
            label-position="top"
            disabled
          >
            <el-form-item label="Licencee Name">
              <el-input v-model="item.licenseeName" maxlength="150" />
            </el-form-item>
            <el-form-item label="Licencee Number">
              <el-input v-model="item.licenseeNumber" maxlength="50" />
            </el-form-item>
            <el-form-item
              label="Full Name of Compliance Team Member at Licencee"
            >
              <el-input
                v-model="item.complianceTeamMemberName"
                maxlength="150"
              />
            </el-form-item>
            <el-form-item label="Position held by Compliance Team Member">
              <el-input
                v-model="item.complianceTeamMemberPosition"
                maxlength="150"
              />
            </el-form-item>
            <el-form-item label="Contact Phone Number">
              <el-input v-model="item.contactPhone" maxlength="20" />
            </el-form-item>
            <el-form-item label="Work Email Address">
              <el-input v-model="item.workEmail" maxlength="250" />
            </el-form-item>
            <el-form-item label="Can contact?">
              <el-radio-group v-model="item.cancontact" disabled>
                <el-radio :label="true">Yes</el-radio>
                <el-radio :label="false">No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<style lang="scss" scoped>
.selectWidth {
  display: block;
}
.action-box {
  display: flex;
  justify-content: space-between;
  color: #206caa;
  .action-item {
    cursor: pointer;
  }
  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }
  .action-value {
    margin-left: 10px;
  }
}
::v-deep .el-collapse-item__wrap {
  background-color: transparent !important;
}
::v-deep .el-collapse-item__header {
  padding: 0;
  background-color: transparent !important;
}
.label {
  margin: 0;
  font-size: 14px;
  color: #606266;
}
</style>
