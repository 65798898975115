import { setPreparation, removePreparation } from '@/utils/preparation'
import { preparationApi } from '@/api/application'
import store from '@/store'
const state = {
  preparation: {
    session: {
      formSections: []
    }
  },
  ReferenceNumber: ''
}

const mutations = {
  SET_PREPARATION: (state, data) => {
    state.preparation = data
  },
  SET_REFERENCENUMBER: (state, ReferenceNumber) => {
    state.ReferenceNumber = ReferenceNumber
  }
}

const actions = {
  // get preparation data after user login
  getPreparation({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      preparationApi(userInfo).then(res => {
        const { data } = res
        setPreparation(data)
        commit('SET_PREPARATION', data)
        commit('SET_REFERENCENUMBER', data.session.current.referenceNumber)
        if (!data.goSelect) {
          const section = {
            section: data.session.current.formSection.blockName,
            type: 'Initialize'
          }
          store.dispatch('section/set_section', section)
        } else {
          store.dispatch('section/reset_section')
        }
        resolve()
      })
    })
  },

  setPreparation({ commit }, data) {
    return new Promise((resolve) => {
      setPreparation(data)
      commit('SET_PREPARATION', data)
      resolve()
    })
  },

  // remove preparation data
  resetPreparation({ commit }) {
    return new Promise((resolve) => {
      removePreparation()
      commit('SET_PREPARATION', {})
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
