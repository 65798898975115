<script>
import { getSearchResultApi, getAddressDetailsApi } from '@/api/Map'
export default {
  name: 'AddressAutocompleteView',
  props: {
    initialaddress: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchAfterCharacters: {
      type: Number,
      default: 3
    },
    placeholder: {
      type: String,
      default: ''
    },
    useKleber: {
      type: Boolean,
      default: false
    },
    useGoogle: {
      type: Boolean,
      default: false
    },
    googleFilterLocation: {
      type: String,
      default: ''
    },
    googleTypes: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      address: '',
      placeholderShow: ''
    }
  },
  computed: {
    Search: {
      get() {
        return this.initialaddress
      },
      set(val) {
        this.$emit('update:initialaddress', val)
      }
    }
  },
  created() {
    this.address = this.initialaddress
  },
  methods: {
    async getSearchResult(val, cb) {
      if (val === this.address && this.address !== '') {
        return false
      }
      if (!val) {
        cb([])
        this.$emit('OnClear')
        return
      }
      const params = {
        Search: val,
        UseKleber: this.useKleber,
        UseGoogle: this.useGoogle,
        Google_FilterLocation: this.googleFilterLocation,
        Google_Types: this.googleTypes
      }
      await getSearchResultApi(params).then(res => {
        const { data } = res
        const list = data.result
        list.forEach(item => {
          item.displayLine3 = item.displayLine1 + ', ' + item.displayLine2
        })
        list.push({
          displayLine1: "can't find the address?",
          displayLine2: 'Enter it manually...'
        })
        cb(list)
      }).catch(() => {
        const tempData = [
          {
            displayLine1: "can't find the address?",
            displayLine2: 'Enter it manually...'
          }
        ]
        cb(tempData)
      })
    },

    async handleSelect(item) {
      console.log(item)
      if (item.displayLine1 === "can't find the address?") {
        this.$emit('OnManualEntry', this.Search)
      } else {
        const params = {
          DisplayLine1: item.displayLine1,
          DisplayLine2: item.displayLine2
        }
        if (item.kleber_AddressId) {
          params.KleberAddressId = item.kleber_AddressId
        } else {
          params.GooglePlaceId = item.google_PlaceId
        }

        // before get address details,verify 'select an address from the list'
        this.Search = item.displayLine3
        // this.$emit('OnDetailReady', item.displayLine3, item.displayLine3)

        await getAddressDetailsApi(params).then((res) => {
          const { data } = res
          const address = data.result
          let displayLine3 = ''
          if (this.from === 'QualificationsNAB' ||
            this.from === 'QualificationsTrade') {
            displayLine3 = !address.suburb ? '' : ((address.suburb + ', ' + address.state) + (address.country !== 'Australia' ? ' (' + address.country + ')' : ''))
          } else if (this.from === 'EmploymentHistory' || this.from === 'QualificationsTertiary') {
            address.suburb = address.suburb ? address.suburb : address.addressLine1
            displayLine3 = !address.suburb ? '' : ((address.suburb + ', ' + address.state) + (address.country !== 'Australia' ? ' (' + address.country + ')' : ''))
          } else if (this.from === 'AddressHistory' || this.from === 'OtherCountries') {
            displayLine3 = address.fullAddress
          } else if (this.from === 'PersonalDetails' ||
           this.from === 'PersonalDetailsInternational') {
            address.suburb = address.suburb ? address.suburb : address.addressLine1
            address.state = address.countryCode === 'AU' ? address.state : 'OVERSEAS'
            displayLine3 = ((address.suburb || '') + ((address.state && address.state !== 'OVERSEAS') ? (', ' + address.state) : '') +
              ((address.country && (address.country ? address.country.toUpperCase() : '') !== 'AUSTRALIA') ? (', ' + address.country) : '') || '')
          }
          this.Search = displayLine3
          this.$emit('OnDetailReady', data.result, displayLine3)
        })
      }
    },

    handleBlur(val) {
      this.placeholderShow = ''
      if (this.initialaddress === this.Search) {
        return
      }
      if (!(this.Search.length < this.SearchAfterCharacters)) {
        this.$emit('OnNoSelectionMade')
      }
    },
    handleFocus() {
      this.placeholderShow = this.placeholder
    }
  }
}
</script>

<template>
  <div class="addressAutocomplete">
    <el-autocomplete
      v-model="Search"
      :placeholder="placeholderShow"
      :fetch-suggestions="getSearchResult"
      :popper-append-to-body="false"
      style="width: 100%"
      value-key="displayLine3"
      :trigger-on-focus="false"
      :disabled="disabled"
      @select="handleSelect"
      @blur="handleBlur"
      @focus="handleFocus"
    >
      <template slot-scope="{ item }">
        <p
          :class="[
            item.displayLine1 == 'can\'t find the address?' ? 'color-blue' : '',
            'name',
          ]"
        >
          {{ item.displayLine1 }}
        </p>
        <p
          :class="[
            item.displayLine2 == 'Enter it manually...' ? 'color-blue' : '',
            'addr',
          ]"
        >
          {{ item.displayLine2 }}
        </p>
      </template>
    </el-autocomplete>
  </div>
</template>

<style lang="scss" scoped>
.addressAutocomplete {
  ::v-deep .el-autocomplete-suggestion li:hover {
    background-color: #eeeeee;
  }
  ::v-deep .el-autocomplete-suggestion__list li {
    line-height: 1.5 !important;
    margin: 0;
    padding: 4px 20px;
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      color: black;
      font-weight: 500;
    }
    .addr {
      font-size: 12px;
      margin: 0;
      color: black;
    }

    .highlighted .addr {
      color: #ddd;
    }

    .color-blue {
      color: #2974b2;
    }
  }
}
</style>
