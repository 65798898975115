var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c("Header", {
        staticClass: "app-header",
        on: { navMobile: _vm.navMobile },
      }),
      _c("div", { staticClass: "app-main" }, [
        _c(
          "section",
          [
            _c(
              "el-row",
              { attrs: { type: "flex", gutter: 20, justify: "center" } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 13 } },
                  [
                    _c(
                      "div",
                      { staticClass: "container-application" },
                      [
                        _c("h1", { staticClass: "title" }, [
                          _vm._v("Background Check"),
                        ]),
                        _vm.device === "mobile"
                          ? _c("h4", { staticClass: "ref" }, [
                              _vm._v("PBS Ref# " + _vm._s(_vm.ReferenceNumber)),
                            ])
                          : _vm._e(),
                        _c("Application", { ref: "application" }),
                        _vm.device === "mobile"
                          ? _c("div", { staticClass: "Previous-Next" }, [
                              _vm.PreviousShow
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "Previous",
                                      on: { click: _vm.Previous },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-arrow-circle-left",
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "f-s-14" }, [
                                        _vm._v("Previous"),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.NextShow
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "Next",
                                      on: { click: _vm.Next },
                                    },
                                    [
                                      _c("span", { staticClass: "f-s-14" }, [
                                        _vm._v("Next"),
                                      ]),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "fa fa-arrow-circle-right",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.device !== "mobile"
                  ? _c("el-col", { staticStyle: { width: "300px" } }, [
                      _c("div", { staticClass: "container-nav" }, [
                        _c(
                          "span",
                          {
                            staticClass: "ref",
                            staticStyle: {
                              "font-weight": "bold",
                              "text-align": "right",
                            },
                          },
                          [_vm._v("PBS Ref# " + _vm._s(_vm.ReferenceNumber))]
                        ),
                        _c("div", [_c("Sider")], 1),
                        _c("div", { staticClass: "Previous-Next" }, [
                          _vm.PreviousShow
                            ? _c(
                                "span",
                                {
                                  staticClass: "Previous",
                                  on: { click: _vm.Previous },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-arrow-circle-left",
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "f-s-14" }, [
                                    _vm._v("Previous"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.NextShow
                            ? _c(
                                "span",
                                {
                                  staticClass: "Next",
                                  on: { click: _vm.Next },
                                },
                                [
                                  _c("span", { staticClass: "f-s-14" }, [
                                    _vm._v("Next"),
                                  ]),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "fa fa-arrow-circle-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c("footer", { staticClass: "app-footer" }, [
          _vm._v(
            " Copyright 2024 PreciseBackground.com. All rights reserved. "
          ),
        ]),
      ]),
      _c("div", { class: _vm.showNav ? "block" : "none" }, [
        _c("div", { staticClass: "nav-box" }, [
          _c("div", { staticClass: "nav" }, [_c("Sider")], 1),
          _c("div", { staticClass: "blank", on: { click: _vm.closeNav } }),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            width: _vm.device === "mobile" ? "90%" : "500px",
            visible: _vm.sessionVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.sessionVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "font-weight": "bold",
                  "font-size": "18px",
                  margin: "20px",
                },
              },
              [_vm._v("Are you still there?")]
            ),
          ]),
          _c("div", { staticClass: "el-dialog__body" }, [
            _c("p", [
              _vm._v("Your session is about to expire due to inactivity."),
            ]),
            _c("p", [
              _vm._v(
                "If you do not respond to this prompt within one minute you will be logged out. "
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  class: _vm.device === "mobile" ? "width100" : "width48",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSession(true)
                    },
                  },
                },
                [_vm._v("I'm still here")]
              ),
              _c(
                "el-button",
                {
                  class: _vm.device === "mobile" ? "width100" : "width48",
                  on: {
                    click: function ($event) {
                      return _vm.handleSession(false)
                    },
                  },
                },
                [_vm._v("Log me out now")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }