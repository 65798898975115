var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PersonalDetailsItem mb-20" },
    [
      _vm._l(_vm.nameList, function (item) {
        return _c("div", { key: item.index }, [
          item.index != _vm.openIndex
            ? _c(
                "div",
                {
                  staticClass: "history-box",
                  on: {
                    click: function ($event) {
                      return _vm.open(item.index)
                    },
                  },
                },
                [
                  item.isSingleNameOnly
                    ? _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(item.type) +
                            ": " +
                            _vm._s(item.lastName) +
                            " "
                        ),
                        _vm.$isNullDate(item.nameChangeDate, "<>")
                          ? _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm._f("parseDefaultTime")(
                                      item.nameChangeDate,
                                      "{d}/{m}/{y}"
                                    )
                                  ) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(item.type) +
                            ": " +
                            _vm._s(item.firstName) +
                            " " +
                            _vm._s(item.middleNames) +
                            " " +
                            _vm._s(item.lastName) +
                            " "
                        ),
                        _vm.$isNullDate(item.nameChangeDate, "<>")
                          ? _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm._f("parseDefaultTime")(
                                      item.nameChangeDate,
                                      "{d}/{m}/{y}"
                                    )
                                  ) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ]),
                ]
              )
            : _vm._e(),
          item.index == _vm.openIndex
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: `addForm${item.index}`,
                      refInFor: true,
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: item,
                        rules: _vm.rules,
                        "label-width": "50%",
                        "label-position": "top",
                        disabled: _vm.allDisabled,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c("el-form-item", { attrs: { prop: "type" } }, [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [_vm._v("* Type")]
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: item.type,
                                          callback: function ($$v) {
                                            _vm.$set(item, "type", $$v)
                                          },
                                          expression: "item.type",
                                        },
                                      },
                                      [
                                        _c("el-radio", {
                                          attrs: { label: "Alias" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "Maiden" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "Previous" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "nameChangeDate" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "label" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "5px",
                                          },
                                        },
                                        [_vm._v("Date of Name Change")]
                                      ),
                                      _c("el-date-picker", {
                                        attrs: {
                                          "value-format": "yyyy-MM-dd",
                                          format: "dd/MM/yyyy",
                                          "picker-options": _vm.pickerOptions,
                                          placeholder: "DD/MM/YYYY",
                                          type: "date",
                                        },
                                        model: {
                                          value: item.nameChangeDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "nameChangeDate",
                                              $$v
                                            )
                                          },
                                          expression: "item.nameChangeDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c("div", { staticClass: "mb-20" }, [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.isSingleNameOnlyChange(
                                              $event,
                                              item.index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.isSingleNameOnly,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "isSingleNameOnly",
                                              $$v
                                            )
                                          },
                                          expression: "item.isSingleNameOnly",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " I was legally known by single name only "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      !item.isSingleNameOnly
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 12,
                                    xl: 12,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "firstName" } },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "label" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "5px",
                                              },
                                            },
                                            [_vm._v("* Given Name")]
                                          ),
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: 100,
                                              disabled: item.isSingleNameOnly,
                                            },
                                            model: {
                                              value: item.firstName,
                                              callback: function ($$v) {
                                                _vm.$set(item, "firstName", $$v)
                                              },
                                              expression: "item.firstName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 12,
                                    xl: 12,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "lastName" } },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "label" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "5px",
                                              },
                                            },
                                            [_vm._v("* Family Name")]
                                          ),
                                          _c("el-input", {
                                            attrs: { maxlength: 100 },
                                            model: {
                                              value: item.lastName,
                                              callback: function ($$v) {
                                                _vm.$set(item, "lastName", $$v)
                                              },
                                              expression: "item.lastName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.isSingleNameOnly
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 12,
                                    xl: 12,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "lastName" } },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "label" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "5px",
                                              },
                                            },
                                            [_vm._v("* Legal Name")]
                                          ),
                                          _c("el-input", {
                                            attrs: { maxlength: 100 },
                                            model: {
                                              value: item.lastName,
                                              callback: function ($$v) {
                                                _vm.$set(item, "lastName", $$v)
                                              },
                                              expression: "item.lastName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !item.isSingleNameOnly
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "middleNames" } },
                            [
                              _c(
                                "label",
                                { staticClass: "label" },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-bottom": "5px" } },
                                    [_vm._v("Middle Name(s)")]
                                  ),
                                  _c("el-input", {
                                    attrs: { maxlength: 100 },
                                    model: {
                                      value: item.middleNames,
                                      callback: function ($$v) {
                                        _vm.$set(item, "middleNames", $$v)
                                      },
                                      expression: "item.middleNames",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.allDisabled
                        ? _c("el-form-item", [
                            _c("div", { staticClass: "action-box" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdd(
                                        `addForm${item.index}`,
                                        1,
                                        item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Add Name"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item.index, item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete-solid",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Remove Name"),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      _vm.outAddBtnShow && !_vm.allDisabled
        ? _c(
            "div",
            {
              staticClass: "action-box",
              on: {
                click: function ($event) {
                  return _vm.handleAdd("", 2)
                },
              },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "action-item" }, [
      _c("i", { staticClass: "el-icon-circle-plus" }),
      _c("span", { staticClass: "action-value f-s-14" }, [_vm._v("Add Name")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }