import { render, staticRenderFns } from "./token-login.vue?vue&type=template&id=464f4ea5&"
import script from "./token-login.vue?vue&type=script&lang=js&"
export * from "./token-login.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('464f4ea5')) {
      api.createRecord('464f4ea5', component.options)
    } else {
      api.reload('464f4ea5', component.options)
    }
    module.hot.accept("./token-login.vue?vue&type=template&id=464f4ea5&", function () {
      api.rerender('464f4ea5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Login/token-login.vue"
export default component.exports