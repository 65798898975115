<script>
export default {
  name: 'SessionExpiredView',
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>
<template>
  <div class="SessionExpiredView">
    <el-row type="flex" class="row-bg" justify="center">
      <el-col
        :xs="22" :sm="21" :md="8" :lg="8"
        :xl="8"
      ><div class="content-box">
        <div class="logo" />
        <h2 class="text-c">Session Expired</h2>
        <h4>Your session has expired.</h4>
        <h4>Please log in again to continue</h4>
        <el-button class="btn" type="primary">Resume Session</el-button>
      </div>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="scss" scoped>
.SessionExpiredView {
  height: 100%;
  background-color: #206baa;
  .content-box {
      padding: 48px;
    background-color: #fff;
    border-radius: 6px;
    margin:180px 0 0 0;
    color: #4f575e;
    text-align: center;
    h2{
        margin-bottom: 50px;
    }
    h4{
        margin: 0 auto;
    }
    .btn{
        width: 100%;
        margin-top: 30px;
    }
    .logo{
         background-image: url("@/assets/imgs/login-logo.svg");
      height: 106px;
      width: 197px;
      margin: 0 auto;
    }
    .tag {
      width: 100%;
      color: #fff;
      i {
        font-size: 18px;
      }
      span {
        margin: 10px;
        font-size: 16px;
      }
    }
  }
}
</style>
