<script>
import { mapState } from 'vuex'
import AddForm from './AddForm/index.vue'
import {
  preparationApi,
  saveFormDataApi,
  continueApi,
  onHasBeenAuthorisedRepresentativeChangeApi
} from '@/api/ComplianceReference'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'ComplianceReference',
  components: { AddForm },
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  provide() {
    return {
      Continue: this.Continue,
      NewComplianceReference: this.NewComplianceReference,
      OnComplianceRemoved: this.OnComplianceRemoved,
      EditCompliance: this.EditCompliance,
      GetAllCompliancesList: this.GetAllCompliancesList
    }
  },
  data() {
    return {
      compliances: [],
      currentFormSection: {},
      applicantDeclaration: {},
      minQty: 0,
      topForm: {
        hasBeenAuthorisedRepresentative: ''
      },
      topRules: {
        hasBeenAuthorisedRepresentative: [
          { required: true, message: 'Please select a value', trigger: 'change' }
        ]
      },
      formConfig: {
        licenseeName: '',
        licenseeNumber: '',
        complianceTeamMemberName: '',
        complianceTeamMemberPosition: '',
        contactPhone: '',
        workEmail: '',
        local_CanContact: '',
        historyShow: false,
        index: 0
      },
      compliancesList: [
        {
          licenseeName: '',
          licenseeNumber: '',
          complianceTeamMemberName: '',
          complianceTeamMemberPosition: '',
          contactPhone: '',
          workEmail: '',
          local_CanContact: '',
          historyShow: false,
          index: 0
        }
      ],
      index: 0,
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: (state) => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: (state) => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    Session() {
      return this.preparation.session
    },
    CompletePercent() {
      return this.Session.current.formSection.completePercent === 100
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    FormShow() {
      return this.topForm.hasBeenAuthorisedRepresentative === 'Yes'
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'ComplianceReference')
      // }
      this.allLoading = true
      const params = {
        ApplicantId: this.ApplicantId,
        session: this.Session,
        AFSRepresentativeNumber: this.Applicant.afsRepresentativeNumber
      }
      await preparationApi(params).then((res) => {
        const { data } = res
        this.topForm.hasBeenAuthorisedRepresentative =
          data.hasBeenAuthorisedRepresentative
        this.currentFormSection = data.currentFormSection
        this.applicantDeclaration = data.applicantDeclaration
        this.compliances = data.compliances
        this.minQty = data.currentFormSection.minQty
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async EditCompliance(list) {
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        formData: {
          hasBeenAuthorisedRepresentative:
            this.topForm.hasBeenAuthorisedRepresentative,
          compliances: []
        }
      }
      list.forEach((item) => {
        params.formData.compliances.push({
          compliance: {
            ...item,
            cancontact: item.local_CanContact === 'Yes',
            applicantId: this.ApplicantId
          },
          local_CanContact: item.local_CanContact
        })
      })
      await saveFormDataApi(params).then((res) => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    async NewComplianceReference(list) {
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        formData: {
          hasBeenAuthorisedRepresentative:
            this.topForm.hasBeenAuthorisedRepresentative,
          compliances: []
        }
      }
      list.forEach((item) => {
        params.formData.compliances.push({
          compliance: {
            ...item,
            cancontact: item.local_CanContact === 'Yes',
            applicantId: this.ApplicantId
          },
          local_CanContact: item.local_CanContact
        })
      })
      await saveFormDataApi(params).then((res) => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    async OnComplianceRemoved(item, list) {
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        formData: {
          hasBeenAuthorisedRepresentative:
            this.topForm.hasBeenAuthorisedRepresentative,
          compliances: [],
          removedCompliances: [{ ...item }]
        }
      }
      list.forEach((item) => {
        params.formData.compliances.push({
          compliance: {
            ...item,
            applicantId: this.ApplicantId
          },
          local_CanContact: item.local_CanContact
        })
      })
      await saveFormDataApi(params).then((res) => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    async OnHasBeenAuthorisedRepresentativeChange(val) {
      if (val === 'No') {
        this.compliancesList = [
          {
            licenseeName: '',
            licenseeNumber: '',
            complianceTeamMemberName: '',
            complianceTeamMemberPosition: '',
            contactPhone: '',
            workEmail: '',
            local_CanContact: '',
            historyShow: false,
            index: 0
          }
        ]
        this.index = 0
        const params1 = {
          applicantId: this.ApplicantId
        }
        await onHasBeenAuthorisedRepresentativeChangeApi(params1).then(
          (res) => {}
        )
      }
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        formData: {
          hasBeenAuthorisedRepresentative:
            this.topForm.hasBeenAuthorisedRepresentative,
          compliances: []
        }
      }
      this.compliances.forEach((item) => {
        params.formData.compliances.push({
          compliance: {
            ...item,
            cancontact: item.local_CanContact === 'Yes',
            applicantId: this.ApplicantId
          },
          local_CanContact: item.local_CanContact
        })
      })
      if (val === 'No') {
        params.formData.compliances = []
      }
      await saveFormDataApi(params).then((res) => {
        if (res.data && res.data.session) {
          this.SaveFormDataSetSession(res.data.session)
        }
      })
    },

    GetAllCompliancesList(list) {
      this.compliances = list
    },

    async Continue(list) {
      this.$refs.topForm.validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicant: this.Applicant,
            compliances: [],
            hasBeenAuthorisedRepresentative:
          this.topForm.hasBeenAuthorisedRepresentative
          }
          if (this.topForm.hasBeenAuthorisedRepresentative === 'Yes') {
            list.forEach((item) => {
              const obj = {
                compliance: { ...item, applicantId: this.ApplicantId },
                local_CanContact: item.local_CanContact
              }
              obj.compliance.cancontact = item.local_CanContact === 'Yes'
              params.compliances.push(obj)
            })
          }

          await continueApi(params).then((res) => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    }
  }
}
</script>
<template>
  <div v-loading="allLoading" class="ComplianceReference">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-divider />
    <el-form
      ref="topForm"
      :model="topForm"
      :rules="topRules"
      label-width="80px"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-form-item prop="hasBeenAuthorisedRepresentative">
        <el-row>
          <el-col :xs="24" :sm="15" :lg="15" :xl="15">
            <span class="label"><span class="color-red">*</span> In the last {{ minQty }} years have you been
              an Authorised Representative?</span>
          </el-col>
          <el-col :xs="24" :sm="9" :lg="9" :xl="9">
            <el-radio-group
              v-model="topForm.hasBeenAuthorisedRepresentative"
              @change="OnHasBeenAuthorisedRepresentativeChange"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>

    <div v-if="FormShow">
      <p class="f-w-600">
        Please provide contact details of compliance team member from all
        licensees.
      </p>

      <AddForm
        ref="AddForms"
        :compliances="compliances"
        :continue-btn="ContinueBtn"
        :complete-percent="CompletePercent"
      />
    </div>
    <el-button
      v-if="!FormShow && ContinueBtn"
      :loading="endAllRequests"
      type="primary"
      @click="Continue"
    >Save and Continue</el-button>
  </div>
</template>

<style lang="scss" scoped>
.ComplianceReference {
  ::v-deep .el-form-item {
    margin-bottom: 20px;
  }
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
  }
}
</style>
