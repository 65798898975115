<script>
export default {
  name: 'PersonalDetailsItem',
  props: {
    otherNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formConfig: {
        type: '',
        nameChangeDate: null,
        firstName: '',
        lastName: '',
        middleNames: ''
      },
      nameList: []
    }
  },
  created() {
    this.nameList = this.otherNames
  }
}
</script>

<template>
  <div class="PersonalDetailsItem">
    <div v-for="item in nameList" :key="item.index">
      <div class="history">
        <p>{{ item.type }}: {{ item.label }} <span v-if="$isNullDate(item.nameChangeDate, '<>')">({{ item.nameChangeDate | parseDefaultTime("{d}/{m}/{y}") }})</span> </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.PersonalDetailsItem {
  .history {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    height: 40px;
    padding: 0 10px;
    margin: 10px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    p {
      font-size: 14px;
      margin: 0;
      line-height: 40px;
    }
  }
}
</style>
