import Cookies from 'js-cookie'

const TokenKey = 'accessToken-applicant'
const TokenTime = 'appTokenTime'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// get token time
export function getTokenTime() {
  return Cookies.get(TokenTime)
}

export function setTokenTime(time) {
  return Cookies.set(TokenTime, time)
}

export function removeTokenTime() {
  return Cookies.remove(TokenTime)
}

