<script>
export default {
  name: 'RefereesItemView',
  props: {
    referees: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formConfig: {
        name: '',
        canContactImmediately: '',
        employerName: '',
        positionHeld: '',
        relationshipToApplicant: '',
        contactPhoneMobile: '',
        contactPhoneBusiness: '',
        contactEmail: ''
      },
      ruleForm: {
        name: '',
        canContactImmediately: '',
        employerName: '',
        positionHeld: '',
        relationshipToApplicant: '',
        contactPhoneMobile: '',
        contactPhoneBusiness: '',
        contactEmail: ''
      },
      refereesList: [
        {
          name: '',
          canContactImmediately: '',
          employerName: '',
          positionHeld: '',
          relationshipToApplicant: '',
          contactPhoneMobile: '',
          contactPhoneBusiness: '',
          contactEmail: '',
          index: 0
        }
      ],
      openIndex: 0, // 当前打开的index
      index: 0, // 持续递增的index
      activeNames: ''
    }
  },
  computed: {
    details() {
      return this.from === 'Edit'
    }
  },
  methods: {
    getRefereesList() {
      if (this.referees.length > 0) {
        this.refereesList = this.referees
        this.refereesList.reverse()
      }
    }
  }
}
</script>

<template>
  <div class="RefereesItemView">
    <el-collapse v-model="activeNames">
      <el-collapse-item
        v-for="item in refereesList" :key="item.index" :title="`${item.name} (${item.positionHeld} at ${item.employerName})`"
        :name="item.index"
      >
        <div>
          <el-form
            :ref="`addForm${item.index}`"
            :model="item"
            class="demo-ruleForm"
            label-position="top"
            disabled
          >
            <el-form-item label="Contact Person" prop="name">
              <el-input v-model="item.name" />
            </el-form-item>

            <el-form-item label="Can Contact Immediately" prop="canContactImmediately">
              <el-checkbox v-model="item.canContactImmediately" />
            </el-form-item>

            <el-form-item label="Employer Name" prop="employerName">
              <el-input v-model="item.employerName" />
            </el-form-item>

            <el-form-item label="Position Held" prop="positionHeld">
              <el-input v-model="item.positionHeld" />
            </el-form-item>

            <el-form-item label="Relationship to You" prop="relationshipToApplicant">
              <el-input v-model="item.relationshipToApplicant" />
            </el-form-item>

            <el-form-item label="Contact Phone Mobile" prop="contactPhoneMobile">
              <el-input v-model="item.contactPhoneMobile" />
            </el-form-item>

            <el-form-item label="Contact Phone Business" prop="contactPhoneBusiness">
              <el-input v-model="item.contactPhoneBusiness" />
            </el-form-item>

            <el-form-item label="Contact Email" prop="contactEmail">
              <el-input v-model="item.contactEmail" />
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<style lang="scss" scoped>
.label {
  line-height: 40px;
  font-size: 14px;
  color: #606266;
}
::v-deep .el-collapse-item__wrap {
  background-color: transparent !important;
}
::v-deep .el-collapse-item__header {
  padding: 0;
  background-color: transparent !important;
}
.action-box {
  display: flex;
  justify-content: space-between;
  color: #206caa;
  .action-item {
    cursor: pointer;
  }
  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }
  .action-value {
    margin-left: 10px;
  }
}
</style>
