var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      attrs: { className: "EmploymentHistoryView" },
    },
    [
      _c("EmploymentHistoryItem", {
        ref: "EmploymentHistoryItemRef",
        attrs: {
          "get-compliances-by-applicant-id": _vm.getCompliancesByApplicantId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }