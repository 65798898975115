import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/personal-details-international' : '/proxy/api/applicant/personal-details-international'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}

export function onShowPassportChangeApi(data) {
  return service({
    url: `${api}/on-show-passport-change`,
    method: 'post',
    data: data
  })
}

export function onShowCreditorBankCardChangeApi(data) {
  return service({
    url: `${api}/on-show-creditor-bank-card-change`,
    method: 'post',
    data: data
  })
}

export function onShowDriversLicenceChangeApi(data) {
  return service({
    url: `${api}/on-show-drivers-licence-change`,
    method: 'post',
    data: data
  })
}

export function onPassportCountryChangeApi(data) {
  return service({
    url: `${api}/on-passport-country-change`,
    method: 'post',
    data: data
  })
}

export function uploadPassportApi(data) {
  return service({
    url: `${api}/upload-passport`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadBankOrDreditCardApi(data) {
  return service({
    url: `${api}/upload-bank-or-credit-card`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadCitizenShipCertApi(data) {
  return service({
    url: `${api}/upload-citizen-ship-cert`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadBirthCertificateApi(data) {
  return service({
    url: `${api}/upload-birth-certificate`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadDriversLicenceeApi(data) {
  return service({
    url: `${api}/upload-drivers-licence`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadDriversLicenceeBackApi(data) {
  return service({
    url: `${api}/upload-drivers-licence-back`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function continueApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}

export function saveFormDataApi(data) {
  return service({
    url: `${api}/save-form-data`,
    method: 'post',
    data: data
  })
}
export function removeDocumentApi(data) {
  return service({
    url: `${api}/remove-document`,
    method: 'post',
    data: data
  })
}
