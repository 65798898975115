var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "ComplianceReference",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "Are you aware of any positions, relationships or any other matters that could give rise to real or apparent conflicts of interest if you obtain the position you have applied for?",
                prop: "hasAssociation",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.coiHasAssociation,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "coiHasAssociation", $$v)
                    },
                    expression: "ruleForm.coiHasAssociation",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.coiHasAssociation
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Please provide details",
                    prop: "coiAssociationDetail",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.coiAssociationDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "coiAssociationDetail", $$v)
                      },
                      expression: "ruleForm.coiAssociationDetail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "Do you have any relatives or associates that have any prior or present association with any organisation or individual that could be considered a real or apparent conflict of interest if you are successful in obtaining the position you have applied for?",
                prop: "hasRelativeWithAssociation",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.coiRelativeHasAssociation,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "coiRelativeHasAssociation", $$v)
                    },
                    expression: "ruleForm.coiRelativeHasAssociation",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.coiRelativeHasAssociation
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Please provide details",
                    prop: "coiRelativeAssociationDetail",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.coiRelativeAssociationDetail,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "coiRelativeAssociationDetail",
                          $$v
                        )
                      },
                      expression: "ruleForm.coiRelativeAssociationDetail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }