var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      ref: "dialogEntity",
      staticClass: "dialog",
      attrs: {
        "lock-scroll": false,
        "append-to-body": true,
        visible: _vm.visible,
        width: _vm.device === "mobile" ? "90%" : "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v(_vm._s(_vm.dialogTitle))]
      ),
      _vm.show
        ? _c(
            "div",
            _vm._l(_vm.metaInfo[_vm.currentIndex].sections, function (section) {
              return _c(
                "div",
                { key: section.title },
                [
                  _c("div", { staticClass: "section-title" }, [
                    _vm._v(_vm._s(section.title)),
                  ]),
                  _vm._l(section.messageList, function (item) {
                    return _c("div", { key: item.title }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(item.content)),
                      ]),
                    ])
                  }),
                  _c(
                    "div",
                    { staticClass: "imageList" },
                    _vm._l(section.imageList, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "imageListItem" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _c("img", {
                            staticClass: "popup-img",
                            attrs: { src: item.src },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("br"),
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }