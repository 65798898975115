import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/authorised-representative' : '/proxy/api/applicant/authorised-representative'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}

export function onIsRepresentativeChangeApi(data) {
  return service({
    url: `${api}/on-is-representative-change`,
    method: 'post',
    data: data
  })
}

export function continueChangeApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}
