var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "EntitlementToWork2View",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "50%",
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selectedCitizenshipCountryCode" } },
                    [
                      _c(
                        "label",
                        { staticClass: "label" },
                        [
                          _vm._v("* What is your Country of Citizenship? "),
                          _c("GetAllGeoCountries", {
                            attrs: {
                              "defalut-value":
                                _vm.ruleForm.selectedCitizenshipCountryCode,
                              disabled: _vm.allDisabled,
                              "geo-countries": _vm.geoCountries,
                            },
                            on: {
                              "update:defalutValue": function ($event) {
                                return _vm.$set(
                                  _vm.ruleForm,
                                  "selectedCitizenshipCountryCode",
                                  $event
                                )
                              },
                              "update:defalut-value": function ($event) {
                                return _vm.$set(
                                  _vm.ruleForm,
                                  "selectedCitizenshipCountryCode",
                                  $event
                                )
                              },
                              getCountryData: _vm.OnCitizenshipCountryChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-form-item",
                { staticClass: "dualCitizen", attrs: { prop: "dualCitizen" } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 16, lg: 15, xl: 19 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(" Do you hold dual Citizenship? "),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 6, lg: 7, xl: 5 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.OnDualCitizenChange },
                          model: {
                            value: _vm.ruleForm.dualCitizen,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "dualCitizen", $$v)
                            },
                            expression: "ruleForm.dualCitizen",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.dualCitizen === "Yes"
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "selectedCitizenshipDualCountryCode" },
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "label" },
                            [
                              _vm._v(
                                "* What is your second Country of Citizenship? "
                              ),
                              _c("GetAllGeoCountries", {
                                attrs: {
                                  "defalut-value":
                                    _vm.ruleForm
                                      .selectedCitizenshipDualCountryCode,
                                  "geo-countries": _vm.geoCountries,
                                },
                                on: {
                                  "update:defalutValue": function ($event) {
                                    return _vm.$set(
                                      _vm.ruleForm,
                                      "selectedCitizenshipDualCountryCode",
                                      $event
                                    )
                                  },
                                  "update:defalut-value": function ($event) {
                                    return _vm.$set(
                                      _vm.ruleForm,
                                      "selectedCitizenshipDualCountryCode",
                                      $event
                                    )
                                  },
                                  getCountryData:
                                    _vm.OnCitizenshipDualCountryChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.PassportShow
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "dualCitizen",
                      attrs: { prop: "hasPassport" },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "label",
                              attrs: { xs: 24, sm: 24, md: 24, lg: 15, xl: 19 },
                            },
                            [
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                " Do you hold a current " +
                                  _vm._s(
                                    _vm.ausCitizen === "Yes"
                                      ? "Australian "
                                      : "International"
                                  ) +
                                  " Passport? "
                              ),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 24, md: 24, lg: 7, xl: 5 } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.hasPassportDisabled },
                                  on: { change: _vm.OnHasPassportChange },
                                  model: {
                                    value: _vm.ruleForm.hasPassport,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "hasPassport", $$v)
                                    },
                                    expression: "ruleForm.hasPassport",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "Yes" } }),
                                  _c("el-radio", { attrs: { label: "No" } }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.ruleForm.hasPassport === "Yes"
                    ? _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "passportNumber" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "label" },
                                    [
                                      _vm._v("* Passport Number "),
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "Enter Passport Number",
                                          disabled: _vm.passportNumberDisabled,
                                        },
                                        on: { input: _vm.passportNumberChange },
                                        model: {
                                          value: _vm.ruleForm.passportNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "passportNumber",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.passportNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _vm.PassportCountryShow
                                ? _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Passport Country",
                                        prop: "selectedPassportCountryCode",
                                      },
                                    },
                                    [
                                      _c("GetAllGeoCountries", {
                                        attrs: {
                                          "defalut-value":
                                            _vm.ruleForm
                                              .selectedPassportCountryCode,
                                          disabled:
                                            _vm.passportNumberDisabled ||
                                            _vm.allDisabled,
                                          "geo-countries": _vm.geoCountries,
                                        },
                                        on: {
                                          "update:defalutValue": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.ruleForm,
                                              "selectedPassportCountryCode",
                                              $event
                                            )
                                          },
                                          "update:defalut-value": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.ruleForm,
                                              "selectedPassportCountryCode",
                                              $event
                                            )
                                          },
                                          getCountryData:
                                            _vm.OnPassportCountryChange,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.BirthOrCitizenshipCertShow
            ? _c(
                "div",
                [
                  _vm.ruleForm.hasPassport === "No"
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "f-w-600",
                          attrs: {
                            label:
                              "Please attach a legible copy of your Birth Certificate or Citizenship Certificate",
                            prop: "selectedCertificateDocumentType",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled:
                                  _vm.selectedCertificateDocumentTypeDisabled,
                              },
                              on: {
                                change: _vm.OnCertificateDocumentTypeChange,
                              },
                              model: {
                                value:
                                  _vm.ruleForm.selectedCertificateDocumentType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "selectedCertificateDocumentType",
                                    $$v
                                  )
                                },
                                expression:
                                  "ruleForm.selectedCertificateDocumentType",
                              },
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "Birth" } },
                                [_vm._v("Birth Certificate")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "Citizenship" } },
                                [_vm._v("Citizenship Certificate")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.DocumentShow
                    ? _c("div", [
                        _vm.DocumentShow2
                          ? _c(
                              "div",
                              [
                                _vm.ruleForm.hasPassport === "Yes"
                                  ? _c("p", { staticClass: "f-w-600" }, [
                                      _vm._v(
                                        "Please attach a legible copy of your " +
                                          _vm._s(
                                            _vm.ausCitizen === "Yes"
                                              ? "Australian "
                                              : "International "
                                          ) +
                                          " Passport "
                                      ),
                                      _c("span", { staticClass: "color-red" }, [
                                        _vm._v("*"),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { prop: "orderApplicantDocuments" },
                                  },
                                  [
                                    _vm.orderApplicantDocuments.length < 1
                                      ? _c("OnlyUpload", {
                                          on: {
                                            Document_Upload:
                                              _vm.CloudinaryUpload_UploadResult,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._l(
                                      _vm.orderApplicantDocuments,
                                      function (item, index) {
                                        return _c("OnlyUpload", {
                                          key: item.id,
                                          attrs: {
                                            "img-item": item,
                                            "icon-color": "#206caa",
                                          },
                                          on: {
                                            Document_Upload:
                                              _vm.CloudinaryUpload_UploadResult,
                                            Document_Remove: function ($event) {
                                              return _vm.Document_Remove(
                                                "order",
                                                index,
                                                $event
                                              )
                                            },
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.OtherNameDocumentShow
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "Please provide either a Change of Name Certificate or Marriage Certificate issued by a government agency or regulator.",
                        prop: "selectedOtherNameDocumentType",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            disabled: _vm.selectedOtherNameDocumentTypeDisabled,
                          },
                          on: { change: _vm.OnOtherNameDocumentTypeChange },
                          model: {
                            value: _vm.ruleForm.selectedOtherNameDocumentType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "selectedOtherNameDocumentType",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.selectedOtherNameDocumentType",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "ChangeOfName" } },
                            [_vm._v("Change of Name Certificate")]
                          ),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "Marriage" } },
                            [_vm._v("Marriage Certificate")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.ruleForm.selectedOtherNameDocumentType
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "otherNameDocuments" } },
                            [
                              _vm.otherNameDocuments.length < 1 &&
                              _vm.imgcanDelete
                                ? _c("OnlyUpload", {
                                    on: {
                                      Document_Upload:
                                        _vm.CloudinaryUpload_UploadResult_OtherName,
                                    },
                                  })
                                : _vm._e(),
                              _vm._l(_vm.otherNameDocuments, function (item) {
                                return _c("OnlyUpload", {
                                  key: item.id,
                                  attrs: {
                                    "img-item": item,
                                    "can-delete": _vm.getImgcanDelete(item),
                                  },
                                  on: {
                                    Document_Upload:
                                      _vm.CloudinaryUpload_UploadResult_OtherName,
                                    Document_Remove: function ($event) {
                                      return _vm.Document_Remove(
                                        "otherName",
                                        $event,
                                        item
                                      )
                                    },
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _vm.ContinueBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.endAllRequests, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.Continue("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.buttonText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }