<script>
import { getAllGeoCountriesApi } from '@/api/application'
export default {
  name: 'GetAllGeoCountriesView',
  props: {
    defalutValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    geoCountries: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: []
    }
  },
  computed: {
    country: {
      get() {
        return this.defalutValue
      },
      set(val) {
        this.$emit('update:defalutValue', val)
      }
    }
  },
  created() {
    if (this.geoCountries.length) {
      this.options = this.geoCountries
      this.changeTheOrder()
    } else {
      this.getCountries()
    }
  },
  mounted() {},
  methods: {
    async getCountries() {
      await getAllGeoCountriesApi().then((res) => {
        const { data } = res
        this.options = data.geoCountries
        this.changeTheOrder()
      })
    },
    changeTheOrder() {
      const auData = this.options.find((item) => item.countryCode === 'AU')
      const auIndex = this.options.findIndex((item) => item.countryCode === 'AU')
      this.options.splice(auIndex, 1)
      this.options.unshift(auData)
    },
    handleChange(val) {
      const data = this.options.find((item) => item.countryCode === val)
      this.$emit('getCountryData', data)
    }
  }
}
</script>

<template>
  <div class="country-select">
    <el-select
      v-model="country"
      filterable
      placeholder="Select a Country"
      :disabled="disabled"
      @change="handleChange"
    >
      <el-option
        v-for="(el, index) in options"
        :key="index"
        :label="el.countryName"
        :value="el.countryCode"
      />
    </el-select>
  </div>
</template>
<style lang="scss" scoped>
.country-select {
  width: 100%;
  .el-select {
    width: 100%;
  }
}
</style>
