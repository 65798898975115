
<script>
import { mapState } from 'vuex'
export default {
  name: 'SiderView',
  inject: ['closeNav'],
  props: {
    fromPage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device,
      section: state => state.section.section,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    routeList() {
      if (this.preparation.session && this.fromPage !== 'select') {
        return this.preparation.session.formSections
      } else {
        return []
      }
    },
    activeMenu() {
      return this.section
    },
    loading() {
      return this.endAllRequests
    }
  },
  watch: {
    section: function(newData, oldData) {
      // page to the top
      window.scrollTo(0, 0)
    }
  },
  methods: {
    getPath(children) {
      const nav = this.preparation.session.formSections
      const data = this.preparation
      const section = nav.find((item) => item.blockName === children)
      data.session.current.formSection = section
      this.$store.dispatch('preparation/setPreparation', data)
      this.$store.dispatch('section/set_section', { section: children })
      this.closeNav()
    },
    Logout() {
      this.$router.push({ name: 'Login' })
      this.$store.dispatch('user/logOut')
    }
  }
}
</script>

<template>
  <div>
    <el-menu
      v-loading="loading"
      :default-active="activeMenu"
      class="el-menu-vertical-demo"
      @select="getPath"
    >
      <nav v-for="item in routeList" :key="item.blockName">
        <el-menu-item :key="item.blockName" :index="item.blockName">
          <div v-if="item.completePercent===100" class="completePercent100">
            <i class="fa fa-check-circle" />
            <span>{{ item.title }}</span>
          </div>
          <div v-else class="completePercent0">
            <i v-if="activeMenu===item.blockName" class="fa fa-dot-circle-o" />
            <i v-else class="fa fa-circle-o" />
            <span>{{ item.title }}</span>
          </div>
        </el-menu-item>
      </nav>
    </el-menu>
    <el-divider v-if="device === 'mobile'" />
    <p v-if="device === 'mobile'" class="Logout" @click="Logout"><i class="fa fa-sign-out" /> Logout</p>
  </div>
</template>

<style lang="scss" scoped>
.Logout{
  text-align: center;
  font-size: 14px;
  color: #206caa;
}
.completePercent100{
  color: #20652B;// #37b24d;
  white-space: pre-wrap;
  line-height: 1.5 !important;
  display: flex;
  align-items: center;
  i{
    display: block;
    font-size: 28px;
    color: #20652B;
    margin-right: 10px;
  }
}
.completePercent0{
  color: #206caa;
  white-space: pre-wrap;
  line-height: 1.5 !important;
  display: flex;
  align-items: center;
  i{
    display: block;
    font-size: 28px;
    margin-right: 10px;
    color: #206caa;
  }
}
::v-deep .el-loading-mask{
  background-color: rgba(255, 255, 255, 0) !important;
}
::v-deep  .el-loading-spinner {
  display: none;
}
</style>
