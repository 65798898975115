var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "OtherCountriesView",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c("el-divider"),
      _c(
        "el-form",
        {
          ref: "Form",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "livedInOtherCountries" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " In the last " +
                          _vm._s(_vm.minQty) +
                          " years have you lived in another country for a period of 6 months or greater? "
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.OnHasOtherCountriesChange },
                          model: {
                            value: _vm.ruleForm.livedInOtherCountries,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "livedInOtherCountries",
                                $$v
                              )
                            },
                            expression: "ruleForm.livedInOtherCountries",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.inAddressHistoryShow
            ? _c(
                "el-form-item",
                { attrs: { prop: "inAddressHistory" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "label",
                          attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                        },
                        [
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            " Has this information been recorded in your address history? "
                          ),
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.OnIsInHistoryChange },
                              model: {
                                value: _vm.ruleForm.inAddressHistory,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "inAddressHistory",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.inAddressHistory",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "Yes" } }),
                              _c("el-radio", { attrs: { label: "No" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.FormShow
            ? [
                _c("OtherCountriesItem", {
                  attrs: {
                    "continue-btn": _vm.ContinueBtn,
                    "previous-addresses": _vm.previousAddresses,
                    "complete-percent": _vm.CompletePercent,
                  },
                }),
              ]
            : _vm._e(),
          !_vm.FormShow
            ? [
                _vm.ContinueBtn
                  ? _c(
                      "el-button",
                      {
                        staticClass: "mt-30",
                        attrs: { loading: _vm.endAllRequests, type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.Continue("Continue")
                          },
                        },
                      },
                      [_vm._v("Save and Continue")]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }