<script>
import { mapState } from 'vuex'
import GetAllGeoCountries from '@/components/GetAllGeoCountries'
import AddressAutocomplete from '@/components/AddressAutocomplete'
import dayjs from 'dayjs'
export default {
  name: 'OtherCountriesItem',
  components: { GetAllGeoCountries, AddressAutocomplete },
  inject: ['Continue', 'SaveFormTempData', 'NewComplianceReference', 'OnComplianceRemoved'],
  props: {
    continueBtn: {
      type: Boolean,
      default: true
    },
    previousAddresses: {
      type: Array,
      default: () => []
    },
    completePercent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const validateSearch = (rule, value, callback) => {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      if (value !== '') {
        if (value === this.compliancesList[spliceIndex].displayLine1) {
          callback()
        } else {
          callback(new Error('Please select an address from the list.'))
        }
      } else {
        callback(new Error('Required field!'))
      }
    }
    return {
      formConfig: {
        fullAddress: '',
        selectedCountryCode: '',
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        fromDate: null,
        toDate: null,
        isManual: false
      },
      contrast: ['fullAddress', 'selectedCountryCode', 'addressLine1', 'addressLine2', 'suburb', 'fromDate', 'toDate'],
      rules: {
        fullAddress: [
          { required: true, validator: validateSearch, trigger: 'blur' }
        ],
        addressLine1: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        suburb: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        fromDate: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        toDate: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ]
      },
      compliancesList: [
        {
          fullAddress: '',
          selectedCountryCode: '',
          addressLine1: '',
          addressLine2: '',
          suburb: '',
          fromDate: null,
          toDate: null,
          index: 0,
          isManual: false
        }
      ],
      openIndex: 0, // 当前打开的index
      index: 0 // 持续递增的index
    }
  },
  computed: {
    ...mapState({
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    outAddBtnShow() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      return spliceIndex === -1
    }
  },
  // watch: {
  //   compliancesList: {
  //     handler(data) {
  //       if (data) {
  //         const oldData = JSON.parse(JSON.stringify(data))
  //         this.SaveFormTempData(oldData)
  //       }
  //     },
  //     immediate: true,
  //     deep: true
  //   }},
  created() {
    if (this.previousAddresses.length > 0) {
      this.compliancesList = this.previousAddresses
      this.previousAddresses.forEach(item => {
        item.displayLine1 = item.fullAddress
        item.selectedCountryCode = item.countryCode
      })
      this.compliancesList = this.$isRepeat(this.compliancesList)
      this.index = Math.max.apply(Math, this.compliancesList.map(item => { return item.index }))
      this.index = this.index ? this.index : 0
      if (this.completePercent) { // continue后 不展开列表
        this.openIndex = null
      } else {
        this.openIndex = (this.index && this.index !== 0) ? this.index : 0
      }
    }
  },
  methods: {
    handleRemove(itemIndex, item) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === itemIndex
      )
      if (this.compliancesList.length > 1) {
        this.compliancesList.splice(spliceIndex, 1)
        this.OnComplianceRemoved(item, this.compliancesList)
      } else {
        this.OnComplianceRemoved(this.compliancesList[0], [])
        this.compliancesList = [
          {
            fullAddress: '',
            selectedCountryCode: '',
            addressLine1: '',
            addressLine2: '',
            suburb: '',
            fromDate: null,
            toDate: null,
            index: 0,
            isManual: false
          }
        ]
      }
    },
    addItem() {
      this.NewComplianceReference(this.compliancesList)
      this.compliancesList.push(
        JSON.parse(
          JSON.stringify({
            ...this.formConfig,
            index: ++this.index
          })
        )
      )
      this.openIndex = this.index
    },
    handleAdd(formName, type, item) {
      if (type === 1) {
        // 表单内
        this.$refs[formName][0].validate(async(valid) => {
          if (valid) {
            this.addItem()
          }
        })
      } else {
        // 全局下
        if (
          this.$refs[`addForm${this.index}`] &&
          this.$refs[`addForm${this.index}`][0]
        ) {
          // 当前存在打开的表单
          this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
            if (valid) {
              this.addItem()
            }
          })
        } else {
          //  不存在打开的表单
          this.addItem()
        }
      }
    },
    open(itemIndex) {
      if (
        this.$refs[`addForm${this.index}`] &&
        this.$refs[`addForm${this.index}`][0]
      ) {
        this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
          if (valid) {
            this.openIndex = itemIndex
          } else {
            const data = this.compliancesList.find((item) => item.index === this.openIndex)
            const spliceIndex = this.compliancesList.findIndex((item) => item.index === this.openIndex)
            for (let i = 0; i < this.contrast.length; i++) {
              if (data[this.contrast[i]]) {
                return false
              }
            }
            this.compliancesList.splice(spliceIndex, 1)
            this.openIndex = itemIndex
          }
        })
      } else {
        this.openIndex = itemIndex
      }
    },
    handleSure(type) {
      if (
        this.$refs[`addForm${this.openIndex}`] &&
        this.$refs[`addForm${this.openIndex}`][0]
      ) {
        this.$refs[`addForm${this.openIndex}`][0].validate(async(valid) => {
          if (valid) {
            type === 'Continue' ? this.Continue(this.compliancesList) : this.SaveFormTempData(this.compliancesList)
          } else {
            if (this.compliancesList.length <= 1) {
              this.$message.error('Please provide at least 1 overseas address')
            }
          }
        })
      } else {
        type === 'Continue' ? this.Continue(this.compliancesList) : this.SaveFormTempData(this.compliancesList)
      }
    },
    onCountryChage(index, val) {
      const num = this.compliancesList.findIndex((item) => item.index === index)
      this.compliancesList[num].selectedCountryCode = val.countryCode
      this.compliancesList[num].country = val.countryName
      this.compliancesList[num].countryCode = val.countryCode
      this.compliancesList[num].countryCode3 = val.isoAlpha3
    },
    AddressAutocompleteOnDetailReady(AddressDetail, displayLine1) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].displayLine1 = displayLine1
      this.compliancesList[spliceIndex].fullAddress = AddressDetail.fullAddress
      this.compliancesList[spliceIndex].addressLine1 = AddressDetail.addressLine1
      this.compliancesList[spliceIndex].addressLine2 = AddressDetail.addressLine2
      this.compliancesList[spliceIndex].suburb = AddressDetail.suburb
      this.compliancesList[spliceIndex].state = AddressDetail.state
      this.compliancesList[spliceIndex].country = AddressDetail.country
      this.compliancesList[spliceIndex].countryCode = AddressDetail.countryCode
      // this.compliancesList[spliceIndex].countryCode3=GetCountry(AddressDetail.CountryCode).IsoAlpha3
      this.compliancesList[spliceIndex].longitude = AddressDetail.longitude
      this.compliancesList[spliceIndex].latitude = AddressDetail.latitude
      this.compliancesList[spliceIndex].meshblock = AddressDetail.meshblock
      this.compliancesList[spliceIndex].gnaf = AddressDetail.gnaf
      this.compliancesList[spliceIndex].dpid = AddressDetail.dpid
      this.compliancesList[spliceIndex].parcelId = AddressDetail.parcelId
      this.compliancesList[spliceIndex].isManual = false

      this.$refs[`addForm${this.openIndex}`][0].validateField('fullAddress')
    },
    AddressAutocompleteOnManualEntry(Search) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].displayLine1 = ''
      this.compliancesList[spliceIndex].addressLine1 = Search
      this.compliancesList[spliceIndex].country = !this.compliancesList[spliceIndex].country ? 'Australia' : this.compliancesList[spliceIndex].country
      this.compliancesList[spliceIndex].countryCode = !this.compliancesList[spliceIndex].countryCode ? 'AU' : this.compliancesList[spliceIndex].countryCode
      this.compliancesList[spliceIndex].countryCode3 = !this.compliancesList[spliceIndex].countryCode3 ? 'AUS' : this.compliancesList[spliceIndex].countryCode3
      this.compliancesList[spliceIndex].selectedCountryCode = this.compliancesList[spliceIndex].countryCode
      this.compliancesList[spliceIndex].isManual = true
      this.compliancesList[spliceIndex].addressLine2 = ''
      this.compliancesList[spliceIndex].suburb = ''
      this.compliancesList[spliceIndex].state = ''
      this.compliancesList[spliceIndex].fullAddress = ''
    },
    AddressAutocompleteOnNoSelectionMade() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      // this.compliancesList[spliceIndex].fullAddress = ''
      this.compliancesList[spliceIndex].addressLine1 = ''
      this.compliancesList[spliceIndex].addressLine2 = ''
      this.compliancesList[spliceIndex].suburb = ''
      this.compliancesList[spliceIndex].state = ''
      this.compliancesList[spliceIndex].country = ''
      this.compliancesList[spliceIndex].longitude = ''
      this.compliancesList[spliceIndex].latitude = ''
      this.compliancesList[spliceIndex].meshblock = ''
      this.compliancesList[spliceIndex].gnaf = ''
      this.compliancesList[spliceIndex].dpid = ''
      this.compliancesList[spliceIndex].parcelId = ''
    },
    datachange(val) {
      return dayjs(val).format('DD/MM/YYYY')
    },
    pickerOptions(row) {
      const compliancesList = this.compliancesList
      const openIndex = this.openIndex
      return {
        disabledDate(time) {
          let disble = time.getTime() > Date.now() - 8.64e6
          compliancesList.forEach((v, k) => {
            if (v.fromDate && v.toDate && openIndex !== v.index) {
              disble = disble || (time.getTime() > new Date(v['fromDate']).getTime() && time.getTime() < new Date(v['toDate']))
            }
          })
          return disble
        }
      }
    }
  }

}
</script>

<template>
  <div class="AddressForm mb-20">
    <div v-for="item in compliancesList" :key="item.index">
      <div
        v-if="item.index != openIndex"
        class="history-box"
        @click="open(item.index)"
      >
        <p v-if="item.isManual">
          {{ item.addressLine1 }}, {{ item.addressLine2 }}, {{ item.suburb }}, {{ item.country }}
          ({{ datachange(item.fromDate) }} - {{ datachange(item.toDate) }})
        </p>
        <p v-else>
          {{ item.fullAddress }}
          ({{ datachange(item.fromDate) }} - {{ datachange(item.toDate) }})
        </p>
      </div>
      <div v-if="item.index == openIndex">
        <el-form
          :ref="`addForm${item.index}`"
          :model="item"
          :rules="rules"
          label-width="80px"
          label-position="top"
          :disabled="allDisabled"
        >

          <el-form-item v-if="!item.isManual" label="Full Address" prop="fullAddress">
            <AddressAutocomplete
              :initialaddress.sync="item.fullAddress"
              :placeholder="'Start typing and pause to select the address'"
              :use-google="true"
              from="OtherCountries"
              @OnDetailReady="AddressAutocompleteOnDetailReady"
              @OnManualEntry="AddressAutocompleteOnManualEntry"
              @OnNoSelectionMade="AddressAutocompleteOnNoSelectionMade"
            />
          </el-form-item>

          <div v-if="item.isManual">
            <el-row>
              <el-col :xs="24" :sm="24" :lg="24" :xl="24">
                <el-form-item prop="selectedCountryCode" label="Country">
                  <GetAllGeoCountries
                    :disabled="allDisabled"
                    :defalut-value.sync="item.selectedCountryCode"
                    @getCountryData="onCountryChage(item.index,$event)"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :xs="24" :sm="24" :lg="24" :xl="24">
                <el-form-item prop="addressLine1" label="Address Line 1">
                  <el-input v-model="item.addressLine1" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :xs="24" :sm="24" :lg="24" :xl="24">
                <el-form-item prop="addressLine2" label="Address Line 2">
                  <el-input v-model="item.addressLine2" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                <el-form-item prop="suburb" label="Suburb">
                  <el-input v-model="item.suburb" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-row :gutter="20">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item label="From Date" prop="fromDate">
                <el-date-picker
                  v-model="item.fromDate"
                  type="date"
                  :picker-options="pickerOptions()"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>

            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item label="To Date" prop="toDate">
                <el-date-picker
                  v-model="item.toDate"
                  type="date"
                  :picker-options="pickerOptions()"
                  placeholder="DD/MM/YYYY"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item v-if="!allDisabled">
            <div class="action-box">
              <div
                class="action-item"
                @click="handleAdd(`addForm${item.index}`, 1, item)"
              >
                <i class="el-icon-circle-plus" />
                <span class="action-value">Add another Address</span>
              </div>
              <div class="action-item" @click="handleRemove(item.index, item)">
                <i class="el-icon-delete-solid" />
                <span class="action-value">Remove Address</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="outAddBtnShow && !allDisabled" class="action-box" @click="handleAdd('', 2)">
      <div class="action-item">
        <i class="el-icon-circle-plus" />
        <span class="action-value f-s-14">Add another Address</span>
      </div>
    </div>
    <!-- <el-button type="primary" @click="handleSure('SaveFormTempData')">Save form data</el-button> -->
    <el-button
      v-if="continueBtn" class="mt-30" :loading="endAllRequests" type="primary"
      @click="handleSure('Continue')"
    >Save and Continue</el-button>
  </div>
</template>

<style lang="scss" scoped>
.action-box {
  display: flex;
  justify-content: space-between;
  color: #206caa;
  .action-item {
    cursor: pointer;
  }
  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }
  .action-value {
    margin-left: 10px;
  }
}
</style>
