<script>
import { mapState } from 'vuex'
// import Welcome from '@/views/ReadOnly/Welcome'
// import ReopenNotes from '@/views/ReadOnly/ReopenNotes'
import AuthorisedRepresentative from '@/views/ReadOnly/AuthorisedRepresentative'
import AddressHistory from '@/views/ReadOnly/AddressHistory'
import OtherCountries from '@/views/ReadOnly/OtherCountries'
import CoIDeclaration from '@/views/ReadOnly/CoIDeclaration'
import CoIDeclarationNAB from '@/views/ReadOnly/CoIDeclarationNAB'
import EYQuestionG1_3 from '@/views/ReadOnly/EYQuestionG1_3'
import EYQuestionG4 from '@/views/ReadOnly/EYQuestionG4'
import EYQuestion from '@/views/ReadOnly/EYQuestion'
import CVUpload from '@/views/ReadOnly/CVUpload'
import ComplianceReference from '@/views/ReadOnly/ComplianceReference'
import ElectronicLodgement from '@/views/ReadOnly/ElectronicLodgement'
import EmploymentHistory from '@/views/ReadOnly/EmploymentHistory'
import EmploymentReference from '@/views/ReadOnly/EmploymentReference'
import EntitlementToWork from '@/views/ReadOnly/EntitlementToWork'
import EntitlementToWork2 from '@/views/ReadOnly/EntitlementToWork2'
import Identity100Points from '@/views/ReadOnly/Identity100Points'
import ManagementDirector from '@/views/ReadOnly/ManagementDirector'
import MedicalDeclaration from '@/views/ReadOnly/MedicalDeclaration'
// import NotImplemented from '@/views/ReadOnly/NotImplemented'
import PersonalDetails from '@/views/ReadOnly/PersonalDetails'
import PersonalDetailsInternational from '@/views/ReadOnly/PersonalDetailsInternational'
import ProfessionalMemberships from '@/views/ReadOnly/ProfessionalMemberships'
import ProofOfIdentity from '@/views/ReadOnly/ProofOfIdentity'
import QualificationsNAB from '@/views/ReadOnly/QualificationsNAB'
import QualificationsTertiary from '@/views/ReadOnly/QualificationsTertiary'
import QualificationsTrade from '@/views/ReadOnly/QualificationsTrade'
import RegisteredTaxAgent from '@/views/ReadOnly/RegisteredTaxAgent'
import VulnerablePeople from '@/views/ReadOnly/VulnerablePeople'
// import Confirmation from '@/views/ReadOnly/Confirmation'
// import AdobeSign from '@/views/ReadOnly/AdobeSign'
// import ThankYou from '@/views/ReadOnly/ThankYou'
import Covid19Vaccination from '@/views/ReadOnly/Covid19Vaccination'
import RequiredDeclarationsNAB from '@/views/ReadOnly/RequiredDeclarationNAB'
import { preparationApi, continueApi } from '@/api/Confirmation'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'ConfirmationView',
  components: {
    // Welcome,
    // ReopenNotes,
    AuthorisedRepresentative,
    AddressHistory,
    OtherCountries,
    CoIDeclaration,
    CoIDeclarationNAB,
    EYQuestionG1_3,
    EYQuestionG4,
    EYQuestion,
    CVUpload,
    ComplianceReference,
    ElectronicLodgement,
    EmploymentHistory,
    EmploymentReference,
    EntitlementToWork,
    EntitlementToWork2,
    Identity100Points,
    ManagementDirector,
    MedicalDeclaration,
    // NotImplemented,
    PersonalDetails,
    PersonalDetailsInternational,
    ProfessionalMemberships,
    ProofOfIdentity,
    QualificationsNAB,
    QualificationsTertiary,
    QualificationsTrade,
    RegisteredTaxAgent,
    RequiredDeclarationsNAB,
    VulnerablePeople,
    // Confirmation,
    // AdobeSign,
    // ThankYou,
    Covid19Vaccination
  },
  inject: ['ContinueSetSession'],
  data() {
    return {
      preparationData: [],
      activeName: '',
      dialogVisible: false,
      isSubmit: true,
      ruleForm: {
        electronicLodgementAgreed: ''
      },
      rules: {
        electronicLodgementAgreed: [{ required: false, message: '', trigger: 'change' }]
      },
      minimumQuantity: null,
      allLoading: false,
      isFormComplete: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device,
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    orderApplicantId() {
      return this.preparation.session.current.orderApplicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    SectionId() {
      return this.preparation.session.current.formSection.sectionId
    },
    Intro() {
      return this.preparation.intro
    },
    UsePersonalDetailsInternational() {
      return this.Session.usePersonalDetailsInternational
    },
    UseEntitlementToWork2() {
      return this.Session.useEntitlementToWork2
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100
      )
    },
    ShowContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100
      console.log('this.Session.current.isSubmitted ===>', this.Session.current.isSubmitted)
      console.log('this.isFormComplete ===>', this.isFormComplete)
      return (!this.Session.current.isSubmitted && this.isFormComplete)
    },
    ShowLodgement() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100
      console.log('this.Session.current.isSubmitted ===>', this.Session.current.isSubmitted)
      console.log('this.isFormComplete ===>', this.isFormComplete)
      return (this.Session.current.isSubmitted || !this.Session.current.isSubmitted && this.isFormComplete)
    },
    buttonText() {
      // "Confirm and " + If(IsSubmit, "Submit Application", "Continue")
      return 'Confirm and ' + (this.isSubmit ? 'Submit Application' : 'Continue')
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'Confirmation')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        applicant: this.Applicant,
        intro: this.Intro
      }
      await preparationApi(params).then(async(res) => {
        const { data } = res
        this.isSubmit = data.isSubmit
        this.ruleForm.electronicLodgementAgreed = data.electronicLodgementAgreed
        this.isFormComplete = data.isFormComplete

        let v = {}
        for (let i = 0; i < data.list.length; i++) {
          v = data.list[i]
          if (v.blockName === 'PersonalDetails' && this.UsePersonalDetailsInternational === true) {
            v.blockName = 'PersonalDetailsInternational'
          } else if (v.blockName === 'EntitlementToWork' && this.UseEntitlementToWork2 === true) {
            v.blockName = 'EntitlementToWork2'
          } else if (v.blockName === 'ComplianceReference') {
            this.minimumQuantity = v.minimumQuantity
          }
          this.preparationData.push(v)
        }

        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async Continue() {
      const applicant = this.Applicant
      applicant.isElectronicLodgementAgreed = true
      const params = {
        session: this.Session,
        applicant: applicant,
        isSubmit: this.isSubmit,
        intro: this.Intro,
        electronicLodgementAgreed: this.ruleForm.electronicLodgementAgreed
      }
      await continueApi(params).then((res) => {
        this.dialogVisible = false
        this.ContinueSetSession(res.data.session)
      }).catch(() => {
        this.dialogVisible = false
      })
    },

    isShow(name) {
      const index = this.preparationData.findIndex(
        (item) => item.blockName === name
      )
      return index !== -1
      // return true
    },
    isComplete(name) {
      const item = this.preparationData.find((item) => item.blockName === name)
      return item.completePercent === 100
      // return true
    },
    openDialog() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.dialogVisible = true
        }
      })
    },

    getTitle(name) {
      let blockName = name
      if (blockName === 'PersonalDetailsInternational' && this.UsePersonalDetailsInternational === true) {
        blockName = 'PersonalDetails'
      } else if (blockName === 'EntitlementToWork2' && this.UseEntitlementToWork2 === true) {
        blockName = 'EntitlementToWork'
      }
      const data = this.Session.formSections.find((item) => item.blockName === blockName)
      return data.title
    },

    getPath(title) {
      let children = title
      if (children === 'PersonalDetailsInternational' && this.UsePersonalDetailsInternational === true) {
        children = 'PersonalDetails'
      } else if (children === 'EntitlementToWork2' && this.UseEntitlementToWork2 === true) {
        children = 'EntitlementToWork'
      }
      const nav = this.preparation.session.formSections
      const data = this.preparation
      const section = nav.find((item) => item.blockName === children)
      data.session.current.formSection = section
      this.$store.dispatch('preparation/setPreparation', data)
      this.$store.dispatch('section/set_section', { section: children })
    },

    Cancel() {
      this.dialogVisible = false
      this.ruleForm.electronicLodgementAgreed = ''
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="ConfirmationView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <div class="mb-20 Confirmation">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item
          v-for="item in preparationData"
          :key="item.index"
          :name="item.blockName"
        >
          <template slot="title">
            <i v-if="isComplete(item.blockName)" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle(item.blockName) }}</span>
          </template>
          <component
            :is="item.blockName"
            v-if="isComplete(item.blockName)"
            :applicant="Applicant"
            :applicant-id="ApplicantId"
            :order-applicant-id="orderApplicantId"
            :section-id="item.id"
            :minimum-quantity="minimumQuantity"
            from="applicant"
          />
          <div v-else class="cursor-pointer color-blue" @click="getPath(item.blockName)">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <!-- <div class="mb-20">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item
          v-if="isShow('AuthorisedRepresentative')"
          name="AuthorisedRepresentative"
        >
          <template slot="title">
            <i v-if="isComplete('AuthorisedRepresentative')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('AuthorisedRepresentative') }}</span>
          </template>
          <AuthorisedRepresentative
            v-if="isComplete('AuthorisedRepresentative')"
            :applicant="Applicant"
          />
          <div v-else class="cursor-pointer color-blue" @click="getPath('AuthorisedRepresentative')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('AddressHistory')"
          name="AddressHistory"
        >
          <template slot="title">
            <i v-if="isComplete('AddressHistory')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('AddressHistory') }}</span>
          </template>
          <AddressHistory v-if="isComplete('AddressHistory')" :applicant-id="ApplicantId" :applicant="Applicant" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('AddressHistory')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('OtherCountries')"
          name="OtherCountries"
        >
          <template slot="title">
            <i v-if="isComplete('OtherCountries')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('OtherCountries') }}</span>
          </template>
          <OtherCountries v-if="isComplete('OtherCountries')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('OtherCountries')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('CoIDeclaration')"
          name="CoIDeclaration"
        >
          <template slot="title">
            <i v-if="isComplete('CoIDeclaration')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('CoIDeclaration') }}</span>
          </template>
          <CoIDeclaration v-if="isComplete('CoIDeclaration')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('CoIDeclaration')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('CoIDeclarationNAB')"
          name="CoIDeclarationNAB"
        >
          <template slot="title">
            <i v-if="isComplete('CoIDeclarationNAB')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('CoIDeclarationNAB') }}</span>
          </template>
          <CoIDeclarationNAB v-if="isComplete('CoIDeclarationNAB')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('CoIDeclarationNAB')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('EYQuestionG1_3')"
          name="EYQuestionG1_3"
        >
          <template slot="title">
            <i v-if="isComplete('EYQuestionG1_3')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('EYQuestionG1_3') }}</span>
          </template>
          <EYQuestionG1_3 v-if="isComplete('EYQuestionG1_3')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('EYQuestionG1_3')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('EYQuestionG4')"
          name="EYQuestionG4"
        >
          <template slot="title">
            <i v-if="isComplete('EYQuestionG4')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('EYQuestionG4') }}</span>
          </template>
          <EYQuestionG4 v-if="isComplete('EYQuestionG4')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('EYQuestionG4')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item v-if="isShow('CVUpload')" name="CVUpload">
          <template slot="title">
            <i v-if="isComplete('CVUpload')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('CVUpload') }}</span>
          </template>
          <CVUpload v-if="isComplete('CVUpload')" :order-applicant-id="orderApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('CVUpload')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('ComplianceReference')"
          name="ComplianceReference"
        >
          <template slot="title">
            <i v-if="isComplete('ComplianceReference')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('ComplianceReference') }}</span>
          </template>
          <ComplianceReference v-if="isComplete('ComplianceReference')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('ComplianceReference')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('ElectronicLodgement')"
          name="ElectronicLodgement"
        >
          <template slot="title">
            <i v-if="isComplete('ElectronicLodgement')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('ElectronicLodgement') }}</span>
          </template>
          <ElectronicLodgement v-if="isComplete('ElectronicLodgement')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('ElectronicLodgement')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('EmploymentHistory')"
          name="EmploymentHistory"
        >
          <template slot="title">
            <i v-if="isComplete('EmploymentHistory')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('EmploymentHistory') }}</span>
          </template>
          <EmploymentHistory v-if="isComplete('EmploymentHistory')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('EmploymentHistory')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('EmploymentReference')"
          name="EmploymentReference"
        >
          <template slot="title">
            <i v-if="isComplete('EmploymentReference')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('EmploymentReference') }}</span>
          </template>
          <EmploymentReference v-if="isComplete('EmploymentReference')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('EmploymentReference')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('EntitlementToWork')"
          name="EntitlementToWork"
        >
          <template slot="title">
            <i v-if="isComplete('EntitlementToWork')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('EntitlementToWork') }}</span>
          </template>
          <EntitlementToWork
            v-if="isComplete('EntitlementToWork')"
            :applicant-id="ApplicantId"
            :section-id="SectionId"
            :applicant="Applicant"
          />
          <div v-else class="cursor-pointer color-blue" @click="getPath('EntitlementToWork')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('EntitlementToWork2')"
          name="EntitlementToWork2"
        >
          <template slot="title">
            <i v-if="isComplete('EntitlementToWork2')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('EntitlementToWork2') }}</span>
          </template>
          <EntitlementToWork2
            v-if="isComplete('EntitlementToWork2')"
            :applicant-id="ApplicantId"
            :section-id="SectionId"
            :applicant="Applicant"
          />
          <div v-else class="cursor-pointer color-blue" @click="getPath('EntitlementToWork2')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('Identity100Points')"
          name="Identity100Points"
        >
          <template slot="title">
            <i v-if="isComplete('Identity100Points')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('Identity100Points') }}</span>
          </template>
          <Identity100Points v-if="isComplete('Identity100Points')" :order-applicant-id="orderApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('Identity100Points')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('ManagementDirector')"
          name="ManagementDirector"
        >
          <template slot="title">
            <i v-if="isComplete('ManagementDirector')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('ManagementDirector') }}</span>
          </template>
          <ManagementDirector v-if="isComplete('ManagementDirector')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('ManagementDirector')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('MedicalDeclaration')"
          name="MedicalDeclaration"
        >
          <template slot="title">
            <i v-if="isComplete('MedicalDeclaration')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('MedicalDeclaration') }}</span>
          </template>
          <MedicalDeclaration v-if="isComplete('MedicalDeclaration')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('MedicalDeclaration')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('PersonalDetails')"
          name="PersonalDetails"
        >
          <template slot="title">
            <i v-if="isComplete('PersonalDetails')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('PersonalDetails') }}</span>
          </template>
          <PersonalDetails
            v-if="isComplete('PersonalDetails')"
            :applicant-id="ApplicantId"
            :applicant="Applicant"
          />
          <div v-else class="cursor-pointer color-blue" @click="getPath('PersonalDetails')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('PersonalDetailsInternational')"
          name="PersonalDetailsInternational"
        >
          <template slot="title">
            <i v-if="isComplete('PersonalDetailsInternational')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('PersonalDetailsInternational') }}</span>
          </template>
          <PersonalDetailsInternational
            v-if="isComplete('PersonalDetailsInternational')"
            :applicant-id="ApplicantId"
            :applicant="Applicant"
          />
          <div v-else class="cursor-pointer color-blue" @click="getPath('PersonalDetailsInternational')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('ProfessionalMemberships')"
          name="ProfessionalMemberships"
        >
          <template slot="title">
            <i v-if="isComplete('ProfessionalMemberships')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('ProfessionalMemberships') }}</span>
          </template>
          <ProfessionalMemberships
            v-if="isComplete('ProfessionalMemberships')"
            :applicant-id="ApplicantId"
          />
          <div v-else class="cursor-pointer color-blue" @click="getPath('ProfessionalMemberships')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('ProofOfIdentity')"
          name="ProofOfIdentity"
        >
          <template slot="title">
            <i v-if="isComplete('ProofOfIdentity')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('ProofOfIdentity') }}</span>
          </template>
          <ProofOfIdentity v-if="isComplete('ProofOfIdentity')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('ProofOfIdentity')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('QualificationsNAB')"
          name="QualificationsNAB"
        >
          <template slot="title">
            <i v-if="isComplete('QualificationsNAB')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('QualificationsNAB') }}</span>
          </template>
          <QualificationsNAB v-if="isComplete('QualificationsNAB')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('QualificationsNAB')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('QualificationsTertiary')"
          name="QualificationsTertiary"
        >
          <template slot="title">
            <i v-if="isComplete('QualificationsTertiary')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('QualificationsTertiary') }}</span>
          </template>
          <QualificationsTertiary v-if="isComplete('QualificationsTertiary')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('QualificationsTertiary')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('QualificationsTrade')"
          name="QualificationsTrade"
        >
          <template slot="title">
            <i v-if="isComplete('QualificationsTrade')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('QualificationsTrade') }}</span>
          </template>
          <QualificationsTrade v-if="isComplete('QualificationsTrade')" :applicant-id="ApplicantId" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('QualificationsTrade')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('RegisteredTaxAgent')"
          name="RegisteredTaxAgent"
        >
          <template slot="title">
            <i v-if="isComplete('RegisteredTaxAgent')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('RegisteredTaxAgent') }}</span>
          </template>
          <RegisteredTaxAgent v-if="isComplete('RegisteredTaxAgent')" :applicant="Applicant" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('RegisteredTaxAgent')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('VulnerablePeople')"
          name="VulnerablePeople"
        >
          <template slot="title">
            <i v-if="isComplete('VulnerablePeople')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('VulnerablePeople') }}</span>
          </template>
          <VulnerablePeople v-if="isComplete('VulnerablePeople')" :applicant="Applicant" />
          <div v-else class="cursor-pointer color-blue" @click="getPath('VulnerablePeople')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>

        <el-collapse-item
          v-if="isShow('Covid19Vaccination')"
          name="Covid19Vaccination"
        >
          <template slot="title">
            <i v-if="isComplete('Covid19Vaccination')" class="fa fa-check-circle completePercent100" />
            <i v-else class="fa fa-circle-o completePercent0" />
            <span class="ml-10">{{ getTitle('Covid19Vaccination') }}</span>
          </template>

          <Covid19Vaccination
            v-if="isComplete('Covid19Vaccination')"
            :applicant-id="ApplicantId"
            :order-applicant-id="orderApplicantId"
          />
          <div v-else class="cursor-pointer color-blue" @click="getPath('Covid19Vaccination')">
            This section is not yet complete. Please complete it before
            continuing.
          </div>
        </el-collapse-item>
      </el-collapse>
    </div> -->

    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="electronic-ruleForm"
      label-position="left"
      label-width="70%"
      hide-required-asterisk
      :disabled="allDisabled"
    >
      <el-form-item
        v-if="ShowLodgement"
        prop="electronicLodgementAgreed"
      >
        <span slot="label">I agree to the electronic lodgement of my personal information</span>
        <el-radio-group v-model="ruleForm.electronicLodgementAgreed">
          <el-radio label="Yes" />
          <el-radio label="No" />
        </el-radio-group>
      </el-form-item>
      <div v-else class="lodgement-confirmation">Please check above and finish all the sections before continuing.</div>
    </el-form>

    <el-button v-if="ShowContinueBtn" class="mt-30" type="primary" @click="openDialog">{{ buttonText }}</el-button>

    <el-dialog
      title="Confirm and Continue"
      :visible.sync="dialogVisible"
      :width="device === 'mobile'?'90%':'40%'"
    >
      <p>You will be unable to modify this information. Are you sure you want to CONFIRM and CONTINUE?</p>
      <el-divider v-if="device === 'mobile'" />
      <span slot="footer" class="dialog-footer">
        <el-button :class="device === 'mobile'?'width100':''" @click="Cancel">Cancel</el-button>
        <el-button :class="device === 'mobile'?'width100':''" type="primary" @click="Continue">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="scss">
.ConfirmationView {
  .el-divider--horizontal{
    margin: 20px 0 8px;
  }
  .width100{
  width:100%;
  display: block;
  margin:0 0 10px 0 !important;
}
  .el-form-item__content{
        line-height: 14px !important;
  }
  .completePercent100{
  color: #37b24d;
  font-size: 28px;
  }
  .completePercent0{
    color: #206caa;
  font-size: 28px;
  }
  .el-collapse-item__header{
    font-size: 16px;
    padding: 12px 0;
    font-weight: 400;
  }
  .color-blue{
    color:#206caa
  }
    .Confirmation{
      .el-form-item__label{
      margin-top: 20px;
    }
    .el-form-item{
      margin-bottom: 0 !important;
    }
    }
    .electronic-ruleForm{
      .el-form-item__label{
        line-height: 1.5;
        font-size: 14px;
    }
    }
  .lodgement-confirmation{
    background-color: #c44f47;
    margin-top: 10px;
    border-radius: 4px;
    border: 1px;
    padding: 20px;
    font-size: 15px;
    color: #FFFFFF;
  }
}
</style>
