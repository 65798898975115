<script>
import { mapState } from 'vuex'
import { covid19VaccinationApi } from '@/api/pattern'
import ImageShow from '../components/ImageShow/index.vue'
export default {
  name: 'Covid19Vaccination',
  components: { ImageShow },
  props: {
    applicantId: {
      type: Number,
      default: null
    },
    orderApplicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      FilePondUploadConfig: {},
      NewCloudinaryResource: {},
      CurrentFormSection: {},
      HasCovid19VaccinationValid: false,
      Vaccination: {},
      Covid19Documents: [],
      ruleForm: {
        HasCovid19Vaccination: 'Yes'
      },
      moduleLoading: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      await covid19VaccinationApi({ applicantId: this.applicantId, orderApplicantId: this.orderApplicantId }).then(res => {
        this.ruleForm.HasCovid19Vaccination = res.data.hasCovid19Vaccination
        this.Covid19Documents = res.data.covid19Documents
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="MedicalDeclarationView">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item>
        <span slot="label">
          Have you been fully vaccinated (received two doses of the COVID-19
          vaccination)?
        </span>
        <el-radio-group v-model="ruleForm.HasCovid19Vaccination" disabled>
          <el-radio label="Yes" />
          <el-radio label="No" />
        </el-radio-group>
      </el-form-item>
      <div v-if="Covid19Documents.length" class="Covid19Vaccination-box">
        <ImageShow :img-list="Covid19Documents" />
      </div>
    </el-form>
  </div>
</template>

<style lang="scss">
.MedicalDeclarationView {
  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
