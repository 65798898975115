var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "MedicalDeclarationView",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(
                  " 1. Do you have any close personal or financial relationships with another employee of NAB? "
                ),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.colHasRelWithNabEmployee,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "colHasRelWithNabEmployee", $$v)
                    },
                    expression: "ruleForm.colHasRelWithNabEmployee",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.colHasRelWithNabEmployee
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "If yes, provide the name of the NAB employee(s) and the nature of each relationship.",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.colHasRelWithNabEmployeeDel,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "colHasRelWithNabEmployeeDel",
                          $$v
                        )
                      },
                      expression: "ruleForm.colHasRelWithNabEmployeeDel",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "hasRelWithOfficial" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(
                  " 2. Do you have a close personal or financial relationship with any of the below persons? "
                ),
                _c("br"),
                _vm._v(
                  "• An executive member of any public department of any Government, State or Territories in Australia or internationally. "
                ),
                _c("br"),
                _vm._v(
                  "• An elected member of any government, state or local municipality in Australia or internationally. "
                ),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.colHasRelWithOfficial,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "colHasRelWithOfficial", $$v)
                    },
                    expression: "ruleForm.colHasRelWithOfficial",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.colHasRelWithOfficial
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "If yes, provide details including the name of the NAB employee(s) and the nature of your relationship.",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.colHasRelWithOfficialDetail,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "colHasRelWithOfficialDetail",
                          $$v
                        )
                      },
                      expression: "ruleForm.colHasRelWithOfficialDetail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(
                  " 3. In relation to any business or other organisation in Australia and internationally, do you: "
                ),
                _c("br"),
                _vm._v(
                  "• hold an official directorship/officeholder position "
                ),
                _c("br"),
                _vm._v("• have any financial interest "),
                _c("br"),
                _vm._v(
                  "• have influence, including over the board or any committee or associated group thereof "
                ),
                _c("br"),
                _vm._v("• provide advisory or other services "),
                _c("br"),
                _vm._v(
                  "Includes competitors of and suppliers to NAB as well as other interests external to NAB. "
                ),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.colHasBizOrgInAu,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "colHasBizOrgInAu", $$v)
                    },
                    expression: "ruleForm.colHasBizOrgInAu",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.colHasBizOrgInAu
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "If yes, provide details including the name of the NAB employee(s) and the nature of your relationship.",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.colHasBizOrgInAuDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "colHasBizOrgInAuDetail", $$v)
                      },
                      expression: "ruleForm.colHasBizOrgInAuDetail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(
                  " 4. Are you the person of interest in an ongoing criminal matter before the Courts or being investigated by a state or federal government body? "
                ),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.colBeingInvestigated,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "colBeingInvestigated", $$v)
                    },
                    expression: "ruleForm.colBeingInvestigated",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.colBeingInvestigated
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "If yes, provide details of the offence, your involvement/interest in the criminal investigation.",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.colBeingInvestigatedDetail,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "colBeingInvestigatedDetail",
                          $$v
                        )
                      },
                      expression: "ruleForm.colBeingInvestigatedDetail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }