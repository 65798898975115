// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/imgs/Logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header[data-v-45285d06] {\n  height: 96px;\n  width: 100%;\n  background-color: #206caa;\n  padding: 10px 0;\n}\n.header .logo-box[data-v-45285d06] {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n.header .logo-box .logo[data-v-45285d06] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 96px;\n  width: 177px;\n  margin: 0 0 0 10px;\n}\n.header .logo-box .icon[data-v-45285d06] {\n  color: #fff;\n  margin: 0 30px 0 30px;\n  font-size: 28px;\n}\n.header .Logout[data-v-45285d06] {\n  position: relative;\n}\n.header .Logout p[data-v-45285d06] {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  color: #fff;\n  margin: 0;\n  text-decoration: none;\n  cursor: pointer;\n}\n.mobileHeader .header-main[data-v-45285d06] {\n  padding: 10px 20px;\n}\n.mobileHeader .header-main .nav-logo[data-v-45285d06] {\n  display: flex;\n  align-items: center;\n}\n.mobileHeader .header-main .nav-logo .el-icon-s-unfold[data-v-45285d06] {\n  margin: 0 16px 0 0;\n  color: #fff;\n  font-size: 20px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
