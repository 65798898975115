<script>
import { eyQuestionG4Api } from '@/api/pattern'
export default {
  name: 'EYQuestionG4View',
  props: {
    applicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      ruleForm: {
        q0: '',
        q1: '',
        q2: '',
        q3: '',
        q4: '',
        q5: '',
        q5_Details: '',
        q5_Q1: '',
        q5_Q1_Details: '',
        q6: '',
        q6_Details: '',
        q7_A: '',
        q7_A_Details: '',
        q7_B: '',
        q7_B_Details: '',
        q7_C: '',
        q7_C_Details: '',
        q7_D: '',
        q7_D_Details: '',
        q7_E: '',
        q7_E_Details: '',
        q8_A: '',
        q8_A_FamilyMemberType: '',
        q8_A_JobTitle: '',
        q8_A_PeriodStart: null,
        q8_A_PeriodConclude: null,
        q8_B: '',
        q8_B_PeriodStart: null,
        q8_B_PeriodConclude: null,
        q8_C: '',
        q8_C_PeriodStart: null,
        q8_C_PeriodConclude: null,
        q8_D: '',
        q8_D_PeriodStart: null,
        q8_D_PeriodConclude: null,
        q8_E: '',
        q8_E_PeriodStart: null,
        q8_E_PeriodConclude: null
      },
      listOf9: [
        {
          key: 'q7_A',
          detail: 'q7_A_Details',
          label:
            'a) you continue to be a partner, principal, shareholder or professional employee of Ernst & Young after your engagement by NAB begins?'
        },
        {
          key: 'q7_B',
          detail: 'q7_B_Details',
          label:
            'b) you have actual ongoing influence over or participation in Ernst & Young’s business?'
        },
        {
          key: 'q7_C',
          detail: 'q7_C_Details',
          label:
            'c) there be a perception that you have ongoing influence over or participation in Ernst & Young’s business, even if in fact you have no such influence?'
        },
        {
          key: 'q7_D',
          detail: 'q7_D_Details',
          label: 'd) you have capital balances in Ernst & Young?'
        },
        {
          key: 'q7_E',
          detail: 'q7_E_Details',
          label:
            'e) you have an on-going financial arrangement with Ernst & Young?'
        }
      ],
      listOf10: [
        {
          key: 'q8_B',
          periodStart: 'q8_B_PeriodStart',
          periodConclude: 'q8_B_PeriodStart',
          label:
            'is (or was) the relevant Close Family Member on the Ernst & Young NAB audit engagement team?'
        },
        {
          key: 'q8_C',
          periodStart: 'q8_C_PeriodStart',
          periodConclude: 'q8_C_PeriodConclude',
          label:
            'is (or was) the relevant Close Family Member a person who supervises or has direct management responsibility for the NAB audit, or who evaluates the performance or recommends the compensation of the audit engagement partner, or who provides quality control or other oversight of NAB’s audit?'
        },
        {
          key: 'q8_D',
          periodStart: 'q8_D_PeriodStart',
          periodConclude: 'q8_D_PeriodConclude',
          label:
            'is (or was) the relevant Close Family Member a partner, principal shareholder or managerial employee of Ernst & Young who  provided (or expects to provide) 10 or more hours of non-audit services to NAB during a financial year?'
        }
      ],
      jobs: [],
      activeNames: '1',
      moduleLoading: false
    }
  },
  computed: {
    PartTwoShow() {
      return (
        this.ruleForm.q0 === 'Yes' ||
        this.ruleForm.q1 === 'Yes' ||
        this.ruleForm.q2 === 'Yes'
      )
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      await eyQuestionG4Api({ applicantId: this.applicantId }).then(res => {
        // console.log('eyQuestionG4Api===', res)
        this.ruleForm = res.data.question
        this.jobs = res.data.jobs
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="EYQuestionG4View">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
    >
      <el-collapse v-model="activeNames">
        <el-collapse-item title="Part 1" name="1">
          <el-form-item
            prop="q0"
            label="1. Are you applying for an Accounting or Financial Reporting Oversight Role, a secretary role on any NAB Group company board, or a role on the Executive Committee AND have you worked for EY (NAB external auditor) at any time since 1 October 2004?"
          >
            <el-radio-group v-model="ruleForm.q0" disabled>
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="q1"
            label="2. If applying for other NAB Group roles, have you worked for EY (NAB's external auditor) at any time in the past three years?"
          >
            <el-radio-group v-model="ruleForm.q1" disabled>
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="q2"
            label="3. In past three years, has your spouse, spousal equivalent, parent, child (dependent or otherwise) or sibling been a partner, principal, shareholder or professional employee of Ernst & Young (NAB's external auditor) AND have they worked on the NAB Audit?"
          >
            <el-radio-group v-model="ruleForm.q2" disabled>
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item title="Part 2" name="2">
          <div v-if="PartTwoShow">
            <el-form-item
              prop="q3"
              label="4. Have you been a professional employee, partner, principal, or shareholder of Ernst & Young at any time since 1 October 2004?"
            >
              <el-radio-group v-model="ruleForm.q3" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <template v-if="ruleForm.q3 === 'Yes'">
              <p class="label mb-10">
                If yes, what was your job title and during what time period(s)?
                (If you had more than one role at Ernst & Young, please list
                each job title and period in that role separately.
              </p>
              <template v-if="jobs.length">
                <div v-for="(el, _index) in jobs" :key="_index" class="box">
                  <p>
                    {{ el.jobTitle }} ({{ el.dateCommenced | parseDefaultTime('{g} {y}') }} - {{ el.dateEnded | parseDefaultTime('{g} {y}') }})
                  </p>
                </div>
              </template>
            </template>

            <el-form-item
              label="5. Please provide a brief description of your role and responsibilities at Ernst & Young:"
              prop="q4"
            >
              <el-input v-model="ruleForm.q4" type="textarea" disabled />
            </el-form-item>

            <el-form-item
              label="6. During your time at Ernst & Young, were you ever a member of the NAB Group Audit Engagement Team?"
              prop="q5"
            >
              <el-radio-group v-model="ruleForm.q5" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <el-form-item
              v-if="ruleForm.q5 === 'Yes'"
              label="If yes, please list the financial years you were a member of the NAB Group Audit Engagement Team."
              prop="q5_Details"
            >
              <el-input
                v-model="ruleForm.q5_Details"
                type="textarea"
                disabled
              />
            </el-form-item>

            <el-form-item
              label="7. During your time at Ernst & Young, were you ever involved in providing non-audit services to the NAB Group?"
              prop="q5_Q1"
            >
              <el-radio-group v-model="ruleForm.q5_Q1" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <el-form-item
              v-if="ruleForm.q5_Q1 === 'Yes'"
              label="If yes, please list what services you provided and during which financial year/s"
              prop="q5_Q1_Details"
            >
              <el-input
                v-model="ruleForm.q5_Q1_Details"
                type="textarea"
                disabled
              />
            </el-form-item>

            <el-form-item prop="q6">
              <span slot="label">8. During your time at Ernst & Young did you serve in a
                professional capacity in a Prudential Audit
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    “Prudential Audit” means an audit in relation to <br>
                    the Banking Act 1959 (Cth) or in relation to the <br>
                    Prudential Standards or Reporting Standards <br>
                    within the meaning of Prudential Standard CPS <br>
                    510 of the Australian Prudential Regulation Authority)
                  </div>
                  <i class="el-icon-info" />
                </el-tooltip>
                of NAB?
              </span>
              <el-radio-group v-model="ruleForm.q6" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <el-form-item
              v-if="ruleForm.q6 === 'Yes'"
              label="If yes, please list the financial years you served in a professional capacity in a Prudential Audit of NAB."
              prop="q6_Details"
            >
              <el-input
                v-model="ruleForm.q6_Details"
                type="textarea"
                disabled
              />
            </el-form-item>

            <p class="label">9. If you accept this position with NAB, will:</p>
            <div v-for="item in listOf9" :key="item.key">
              <el-form-item :label="item.label" :prop="item.key">
                <el-radio-group v-model="ruleForm[item.key]" disabled>
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </el-form-item>

              <el-form-item
                v-if="ruleForm[item.key] === 'Yes'"
                label="If yes, please provide details"
                :prop="item.detail"
              >
                <el-input
                  v-model="ruleForm[item.detail]"
                  type="textarea"
                  disabled
                />
              </el-form-item>
            </div>

            <el-form-item
              label="10. Is your spouse, spousal equivalent, parent, child (dependent or not) or sibling (a Close Family Member) currently, or have they been at any time since 1 October 2004, a partner, principal, shareholder or employee of Ernst & Young?"
              prop="q8_A"
            >
              <el-radio-group v-model="ruleForm.q8_A" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <template v-if="ruleForm.q8_A === 'Yes'">
              <p class="label mb-10">If yes, please provide details</p>
              <el-form-item
                label="Type of Close Family Member (i.e. spouse, sibling etc)"
                prop="q8_A_FamilyMemberType"
              >
                <el-input v-model="ruleForm.q8_A_FamilyMemberType" disabled />
              </el-form-item>
              <el-form-item label="Job Title" prop="q8_A_JobTitle">
                <el-input v-model="ruleForm.q8_A_JobTitle" disabled />
              </el-form-item>
              <el-row :gutter="20">
                <el-col
                  :xs="12" :sm="12" :md="12" :lg="12"
                  :xl="12"
                >
                  <el-form-item label="Period start?" prop="q8_A_PeriodStart">
                    <el-date-picker
                      v-model="ruleForm.q8_A_PeriodStart"
                      format="MM/yyyy"
                      type="month"
                      disabled
                    />
                  </el-form-item>
                </el-col>
                <el-col
                  :xs="12" :sm="12" :md="12" :lg="12"
                  :xl="12"
                >
                  <el-form-item
                    label="Period conclude?"
                    prop="q8_A_PeriodConclude"
                  >
                    <el-date-picker
                      v-model="ruleForm.q8_A_PeriodConclude"
                      format="MM/yyyy"
                      type="month"
                      disabled
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </template>

            <div v-for="item in listOf10" :key="item.key">
              <el-form-item :label="item.label" :prop="item.key">
                <el-radio-group v-model="ruleForm[item.key]" disabled>
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </el-form-item>

              <template v-if="ruleForm[item.key] === 'Yes'">
                <p class="label mb-10">If yes, please provide details</p>
                <el-row :gutter="20">
                  <el-col
                    :xs="12" :sm="12" :md="12" :lg="12"
                    :xl="12"
                  >
                    <el-form-item
                      label="Period start?"
                      :prop="item.periodStart"
                    >
                      <el-date-picker
                        v-model="ruleForm[item.periodStart]"
                        format="MM/yyyy"
                        type="month"
                        disabled
                      />
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="12" :sm="12" :md="12" :lg="12"
                    :xl="12"
                  >
                    <el-form-item
                      label="Period conclude?"
                      :prop="item.periodConclude"
                    >
                      <el-date-picker
                        v-model="ruleForm[item.periodConclude]"
                        disabled
                        format="MM/yyyy"
                        type="month"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </div>

            <el-form-item prop="q8_E">
              <span slot="label">is (or was) the relevant Close Family Member a partner,
                principal or shareholder from an Ernst & Young “office”
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    In this question, “office” means a distinct sub-group <br>
                    within Ernst & Young, whether distinguished <br>
                    along geographic or practice lines.
                  </div>
                  <i class="el-icon-info" />
                </el-tooltip>
                where the lead Ernst & Young NAB audit engagement partner
                primarily practices?"
              </span>
              <el-radio-group v-model="ruleForm.q8_E" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <template v-if="ruleForm.q8_E === 'Yes'">
              <p class="label mb-10">If yes, please provide details</p>
              <el-row :gutter="20">
                <el-col
                  :xs="12" :sm="12" :md="12" :lg="12"
                  :xl="12"
                >
                  <el-form-item label="Period start?" prop="q8_E_PeriodStart">
                    <el-date-picker
                      v-model="ruleForm.q8_E_PeriodStart"
                      format="MM/yyyy"
                      type="month"
                      disabled
                    />
                  </el-form-item>
                </el-col>
                <el-col
                  :xs="12" :sm="12" :md="12" :lg="12"
                  :xl="12"
                >
                  <el-form-item
                    label="Period conclude?"
                    prop="q8_E_PeriodConclude"
                  >
                    <el-date-picker
                      v-model="ruleForm.q8_E_PeriodConclude"
                      format="MM/yyyy"
                      type="month"
                      disabled
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </div>
          <p v-else>No Part 2 questions required.</p>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.EYQuestionG4View {
    .box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
  margin: 10px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: no-drop;
  p {
    color: #206caa;
    font-size: 14px;
    margin: 0;
    line-height: 40px;
  }
}
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
  }
  ::v-deep .el-collapse-item__wrap {
  background-color: transparent !important;
}
::v-deep .el-collapse-item__header {
  padding: 0;
  background-color: transparent !important;
}
}
</style>
