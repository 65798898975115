<script>
export default {
  name: 'RegisteredTaxAgent',
  props: {
    applicant: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {}
    }
  },

  created() {
    this.Preparation()
  },
  methods: {
    Preparation() {
      this.ruleForm = this.applicant
      this.ruleForm.isRegisteredTaxAgent = this.applicant.tpbRegistrationNumber !== '' ? 'Yes' : 'No'
    }
  }
}
</script>

<template>
  <div class="RegisteredTaxAgent">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      label-width="50%"
      label-position="top"
      style="margin: 24px 0"
    >
      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="12"
          :xl="12" class="label"
        >
          Are you a Registered Tax Agent?
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="24" :lg="12"
          :xl="12"
        >
          <el-form-item prop="isRegisteredTaxAgent">
            <el-radio-group v-model="ruleForm.isRegisteredTaxAgent" disabled>
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
        v-if="ruleForm.isRegisteredTaxAgent === 'Yes'"
        prop="TPBRegistrationNumber"
        label="What is your TPB Registration Number?"
      >
        <el-input v-model="ruleForm.tpbRegistrationNumber" disabled />
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.label {
  margin: 0;
  font-size: 14px;
  color: #606266;
}
</style>
