import CryptoJS from 'crypto-js'
//* *************************************************************
//* 字符串/对象加密
//*   0：须要解密的字符串或对象
//* ***************************************************************/
const iv = CryptoJS.enc.Utf8.parse('4745b0dc2ca64718')
const key = CryptoJS.enc.Utf8.parse('DhccAesKeyForPbs')
export function Encrypt(o) {
  const srcs = CryptoJS.enc.Utf8.parse(o)
  return CryptoJS.AES.encrypt(srcs, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
}

//* *************************************************************
//* 字符串解密
//*   str：须要解密的字符串
//* ***************************************************************/
export function Decrypt(str) {
  const decrypt = CryptoJS.AES.decrypt(str, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr
}
