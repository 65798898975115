var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ReopenNotes" }, [
    _c("div", { staticClass: "expression-left-box" }, [
      _c("i", { staticClass: "el-icon-circle-check" }),
      _c("span", { staticStyle: { "margin-left": "10px" } }, [
        _vm._v(" " + _vm._s(_vm.Notes)),
      ]),
    ]),
    _c("i", { staticClass: "el-icon-close" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }