<script>
import { mapState } from 'vuex'
import { preparationApi, onIsRepresentativeChangeApi, continueChangeApi } from '@/api/AuthorisedRepresentative'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'AuthorisedRepresentative',
  inject: ['OnApplicantChange', 'ContinueSetSession'],
  data() {
    return {
      currentFormSection: {},
      ruleForm: {
        isRepresentative: '',
        afsRepresentativeNumber: ''
      },
      rules: {
        isRepresentative: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        afsRepresentativeNumber: [{ required: true, message: 'Required field!', trigger: 'blur' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      device: state => state.setting.device,
      sectionTitle: state => state.section.sectionTitle,
      introduction: state => state.section.introduction,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.preparation.session
    },
    Applicant() {
      return this.preparation.applicant
    },
    showItem() {
      return this.ruleForm.isRepresentative === 'Yes'
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
    this.ruleForm.afsRepresentativeNumber = this.Applicant.afsRepresentativeNumber
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'AuthorisedRepresentative')
      // }
      this.allLoading = true
      const params = {
        Session: this.Session,
        AFSRepresentativeNumber: this.Applicant.afsRepresentativeNumber
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.ruleForm.isRepresentative = data.isRepresentative
        this.currentFormSection = data.currentFormSection
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = this.preparation
          params.currentFormSection = this.currentFormSection
          await continueChangeApi(params).then(res => {
            console.log(res.data.session)
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    async OnIsRepresentativeChange(val) {
      val === 'No' ? this.ruleForm.afsRepresentativeNumber = '' : ''
      const params = {
        session: this.Session,
        isRepresentative: val,
        applicantId: this.Session.current.applicantId
      }
      await onIsRepresentativeChangeApi(params).then(res => {})
    },

    afsRepresentativeNumberOnchange() {
      const data = this.preparation
      data.applicant.afsRepresentativeNumber = this.ruleForm.afsRepresentativeNumber
      this.$store.dispatch('preparation/setPreparation', data).then(() => {
        this.OnApplicantChange(data)
      })
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="AuthorisedRepresentative">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="60%"
      :label-position="isMobile ? 'top' : 'left'"
      :disabled="allDisabled"
    >
      <el-form-item prop="isRepresentative">
        <span slot="label">
          Do you currently or have you previously had an AFS Representative Number?
        </span>
        <el-radio-group v-model="ruleForm.isRepresentative" @change="OnIsRepresentativeChange">
          <el-radio label="Yes" />
          <el-radio label="No" />
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="showItem" prop="afsRepresentativeNumber">
        <el-row>
          <el-col :xs="24" :sm="14" :lg="14" :xl="14">
            <label class="label">* Please provide your AFS Representative Number
              <el-input v-model="ruleForm.afsRepresentativeNumber" style="margin-top: 10px" @change="afsRepresentativeNumberOnchange" />
            </label>
          </el-col>
        </el-row>
      </el-form-item>

      <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and Continue</el-button>
    </el-form>
  </div>
</template>

<style lang="scss" >
.AuthorisedRepresentative {
  .el-form-item__label {
    line-height: 18px;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
