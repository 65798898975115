var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _vm.cvOrderApplicantDocuments.length < 1 && _vm.imgcanDelete
        ? _c("OnlyUpload", {
            on: { Document_Upload: _vm.CloudinaryUpload_UploadResult_CV },
          })
        : _vm._e(),
      _vm._l(_vm.cvOrderApplicantDocuments, function (item) {
        return _c("OnlyUpload", {
          key: item.id,
          attrs: { "img-item": item, "can-delete": _vm.getImgcanDelete(item) },
          on: {
            Document_Upload: _vm.CloudinaryUpload_UploadResult_CV,
            Document_Remove: _vm.Document_Remove,
          },
        })
      }),
      _vm.ContinueBtn
        ? _c(
            "el-button",
            {
              staticClass: "mt-30",
              attrs: { loading: _vm.endAllRequests, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.Continue()
                },
              },
            },
            [_vm._v("Save and Continue")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }