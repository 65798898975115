<template lang="en">
  <div id="app" lang="en">
    <router-view />
  </div>
</template>

<script>
const getLang = () => {
  document.querySelector('html').setAttribute('lang', 'en')
}
getLang()
</script>
<style lang="scss">
#app{
  background-color: #ededed;
}
</style>
