<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthorisedRepresentative',
  props: {
    applicant: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {
        isRepresentative: '',
        afsRepresentativeNumber: ''
      }
    }
  },
  computed: {
    ...mapState({
      device: state => state.setting.device
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    showItem() {
      return this.ruleForm.isRepresentative === 'Yes'
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      this.ruleForm.isRepresentative = this.applicant.afsRepresentativeNumber ? 'Yes' : 'No'
      this.ruleForm.afsRepresentativeNumber = this.applicant.afsRepresentativeNumber
    }
  }
}
</script>

<template>
  <div class="AuthorisedRepresentative">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-position="top"
      disabled
    >

      <el-form-item prop="isRepresentative">
        <el-row>
          <el-col :xs="24" :sm="16" :lg="16" :xl="16">
            <span class="label">Do you currently or have you previously had an AFS Representative Number?</span>
          </el-col>
          <el-col :xs="24" :sm="8" :lg="8" :xl="8">
            <el-radio-group v-model="ruleForm.isRepresentative">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item v-if="showItem" prop="afsRepresentativeNumber" label="Please provide your AFS Representative Number">
        <el-row>
          <el-col :xs="24" :sm="14" :lg="14" :xl="14">
            <el-input v-model="ruleForm.afsRepresentativeNumber" style="margin-top: 10px" />
          </el-col>
        </el-row>
      </el-form-item>

    </el-form>
  </div>
</template>

<style lang="scss" >
.AuthorisedRepresentative {
  .el-form-item__label {
    line-height: 18px;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
    line-height: 1.5;
  }
}
</style>
