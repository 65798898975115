<script>
export default {
  name: 'EYQuestionG13Item',
  props: {
    jobs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      compliancesList: [
        {
          jobTitle: '',
          dateCommenced: '',
          dateEnded: '',
          index: 0
        }
      ],
      openIndex: 0, // 当前打开的index
      index: 0 // 持续递增的index
    }
  },
  created() {
    if (this.jobs.length > 0) {
      this.compliancesList = this.jobs
    }
  },
  methods: {
  }
}
</script>

<template>
  <div>
    <div v-for="item in compliancesList" :key="item.index">
      <div class="box">
        <p>
          {{ item.jobTitle }}
          ({{ item.dateCommenced | parseDefaultTime('{g} {y}') }} - {{ item.dateEnded | parseDefaultTime('{g} {y}') }})
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
  margin: 10px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: no-drop;
  p {
    color: #206caa;
    font-size: 14px;
    margin: 0;
    line-height: 40px;
  }
}</style>
