<script>
import { mapState } from 'vuex'
import {
  preparationApi,
  uploadApi,
  removeDocumentApi,
  submitFeedbackApi
} from '@/api/ThankYou.js'
import { getThankYouDocumentIdListApi } from '@/api/application'
import iconveryunhappycolor from '@/assets/icons/iconveryunhappycolor.svg'
import iconveryunhappy from '@/assets/icons/iconveryunhappy.svg'
import iconunhappycolor from '@/assets/icons/iconunhappycolor.svg'
import iconunhappy from '@/assets/icons/iconunhappy.svg'
import iconneutralcolor from '@/assets/icons/iconneutralcolor.svg'
import iconneutral from '@/assets/icons/iconneutral.svg'
import iconhappycolor from '@/assets/icons/iconhappycolor.svg'
import iconhappy from '@/assets/icons/iconhappy.svg'
import iconveryhappycolor from '@/assets/icons/iconveryhappycolor.svg'
import iconveryhappy from '@/assets/icons/iconveryhappy.svg'
import DocumnetGalleryUpload from '@/components/DocumnetGalleryUpload'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'

export default {
  name: 'WelcomeView',
  components: { DocumnetGalleryUpload },
  inject: ['OnApplicantChange'],
  data() {
    return {
      CurrentFormSection: {},
      HasResult: false,
      modalShow: false,
      ResultType: '',
      RefreshGUID: '',
      CSATNotes: '',
      introductionShow: '',
      getOrderApplicantDocumentsByOrderApplicantId: [],
      evaluateImg: [
        {
          title: 'Very Dissatisfied',
          clickedRating: 1,
          src: iconveryunhappy,
          color: iconveryunhappycolor
        },
        {
          title: 'Dissatisfied',
          clickedRating: 2,
          src: iconunhappy,
          color: iconunhappycolor
        },
        {
          title: 'Fair',
          clickedRating: 3,
          src: iconneutral,
          color: iconneutralcolor
        },
        {
          title: 'Satisfied',
          clickedRating: 4,
          src: iconhappy,
          color: iconhappycolor
        },
        {
          title: 'Very Satisfied',
          clickedRating: 5,
          src: iconveryhappy,
          color: iconveryhappycolor
        }
      ],
      Rating: 0,
      allLoading: false,
      isSubmitted: false,
      IsShowRating: false,
      thankYouDocumentIdList: [],
      documentList: [],
      adobeDocumentList: []
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device,
      introduction: (state) => state.section.introduction,
      sectionTitle: (state) => state.section.sectionTitle,
      preparation: (state) => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    ApplicantFirstName() {
      if (this.preparation.applicant.isSingleNameOnly) {
        return this.preparation.applicant.lastName || ''
      } else {
        return this.preparation.applicant.firstName || ''
      }
    },
    Applicant() {
      return this.preparation.applicant
    },
    IsSubmitted() {
      return !this.isSubmitted
    },
    IsNotesEnabled() {
      return this.Applicant.csatNotes || this.Applicant.csatScore !== 0 || this.Rating !== 0
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'ThankYou')
      // }
      this.allLoading = true
      const params = {
        Session: this.Session,
        ApplicantId: this.ApplicantId
      }

      await getThankYouDocumentIdListApi({ orderApplicantId: this.Session.current.orderApplicantId }).then(res => {
        const { data } = res
        this.thankYouDocumentIdList = data.list || []
      })

      await preparationApi(params).then((res) => {
        const { data } = res
        this.IsShowRating = !this.Applicant.csatScore
        this.Rating = this.Applicant.csatScore || 0
        this.CSATNotes = this.Applicant.csatNotes
        this.adobeDocumentList = data.adobeDocumentList

        this.introductionShow = this.introduction
        const index = this.introductionShow.indexOf('{{ApplicantFirstName}}')
        this.getOrderApplicantDocumentsByOrderApplicantId =
          data.getOrderApplicantDocumentsByOrderApplicantId
        if (index !== -1) {
          this.introductionShow = this.introductionShow.replace(
            '{{ApplicantFirstName}}',
            this.ApplicantFirstName
          )
        }
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },
    handle(clickedRating) {
      this.Rating = clickedRating
    },

    async Continue() {
      const params = {
        applicant: this.Applicant
      }
      params.applicant.csatScore = this.Rating
      params.applicant.csatNotes = this.CSATNotes
      params.applicant.csatScoreOn = new Date()
      await submitFeedbackApi(params).then(res => {
        this.isSubmitted = true
      })
    },
    Logout() {
      this.$router.push({ name: 'Login' })
      this.$store.dispatch('user/logOut')
    },

    async Document_Upload(val) {
      const formData = new FormData()
      formData.append('Subtitle', val.subtitle)
      formData.append('File', val.file)
      formData.append(
        'OrderApplicantId',
        this.Session.current.orderApplicantId
      )

      await uploadApi(formData).then((res) => {
        const { data } = res
        this.getOrderApplicantDocumentsByOrderApplicantId = data.getOrderApplicantDocumentsByOrderApplicantId
        this.modalShow = false
      })
    },

    async Document_Remove(item) {
      const params = {
        orderApplicantDocumentId: item.id,
        orderApplicantId: this.Session.current.orderApplicantId
      }
      await removeDocumentApi(params).then((res) => {
        const { data } = res
        this.getOrderApplicantDocumentsByOrderApplicantId = data.getOrderApplicantDocumentsByOrderApplicantId
      })
    },

    isShow(clickedRating) {
      return (
        clickedRating === this.Rating || clickedRating === 0 || !this.Rating
      )
    },
    async downloadFile(item) {
      console.log(item)
      // window.open(`https://localhost:5001/api/applicant/anonymous/download-adobe-agreement-document?orderApplicantId=${this.Session.current.orderApplicantId}&documentId=${item.id}`, '_blank')
      window.open(`${process.env.VUE_APP_BASE_API}/api/applicant/anonymous/download-adobe-agreement-document?orderApplicantId=${this.Session.current.orderApplicantId}&documentId=${item.id}`, '_blank')
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="welcome">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introductionShow" />
    <div class="documnet-box">
      <div v-if="adobeDocumentList" class="documnet-box">
        <el-divider />
        <h4>Signed Documents</h4>
        <div v-for="(item, index) in adobeDocumentList" :key="index">
          <span style="font-size: 14px;"><span class="fa fa-download" style="margin: 8px 10px 8px 5px; cursor: pointer;" @click="downloadFile(item)" /><span style="font-size: 14px;">{{ item.name }}</span></span>
        </div>
      </div>
      <el-divider />
      <h4>Additional Documents</h4>
      <DocumnetGalleryUpload
        :modal-show.sync="modalShow"
        :img-list="getOrderApplicantDocumentsByOrderApplicantId"
        :thank-you-document-id-list="thankYouDocumentIdList"
        @Document_Upload="Document_Upload"
        @Document_Remove="Document_Remove"
      />
    </div>

    <div v-if="IsShowRating" class="img-box">
      <div class="evaluate-box">
        <p v-if="IsSubmitted" class="title text-style f-w-600">
          Was this online experience user friendly? Rate your experience!
        </p>
        <p v-else class="title text-style f-w-600">
          Thank you for your feedback!
        </p>

        <div v-if="IsSubmitted">
          <div class="evaluate-box-2">
            <div
              v-for="item in evaluateImg"
              :key="item.title"
              :class="device === 'mobile'?'evaluate-item-mobile':'evaluate-item'"
            >
              <img
                v-if="!isShow(item.clickedRating)"
                :src="item.src"
                class="img"
                alt=""
                @click="handle(item.clickedRating)"
              >
              <img
                v-if="isShow(item.clickedRating)"
                :src="item.color"
                class="img"
                alt=""
                @click="handle(item.clickedRating)"
              >
              <p>{{ item.title }}</p>
            </div>
          </div>
          <div v-if="Rating">
            <p class="f-w-600">Thank you!</p>
            <div v-if="IsNotesEnabled">
              <p class="f-w-600">
                Your feedback is valuable, tell us how we can improve?
              </p>
              <el-input
                v-model="CSATNotes"
                type="textarea"
                :rows="7"
                placeholder="Leave a comment here..."
              />
              <el-button type="primary" style="margin-top: 20px" @click="Continue">Submit</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-button type="primary" @click="Logout">Logout</el-button>
  </div>
</template>

<style lang="scss" scoped>
$border-color: #000;
.title {
  font-size: 22px;
}
.text-style {
  color: #272b30;
}
.img-box {
  border: 1px solid #eceef1;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
}
.evaluate-box {
  text-align: center;
  .evaluate-box-2 {
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .evaluate-item {
      padding: 0 30px;
      width:80px;
      .img {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }
    .evaluate-item-mobile {
      padding: 0 10px;
      width:70px;
      .img {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }
  }
}
</style>
