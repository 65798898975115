var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "Identity100Points",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { margin: "24px 0" },
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "50%",
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "hasProvidedDocument" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "label",
                      attrs: { xs: 24, sm: 24, md: 24, lg: 16, xl: 16 },
                    },
                    [
                      _c("span", [
                        _vm._v("Have you provided a document proving your "),
                        _c("strong", [_vm._v("current address")]),
                        _c("span", [_vm._v(" yet?")]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.OnHasProvidedDocumentChange },
                          model: {
                            value: _vm.ruleForm.hasProvidedDocument,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "hasProvidedDocument", $$v)
                            },
                            expression: "ruleForm.hasProvidedDocument",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.HasProvidedDocumentShow
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "f-s-14" }, [
                    _vm._v("Please provide a Document stating both your "),
                    _c("strong", [_vm._v("name")]),
                    _vm._v(" and "),
                    _c("strong", [_vm._v("current address")]),
                    _vm._v(" e.g. Utility bill, licence, bank statement."),
                  ]),
                  _vm.orderApplicantDocuments.length < 1 && _vm.imgcanDelete
                    ? _c("OnlyUpload", {
                        on: {
                          Document_Upload: _vm.CloudinaryUpload_UploadResult,
                        },
                      })
                    : _vm._e(),
                  _vm._l(_vm.orderApplicantDocuments, function (item) {
                    return _c("OnlyUpload", {
                      key: item.id,
                      attrs: {
                        "img-item": item,
                        "can-delete": _vm.getImgcanDelete(item),
                      },
                      on: {
                        Document_Upload: _vm.CloudinaryUpload_UploadResult,
                        Document_Remove: _vm.Document_Remove,
                      },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.ContinueBtn
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { loading: _vm.endAllRequests, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.Continue("ruleForm")
                    },
                  },
                },
                [_vm._v("Save and Continue")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }