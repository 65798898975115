<script>
import EmploymentHistoryItem from './AddForm/index.vue'
import { employmentHistoryApi } from '@/api/pattern'

export default {
  name: 'EmploymentHistoryView',
  components: { EmploymentHistoryItem },
  props: {
    applicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      getCompliancesByApplicantId: [],
      moduleLoading: false
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      await employmentHistoryApi({ applicantId: this.applicantId }).then(res => {
        // console.log('employmentHistoryApi===', res)
        this.getCompliancesByApplicantId = res.data.getEmploymentsByApplicantId
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" className="EmploymentHistoryView">
    <EmploymentHistoryItem
      ref="EmploymentHistoryItemRef"
      :get-compliances-by-applicant-id="getCompliancesByApplicantId"
    />
  </div>
</template>

<style lang="scss" scoped>
.EmploymentHistoryView {
  .label {
    line-height: 40px;
    font-size: 14px;
  }

  .year {
    line-height: 40px;
  }

  .year-label :first-child .test {
    display: flex;
    justify-content: flex-end;
  }

  .month {
    color: #fff;
    font-weight: bold;
    line-height: 40px;
    padding: 0 !important;
    margin-bottom: 10px;

    div {
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }

  .bg-green {
    background: #29823b !important;
  }

  .bg-yellow {
    background: #e9a100;
  }

  .visibility {
    visibility: hidden;
  }
}
</style>
