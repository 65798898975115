var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "img-box" },
      _vm._l(_vm.list, function (imgItem, index) {
        return _c("div", { key: index, staticClass: "item" }, [
          _c("div", { staticClass: "img" }, [
            _c("img", {
              staticStyle: { cursor: "pointer" },
              attrs: {
                src: imgItem.thumbUrl,
                alt: "",
                width: "140px",
                height: "200px",
              },
              on: {
                click: function ($event) {
                  return _vm.openImg(imgItem.docurl)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "msg" }, [_vm._v(_vm._s(imgItem.fileName))]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }