<script>
import { mapState } from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    visible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  }
}
</script>

<template>
  <div v-show="visible" class="modal">
    <div class="modal-content">
      <h4><i style="margin-right: 10px;" class="fa fa-spin fa-circle-o-notch" />Your application is currently being submitted, please wait...</h4>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-bottom {
  margin: 20px 0;
}
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fff;
  margin: 50vh auto;
  padding: 10px 20px;
  border: 1px solid #888;
  width: 40%;
  min-width: 400px;
  border-radius: 10px;
  text-align: center;
}

.close-btn {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
