
<script>
import { mapState } from 'vuex'
import { continueApi } from '@/api/ElectronicLodgement'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'ElectronicLodgement',
  inject: ['ContinueSetSession'],
  data() {
    return {
      ruleForm: {
        electronicLodgementAgreed: ''
      },
      rules: {
        electronicLodgementAgreed: [{ required: true, message: 'Please select a value', trigger: 'change' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      device: state => state.setting.device,
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.preparation.session
    },
    currentFormSection() {
      return this.preparation.session.current.formSection
    },
    Applicant() {
      return this.preparation.applicant
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'ElectronicLodgement')
      // }
      this.allLoading = true
      this.ruleForm.electronicLodgementAgreed = this.Applicant.isElectronicLodgementAgreed ? 'Yes' : ''
      this.allLoading = false
    },
    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicant: this.Applicant,
            currentFormSection: this.currentFormSection,
            electronicLodgementAgreed: this.ruleForm.electronicLodgementAgreed
          }
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="ElectronicLodgement">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-divider />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-form-item
        prop="electronicLodgementAgreed"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span class="color-red">*</span> I agree to the electronic lodgement of my personal information
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.electronicLodgementAgreed">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and Continue</el-button>

    </el-form>
  </div>
</template>

<style lang="scss" >
.ElectronicLodgement {
  .label {
    line-height: 1.5;
    font-size: 14px;
    color: #606266;
  }
  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
