<script>
import _ from 'lodash'
import global from '@/utils/global'
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import AddressAutocomplete from '@/components/AddressAutocomplete'
import GetAllGeoCountries from '@/components/GetAllGeoCountries'
import { onPreviousAddressChangeApi } from '@/api/AddressHistory'
import { getAuStatesApi } from '@/api/application'
export default {
  name: 'AddressForm',
  components: { GetAllGeoCountries, AddressAutocomplete },
  inject: ['GetAllCompliancesList'],
  props: {
    previousAddresses: {
      type: Array,
      default: () => []
    },
    currentAddressFromDate: {
      type: String,
      default: ''
    },
    minimumYears: {
      type: Number,
      default: 0
    },
    targetMinDate: {
      type: String,
      default: ''
    }
  },
  data() {
    const validateSearch = (rule, value, callback) => {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      if (value !== '') {
        if (value === this.compliancesList[spliceIndex].displayLine1) {
          callback()
        } else {
          callback(new Error('Please select an address from the list.'))
        }
      } else {
        callback(new Error('Required field!'))
      }
    }
    return {
      form: {},
      formConfig: {
        fullAddress: '',
        country: '',
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        state: '',
        postCode: '',
        latitude: '',
        longitude: '',
        meshblock: '',
        fromDate: null,
        toDate: null,
        isManual: false
      },
      australianStates: [],
      rules: {
        fullAddress: [
          { required: true, validator: validateSearch, trigger: 'blur' }
        ],
        countryCode: [
          { required: true, message: 'Required field!', trigger: 'change' }
        ],
        addressLine1: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        suburb: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        state: [
          { required: true, message: 'Required field!', trigger: 'change' }
        ],
        fromDate: [
          { required: true, message: 'Required field!', trigger: 'change' }
        ],
        toDate: [
          { required: true, message: 'Required field!', trigger: 'change' }
        ]
      },
      compliancesList: [
        // {
        //   fullAddress: '',
        //   country: '',
        //   addressLine1: '',
        //   addressLine2: '',
        //   suburb: '',
        //   state: '',
        //   postCode: '',
        //   latitude: '',
        //   longitude: '',
        //   meshblock: '',
        //   fromDate: null,
        //   toDate: null,
        //   isManual: false,
        //   index: 0
        // }
      ],
      openIndex: 0, // 当前打开的index
      index: 0, // 持续递增的index
      fullAddress: '',
      passStatus: false
    }
  },
  computed: {
    ...mapState({
      preparation: (state) => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    isManualAddressEntryShow() {
      return !this.isManualAddressEntry
    },
    pickerOptions() {
      const minDate = this.minDate
      return {
        disabledDate(time) {
          return time.getTime() > new Date(minDate).getTime()
        }
      }
    },
    minDate() {
      return this.compliancesList[this.compliancesList.length - 1].toDate
    },
    firstDate: {
      get(val) {
        return this.currentAddressFromDate
      },
      set(val) {
        this.$emit('update:currentAddressFromDate', val)
      }
    }
  },
  watch: {
    firstDate: function(newData, oldData) {
      if (this.compliancesList.length === 1) {
        this.compliancesList[0].toDate = this.firstDate
      }
    },
    compliancesList: {
      handler(data) {
        if (data) {
          this.GetAllCompliancesList(this.compliancesList)
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (this.previousAddresses.length > 0) {
      this.compliancesList = []
      this.previousAddresses.forEach(item => {
        item.displayLine1 = item.fullAddress
        this.compliancesList.push(item)
      })
      this.compliancesList = this.$isRepeat(this.compliancesList)
      this.index = Math.max.apply(Math, this.compliancesList.map(item => { return item.index }))
      this.index = this.index ? this.index : 0
      this.openIndex = this.index && this.index !== 0 ? this.index : 0
      this.ValidateForm('start')
    }
    this.getAuStates()
  },
  methods: {
    async AddressAutocompleteOnDetailReady(AddressDetail, displayLine1) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].fullAddress = AddressDetail.fullAddress
      this.compliancesList[spliceIndex].addressLine1 = AddressDetail.addressLine1
      this.compliancesList[spliceIndex].addressLine2 = AddressDetail.addressline2
      this.compliancesList[spliceIndex].suburb = AddressDetail.suburb
      this.compliancesList[spliceIndex].state = AddressDetail.state
      this.compliancesList[spliceIndex].postCode = AddressDetail.postcode
      this.compliancesList[spliceIndex].country = AddressDetail.country
      this.compliancesList[spliceIndex].countryCode = AddressDetail.countryCode
      // this.compliancesList[spliceIndex].countryCode3=GetCountry(AddressDetail.CountryCode).IsoAlpha3
      this.compliancesList[spliceIndex].longitude = AddressDetail.longitude || ''
      this.compliancesList[spliceIndex].latitude = AddressDetail.latitude || ''
      this.compliancesList[spliceIndex].meshblock = AddressDetail.meshblock || ''
      this.compliancesList[spliceIndex].gnaf = AddressDetail.gnaf
      this.compliancesList[spliceIndex].dpid = AddressDetail.dpid
      this.compliancesList[spliceIndex].parcelId = AddressDetail.parcelId
      this.compliancesList[spliceIndex].isManual = false
      // await this.PreviousAddressChange(this.compliancesList[spliceIndex])
      // this.compliancesList[spliceIndex].fullAddress = this.fullAddress
      this.compliancesList[spliceIndex].displayLine1 = AddressDetail.fullAddress

      this.$refs[`addForm${this.openIndex}`][0].validateField('fullAddress')
      this.ValidateForm()
    },
    AutocompleteAddressOnClear() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].displayLine1 = ''
      this.compliancesList[spliceIndex].fullAddress = ''
      this.compliancesList[spliceIndex].addressLine1 = ''
      this.compliancesList[spliceIndex].addressLine2 = ''
      this.compliancesList[spliceIndex].suburb = ''
      this.compliancesList[spliceIndex].state = ''
      this.compliancesList[spliceIndex].postCode = ''
      this.compliancesList[spliceIndex].country = ''
      this.compliancesList[spliceIndex].countryCode = ''
      this.compliancesList[spliceIndex].longitude = ''
      this.compliancesList[spliceIndex].latitude = ''
      this.compliancesList[spliceIndex].meshblock = ''
      this.compliancesList[spliceIndex].gnaf = ''
      this.compliancesList[spliceIndex].dpid = ''
      this.compliancesList[spliceIndex].parcelId = ''
    },
    AddressAutocompleteOnManualEntry(Search) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].displayLine1 = ''
      this.compliancesList[spliceIndex].addressLine1 = Search
      this.compliancesList[spliceIndex].fullAddress = ''
      this.compliancesList[spliceIndex].country = !this.compliancesList[spliceIndex].country ? 'Australia' : this.compliancesList[spliceIndex].country
      this.compliancesList[spliceIndex].countryCode = !this.compliancesList[spliceIndex].countryCode ? 'AU' : this.compliancesList[spliceIndex].countryCode
      this.compliancesList[spliceIndex].isManual = true
      this.compliancesList[spliceIndex].addressLine2 = ''
      this.compliancesList[spliceIndex].suburb = ''
      this.compliancesList[spliceIndex].state = ''
      this.compliancesList[spliceIndex].postCode = ''
      this.getAuStates()
    },
    AddressAutocompleteOnNoSelectionMade() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      this.compliancesList[spliceIndex].addressLine1 = ''
      this.compliancesList[spliceIndex].addressLine2 = ''
      this.compliancesList[spliceIndex].suburb = ''
      this.compliancesList[spliceIndex].state = ''
      this.compliancesList[spliceIndex].postCode = ''
      this.compliancesList[spliceIndex].country = ''
      this.compliancesList[spliceIndex].longitude = ''
      this.compliancesList[spliceIndex].latitude = ''
      this.compliancesList[spliceIndex].meshblock = ''
      this.compliancesList[spliceIndex].gnaf = ''
      this.compliancesList[spliceIndex].dpid = ''
      this.compliancesList[spliceIndex].parcelId = ''
    },

    async PreviousAddressChange(data) {
      const params = {
        previousAddress: data
      }
      await onPreviousAddressChangeApi(params).then((res) => {
        this.fullAddress = res.data.fullAddress
      })
    },

    handleRemove(itemIndex, item, open) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === itemIndex
      )
      this.passStatus = false
      this.$emit('getPassinfo', this.passStatus)
      if (this.compliancesList.length === 1) {
        this.compliancesList[0].fullAddress = ''
        this.compliancesList[0].country = ''
        this.compliancesList[0].countryCode = ''
        this.compliancesList[0].addressLine1 = ''
        this.compliancesList[0].addressLine2 = ''
        this.compliancesList[0].suburb = ''
        this.compliancesList[0].state = ''
        this.compliancesList[0].postCode = ''
        this.compliancesList[0].latitude = ''
        this.compliancesList[0].longitude = ''
        this.compliancesList[0].meshblock = ''
        this.compliancesList[0].fromDate = null
        this.compliancesList[0].isManual = false
      } else {
        this.openIndex = spliceIndex - 1
        this.compliancesList.splice(spliceIndex, 1)
      }
      // open !== 'open' && this.addItem()
      // console.log(this.compliancesList.length)
      // if (this.compliancesList.length === 0) {
      //   console.log(123)
      //   this.addItem()
      // }
      // this.compliancesList.length < 1 && this.addItem()
    },

    addItem() {
      const obj = {
        ...this.formConfig,
        index: ++this.index
      }
      obj.toDate = this.compliancesList[this.compliancesList.length - 1].fromDate
      this.compliancesList.push(
        JSON.parse(
          JSON.stringify(obj)
        )
      )
      this.openIndex = this.index
    },

    open(itemIndex) {
      // if (
      //   this.$refs[`addForm${this.index}`] &&
      //   this.$refs[`addForm${this.index}`][0]
      // ) {
      //   this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
      //     if (valid) {
      //       this.openIndex = itemIndex
      //     }
      //   })
      // } else {
      // this.openIndex = itemIndex
      // }
      const index = this.compliancesList.findIndex(v => v.index === this.openIndex)
      if (index === -1) {
        this.openIndex = itemIndex
        return
      }
      const isManualArr = ['countryCode', 'addressLine1', 'state', 'fromDate']
      const notManualArr = ['fullAddress', 'fromDate']

      const preAddressRules = this.compliancesList[index].isManual ? isManualArr : notManualArr
      for (let j = 0; j < preAddressRules.length; j++) {
        if (this.compliancesList[index][preAddressRules[j]]) {
          this.openIndex = itemIndex
          return
        }
      }
      this.openIndex = itemIndex
      this.handleRemove(index, '', 'open')
    },

    onlyValidate() {
      if (this.$refs[`addForm${this.openIndex}`] && this.$refs[`addForm${this.openIndex}`][0]) {
        this.$refs[`addForm${this.openIndex}`][0].validate(async(valid) => {
          if (valid) {
            // 单纯地验证表单 不用做任何操作
          }
        })
      }
    },

    async ValidateForm(type) {
      this.passStatus = false
      this.$emit('getPassinfo', this.passStatus)

      // const data = type !== 'start' ? this.compliancesList[this.compliancesList.length - 1] : this.compliancesList[0]

      let data = {}
      for (let i = 0; i < this.compliancesList.length; i++) {
        data = this.compliancesList[i]

        if (data.isManual) {
          this.onlyValidate()
        }

        const isManualArr = ['countryCode', 'addressLine1', 'suburb', 'fromDate']
        const notManualArr = ['fullAddress', 'fromDate']

        let preAddressRules = []

        if (data.isManual) {
          if (data.countryCode === 'AU') {
            isManualArr.push('state')
            isManualArr.push('postCode')
          }
          preAddressRules = isManualArr
        } else {
          preAddressRules = notManualArr
        }

        for (let j = 0; j < preAddressRules.length; j++) {
          if (!data[preAddressRules[j]]) {
            return
          }
        }

        const isBefore = await dayjs(data.fromDate).isBefore(dayjs(this.targetMinDate))
        const isSame = await dayjs(data.fromDate).isSame(dayjs(this.targetMinDate))

        // console.log(isBefore)
        // console.log(isSame)
        this.passStatus = isBefore || isSame
        if (this.passStatus) {
          break
        }
      }

      // console.log('passStatus335', this.passStatus)
      if (this.passStatus) {
        this.$emit('getPassinfo', this.passStatus)
        this.openIndex = null
        return
      }
      this.addItem()
    },

    async onCountryChage(item, val) {
      const index = this.compliancesList.findIndex(v => v.index === item.index)
      this.compliancesList[index].country = val.countryName
      this.compliancesList[index].countryCode = val.countryCode
      this.compliancesList[index].countryCode3 = val.isoAlpha3
      if (val !== 'AU') {
        this.compliancesList[index].state = ''
      }
      await this.PreviousAddressChange(this.compliancesList[index])
      this.compliancesList[index].fullAddress = this.fullAddress
      this.$forceUpdate()
      this.$nextTick(() => {
        this.$refs[`addForm${this.openIndex}`][0].clearValidate()
      })
    },

    async DatePickerOnSelect(itemIndex) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === itemIndex
      )
      await this.PreviousAddressChange(this.compliancesList[spliceIndex])
      this.compliancesList[spliceIndex].fullAddress = this.fullAddress

      if (!this.compliancesList[spliceIndex].isManual) {
        this.ValidateForm()
      }
    },

    async DatePickerOnSelect2(itemIndex) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === itemIndex
      )
      await this.PreviousAddressChange(this.compliancesList[spliceIndex])
      this.compliancesList[spliceIndex].fullAddress = this.fullAddress
    },

    OnManualAddressChange: _.debounce(
      function(itemIndex) {
        const spliceIndex = this.compliancesList.findIndex(
          (data) => data.index === itemIndex
        )
        console.log('this.compliancesList[spliceIndex]', this.compliancesList[spliceIndex])
        this.PreviousAddressChange(this.compliancesList[spliceIndex])
        this.compliancesList[spliceIndex].fullAddress = this.fullAddress
      }, global.debounceTime),

    timeChange(time) {
      return dayjs(time).format('DD/MM/YYYY')
    },
    priortimeChange(time) {
      const monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const year = dayjs(time).year()
      const month = dayjs(time).month()
      const date = dayjs(time).date()
      return `${date} ${monthList[month]} ${year}`
    },
    async getAuStates() {
      await getAuStatesApi().then((res) => {
        const { data } = res
        this.australianStates = data.australianStates
      })
    }
  }
}
</script>

<template>
  <div class="AddressForm">
    <div v-for="item in compliancesList" :key="item.index">
      <div
        v-if="item.index != openIndex"
        class="history-box"
        @click="open(item.index)"
      >
        <p>
          {{ item.fullAddress || item.addressLine1 }} ({{ timeChange(item.fromDate) }} - {{ timeChange(item.toDate) }})
        </p>
      </div>
      <div v-if="item.index == openIndex">
        <p>Where did you live prior to {{ priortimeChange(item.toDate) }}?</p>
        <el-form
          :ref="`addForm${item.index}`"
          :model="item"
          :rules="rules"
          label-width="80px"
          label-position="top"
          :disabled="allDisabled"
        >
          <el-row v-if="!item.isManual">
            <el-col :xs="24" :sm="24" :lg="24" :xl="24">
              <el-form-item prop="fullAddress" label="Full Address">
                <AddressAutocomplete
                  :initialaddress.sync="item.fullAddress"
                  :placeholder="'Start typing and pause to select the address'"
                  :use-kleber="true"
                  :use-google="true"
                  from="AddressHistory"
                  @OnDetailReady="AddressAutocompleteOnDetailReady"
                  @OnClear="AutocompleteAddressOnClear"
                  @OnManualEntry="AddressAutocompleteOnManualEntry"
                  @OnNoSelectionMade="AddressAutocompleteOnNoSelectionMade"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <div v-else>
            <el-row>
              <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                <el-form-item prop="countryCode" label="Country">
                  <GetAllGeoCountries
                    :defalut-value.sync="item.countryCode"
                    @getCountryData="onCountryChage(item,$event)"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                <el-form-item prop="addressLine1" label="Address Line 1">
                  <el-input v-model="item.addressLine1" @input="OnManualAddressChange(item.index,$event)" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                <el-form-item prop="addressLine2" label="Address Line 2">
                  <el-input v-model="item.addressLine2" @input="OnManualAddressChange(item.index,$event)" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                <el-form-item prop="suburb" label="Suburb">
                  <el-input v-model="item.suburb" @input="OnManualAddressChange(item.index,$event)" />
                </el-form-item>
              </el-col>

              <el-col
                v-if="item.countryCode === 'AU'" :xs="24" :sm="6" :lg="6"
                :xl="6"
              >
                <el-form-item prop="state" label="State">
                  <el-select v-model="item.state" popper-class="dropdownClass" class="selectWidth" @change="OnManualAddressChange(item.index,$event)">
                    <el-option
                      v-for="item in australianStates"
                      :key="item.adminCodes1.ISO3166_2"
                      :label="item.adminCodes1.ISO3166_2"
                      :value="item.adminCodes1.ISO3166_2"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :xs="24" :sm="6" :lg="6" :xl="6">
                <el-form-item
                  prop="postCode"
                  :rules="{
                    required: item.countryCode === 'AU', message: 'Required field!', trigger: 'change'
                  }"
                  label="Postcode"
                >
                  <el-input v-model="item.postCode" @input="OnManualAddressChange(item.index,$event)" />
                  <!-- <el-input v-model="item.postCode" /> -->
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :lg="12" :xl="12">
              <el-form-item prop="fromDate" label="From Date">
                <el-date-picker
                  v-model="item.fromDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="dd/MM/yyyy"
                  :picker-options="pickerOptions"
                  placeholder="DD/MM/YYYY"
                  @change="DatePickerOnSelect(item.index,$event)"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :lg="12" :xl="12">
              <el-form-item prop="toDate" label="To Date">
                <el-date-picker
                  v-model="item.toDate"
                  disabled
                  type="date"
                  value-format="yyyy-MM-dd"
                  format="dd/MM/yyyy"
                  @change="DatePickerOnSelect2(item.index,$event)"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item>
            <div class="action-box">
              <div
                v-if="item.isManual"
                class="action-item"
                @click="ValidateForm"
              >
                <i class="el-icon-circle-plus" />
                <span class="action-value">Add Another Address</span>
              </div>
              <div v-else />
              <div v-if="compliancesList[compliancesList.length-1].index===item.index && !allDisabled" class="action-item" @click="handleRemove(item.index, item)">
                <i class="el-icon-delete-solid" />
                <span class="action-value">Remove Address</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>

    </div>
    <p v-if="passStatus" style="color:#37b24d">You have provided enough information. Please Save and Continue.</p>
    <!-- <div class="action-box" @click="handleAdd('', 2)">
      <div class="action-item">
        <i class="el-icon-circle-plus" />
        <span class="action-value f-s-14">Add Another Address</span>
      </div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.action-box {
  display: flex;
  justify-content: space-between;
  color: #206caa;
  .action-item {
    cursor: pointer;
  }
  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }
  .action-value {
    margin-left: 10px;
  }
}
.dropdownClass .el-select-dropdown__item{
  width: 90vw !important;
  max-width: 500px;
}
</style>
