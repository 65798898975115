// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/login-logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginView[data-v-724a6f79] {\n  height: 100%;\n  background-color: #206baa;\n}\n.LoginView .message-box[data-v-724a6f79] {\n  padding: 20px 40px;\n  background: #c44f47 !important;\n  color: white;\n  border-radius: 6px;\n  margin: 20px 0;\n  font-size: 15px;\n  text-align: center;\n}\n.LoginView .content-box[data-v-724a6f79] {\n  padding: 48px;\n  background-color: #fff;\n  border-radius: 6px;\n  margin: 160px 0 0 0;\n  color: #4f575e;\n}\n.LoginView .content-box .logo[data-v-724a6f79] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 106px;\n  width: 197px;\n  margin: 0 auto;\n}\n.LoginView .content-box .tag[data-v-724a6f79] {\n  width: 100%;\n  color: #fff;\n  background-color: #007aad;\n  padding: 10px 10px;\n  box-sizing: border-box;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n}\n.LoginView .content-box .tag i[data-v-724a6f79] {\n  font-size: 18px;\n  display: block;\n}\n.LoginView .content-box .tag span[data-v-724a6f79] {\n  margin: 10px;\n  font-size: 16px;\n  display: block;\n  word-break: break-all;\n}\n.LoginView .welcomeText[data-v-724a6f79] {\n  padding: 10px 10px;\n  font-size: 16px;\n  text-align: center;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
