<script>
import { mapState } from 'vuex'
export default {
  name: 'RefereesItemView',
  inject: ['AddAnotherReferee', 'OnRefereeRemoved', 'Continue'],
  props: {
    referees: {
      type: Array,
      default: () => []
    },
    continueBtn: {
      type: Boolean,
      default: true
    },
    minQty: {
      type: Number,
      default: 0
    },
    editRefereeIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      formConfig: {
        name: '',
        canContactImmediately: '',
        employerName: '',
        positionHeld: '',
        relationshipToApplicant: '',
        contactPhoneMobile: '',
        contactPhoneBusiness: '',
        contactEmail: ''
      },
      ruleForm: {
        name: '',
        canContactImmediately: '',
        employerName: '',
        positionHeld: '',
        relationshipToApplicant: '',
        contactPhoneMobile: '',
        contactPhoneBusiness: '',
        contactEmail: ''
      },
      rules: {
        name: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        canContactImmediately: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        employerName: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        positionHeld: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        relationshipToApplicant: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        contactPhoneMobile: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        contactPhoneBusiness: [{ required: false, message: 'Required field!', trigger: 'blur' }],
        contactEmail: [
          { required: true, message: 'Required field!', trigger: 'blur' },
          { type: 'email', message: 'Email expected!', trigger: ['blur'] }
        ]
      },
      refereesList: [
        {
          name: '',
          canContactImmediately: '',
          employerName: '',
          positionHeld: '',
          relationshipToApplicant: '',
          contactPhoneMobile: '',
          contactPhoneBusiness: '',
          contactEmail: '',
          index: 0
        }
      ],
      openIndex: 0, // 当前打开的index
      index: 0 // 持续递增的index
    }
  },
  computed: {
    ...mapState({
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    outAddBtnShow() {
      const spliceIndex = this.refereesList.findIndex(
        (data) => data.index === this.openIndex
      )
      return spliceIndex === -1
    }
  },
  created() {
    // this.getRefereesList()
  },
  methods: {
    getRefereesList() {
      if (this.referees.length > 0) {
        // console.log(this.referees)
        this.refereesList = []
        let container = {}
        this.referees.forEach(item => {
          container = item.referee
          container.canContactImmediately = item.canContactImmediately
          this.refereesList.push(container)
        })
        this.refereesList = this.$isRepeat(this.refereesList)
        this.index = Math.max.apply(Math, this.refereesList.map(item => { return item.index }))
        this.index = this.index ? this.index : 0
        this.openIndex = this.editRefereeIndex
      }
    },
    handleRemove(itemIndex, item) {
      const spliceIndex = this.refereesList.findIndex(
        (data) => data.index === itemIndex
      )
      if (this.refereesList.length > 1) {
        this.refereesList.splice(spliceIndex, 1)
        this.OnRefereeRemoved(item, this.refereesList)
      } else {
        this.refereesList = []
        this.index = 0
        this.addItem()
      }
    },
    addItem() {
      this.refereesList.push(
        JSON.parse(
          JSON.stringify({
            ...this.formConfig,
            index: ++this.index
          })
        )
      )
      this.AddAnotherReferee(this.refereesList)
      this.openIndex = this.index
    },
    handleAdd(formName, type, item) {
      if (type === 1) {
        // 表单内
        this.$refs[formName][0].validate(async(valid) => {
          if (valid) {
            this.addItem()
          }
        })
      } else {
        // 全局下
        if (
          this.$refs[`addForm${this.index}`] &&
          this.$refs[`addForm${this.index}`][0]
        ) {
          // 当前存在打开的表单
          this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
            if (valid) {
              this.addItem()
            }
          })
        } else {
          //  不存在打开的表单
          this.addItem()
        }
      }
    },
    open(itemIndex) {
      if (
        this.$refs[`addForm${this.index}`] &&
        this.$refs[`addForm${this.index}`][0]
      ) {
        this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
          if (valid) {
            this.openIndex = itemIndex
          }
        })
      } else {
        this.openIndex = itemIndex
      }
    },

    handleSure() {
      if (this.refereesList.length < this.minQty) {
        return this.$message.error(`Please provide at least ${this.minQty} referees`)
      }
      if (
        this.$refs[`addForm${this.openIndex}`] &&
        this.$refs[`addForm${this.openIndex}`][0]
      ) {
        this.$refs[`addForm${this.openIndex}`][0].validate(async(valid) => {
          if (valid) {
            this.Continue(this.refereesList)
            return
          }
        })
      } else {
        this.Continue(this.refereesList)
      }
    }
  }
}
</script>

<template>
  <div class="RefereesItemView">
    <div v-for="item in refereesList" :key="item.index" class="mb-20">
      <div
        v-if="item.index != openIndex"
        class="history-box"
        @click="open(item.index)"
      >
        <p>
          {{ item.name }}
        </p>
      </div>
      <div v-if="item.index == openIndex">
        <el-form
          :ref="`addForm${item.index}`"
          :model="item"
          :rules="rules"
          class="demo-ruleForm"
          label-position="top"
          :disabled="allDisabled"
        >
          <el-form-item label="Contact Person" prop="name">
            <el-input v-model="item.name" />
          </el-form-item>
          <el-row :gutter="20">
            <el-col
              :xs="24" :sm="15" :md="15" :lg="15"
              :xl="15" class="label"
            >
              <span class="color-red">*</span> Can this person be contacted immediately?
            </el-col>
            <el-col
              :xs="24" :sm="9" :md="9" :lg="9"
              :xl="9"
            >
              <el-form-item prop="canContactImmediately" style="margin-bottom: 0">
                <el-radio-group v-model="item.canContactImmediately">
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Employer Name" prop="employerName">
            <el-input v-model="item.employerName" />
          </el-form-item>

          <el-form-item label="Position Held" prop="positionHeld">
            <el-input v-model="item.positionHeld" />
          </el-form-item>

          <el-form-item label="Relationship to You" prop="relationshipToApplicant">
            <el-input v-model="item.relationshipToApplicant" />
          </el-form-item>

          <el-form-item label="Contact Phone Mobile" prop="contactPhoneMobile">
            <el-input v-model="item.contactPhoneMobile" maxlength="20" />
          </el-form-item>

          <el-form-item label="Contact Phone Business" prop="contactPhoneBusiness">
            <el-input v-model="item.contactPhoneBusiness" maxlength="20" />
          </el-form-item>

          <el-form-item label="Contact Email" prop="contactEmail">
            <el-input v-model="item.contactEmail" />
          </el-form-item>
          <el-form-item v-if="!allDisabled">
            <div class="action-box">
              <div
                class="action-item"
                @click="handleAdd(`addForm${item.index}`, 1,item)"
              >
                <i class="el-icon-circle-plus" />
                <span class="action-value">Add New Referee</span>
              </div>
              <div class="action-item" @click="handleRemove(item.index,item)">
                <i class="el-icon-delete-solid" />
                <span class="action-value">Remove Referee</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="outAddBtnShow && !allDisabled" class="action-box" @click="handleAdd('', 2)">
      <div class="action-item">
        <i class="el-icon-circle-plus" />
        <span class="action-value f-s-14">Add New Referee</span>
      </div>
    </div>
    <br>
    <el-button v-if="continueBtn" :loading="endAllRequests" type="primary" @click="handleSure">Save and Continue</el-button>
  </div></template>

<style lang="scss" scoped>
.label {
  line-height: 40px;
  font-size: 14px;
  color: #606266;
}
.action-box {
  display: flex;
  justify-content: space-between;
  color: #206caa;
  .action-item {
    cursor: pointer;
  }
  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }
  .action-value {
    margin-left: 10px;
  }
}
</style>
