<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import { preparationApi, saveFormDataApi, continueApi } from '@/api/ManagementDirector'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'ManagementDirectorView',
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  data() {
    return {
      ruleForm: {
        director: '',
        directorDetails: '',
        disqualified: '',
        disqualificationDetails: '',
        financialJudgements: '',
        judgementsDetails: '',
        declaredBankruptcy: '',
        bankruptcyDetails: ''
      },
      rules: {
        director: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        directorDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        disqualified: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        disqualificationDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        financialJudgements: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        judgementsDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        declaredBankruptcy: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        bankruptcyDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }]
      },
      id: 0,
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    Applicant() {
      return this.preparation.applicant
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    DirectorShow() {
      return this.ruleForm.director === 'Yes'
    },
    DisqualifiedShow() {
      return this.ruleForm.disqualified === 'Yes'
    },
    FinancialJudgementsShow() {
      return this.ruleForm.financialJudgements === 'Yes'
    },
    DeclaredBankruptcyShow() {
      return this.ruleForm.declaredBankruptcy === 'Yes'
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    },
    buttonText() {
    // If(Session.Current.FormSection.Index < (Session.FormSections.Length - 1), "Save and Continue", "Submit")
      return this.Session.current.formSection.index < this.Session.formSections.length - 1 ? 'Save and Continue' : 'Submit'
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'ManagementDirector')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.ruleForm.declaredBankruptcy = data.declaredBankruptcy
        this.ruleForm.director = data.director
        this.ruleForm.disqualified = data.disqualified
        this.ruleForm.financialJudgements = data.financialJudgements

        this.ruleForm.directorDetails = data.managementDirector.directorDetails
        this.ruleForm.disqualificationDetails = data.managementDirector.disqualificationDetails
        this.ruleForm.bankruptcyDetails = data.managementDirector.bankruptcyDetails
        this.ruleForm.judgementsDetails = data.managementDirector.judgementsDetails
        this.id = data.managementDirector.id
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },
    async RadioButtonOnChange() {
      if (this.ruleForm.director === 'No') {
        this.ruleForm.directorDetails = ''
        this.ruleForm.disqualified = ''
        this.ruleForm.disqualificationDetails = ''
        this.ruleForm.financialJudgements = ''
        this.ruleForm.judgementsDetails = ''
      }
      if (this.ruleForm.disqualified === 'No') {
        this.ruleForm.disqualificationDetails = ''
      }
      if (this.ruleForm.financialJudgements === 'No') {
        this.ruleForm.judgementsDetails = ''
      }
      if (this.ruleForm.declaredBankruptcy === 'No') {
        this.ruleForm.bankruptcyDetails = ''
      }
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        formData: {
          managementDirector: {
            applicantId: this.ApplicantId,
            hasBeenDirector: this.ruleForm.director === 'Yes',
            hasBeenDisqualified: this.ruleForm.disqualified === 'Yes' && this.ruleForm.director === 'Yes',
            hasDeclaredBankruptcy: this.ruleForm.declaredBankruptcy === 'Yes',
            hasFinancialJudgements: this.ruleForm.financialJudgements === 'Yes' && this.ruleForm.director === 'Yes',
            directorDetails: this.ruleForm.directorDetails,
            disqualificationDetails: this.ruleForm.disqualificationDetails,
            bankruptcyDetails: this.ruleForm.bankruptcyDetails,
            judgementsDetails: this.ruleForm.judgementsDetails
          },
          director: this.ruleForm.director,
          disqualified: this.ruleForm.disqualified,
          declaredBankruptcy: this.ruleForm.declaredBankruptcy,
          financialJudgements: this.ruleForm.financialJudgements
        }
      }
      await saveFormDataApi(params).then(res => {
        if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
      })
    },

    SaveFormTempData: _.debounce(
      function() {
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId,
          formData: {
            managementDirector: {
              id: this.id,
              applicantId: this.ApplicantId,
              hasBeenDirector: this.ruleForm.director === 'Yes',
              hasBeenDisqualified: this.ruleForm.disqualified === 'Yes' && this.ruleForm.director === 'Yes',
              hasDeclaredBankruptcy: this.ruleForm.declaredBankruptcy === 'Yes',
              hasFinancialJudgements: this.ruleForm.financialJudgements === 'Yes' && this.ruleForm.director === 'Yes',
              directorDetails: this.ruleForm.directorDetails,
              disqualificationDetails: this.ruleForm.disqualificationDetails,
              bankruptcyDetails: this.ruleForm.bankruptcyDetails,
              judgementsDetails: this.ruleForm.judgementsDetails
            },
            director: this.ruleForm.director,
            disqualified: this.ruleForm.disqualified,
            declaredBankruptcy: this.ruleForm.declaredBankruptcy,
            financialJudgements: this.ruleForm.financialJudgements
          }
        }
        saveFormDataApi(params).then(res => {
          if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
        })
      }, global.debounceTime),

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            managementDirector: {
              id: this.id,
              applicantId: this.ApplicantId,
              hasBeenDirector: this.ruleForm.director === 'Yes',
              hasBeenDisqualified: this.ruleForm.disqualified === 'Yes' && this.ruleForm.director === 'Yes',
              hasDeclaredBankruptcy: this.ruleForm.declaredBankruptcy === 'Yes',
              hasFinancialJudgements: this.ruleForm.financialJudgements === 'Yes' && this.ruleForm.director === 'Yes',
              directorDetails: this.ruleForm.directorDetails,
              disqualificationDetails: this.ruleForm.disqualificationDetails,
              bankruptcyDetails: this.ruleForm.bankruptcyDetails,
              judgementsDetails: this.ruleForm.judgementsDetails
            },
            director: this.ruleForm.director,
            disqualified: this.ruleForm.disqualified,
            declaredBankruptcy: this.ruleForm.declaredBankruptcy,
            financialJudgements: this.ruleForm.financialJudgements
          }
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="ManagementDirectorView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-form-item prop="director">
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="24" :lg="16"
              :xl="16" class="label"
            >
              <span class="color-red">*</span> Have you ever been a Director of a Pty Ltd Company?
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="24" :lg="8"
              :xl="8"
            >
              <el-radio-group v-model="ruleForm.director" @change="RadioButtonOnChange">
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <div v-if="DirectorShow">
        <el-form-item
          prop="directorDetails"
        >
          <label class="label">
            * Please provide details of the Companies you've been a Director of
            <el-input v-model="ruleForm.directorDetails" type="textarea" maxlength="2000" @input="SaveFormTempData" />
          </label>
        </el-form-item>

        <el-form-item prop="disqualified">
          <el-row :gutter="20">
            <label class="label">
              <el-col
                :xs="24" :sm="24" :md="24" :lg="16"
                :xl="16" class="label"
              >
                <span class="color-red">*</span> Have you ever been disqualified from acting as a Director?
              </el-col>
              <el-col
                :xs="24" :sm="24" :md="24" :lg="8"
                :xl="8"
              >
                <el-radio-group v-model="ruleForm.disqualified" @change="RadioButtonOnChange">
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </el-col>
            </label>
          </el-row>
        </el-form-item>

        <el-form-item
          v-if="DisqualifiedShow"
          prop="disqualificationDetails"
        >
          <label class="label">
            * Please provide details of disqualifications
            <el-input v-model="ruleForm.disqualificationDetails" type="textarea" maxlength="2000" @input="SaveFormTempData" />
          </label>
        </el-form-item>

        <el-form-item prop="financialJudgements">
          <el-row :gutter="20">
            <label class="label">
              <el-col
                :xs="24" :sm="24" :md="24" :lg="16"
                :xl="16" class="label"
              >
                <span class="color-red">*</span> Do you have any financial judgements or defaults against you in the
                capacity of Director?
              </el-col>
              <el-col
                :xs="24" :sm="24" :md="24" :lg="8"
                :xl="8"
              >
                <el-radio-group v-model="ruleForm.financialJudgements" @change="RadioButtonOnChange">
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </el-col>
            </label>
          </el-row>
        </el-form-item>

        <el-form-item
          v-if="FinancialJudgementsShow"
          prop="judgementsDetails"
        >
          <label class="label">
            * Please provide details of financial judgements
            <el-input v-model="ruleForm.judgementsDetails" type="textarea" maxlength="2000" @input="SaveFormTempData" />
          </label>
        </el-form-item>
      </div>

      <el-form-item prop="declaredBankruptcy">
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="24" :lg="16"
              :xl="16" class="label"
            >
              <span class="color-red">*</span> Have you ever declared bankruptcy?</el-col>
            <el-col
              :xs="24" :sm="24" :md="24" :lg="8"
              :xl="8"
            >
              <el-radio-group v-model="ruleForm.declaredBankruptcy" @change="RadioButtonOnChange">
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item v-if="DeclaredBankruptcyShow" prop="bankruptcyDetails">
        <label class="label">
          * Please provide bankruptcy details
          <el-input v-model="ruleForm.bankruptcyDetails" type="textarea" maxlength="2000" @input="SaveFormTempData" />
        </label>
      </el-form-item>
      <el-form-item>
        <!-- <el-button type="primary" @click="SaveFormTempData('ruleForm')">Save form data</el-button> -->
        <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">{{ buttonText }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.ManagementDirectorView {
  .label {
    line-height: 40px;
    font-size: 14px;
    color: #606266;
  }
}
</style>
