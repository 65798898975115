<script>
import { qualificationsTertiaryApi } from '@/api/pattern'
export default {
  name: 'QualificationsTertiary',
  props: {
    applicantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: {
        hasTertiaryQualification: '',
        suburb: '',
        state: '',
        selectedCountryCode: '',
        schoolLocality: '',
        schoolState: '',
        schoolCountry: '',
        dateCommenced: '',
        dateCompleted: '',
        hasOtherName: '',
        selectedOtherNameId: '',
        qualificationName: '',
        dateGraduated: '',
        applicantStudentId: '',
        contactPhone: '',
        contactEmail: ''
      },
      moduleLoading: false
    }
  },
  computed: {
    HasTertiaryQualificationShow() {
      return this.ruleForm.hasTertiaryQualification === 'Yes'
    },
    HasOtherNameShow() {
      return (
        this.ruleForm.hasOtherName === 'Yes'
      )
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }

      await qualificationsTertiaryApi(params).then((res) => {
        const { data } = res
        if (data.qualifications.length !== 0) {
          this.ruleForm = data.qualifications[0]
          this.ruleForm.initialAddress = !this.ruleForm.schoolLocality ? '' : (this.ruleForm.schoolLocality + ', ' + this.ruleForm.schoolState + (this.ruleForm.schoolCountry !== 'Australia' ? ' (' + this.ruleForm.schoolCountry + ')' : ''))
        }
        this.ruleForm.hasOtherName = data.hasOtherName
        this.ruleForm.hasTertiaryQualification = data.hasTertiaryQualification
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>
<template>
  <div v-loading="moduleLoading" class="QualificationsTertiary">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-position="top"
      disabled
    >
      <el-form-item
        prop="hasTertiaryQualification"
        label="Do you have a Tertiary Qualification?"
        style="margin-bottom: 0"
      >
        <el-radio-group v-model="ruleForm.hasTertiaryQualification" disabled>
          <el-radio label="Yes" />
          <el-radio label="No" />
        </el-radio-group>
      </el-form-item>

      <div v-if="HasTertiaryQualificationShow">
        <el-form-item label="Name of Qualification" prop="qualificationName">
          <el-input v-model="ruleForm.qualificationName" disabled />
        </el-form-item>

        <el-form-item
          label="University/College/School (Full Name)"
          prop="schoolName"
        >
          <el-input v-model="ruleForm.schoolName" disabled />
        </el-form-item>

        <el-form-item
          label="School Location"
          prop="initialAddress"
        >
          <el-input v-model="ruleForm.initialAddress" disabled />
        </el-form-item>

        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Date Commenced" prop="dateCommenced">
              <el-date-picker
                v-model="ruleForm.dateCommenced"
                value-format="yyyy-MM-dd"
                format="MM/yyyy"
                type="date"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Date Completed" prop="dateCompleted">
              <el-date-picker
                v-model="ruleForm.dateCompleted"
                value-format="yyyy-MM-dd"
                format="MM/yyyy"
                type="date"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Date Graduated" prop="dateGraduated">
              <el-date-picker
                v-model="ruleForm.dateGraduated"
                value-format="yyyy-MM-dd"
                format="MM/yyyy"
                type="date"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="applicantStudentId" label="Student Number">
              <el-input
                v-model="ruleForm.applicantStudentId"
                class="input-with-select"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          prop="hasOtherName"
          label="Were you known under a different name while you were studying?"
          style="margin-bottom: 0"
        >
          <el-radio-group v-model="ruleForm.hasOtherName" disabled>
            <el-radio label="Yes" />
            <el-radio label="No" />
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="HasOtherNameShow" prop="applicantName" label="Your name at the time of studying">
          <el-input
            v-model="ruleForm.applicantName"
            disabled
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<style lang="scss" scoped>
.QualificationsTertiary {
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
  }
  .img-item {
    width: 156px;
    height: 242px;
    background: #fff;
    margin: 10px 20px 10px 0;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    .img {
      width: 140px;
      height: 205px;
      padding: 8px;
    }
    .msg {
      font-size: 12px;
      padding: 8px;
      height: 21px;
      margin-top: -16px;
      text-align: center;
      overflow: hidden;
    }
  }
}
</style>
