const state = {
  startPort: 0,
  endPort: 0,
  endAllRequests: false
}

const mutations = {
  ADD_STARTPORT: (state) => {
    state.startPort++
  },
  ADD_ENDPORT: (state) => {
    state.endPort++
  },
  IS_ENDALLREQUESTS: (state) => {
    if (state.startPort === state.endPort) {
      state.endAllRequests = false
    } else {
      state.endAllRequests = true
    }
  }
}

const actions = {
  add_startPort({ commit }) {
    commit('ADD_STARTPORT')
    commit('IS_ENDALLREQUESTS')
  },
  add_endPort({ commit }) {
    commit('ADD_ENDPORT')
    commit('IS_ENDALLREQUESTS')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

