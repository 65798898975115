var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-menu",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "el-menu-vertical-demo",
          attrs: { "default-active": _vm.activeMenu },
          on: { select: _vm.getPath },
        },
        _vm._l(_vm.routeList, function (item) {
          return _c(
            "nav",
            { key: item.blockName },
            [
              _c(
                "el-menu-item",
                { key: item.blockName, attrs: { index: item.blockName } },
                [
                  item.completePercent === 100
                    ? _c("div", { staticClass: "completePercent100" }, [
                        _c("i", { staticClass: "fa fa-check-circle" }),
                        _c("span", [_vm._v(_vm._s(item.title))]),
                      ])
                    : _c("div", { staticClass: "completePercent0" }, [
                        _vm.activeMenu === item.blockName
                          ? _c("i", { staticClass: "fa fa-dot-circle-o" })
                          : _c("i", { staticClass: "fa fa-circle-o" }),
                        _c("span", [_vm._v(_vm._s(item.title))]),
                      ]),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm.device === "mobile" ? _c("el-divider") : _vm._e(),
      _vm.device === "mobile"
        ? _c("p", { staticClass: "Logout", on: { click: _vm.Logout } }, [
            _c("i", { staticClass: "fa fa-sign-out" }),
            _vm._v(" Logout"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }