import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/welcome' : '/proxy/api/applicant/welcome'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}
