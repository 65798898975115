var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ApplicantForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ApplicantForm, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Type of Employment",
                        prop: "employmentType",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.ApplicantForm.employmentType,
                          callback: function ($$v) {
                            _vm.$set(_vm.ApplicantForm, "employmentType", $$v)
                          },
                          expression: "ApplicantForm.employmentType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", {
                attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
              }),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Employment Location",
                        prop: "employmentLocation",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.ApplicantForm.employmentLocation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ApplicantForm,
                              "employmentLocation",
                              $$v
                            )
                          },
                          expression: "ApplicantForm.employmentLocation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", {
                attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
              }),
            ],
            1
          ),
          _vm.ApplicantForm.vulnerablePeopleClass
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Vulnerable People Class",
                    prop: "vulnerablePeopleClass",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ApplicantForm.vulnerablePeopleClass,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ApplicantForm,
                          "vulnerablePeopleClass",
                          $$v
                        )
                      },
                      expression: "ApplicantForm.vulnerablePeopleClass",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }