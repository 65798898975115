<script>
import { identity100PointsApi } from '@/api/pattern'
import ImageShow from '../components/ImageShow/index.vue'
export default {
  name: 'Identity100Points',
  components: { ImageShow },
  props: {
    orderApplicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      orderApplicantDocuments: [],
      moduleLoading: false
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      await identity100PointsApi({ orderApplicantId: this.orderApplicantId }).then(res => {
        this.orderApplicantDocuments = res.data.orderApplicantDocuments
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="Identity100Points">
    <div v-if="orderApplicantDocuments.length===0">
      Proof of address document already provided.
    </div>
    <div v-else>
      <div class="CVUpload-box">
        <ImageShow :img-list="orderApplicantDocuments" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
