var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "ComplianceReference",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c("el-divider"),
      _c(
        "el-form",
        {
          ref: "topForm",
          attrs: {
            model: _vm.topForm,
            rules: _vm.topRules,
            "label-width": "80px",
            "label-position": "top",
            disabled: _vm.allDisabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "hasBeenAuthorisedRepresentative" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { xs: 24, sm: 15, lg: 15, xl: 15 } }, [
                    _c("span", { staticClass: "label" }, [
                      _c("span", { staticClass: "color-red" }, [_vm._v("*")]),
                      _vm._v(
                        " In the last " +
                          _vm._s(_vm.minQty) +
                          " years have you been an Authorised Representative?"
                      ),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 9, lg: 9, xl: 9 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: _vm.OnHasBeenAuthorisedRepresentativeChange,
                          },
                          model: {
                            value: _vm.topForm.hasBeenAuthorisedRepresentative,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.topForm,
                                "hasBeenAuthorisedRepresentative",
                                $$v
                              )
                            },
                            expression:
                              "topForm.hasBeenAuthorisedRepresentative",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.FormShow
        ? _c(
            "div",
            [
              _c("p", { staticClass: "f-w-600" }, [
                _vm._v(
                  " Please provide contact details of compliance team member from all licensees. "
                ),
              ]),
              _c("AddForm", {
                ref: "AddForms",
                attrs: {
                  compliances: _vm.compliances,
                  "continue-btn": _vm.ContinueBtn,
                  "complete-percent": _vm.CompletePercent,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.FormShow && _vm.ContinueBtn
        ? _c(
            "el-button",
            {
              attrs: { loading: _vm.endAllRequests, type: "primary" },
              on: { click: _vm.Continue },
            },
            [_vm._v("Save and Continue")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }