<script>
import { mapState } from 'vuex'
import { coiDeclarationApi } from '@/api/pattern'
export default {
  name: 'ComplianceReference',
  props: {
    applicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      ruleForm: {
        hasAssociation: '',
        coiAssociationDetail: '',
        hasRelativeWithAssociation: '',
        coiRelativeAssociationDetail: ''
      },
      moduleLoading: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      await coiDeclarationApi({ applicantId: this.applicantId }).then(res => {
        // console.log('coiDeclarationApi===', res)
        this.ruleForm = res.data.getApplicantDeclarationByApplicantId || {}
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="ComplianceReference">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item
        label="Are you aware of any positions, relationships or any other matters that could give rise to real or apparent conflicts of interest if you obtain the position you have applied for?"
        prop="hasAssociation"
      >
        <el-radio-group v-model="ruleForm.coiHasAssociation" disabled>
          <el-radio :label="true">Yes</el-radio>
          <el-radio :label="false">No</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.coiHasAssociation"
        label="Please provide details"
        prop="coiAssociationDetail"
      >
        <el-input
          v-model="ruleForm.coiAssociationDetail"
          type="textarea"
          maxlength="1000"
          disabled
        />
      </el-form-item>
      <el-form-item
        label="Do you have any relatives or associates that have any prior or present association with any organisation or individual that could be considered a real or apparent conflict of interest if you are successful in obtaining the position you have applied for?"
        prop="hasRelativeWithAssociation"
      >
        <el-radio-group v-model="ruleForm.coiRelativeHasAssociation" disabled>
          <el-radio :label="true">Yes</el-radio>
          <el-radio :label="false">No</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.coiRelativeHasAssociation"
        label="Please provide details"
        prop="coiRelativeAssociationDetail"
      >
        <el-input
          v-model="ruleForm.coiRelativeAssociationDetail"
          type="textarea"
          maxlength="1000"
          disabled
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss">
.ComplianceReference {
  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
