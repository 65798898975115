var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "VulnerablePeopleView",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c(
        "el-form",
        {
          ref: "ApplicantForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ApplicantForm,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c("el-form-item", { attrs: { prop: "varEmploymentType" } }, [
            _c(
              "label",
              {
                staticClass: "label",
                staticStyle: { display: "flex", "flex-direction": "column" },
              },
              [
                _c("span", [_vm._v("* Type of Employment")]),
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.ApplicantForm.varEmploymentType,
                      callback: function ($$v) {
                        _vm.$set(_vm.ApplicantForm, "varEmploymentType", $$v)
                      },
                      expression: "ApplicantForm.varEmploymentType",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: "Paid" } }, [
                      _vm._v("Paid Employment"),
                    ]),
                    _c("el-radio-button", { attrs: { label: "Volunteer" } }, [
                      _vm._v("Volunteer Work"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("el-form-item", { attrs: { prop: "employmentLocation" } }, [
            _c(
              "label",
              { staticClass: "label" },
              [
                _vm._v("* Employment Location (Town / State) "),
                _c("el-input", {
                  model: {
                    value: _vm.ApplicantForm.employmentLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.ApplicantForm, "employmentLocation", $$v)
                    },
                    expression: "ApplicantForm.employmentLocation",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.ApplicantForm.varVulnerablePeopleClass
            ? [
                _c(
                  "el-row",
                  { staticClass: "f-s-14", attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 22, sm: 22, md: 22, lg: 22, xl: 22 } },
                      [
                        _c("p", { staticClass: "label" }, [
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            " Select the statement that best describes the role:"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 } },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "left",
                              width: "400",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("span", { staticClass: "f-w-600" }, [
                              _vm._v(
                                "Definition of Working with Vulnerable People"
                              ),
                            ]),
                            _c("el-divider"),
                            _c("div", { staticClass: "text item" }, [
                              _c("p", { staticClass: "f-w-600" }, [
                                _vm._v("Vulnerable groups mean:"),
                              ]),
                              _c("p", [
                                _vm._v(
                                  " a child an adult who is disadvantaged or in need of special care, support or protection because of age, disability or risk of abuse or neglect. "
                                ),
                              ]),
                              _c("p", [
                                _c("span", { staticClass: "f-w-600" }, [
                                  _vm._v("Contact means"),
                                ]),
                                _vm._v(
                                  " direct or indirect face-to-face contact, phone contact or any type of communication over the internet. "
                                ),
                              ]),
                              _c("p", [
                                _c("span", { staticClass: "f-w-600" }, [
                                  _vm._v("Supervision means"),
                                ]),
                                _vm._v(
                                  " in the presence of an adult who is responsible for the safety or care of the vulnerable person. "
                                ),
                              ]),
                              _c("p", [
                                _c("span", { staticClass: "f-w-600" }, [
                                  _vm._v("Unsupervised means"),
                                ]),
                                _vm._v(
                                  " you will not be in the presense of an adult who is responsible for the safety or care of the vulnerable person. "
                                ),
                              ]),
                            ]),
                            _c("i", {
                              staticClass: "fa fa-info",
                              attrs: { slot: "reference" },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "varVulnerablePeopleClass" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ApplicantForm.varVulnerablePeopleClass,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ApplicantForm,
                              "varVulnerablePeopleClass",
                              $$v
                            )
                          },
                          expression: "ApplicantForm.varVulnerablePeopleClass",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mt-10" },
                          [
                            _c("el-radio", { attrs: { label: "Supervised" } }, [
                              _c("span", { staticClass: "f-w-600" }, [
                                _vm._v("Supervised contact"),
                              ]),
                              _vm._v(" with vulnerable people "),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mt-10" },
                          [
                            _c(
                              "el-radio",
                              { attrs: { label: "Unsupervised" } },
                              [
                                _c("span", { staticClass: "f-w-600" }, [
                                  _vm._v("Unsupervised contact"),
                                ]),
                                _vm._v(" with vulnerable people "),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mt-10" },
                          [
                            _c("el-radio", { attrs: { label: "No contact" } }, [
                              _c("span", { staticClass: "f-w-600" }, [
                                _vm._v("No contact"),
                              ]),
                              _vm._v(" with vulnerable people "),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _vm.ContinueBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.endAllRequests, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.Continue("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.buttonText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }