import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/identity-100-points' : '/proxy/api/applicant/identity-100-points'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}

export function uploadApi(data) {
  return service({
    url: `${api}/upload`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function removeDocumentApi(data) {
  return service({
    url: `${api}/remove-document`,
    method: 'post',
    data: data
  })
}

export function onChangeApi(data) {
  return service({
    url: `${api}/on-change`,
    method: 'post',
    data: data
  })
}

export function continueApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}
