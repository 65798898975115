<script>
import { qualificationsNabApi } from '@/api/pattern'
export default {
  name: 'QualificationsNABView',
  props: {
    applicantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: {
        initialAddress: '',
        schoolName: '',
        schoolLocality: '',
        suburb: '',
        state: '',
        selectedCountryCode: '',
        contactPhone: '',
        contactEmail: '',
        qualificationName: '',
        dateCommenced: '',
        dateGraduated: '',
        hasOtherName: '',
        selectedOtherNameId: '',
        applicantStudentId: ''
      },
      moduleLoading: false
    }
  },
  computed: {
    HasOtherNameShow() {
      return (
        this.ruleForm.hasOtherName === 'Yes'
      )
    }
  },
  mounted() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }

      await qualificationsNabApi(params).then((res) => {
        const { data } = res
        if (data.qualifications.length !== 0) {
          this.ruleForm = data.qualifications[0]
        }
        this.ruleForm.hasOtherName = data.hasOtherName
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="QualificationsNAB">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item label="Name of Qualification" prop="qualificationName">
        <el-input v-model="ruleForm.qualificationName" disabled />
      </el-form-item>

      <el-form-item
        label="University/College/School (Full Name)"
        prop="schoolName"
      >
        <el-input v-model="ruleForm.schoolName" disabled />
      </el-form-item>

      <el-form-item label="School Location" prop="initialAddress">
        <div class="box">
          <p>
            {{ ruleForm.schoolLocality }} {{ ruleForm.schoolState }} {{ ruleForm.schoolCountry }}
          </p>
        </div>
      </el-form-item>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="12" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Date Commenced" prop="dateCommenced">
            <el-date-picker
              v-model="ruleForm.dateCommenced"
              value-format="yyyy-MM-dd"
              format="MM/yyyy"
              type="date"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="12" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Date Graduated"
            prop="dateGraduated"
          >
            <el-date-picker
              v-model="ruleForm.dateGraduated"
              value-format="yyyy-MM-dd"
              format="MM/yyyy"
              type="date"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="Student Number" prop="applicantStudentId">
        <el-input v-model="ruleForm.applicantStudentId" disabled />
      </el-form-item>

      <el-form-item
        label="Were you known under a different name while you were studying?"
        prop="hasOtherName"
      >
        <el-radio-group v-model="ruleForm.hasOtherName" disabled>
          <el-radio label="Yes" />
          <el-radio label="No" />
        </el-radio-group>
      </el-form-item>

      <el-form-item
        v-if="HasOtherNameShow"
        label="Your name at the time of studying"
      >
        <el-input v-model="ruleForm.applicantName" disabled />
      </el-form-item>

    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.QualificationsNAB {
  .box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
  margin: 10px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: no-drop;
  p {
    font-size: 14px;
    margin: 0;
    line-height: 40px;
  }
}
  .img-item {
    width: 156px;
    height: 242px;
    background: #fff;
    margin: 10px 20px 10px 0;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    .img {
      width: 140px;
      height: 205px;
      padding: 8px;
    }
    .msg {
      font-size: 12px;
      padding: 8px;
      height: 21px;
      margin-top: -16px;
      text-align: center;
      overflow: hidden;
    }
  }
}
</style>
