var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "MedicalDeclarationView",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(
                  " Have you been fully vaccinated (received two doses of the COVID-19 vaccination)? "
                ),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.HasCovid19Vaccination,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "HasCovid19Vaccination", $$v)
                    },
                    expression: "ruleForm.HasCovid19Vaccination",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Yes" } }),
                  _c("el-radio", { attrs: { label: "No" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.Covid19Documents.length
            ? _c(
                "div",
                { staticClass: "Covid19Vaccination-box" },
                [
                  _c("ImageShow", {
                    attrs: { "img-list": _vm.Covid19Documents },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }