<script>
import { getNameApi } from '@/api/documentTypes'
export default {
  name: 'ImageShowView',
  props: {
    imgList: {
      type: Array,
      default: () => []
    },
    caption: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      list: ''
    }
  },
  created() {
    this.getDocumentName()
  },
  methods: {
    async getDocumentName() {
      this.list = this.imgList

      this.list.forEach(async element => {
        if (!this.caption) {
          await getNameApi({ id: element.documentTypeId }).then(res => {
            element.fileName = res.data.name
          })
        } else {
          element.fileName = this.caption
        }
      })
    },
    openImg(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<template>
  <div>
    <div class="img-box">
      <div
        v-for="(imgItem, index) in list"
        :key="index"
        class="item"
      >
        <div class="img">
          <img
            :src="imgItem.thumbUrl" alt="" width="140px" height="200px"
            style="cursor: pointer;"
            @click="openImg(imgItem.docurl)"
          >
        </div>
        <div class="msg">{{ imgItem.fileName }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.img-box {
  display: flex;
  flex-wrap: wrap;
    .item {
      width: 156px;
      height: 242px;
      background: #fff;
      margin: 10px 20px 10px 0;
      border: 1px solid #dee2e6;
      border-radius: 4px;
      .img {
        width: 140px;
        height: 205px;
        padding: 8px;
      }
      .msg {
        font-size: 12px;
        padding: 8px;
        height: 21px;
        margin-top: -16px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
</style>
