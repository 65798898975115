<script>
import { preparationApi } from '@/api/Welcome'
import { mapState } from 'vuex'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'WelcomeView',
  inject: ['BackNext'],
  data() {
    return {
      introductionShow: '',
      allLoading: false

    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    ApplicantFirstName() {
      if (this.preparation.applicant.isSingleNameOnly) {
        return this.preparation.applicant.lastName || ''
      } else {
        return this.preparation.applicant.firstName || ''
      }
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'Welcome')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        this.introductionShow = this.introduction
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },
    Continue() {
      this.BackNext({ session: this.Session })
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="welcome">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-20 f-s-14">Dear {{ ApplicantFirstName }},</div>
    <div class="mb-20 f-s-14" v-html="introductionShow" />
    <el-button v-if="ContinueBtn" type="primary" @click="Continue">Continue</el-button>
  </div>
</template>
