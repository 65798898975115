var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "MedicalDeclarationView",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "label-width": "50%",
            "label-position": "top",
            disabled: "",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "Do you have any pre-existing illnesses, injuries or medical conditions* that may impact your ability to undertake the duties of the role?",
                prop: "conditionAffectingRole",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.conditionAffectingRole,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "conditionAffectingRole", $$v)
                    },
                    expression: "ruleForm.conditionAffectingRole",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Yes" } }),
                  _c("el-radio", { attrs: { label: "No" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.HasConditionAffectingRoleShow
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "If you answer ‘Yes’, Please provide further details of the condition in the space provided below including details of any treatment requirements and any adjustments that may be required in the workplace to accommodate that condition.",
                    prop: "roleConditionDetail",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.roleConditionDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "roleConditionDetail", $$v)
                      },
                      expression: "ruleForm.roleConditionDetail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "Do you have any pre-existing illnesses, injuries or medical conditions that may impact your ability to maintain your attendance at work?",
                prop: "conditionAffectingAttendence",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.conditionAffectingAttendence,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "conditionAffectingAttendence",
                        $$v
                      )
                    },
                    expression: "ruleForm.conditionAffectingAttendence",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Yes" } }),
                  _c("el-radio", { attrs: { label: "No" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.HasConditionAffectingAttendenceshow
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "If you answer ‘Yes’, Please provide further details of the condition in the space provided below including details of any treatment requirements and any adjustments that may be required in the workplace to accommodate that condition.",
                    prop: "attendenceConditionDetail",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.attendenceConditionDetail,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "attendenceConditionDetail", $$v)
                      },
                      expression: "ruleForm.attendenceConditionDetail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "Please provide any further information or workplace modifications you believe may be necessary for you to perform the duties of the role applied for",
                prop: "otherMedicalInformation",
              },
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", maxlength: "1000", disabled: "" },
                model: {
                  value: _vm.ruleForm.otherMedicalInformation,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "otherMedicalInformation", $$v)
                  },
                  expression: "ruleForm.otherMedicalInformation",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }