import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/map' : '/proxy/api/map'

export function getSearchResultApi(data) {
  return service({
    url: `${api}/get-search-result`,
    method: 'get',
    params: data
  })
}

export function getAddressDetailsApi(data) {
  return service({
    url: `${api}/get-address-details`,
    method: 'get',
    params: data
  })
}

