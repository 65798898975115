import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/application' : '/proxy/api/applicant/application'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data || { Session: null }
  })
}

export function backNextApi(data) {
  return service({
    url: `${api}/back-next`,
    method: 'post',
    data: data
  })
}

export function onApplicantChangeApi(data) {
  return service({
    url: `${api}/on-applicant-change`,
    method: 'post',
    data: data
  })
}

export function submitApi(data) {
  return service({
    url: `${api}/submit`,
    method: 'post',
    data: data
  })
}

export function freshSidebarApi(data) {
  return service({
    url: `${api}/fresh-sidebar`,
    method: 'post',
    data: data
  })
}

export function onFormDataSaveApi(data) {
  return service({
    url: `${api}/on-form-data-save`,
    method: 'post',
    data: data
  })
}

export function getAllGeoCountriesApi(data) {
  return service({
    url: `${api}/get-all-geo-countries`,
    method: 'get',
    params: data
  })
}

export function saveFormDataApi(data) {
  return service({
    url: `${api}/save-form-data`,
    method: 'post',
    data: data
  })
}

export function getAuStatesApi(data) {
  return service({
    url: `${api}/get-au-states`,
    method: 'get',
    params: data
  })
}

export function getStatesApi(data) {
  return service({
    url: `${api}/get-states`,
    method: 'get',
    params: data
  })
}

export function getThankYouDocumentIdListApi(data) {
  return service({
    url: `${api}/get-thank-you-document-id-list`,
    method: 'get',
    params: data
  })
}

export function generateRepoortTokenApi(data) {
  return service({
    url: `${api}/generate-report-token`,
    method: 'get',
    params: data
  })
}

export function showMessageApi(data) {
  return service({
    url: `${api}/get-show-message`,
    method: 'get',
    params: data
  })
}
