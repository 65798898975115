var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "documnetGalleryUpload" },
    [
      _vm.imgItem
        ? _c("div", { staticClass: "DocumentGalleryBox" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.removeLoding,
                    expression: "removeLoding",
                  },
                ],
                staticClass: "DocumentGalleryItem",
              },
              [
                _c(
                  "div",
                  { staticClass: "img" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "140px", height: "200px" },
                      attrs: {
                        alt: "document",
                        src: _vm.imgItem.thumbUrl,
                        fit: "cover",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openImg(_vm.imgItem.docurl)
                        },
                      },
                    }),
                    _vm.canDelete
                      ? _c("i", {
                          staticClass: "el-icon-error",
                          style: {
                            color:
                              _vm.iconColor === "red" ? "red" : _vm.iconColor,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeDocuments(_vm.imgItem)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(_vm._s(_vm.fileName)),
                ]),
              ]
            ),
          ])
        : [
            _vm.defaultImg || _vm.loadingCover
              ? _c(
                  "el-row",
                  [
                    _c("el-col", [
                      _vm.loadingCover
                        ? _c("div", { staticClass: "img-box" }, [
                            _c("img", {
                              staticStyle: { "max-width": "200px" },
                              attrs: { src: _vm.loadingCover, alt: "" },
                            }),
                            _c("div", { staticClass: "upload-test" }, [
                              _c("div", [
                                _c("p", { staticClass: "color-white" }, [
                                  _vm._v(_vm._s(_vm.notPassFileName)),
                                ]),
                                _c("p", { staticClass: "color-white" }, [
                                  _vm._v(_vm._s(_vm.notPassFileSize) + "KB"),
                                ]),
                              ]),
                              _c("div", [
                                _c("p", { staticClass: "color-white" }, [
                                  _vm._v(
                                    "Uploading " + _vm._s(_vm.processLen) + "% "
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "fa fa-circle-o-notch fa-pulse",
                                  }),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      [
                        _vm.fileTypePass
                          ? _c(
                              "el-upload",
                              {
                                ref: "upload",
                                staticClass: "upload-demo",
                                attrs: {
                                  drag: "",
                                  action: "#",
                                  "http-request": _vm.uploadFile,
                                  "before-remove": _vm.cancelUpload,
                                  "show-file-list": false,
                                  "on-change": _vm.uploadChange,
                                  "on-progress": _vm.uploadProgress,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "column",
                                      height: "80px",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          height: "20px",
                                          "line-height": "20px",
                                          "font-size": "13px",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [
                                        _vm._v("Drag & Drop your files or"),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "browse",
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [_vm._v("Browser")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          height: "20px",
                                          "line-height": "18px",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Max file size: 20MB. Acceptable formats: jpeg, jpg, png, pdf, or heic."
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c("div", [
                              _c("div", { staticClass: "not-pass-box" }, [
                                _c("div", { staticClass: "not-pass-content" }, [
                                  _c("div", { staticClass: "content1" }, [
                                    _c("i", {
                                      staticClass: "el-icon-error",
                                      on: {
                                        click: function ($event) {
                                          _vm.fileTypePass = !_vm.fileTypePass
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "content2" }),
                                  _c("div", { staticClass: "content3" }, [
                                    _c("p", { staticClass: "color-white" }, [
                                      _vm._v(
                                        "File size exceed 20MB or file type is invalid"
                                      ),
                                    ]),
                                    _c("p", { staticClass: "color-pink" }, [
                                      _vm._v(
                                        "Expects jpeg, jpg, png, pdf, or heic"
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }