<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import { continueApi, preparationApi, saveFormDataApi } from '@/api/RequiredDeclarationNAB'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'

export default {
  name: 'RequiredDeclarationsNAB',
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  data() {
    return {
      applicantDeclaration: {},
      currentFormSection: {},
      ruleForm: {
        haveClosePersonalRelationship: '',
        closePersonalRelationshipDetails: '',
        haveOutsideBusinessInterests: '',
        outsideBusinessInterestsDetails: '',
        haveSecondJobs: '',
        secondJobsDetails: '',
        haveDisciplinaryProcess: '',
        disciplinaryProcessDetails: '',
        haveGovernmentOfficials: '',
        governmentOfficialsDetails: '',
        haveCriminalMatters: '',
        criminalMattersDetails: '',
        haveBankruptcy: '',
        bankruptcyDetails: ''
      },
      id: 0,
      rules: {
        haveClosePersonalRelationship: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        closePersonalRelationshipDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        haveOutsideBusinessInterests: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        outsideBusinessInterestsDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        haveSecondJobs: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        secondJobsDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        haveDisciplinaryProcess: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        disciplinaryProcessDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        haveGovernmentOfficials: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        governmentOfficialsDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        haveCriminalMatters: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        criminalMattersDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        haveBankruptcy: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        bankruptcyDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      device: state => state.setting.device,
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'RequiredDeclarationsNAB')
      // }
      this.allLoading = true
      const params = {
        Session: this.Session,
        ApplicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.currentFormSection = data.currentFormSection
        this.ruleForm.haveClosePersonalRelationship = data.haveClosePersonalRelationship
        this.ruleForm.haveOutsideBusinessInterests = data.haveOutsideBusinessInterests
        this.ruleForm.haveSecondJobs = data.haveSecondJobs
        this.ruleForm.haveDisciplinaryProcess = data.haveDisciplinaryProcess
        this.ruleForm.haveGovernmentOfficials = data.haveGovernmentOfficials
        this.ruleForm.haveCriminalMatters = data.haveCriminalMatters
        this.ruleForm.haveBankruptcy = data.haveBankruptcy

        this.requiredDeclarationNab = data.requiredDeclarationNab || {}
        this.id = data.requiredDeclarationNab ? data.requiredDeclarationNab.id : 0
        this.ruleForm.closePersonalRelationshipDetails = data.requiredDeclarationNab ? this.requiredDeclarationNab.closePersonalRelationshipDetails : ''
        this.ruleForm.outsideBusinessInterestsDetails = data.requiredDeclarationNab ? this.requiredDeclarationNab.outsideBusinessInterestsDetails : ''
        this.ruleForm.secondJobsDetails = data.requiredDeclarationNab ? this.requiredDeclarationNab.secondJobsDetails : ''
        this.ruleForm.disciplinaryProcessDetails = data.requiredDeclarationNab ? this.requiredDeclarationNab.disciplinaryProcessDetails : ''
        this.ruleForm.governmentOfficialsDetails = data.requiredDeclarationNab ? this.requiredDeclarationNab.governmentOfficialsDetails : ''
        this.ruleForm.criminalMattersDetails = data.requiredDeclarationNab ? this.requiredDeclarationNab.criminalMattersDetails : ''
        this.ruleForm.bankruptcyDetails = data.requiredDeclarationNab ? this.requiredDeclarationNab.bankruptcyDetails : ''
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    Change(val, type) {
      val === 'No' ? this.ruleForm[type] = '' : ''
      this.SaveFormData()
    },

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            currentFormSection: this.currentFormSection
          }
          params.requiredDeclarationNab = this.requiredDeclarationNab
          params.requiredDeclarationNab.id = this.id

          params.haveClosePersonalRelationship = this.ruleForm.haveClosePersonalRelationship
          params.requiredDeclarationNab.haveClosePersonalRelationship = this.ruleForm.haveClosePersonalRelationship === 'Yes'
          params.requiredDeclarationNab.closePersonalRelationshipDetails = this.ruleForm.closePersonalRelationshipDetails

          params.haveOutsideBusinessInterests = this.ruleForm.haveOutsideBusinessInterests
          params.requiredDeclarationNab.haveOutsideBusinessInterests = this.ruleForm.haveOutsideBusinessInterests === 'Yes'
          params.requiredDeclarationNab.outsideBusinessInterestsDetails = this.ruleForm.outsideBusinessInterestsDetails

          params.haveSecondJobs = this.ruleForm.haveSecondJobs
          params.requiredDeclarationNab.haveSecondJobs = this.ruleForm.haveSecondJobs === 'Yes'
          params.requiredDeclarationNab.secondJobsDetails = this.ruleForm.secondJobsDetails

          params.haveDisciplinaryProcess = this.ruleForm.haveDisciplinaryProcess
          params.requiredDeclarationNab.haveDisciplinaryProcess = this.ruleForm.haveDisciplinaryProcess === 'Yes'
          params.requiredDeclarationNab.disciplinaryProcessDetails = this.ruleForm.disciplinaryProcessDetails

          params.haveGovernmentOfficials = this.ruleForm.haveGovernmentOfficials
          params.requiredDeclarationNab.haveGovernmentOfficials = this.ruleForm.haveGovernmentOfficials === 'Yes'
          params.requiredDeclarationNab.governmentOfficialsDetails = this.ruleForm.governmentOfficialsDetails

          params.haveCriminalMatters = this.ruleForm.haveCriminalMatters
          params.requiredDeclarationNab.haveCriminalMatters = this.ruleForm.haveCriminalMatters === 'Yes'
          params.requiredDeclarationNab.criminalMattersDetails = this.ruleForm.criminalMattersDetails

          params.haveBankruptcy = this.ruleForm.haveBankruptcy
          params.requiredDeclarationNab.haveBankruptcy = this.ruleForm.haveBankruptcy === 'Yes'
          params.requiredDeclarationNab.bankruptcyDetails = this.ruleForm.bankruptcyDetails

          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    SaveFormData: _.debounce(
      function() {
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId
        }
        params.requiredDeclarationNab = this.requiredDeclarationNab

        params.haveClosePersonalRelationship = this.ruleForm.haveClosePersonalRelationship
        params.requiredDeclarationNab.haveClosePersonalRelationship = this.ruleForm.haveClosePersonalRelationship === 'Yes'
        params.requiredDeclarationNab.closePersonalRelationshipDetails = this.ruleForm.closePersonalRelationshipDetails

        params.haveOutsideBusinessInterests = this.ruleForm.haveOutsideBusinessInterests
        params.requiredDeclarationNab.haveOutsideBusinessInterests = this.ruleForm.haveOutsideBusinessInterests === 'Yes'
        params.requiredDeclarationNab.outsideBusinessInterestsDetails = this.ruleForm.outsideBusinessInterestsDetails

        params.haveSecondJobs = this.ruleForm.haveSecondJobs
        params.requiredDeclarationNab.haveSecondJobs = this.ruleForm.haveSecondJobs === 'Yes'
        params.requiredDeclarationNab.secondJobsDetails = this.ruleForm.secondJobsDetails

        params.haveDisciplinaryProcess = this.ruleForm.haveDisciplinaryProcess
        params.requiredDeclarationNab.haveDisciplinaryProcess = this.ruleForm.haveDisciplinaryProcess === 'Yes'
        params.requiredDeclarationNab.disciplinaryProcessDetails = this.ruleForm.disciplinaryProcessDetails

        params.haveGovernmentOfficials = this.ruleForm.haveGovernmentOfficials
        params.requiredDeclarationNab.haveGovernmentOfficials = this.ruleForm.haveGovernmentOfficials === 'Yes'
        params.requiredDeclarationNab.governmentOfficialsDetails = this.ruleForm.governmentOfficialsDetails

        params.haveCriminalMatters = this.ruleForm.haveCriminalMatters
        params.requiredDeclarationNab.haveCriminalMatters = this.ruleForm.haveCriminalMatters === 'Yes'
        params.requiredDeclarationNab.criminalMattersDetails = this.ruleForm.criminalMattersDetails

        params.haveBankruptcy = this.ruleForm.haveBankruptcy
        params.requiredDeclarationNab.haveBankruptcy = this.ruleForm.haveBankruptcy === 'Yes'
        params.requiredDeclarationNab.bankruptcyDetails = this.ruleForm.bankruptcyDetails

        saveFormDataApi(params).then(res => {
          if (res.data && res.data.session) {
            this.SaveFormDataSetSession(res.data.session)
          }
        })
      }, global.debounceTime)
  }
}
</script>

<template>
  <div v-loading="allLoading" class="RequiredDeclarationNabView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-divider />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-form-item prop="haveClosePersonalRelationship">
        <div style="font-weight: bold">1. Close Personal Relationships</div>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span>Do you have a Close Personal Relationship/s
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">Close Personal Relationship is defined as a regular and ongoing relationship (connection) that is intimate, familial or financial.<br>
                  <br>
                  <br>
                  <span style="font-style: italic;">
                    Examples of relationships requiring disclosure include:<br>
                    • a close relative is a NAB client you may directly service.<br>
                    • your spouse works for NAB in a role that may overlap with your role or prospective role<br>
                    • your parent works in the same line of business as you/you will be<br>
                    • any role where a Close Personal Relationship exists which could influence or be perceived to influence employment or business outcomes.<br>
                  </span>
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
              with another NAB employee, customer, supplier or regulator?
              <br>
              <span style="font-style: italic;">
                This is limited to situations where there may be an actual or potential conflict of Interest
              </span>
            </span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group
              v-model="ruleForm.haveClosePersonalRelationship"
              @change="Change($event,'closePersonalRelationshipDetails')"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.haveClosePersonalRelationship==='Yes'"
        prop="closePersonalRelationshipDetails"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              <div style="display: flex;">
                <div style="min-width: 38px !important;">Yes -</div>
                <div>for each situation please provide the name of the other party,
                  connected entity if
                  applicable, nature of relationship and outline your position or prospective role at NAB.
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.closePersonalRelationshipDetails"
                type="textarea"
                maxlength="1000"
                :autosize="{ minRows: 2 }"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item prop="haveOutsideBusinessInterests">
        <div style="font-weight: bold">2. Outside Business Interests</div>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span>Do you have any Outside Business Interests (OBI) which requires disclosure?
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  <span style="font-style: italic;">
                    Examples include
                    <br>• Directorships, officeholder (incl Treasurer) of a business or organisation
                    <br>• Material investments (10%+) in private companies which conduct commercial activities
                    <br>A holder or an authorised representative of an Australian Financial Services Licence (AFSL) or Australian Credit Licence (ACL) not connected to NAB?
                  </span>
                  <br>
                  <br>
                  <br>Interests where disclosure not required:
                  <br>Roles at external entities which do not conduct any commercial enterprise and where the only positions within that entity are held by the Employee and members of their family or extended family (eg. SMSF);
                  <br>Directorships, positions at non-profit companies, or associations engaged in community-based activities (sporting, schools, religious, etc), that do not have a banking/client relationship with NAB; and
                  <br>Investments in private companies where the investment is nominal or the company is a shelf company, small family company (where the only positions within that company are held by the Employee and members of their family or extended family) or family trust.
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
            </span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group
              v-model="ruleForm.haveOutsideBusinessInterests"
              @change="Change($event,'outsideBusinessInterestsDetails')"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.haveOutsideBusinessInterests==='Yes'"
        prop="outsideBusinessInterestsDetails"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              class="label"
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <div style="display: flex;">
                <div style="min-width: 38px !important;">Yes -</div>
                <div>for each OBI please provide the name of the connected entity, your
                  role, nature of the business and outline any actual, potential or perceived conflicts of Interest
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.outsideBusinessInterestsDetails"
                type="textarea"
                maxlength="1000"
                :autosize="{ minRows: 2 }"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item prop="haveSecondJobs">
        <div style="font-weight: bold">3. Second Jobs</div>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span>Do you have a Second Job which requires disclosure?
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  You must disclose any role with another organisation where you are receiving remuneration.
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
            </span>

          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group v-model="ruleForm.haveSecondJobs" @change="Change($event,'secondJobsDetails')">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.haveSecondJobs==='Yes'"
        prop="secondJobsDetails"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              <div style="display: flex;">
                <div style="min-width: 38px !important;">Yes -</div>
                <div>please provide details including role overview, organisation, hours
                  and remuneration.
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.secondJobsDetails"
                type="textarea"
                maxlength="1000"
                :autosize="{ minRows: 2 }"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item prop="haveDisciplinaryProcess">
        <div style="font-weight: bold">4. Disciplinary Processes</div>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span>Are you currently or have you been subject to any disciplinary processes by a government regulator (eg. ASIC, APRA) or professional body?</span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group
              v-model="ruleForm.haveDisciplinaryProcess"
              @change="Change($event,'disciplinaryProcessDetails')"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.haveDisciplinaryProcess==='Yes'"
        prop="disciplinaryProcessDetails"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              Yes - please provide details
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.disciplinaryProcessDetails"
                type="textarea"
                maxlength="1000"
                :autosize="{ minRows: 2 }"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item prop="haveGovernmentOfficials">
        <div style="font-weight: bold">5. Government Official</div>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span>Are you a Government Official or do you have Close Personal Relationship with any of the following persons?
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  • An elected member of any government, state or local municipality in Australia or internationally.<br>
                  • An Executive in a prominent public position of a public service of any Government, State or Territory in Australia or internationally. e.g. Commissioner of Australian Taxation Office, Secretary Department of Education.<br>
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
            </span>

          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group
              v-model="ruleForm.haveGovernmentOfficials"
              @change="Change($event,'governmentOfficialsDetails')"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.haveGovernmentOfficials==='Yes'"
        prop="governmentOfficialsDetails"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              <div style="display: flex;">
                <div style="min-width: 38px !important;">Yes -</div>
                <div>provide details including the name of the person, their position,
                  name of the government body, location of their position and the nature of your relationship.
                </div>
              </div>
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.governmentOfficialsDetails"
                type="textarea"
                maxlength="1000"
                :autosize="{ minRows: 2 }"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item prop="haveCriminalMatters">
        <div style="font-weight: bold">6. Criminal Matters</div>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span>Do you have any:</span>
            <ul>
              <li>Disclosable Court outcomes
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">
                    A Disclosable Court outcome can include, but is not limited
                    to:
                    <ol style="list-style-type: lower-alpha">
                      <li>court convictions;</li>
                      <li>findings of guilt with no conviction;</li>
                      <li>court appearances;</li>
                      <li>good behaviour bonds or other court orders;</li>
                      <li>pending matters awaiting court hearing;</li>
                      <li>traffic offence history.</li>
                    </ol>
                  </div>
                  <i class="el-icon-info" />
                </el-tooltip>; or</li>
              <li>Being investigated for a
                Criminal Offence
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">
                    A criminal offence is an offence (or crime) in breach of the law.
                  </div>
                  <i class="el-icon-info" />
                </el-tooltip>
                that is yet to
                be resolved (including but not
                limited to charge/s); or
              </li>
              <li>Being investigated by a state or
                federal government body?
              </li>
            </ul>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group
              v-model="ruleForm.haveCriminalMatters"
              @change="Change($event,'criminalMattersDetails')"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.haveCriminalMatters==='Yes'"
        prop="criminalMattersDetails"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              Yes - please provide details
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.criminalMattersDetails"
                type="textarea"
                maxlength="1000"
                :autosize="{ minRows: 2 }"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item prop="haveBankruptcy">
        <div style="font-weight: bold">7. Bankruptcy</div>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span>Are you:
              <ul>
                <li>currently bankrupt?</li>
                <li>been bankrupt in the last 7 years? or</li>
                <li>likely to become bankrupt or currently the subject of bankruptcy proceedings?</li>
              </ul>
            </span>

          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group
              v-model="ruleForm.haveBankruptcy"
              @change="Change($event,'bankruptcyDetails')"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.haveBankruptcy==='Yes'"
        prop="bankruptcyDetails"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              Yes - provide further details
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.bankruptcyDetails"
                type="textarea"
                maxlength="1000"
                :autosize="{ minRows: 2 }"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>
      <!-- <el-button type="primary" @click="SaveFormData('ruleForm')">Save form data</el-button> -->
      <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and
        Continue
      </el-button>
    </el-form>
  </div>
</template>

<style lang="scss">
.RequiredDeclarationNabView {
  .label {
    line-height: 1.5;
    font-size: 14px;
    color: #606266;
  }

  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
