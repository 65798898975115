<script>
export default {
  name: 'OtherCountriesItem',
  props: {
    continueBtn: {
      type: Boolean,
      default: true
    },
    previousAddresses: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formConfig: {
        fullAddress: '',
        selectedCountryCode: '',
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        fromDate: null,
        toDate: null,
        isManual: false
      },
      rules: {
        // fullAddress: [
        //   { required: true, message: 'Required field!', trigger: 'change' }
        // ],
        addressLine1: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        suburb: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        fromDate: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        toDate: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ]
      },
      compliancesList: [
      ],
      openIndex: 0, // 当前打开的index
      index: 0 // 持续递增的index
    }
  },
  created() {
    if (this.previousAddresses.length > 0) {
      this.compliancesList = this.previousAddresses
      this.index = Math.max.apply(Math, this.compliancesList.map(item => { return item.index }))
      this.index = this.index ? this.index : 0
      this.openIndex = this.index && this.index !== 0 ? this.index : 0
    }
  },
  methods: {
  }
}
</script>

<template>
  <div class="AddressForm">
    <div v-for="item in compliancesList" :key="item.index" class="box">
      <div>
        <p>
          {{ item.fullAddress }}
          ({{ item.fromDate | parseDefaultTime("{d}/{m}/{y}") }} - {{ item.toDate | parseDefaultTime("{d}/{m}/{y}") }})
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  min-height: 40px;
  padding: 0 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  cursor: no-drop;
  p {
    font-size: 14px;
    margin: 0;
  }
}
</style>
