import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/qualifications-tertiary' : '/proxy/api/applicant/qualifications-tertiary'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}
export function uploadApi(data) {
  return service({
    url: `${api}/upload`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export function removeDocumentsApi(data) {
  return service({
    url: `${api}/remove-documents`,
    method: 'post',
    data: data
  })
}
export function otherNameOnChangeApi(data) {
  return service({
    url: `${api}/has-other-name-on-change`,
    method: 'post',
    data: data
  })
}
export function tertiaryQualificationOnChangeApi(data) {
  return service({
    url: `${api}/has-tertiary-qualification-on-change`,
    method: 'post',
    data: data
  })
}
export function continueApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}
export function saveFormDataApi(data) {
  return service({
    url: `${api}/save-form-data`,
    method: 'post',
    data: data
  })
}
