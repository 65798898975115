var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RegisteredTaxAgent" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticStyle: { margin: "24px 0" },
          attrs: {
            model: _vm.ruleForm,
            "label-width": "50%",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "label",
                  attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                },
                [_vm._v(" Are you a Registered Tax Agent? ")]
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "isRegisteredTaxAgent" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.ruleForm.isRegisteredTaxAgent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "isRegisteredTaxAgent",
                                $$v
                              )
                            },
                            expression: "ruleForm.isRegisteredTaxAgent",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.isRegisteredTaxAgent === "Yes"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "TPBRegistrationNumber",
                    label: "What is your TPB Registration Number?",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.tpbRegistrationNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "tpbRegistrationNumber", $$v)
                      },
                      expression: "ruleForm.tpbRegistrationNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }