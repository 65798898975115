<script>
import {
  preparationApi,
  submitAgreementApi,
  createAgreementApi,
  createButtonApi,
  continueApi,
  attemptSubmitAgreementApi
} from '@/api/AdobeSign'
import { mapState } from 'vuex'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
import SubmittingModal from '@/views/Edit/AdobeSign/Modals/submittingModal.vue'
export default {
  name: 'AdobeSignView',
  components: { SubmittingModal },
  inject: ['Submit', 'ContinueSetSession'],
  data() {
    return {
      agreementId: '',
      agreementSigningURL: '',
      agreementStatus: '',
      isWait: false,
      validationMessage: '',
      currentFormSection: {},
      expressionShow: true,
      loading: false,
      allLoading: false,
      timer: null,
      isSubmitted: false,
      isSubmitting: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    Intro() {
      return this.preparation.intro
    },
    show() {
      // not Session.Current.IsConfirmed
      // console.log('this.Session.current.isConfirmed===>', this.Session.current.isConfirmed)
      return !this.Session.current.isConfirmed
    },
    IsWaitShow() {
      return this.isWait
    },
    AgreementSigningURLShow() {
      return this.agreementSigningURL
    },
    SessionCurrentIsConfirmed() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'AdobeSign')
      // }
      this.allLoading = true
      const params = {
        Intro: this.preparation.intro,
        Session: this.Session
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.agreementId = data.agreementId
        this.agreementSigningURL = data.agreementSigningURL
        this.agreementStatus = data.agreementStatus
        this.isWait = data.isWait
        this.currentFormSection = data.currentFormSection
        this.isSubmitted = data.isSubmitted
        this.ContinueSetSession(res.data.session)
        this.AgreementSigningURLShow && this.iframeLoad()
        if (!this.show && this.isWait) {
          this.CreateAgreement()
        }
        this.allLoading = false
      }).catch(error => {
        this.validationMessage = error
        this.allLoading = false
      })
    },
    async SubmitAgreement() {
      const params = {
        session: this.Session,
        agreementId: this.agreementId,
        currentFormSection: this.currentFormSection
      }
      await submitAgreementApi(params).then(res => {
        this.ContinueSetSession(res.data.session)
      })
    },
    async CreateAgreement() {
      const params = {
        session: this.Session
      }
      await createAgreementApi(params).then(res => {
        this.agreementId = res.data.agreementId
        this.agreementSigningURL = res.data.agreementSigningURL
        this.isWait = false
        this.iframeLoad()
      })
    },
    async CreateButton() {
      const params = {
        session: this.Session
      }
      await createButtonApi(params).then(res => {
        this.agreementId = res.data.agreementId
        this.agreementSigningURL = res.data.agreementSigningURL
      })
    },
    async Continue() {
      const params = {
        session: this.Session,
        currentFormSection: this.currentFormSection
      }
      await continueApi(params).then(res => {
        this.ContinueSetSession(res.data.session)
      })
    },
    iframeLoad() {
      this.loading = true
      this.$nextTick(() => {
        const iframe = this.$refs.Iframe
        if (iframe.attachEvent) { // IE
          iframe.attachEvent('onload', () => { this.loading = false })
        } else { // 非IE
          iframe.onload = () => { this.loading = false }
        }
        this.launchOnCheckingTimer()
      })
    },
    launchOnCheckingTimer() {
      if (!this.isSubmitted) {
        clearInterval(this.timer)
        this.timer = null
        this.timer = setInterval(async() => {
          const params = {
            session: this.Session,
            agreementId: this.agreementId,
            currentFormSection: this.currentFormSection
          }
          await attemptSubmitAgreementApi(params).then(res => {
            const { data } = res
            // console.log(res)
            if (data.goSubmit) {
              this.isSubmitting = true
              clearInterval(this.timer)
              this.SubmitAgreement()
            }
          })
        }, 3000)
      }
    }
  }
}
</script>

<template>
  <div class="AdobeSign">
    <div class="section-header">{{ sectionTitle }}</div>

    <div v-if="expressionShow && show" class="expression">
      <div class="expression-left-box">
        <i class="el-icon-warning" />
        <span style="margin-left: 10px">&nbsp;{{ validationMessage || 'There was an error preparing your documents for signing.' }}</span>
      </div>
      <i class="el-icon-close" @click="expressionShow = !expressionShow" />
    </div>

    <div v-if="!show">
      <p v-if="IsWaitShow"><i class="fa fa-spinner fa-pulse" /> Please wait while we prepare your documents for signing...</p>
      <div v-else class="mt-10 mb-10" v-html="introduction" />
      <div v-if="AgreementSigningURLShow">
        <div v-if="!loading && !isSubmitted" class="waitingBox">
          <i class="fa fa-spin fa-circle-o-notch" />
          <span>Please follow the instructions and submit your signature below. The system is awaiting for your signature.</span>
        </div>
        <div v-loading="loading" style="margin-top:10px; padding: 5px; height:630px; border: #206caa dashed 2px; border-radius: 5px;">
          <div class="instruction">
            <span>Please scroll down to complete your signature. Zoom out to check the document if you're using a mobile screen.</span>
          </div>
          <iframe
            id="iframe"
            ref="Iframe"
            :src="agreementSigningURL"
            style="height:580px; margin-top: 5px; border-radius: 5px;"
            width="100%"
            frameborder="0"
          />
        </div>
      </div>
    </div>

    <!-- <p class="cursor-pointer" @click="CreateAgreement">CreateAgreement</p> -->
    <div class="btn-bottom">
      <el-button v-if="show" type="primary" :loading="endAllRequests" @click="Continue">Continue</el-button>
    </div>
    <SubmittingModal
      :show.sync="isSubmitting"
    />
  </div>
</template>

<style lang="scss" scoped>
.waitingBox{
  background: #206caa;
  color: #f8f9fa;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  span{
    padding-left: 8px;
  }
}
.instruction{
  color: #fff;
  background: #e9a100;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  span{
    padding-left: 8px;
  }
}
.expression {
  color: #fff;
  background: #e9a100;
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
}
.btn-bottom {
  margin-top: 40px;
}
.fa-spinner {
  font-size: 20px;
}
</style>
