var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header", class: _vm.classObj },
    [
      _c(
        "el-row",
        { attrs: { type: "flex", gutter: 20, justify: "center" } },
        [
          _c(
            "el-col",
            {
              staticClass: "logo-box",
              attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 13 },
            },
            [
              _vm.device === "mobile"
                ? _c("i", {
                    staticClass: "fa fa-solid fa-bars icon",
                    on: { click: _vm.open },
                  })
                : _vm._e(),
              _c("div", { staticClass: "logo" }),
            ]
          ),
          _vm.device !== "mobile"
            ? _c(
                "el-col",
                { staticClass: "Logout", staticStyle: { width: "300px" } },
                [
                  _c("div", [
                    _c("p", { on: { click: _vm.Logout } }, [
                      _c("i", { staticClass: "fa fa-sign-out" }),
                      _vm._v(" Logout"),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }