var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "employmentOrGapForm" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        _vm._l(_vm.formData, function (item, _index) {
          return _c(
            "el-collapse-item",
            { key: _index, attrs: { title: item.collapseTitle } },
            [
              _c(
                "el-form",
                {
                  ref: `addForm${item.index}`,
                  refInFor: true,
                  attrs: {
                    model: item,
                    "label-width": "80px",
                    "label-position": "top",
                    disabled: "",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: {
                                label: item.isGap
                                  ? "When did your gap commence?"
                                  : "When did you start working for this employer?",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  value: item.dateCommenced,
                                  type: "month",
                                  format: "MM/yyyy",
                                  "value-format": "yyyy-MM-dd",
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.dateEndedShow(item.dateEnded)
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: {
                                    label: item.isGap
                                      ? "When did your gap conclude?"
                                      : "When did your employment conclude?",
                                    disabled: "",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      value: item.dateEnded,
                                      type: "month",
                                      format: "MM/yyyy",
                                      "value-format": "yyyy-MM-dd",
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  item.isGap
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Gap Reason" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.gapReason,
                                  callback: function ($$v) {
                                    _vm.$set(item, "gapReason", $$v)
                                  },
                                  expression: "item.gapReason",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Employer / Business Name (Full Name).",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.businessFullName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "businessFullName", $$v)
                                  },
                                  expression: "item.businessFullName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Type of Employment:" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.typeOfEmployment,
                                  callback: function ($$v) {
                                    _vm.$set(item, "typeOfEmployment", $$v)
                                  },
                                  expression: "item.typeOfEmployment",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _vm._v(
                                    "Are you still employed at this company?"
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: item.isStillEmployed,
                                    callback: function ($$v) {
                                      _vm.$set(item, "isStillEmployed", $$v)
                                    },
                                    expression: "item.isStillEmployed",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Yes"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("No"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.isStillEmployed ? "Is " : "Was "
                                    ) + " the Employment Paid?"
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: item.wasPaidEmployment,
                                    callback: function ($$v) {
                                      _vm.$set(item, "wasPaidEmployment", $$v)
                                    },
                                    expression: "item.wasPaidEmployment",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Yes"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("No"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          !item.isStillEmployed
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "Is the Employer still in business?"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: item.isEmployerStillinBusiness,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "isEmployerStillinBusiness",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.isEmployerStillinBusiness",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true } },
                                        [_vm._v("Yes")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: false } },
                                        [_vm._v("No")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.isStillEmployed
                            ? _c(
                                "el-form-item",
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _vm._v(
                                        "Can we contact this Employer to verify your Employment?"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: item.canContactEmployer,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "canContactEmployer",
                                            $$v
                                          )
                                        },
                                        expression: "item.canContactEmployer",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true } },
                                        [_vm._v("Yes")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: false } },
                                        [_vm._v("No")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("h3", [
                            _vm._v("Employer / Business Address Details"),
                          ]),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 12,
                                    xl: 12,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "City / Suburb" } },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.businessLocality,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "businessLocality",
                                              $$v
                                            )
                                          },
                                          expression: "item.businessLocality",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 24,
                                    md: 12,
                                    lg: 12,
                                    xl: 12,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "State" } },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.businessState,
                                          callback: function ($$v) {
                                            _vm.$set(item, "businessState", $$v)
                                          },
                                          expression: "item.businessState",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Country" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.businessCountry,
                                  callback: function ($$v) {
                                    _vm.$set(item, "businessCountry", $$v)
                                  },
                                  expression: "item.businessCountry",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "Employer Phone Number (Business, landline or mobile)",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.employerPhone,
                                  callback: function ($$v) {
                                    _vm.$set(item, "employerPhone", $$v)
                                  },
                                  expression: "item.employerPhone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "Email Address (Payroll / Human Resources)",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.payrollHrEmail,
                                  callback: function ($$v) {
                                    _vm.$set(item, "payrollHrEmail", $$v)
                                  },
                                  expression: "item.payrollHrEmail",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _vm._v(
                                    "What " +
                                      _vm._s(
                                        item.isStillEmployed ? "is" : "was"
                                      ) +
                                      " the title of your position under your Employer?"
                                  ),
                                ]
                              ),
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.positionTitle,
                                  callback: function ($$v) {
                                    _vm.$set(item, "positionTitle", $$v)
                                  },
                                  expression: "item.positionTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.typeOfEmployment !== "Self-Employed"
                                        ? "What " +
                                            (item.isStillEmployed
                                              ? "is "
                                              : "was ") +
                                            "your Employee Number / ID?"
                                        : "What is the ABN of your business?"
                                    )
                                  ),
                                ]
                              ),
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: item.employeeId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "employeeId", $$v)
                                  },
                                  expression: "item.employeeId",
                                },
                              }),
                            ],
                            1
                          ),
                          item.reasonForLeaving !== ""
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      "Please select your reason for leaving:",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: item.reasonForLeaving,
                                      callback: function ($$v) {
                                        _vm.$set(item, "reasonForLeaving", $$v)
                                      },
                                      expression: "item.reasonForLeaving",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.isStillEmployed
                                        ? "Are you "
                                        : "Were you "
                                    ) + " paid by a Recruitment Agency?"
                                  ),
                                ]
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: item.isPaidByRecruitment,
                                    callback: function ($$v) {
                                      _vm.$set(item, "isPaidByRecruitment", $$v)
                                    },
                                    expression: "item.isPaidByRecruitment",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("Yes"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("No"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          item.isPaidByRecruitment
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Recruitment Agency Name",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.recruitmentAgencyName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "recruitmentAgencyName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.recruitmentAgencyName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  "Recruitment Agency Phone",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    item.recruitmentAgencyPhone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "recruitmentAgencyPhone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.recruitmentAgencyPhone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  "Recruitment Agency Email",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    item.recruitmentAgencyEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "recruitmentAgencyEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.recruitmentAgencyEmail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }