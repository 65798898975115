var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RegisterView" },
    [
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "center" } },
        [
          _c("el-col", { attrs: { xs: 22, sm: 21, md: 9, lg: 9, xl: 9 } }, [
            _c(
              "div",
              { staticClass: "content-box" },
              [
                _c("div", { staticClass: "logo" }),
                _c("h2", { staticClass: "text-c" }, [
                  _vm._v("Applicant Portal"),
                ]),
                _c("div", { staticClass: "tag" }, [
                  _c("i", { staticClass: "el-icon-info" }),
                  _c("span", {
                    staticStyle: { "word-break": "break-word" },
                    domProps: { innerHTML: _vm._s(_vm.message) },
                  }),
                ]),
                _vm.isRegister
                  ? [
                      _c("h4", { staticClass: "text-c" }, [
                        _vm._v(
                          " It looks like you're new here... Please sign up for an account. "
                        ),
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "registerForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.registerForm,
                            "label-width": "100px",
                            rules: _vm.registerRules,
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "fullName", label: "Full Name" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.registerForm.fullName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "fullName", $$v)
                                  },
                                  expression: "registerForm.fullName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "email", label: "Email Address" },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.registerForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "email", $$v)
                                  },
                                  expression: "registerForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "mobilePhone",
                                label: "Mobile Phone",
                              },
                            },
                            [
                              _c("VuePhoneNumberInput", {
                                attrs: {
                                  error: _vm.error,
                                  "valid-color": _vm.validColor,
                                  color: _vm.color,
                                  "no-country-selector": _vm.noCountrySelector,
                                  "default-country-code":
                                    _vm.defaultCountryCode,
                                  "preferred-countries": _vm.preferredCountries,
                                  disabled: _vm.disabled,
                                  clearable: _vm.clearable,
                                  "show-code-on-list": _vm.showCodeOnList,
                                  "no-flags": _vm.noFlags,
                                  "border-radius": _vm.borderRadius,
                                  "no-example": _vm.noExample,
                                  translations: _vm.translations,
                                },
                                on: {
                                  update: _vm.update,
                                  "phone-number-blur": function ($event) {
                                    return _vm.PhoneNumberBlur("registerForm")
                                  },
                                },
                                model: {
                                  value: _vm.registerForm.mobilePhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.registerForm,
                                      "mobilePhone",
                                      $$v
                                    )
                                  },
                                  expression: "registerForm.mobilePhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { type: "primary" },
                          on: { click: _vm.Register },
                        },
                        [_vm._v("Register")]
                      ),
                    ]
                  : [
                      _c("h4", {
                        staticClass: "text-c",
                        domProps: { innerHTML: _vm._s(_vm.introduction) },
                      }),
                      _c(
                        "el-form",
                        {
                          ref: "loginForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.loginForm,
                            "label-width": "100px",
                            rules: _vm.passRules,
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "password", label: "Password" } },
                            [
                              _c("el-input", {
                                attrs: { type: "password" },
                                model: {
                                  value: _vm.loginForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "password", $$v)
                                  },
                                  expression: "loginForm.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { loading: _vm.LoginLoading, type: "primary" },
                          on: { click: _vm.LogIn },
                        },
                        [_vm._v("Log In")]
                      ),
                    ],
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }