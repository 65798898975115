<script>
import { mapState } from 'vuex'
import { continueApi } from '@/api/NotImplemented'
export default {
  name: 'NotImplementedView',
  inject: ['ContinueSetSession'],
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      introduction: (state) => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation
    }),
    Session() {
      return this.preparation.session
    },
    currentSection() {
      return this.preparation.session.current.formSection
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100)
    }
  },
  methods: {
    async Continue() {
      const params = {
        session: this.Session,
        currentSection: this.currentSection,
        applicantId: this.ApplicantId
      }
      await continueApi(params).then(res => {
        this.ContinueSetSession(res.data.session)
      })
    }
  }
}
</script>

<template>
  <div class="welcome">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-divider />
    <p>This feature is not implemented yet.</p>
    <p>Please continue to the next section...</p>
    <el-button v-if="ContinueBtn" type="primary" @click="Continue">Continue</el-button>
  </div>
</template>
