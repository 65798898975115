<script>
import { mapState } from 'vuex'
import OnlyUpload from '@/components/OnlyUpload'
import { preparationApi, uploadApi, removeDocumentApi, onChangeApi, continueApi } from '@/api/Identity100Points'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'Identity100Points',
  components: { OnlyUpload },
  inject: ['ContinueSetSession', 'SaveFormDataSetSession'],
  data() {
    return {
      currentFormSection: {},

      orderApplicantDocuments: [],
      ruleForm: {
        hasProvidedDocument: ''
      },
      rules: {
        hasProvidedDocument: [{ required: true, message: 'Please select a value', trigger: 'change' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    BlockName() {
      return this.preparation.session.current.formSection.blockName
    },
    Applicant() {
      return this.preparation.applicant
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    HasProvidedDocumentShow() {
      return this.ruleForm.hasProvidedDocument === 'No'
    },
    imgcanDelete() {
      // (not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100) or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100) || this.Session.current.isAdmin
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'Identity100Points')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.ruleForm.hasProvidedDocument = data.hasProvidedDocument
        this.orderApplicantDocuments = data.orderApplicantDocuments
        this.currentFormSection = data.currentFormSection
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async CloudinaryUpload_UploadResult(file) {
      const formData = new FormData()
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)
      await uploadApi(formData).then(res => {
        const { data } = res
        this.orderApplicantDocuments = data.orderApplicantDocuments
      })
    },

    async Document_Remove(item) {
      const params = {
        orderApplicantDocumentId: item.id,
        session: this.Session
      }
      await removeDocumentApi(params).then(res => {
        this.orderApplicantDocuments = []
        this.SaveFormDataSetSession(res.data.session)
      })
    },

    async OnHasProvidedDocumentChange(val) {
      const params = {
        session: this.Session,
        hasProvidedDocument: this.ruleForm.hasProvidedDocument
      }
      if (val === 'No') {
        this.orderApplicantDocuments = []
      }
      await onChangeApi(params).then(res => {
      })
    },

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (this.ruleForm.hasProvidedDocument === 'No' && this.orderApplicantDocuments.length < 1) return this.$message.error('Please upload a proof of address document before continuing')
          const params = {
            session: this.Session,
            hasProvidedDocument: this.ruleForm.hasProvidedDocument,
            currentFormSection: this.currentFormSection
          }
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    getImgcanDelete(item) {
      // (Documents.List.Current.UploadedBlockName = Session.Current.FormSection.BlockName and
      //  (not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100)) or Session.Current.IsAdmin
      return (item.uploadedBlockName === this.Session.current.formSection.blockName &&
      (!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100)) || this.Session.current.isAdmin
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="Identity100Points">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="50%"
      label-position="top"
      style="margin:24px 0"
      :disabled="allDisabled"
    >
      <el-form-item prop="hasProvidedDocument">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            <span>Have you provided a document proving your <strong>current address</strong><span> yet?</span></span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.hasProvidedDocument" @change="OnHasProvidedDocumentChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <div v-if="HasProvidedDocumentShow">
        <div class="f-s-14">Please provide a Document stating both your <strong>name</strong> and <strong>current address</strong> e.g. Utility bill, licence, bank statement.</div>
        <OnlyUpload v-if="orderApplicantDocuments.length<1 && imgcanDelete" @Document_Upload="CloudinaryUpload_UploadResult" />
        <OnlyUpload
          v-for="item in orderApplicantDocuments"
          :key="item.id"
          :img-item="item"
          :can-delete="getImgcanDelete(item)"
          @Document_Upload="CloudinaryUpload_UploadResult"
          @Document_Remove="Document_Remove"
        />
      </div>

      <el-button
        v-if="ContinueBtn" :loading="endAllRequests" type="primary" style="margin-top:20px"
        @click="Continue('ruleForm')"
      >Save and Continue</el-button>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>

</style>
