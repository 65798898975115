<script>
import { managementDirectorApi } from '@/api/pattern'
export default {
  name: 'ManagementDirectorView',
  props: {
    applicantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        director: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        directorDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        disqualified: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        disqualificationDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        financialJudgements: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        judgementsDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        declaredBankruptcy: [{ required: true, message: 'Please select a value', trigger: 'blur' }],
        bankruptcyDetails: [{ required: true, message: 'Required field!', trigger: 'blur' }]
      },
      moduleLoading: false
    }
  },
  computed: {
    DirectorShow() {
      return this.ruleForm.director === 'Yes'
    },
    DisqualifiedShow() {
      return this.ruleForm.disqualified === 'Yes'
    },
    FinancialJudgementsShow() {
      return this.ruleForm.financialJudgements === 'Yes'
    },
    DeclaredBankruptcyShow() {
      return this.ruleForm.declaredBankruptcy === 'Yes'
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }
      await managementDirectorApi(params).then((res) => {
        const { data } = res
        this.ruleForm.declaredBankruptcy = data.declaredBankruptcy
        this.ruleForm.director = data.director
        this.ruleForm.disqualified = data.disqualified
        this.ruleForm.financialJudgements = data.financialJudgements

        if (data.getManagementDirectorDetailsByApplicantId.length !== 0) {
          this.ruleForm.directorDetails = data.getManagementDirectorDetailsByApplicantId[0].directorDetails
          this.ruleForm.disqualificationDetails = data.getManagementDirectorDetailsByApplicantId[0].disqualificationDetails
          this.ruleForm.bankruptcyDetails = data.getManagementDirectorDetailsByApplicantId[0].bankruptcyDetails
          this.ruleForm.judgementsDetails = data.getManagementDirectorDetailsByApplicantId[0].judgementsDetails
        }
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="ManagementDirectorView">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="16"
          :xl="16" class="label"
        >
          Have you ever been a Director of a Pty Ltd Company? <span style="color:#F56C6C">*</span>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="24" :lg="8"
          :xl="8"
        >
          <el-form-item prop="director" style="margin-bottom: 0">
            <el-radio-group v-model="ruleForm.director" disabled>
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="DirectorShow">
        <el-form-item
          label="Please provide details of the Companies you've been a Director of"
          prop="directorDetails"
        >
          <el-input
            v-model="ruleForm.directorDetails"
            type="textarea"
            maxlength="2000"
            disabled
          />
        </el-form-item>

        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            Have you ever been disqualified from acting as a Director? <span style="color:#F56C6C">*</span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-form-item prop="disqualified" style="margin-bottom: 0">
              <el-radio-group v-model="ruleForm.disqualified" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          v-if="DisqualifiedShow"
          label="Please provide details of disqualifications"
          prop="disqualificationDetails"
        >
          <el-input
            v-model="ruleForm.disqualificationDetails"
            type="textarea"
            maxlength="2000"
            disabled
          />
        </el-form-item>

        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            Do you have any financial judgements or defaults against you in the capacity of Director?  <span style="color:#F56C6C">*</span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-form-item prop="financialJudgements" style="margin-bottom: 0">
              <el-radio-group v-model="ruleForm.financialJudgements" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          v-if="FinancialJudgementsShow"
          label="Please provide details of financial judgements"
          prop="judgementsDetails"
        >
          <el-input
            v-model="ruleForm.judgementsDetails"
            type="textarea"
            maxlength="2000"
            disabled
          />
        </el-form-item>
      </div>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="16"
          :xl="16" class="label"
        >
          Have you ever declared bankruptcy? <span style="color:#F56C6C">*</span></el-col>
        <el-col
          :xs="24" :sm="24" :md="24" :lg="8"
          :xl="8"
        >
          <el-form-item prop="declaredBankruptcy" style="margin-bottom: 0">
            <el-radio-group v-model="ruleForm.declaredBankruptcy" disabled>
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        v-if="DeclaredBankruptcyShow"
        label="Please provide bankruptcy details"
        prop="bankruptcyDetails"
      >
        <el-input
          v-model="ruleForm.bankruptcyDetails"
          type="textarea"
          maxlength="2000"
          disabled
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.ManagementDirectorView {
  .label {
    line-height: 40px;
    font-size: 14px;
    color: #606266;
  }
  ::v-deep .el-textarea.is-disabled .el-textarea__inner{
    color:black !important;
  }
}
</style>
