import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/registered-tax-agent' : '/proxy/api/applicant/registered-tax-agent'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}
export function continueApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}
export function saveFormDataApi(data) {
  return service({
    url: `${api}/save-form-data`,
    method: 'post',
    data: data
  })
}
