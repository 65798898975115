<template>
  <div :class="classObj" class="header">
    <el-row type="flex" :gutter="20" justify="center">
      <el-col
        :xs="24" :sm="24" :md="24" :lg="18"
        :xl="13"
        class="logo-box"
      >
        <i v-if="device === 'mobile'" class="fa fa-solid fa-bars icon" @click="open" />
        <div class="logo" />
      </el-col>
      <el-col v-if="device !== 'mobile'" style="width:300px" class="Logout">
        <div>
          <p @click="Logout"><i class="fa fa-sign-out" /> Logout</p>
        </div>
      </el-col>
    </el-row>
    <!-- <div class="nav-logo">
        <i v-if="device === 'mobile' && section" class="el-icon-s-unfold" @click="open" />
        <div class="logo" />
      </div>
      <div v-if="device !== 'mobile'" class="Logout">
        <p @click="Logout"><i class="fa fa-sign-out" /> Logout</p>
      </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'HeaderView',
  data() {
    return {}
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device,
      section: state => state.section.section
    }),
    classObj() {
      return {
        mobileHeader: this.device === 'mobile'
      }
    }
  },
  methods: {
    open() {
      this.$emit('navMobile', true)
    },
    Logout() {
      this.$router.push({ name: 'Login' })
      this.$store.dispatch('user/logOut')
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  height: 96px;
  width: 100%;
  background-color: #206caa;
  padding: 10px 0;
  .logo-box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo {
      background-image: url("../../../assets/imgs/Logo.svg");
      height: 96px;
      width: 177px;
      margin: 0 0 0 10px;
    }
    .icon{
     color: #fff;
     margin: 0 30px 0 30px;
     font-size: 28px;
  }
  }

    .Logout {
      position: relative;
      p {
        position: absolute;
        bottom: 0;
        right: 0;
        color: #fff;
        margin: 0;
        text-decoration: none;
        cursor: pointer;
      }
    }

}
.mobileHeader {
  .header-main {
    padding: 10px 20px;
    .nav-logo{
      display: flex;
      align-items: center;
      .el-icon-s-unfold{
        margin: 0 16px 0 0;
        color: #fff;
        font-size: 20px;
      }
    }
  }
}
</style>
