<script>
import * as VuePhoneNumberInput from '@/components/phoneNumber/vue-phone-number-input/vue-phone-number-input.umd'
import '@/components/phoneNumber/vue-phone-number-input/vue-phone-number-input.css'
import { registerApi } from '@/api/user'
export default {
  name: 'RegisterView',
  components: { VuePhoneNumberInput },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    error: {
      // 校验失败
      type: Boolean,
      default: false
    },
    disabled: {
      // 禁用
      type: Boolean,
      default: false
    },
    clearable: {
      // 是否可清空
      type: Boolean,
      default: false
    },
    validColor: {
      // 验证通过的颜色
      type: String,
      default: 'yellowgreen'
    },
    color: {
      // 默认外观
      type: String,
      default: 'dodgerblue'
    },
    translations: {
      // 自定义文案
      type: Object,
      default: function() {
        return {
          countrySelectorLabel: '',
          countrySelectorError: '',
          phoneNumberLabel: '',
          example: 'example'
        }
      }
    },
    noCountrySelector: {
      // 是否不使用国家选择器，默认使用
      type: Boolean,
      default: false
    },
    defaultCountryCode: {
      type: String,
      default: 'AU'
    },
    preferredCountries: {
      // 默认展示在前面的国家，数组格式，值是国家代码的集合,如中国“CN”
      type: Array,
      default: () => ['AU', 'NZ']
    },
    borderRadius: {
      type: Number,
      default: 4
    },
    showCodeOnList: {
      // 是否在国家选择器内展示国际号
      type: Boolean,
      default: true
    },
    noFlags: {
      // 是否在国家选择器内展示国家的国旗
      type: Boolean,
      default: false
    },
    noExample: {
      // 是否展示输入示例
      type: Boolean,
      default: true
    }
  },
  data() {
    const validateMobilePhone = (rule, value, callback) => {
      if (this.isValid) {
        callback()
      } else if (!this.isValid && !this.registerForm.mobilePhone) {
        callback()
      } else {
        callback(new Error('Please enter a valid phone number'))
      }
    }
    return {
      isValid: false,
      introduction: '',
      registerForm: {
        fullName: '',
        email: '',
        mobilePhone: ''
      },
      loginForm: {
        email: '',
        userId: 0,
        password: ''
      },
      registerRules: {
        fullName: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Required field!', trigger: 'blur' },
          { type: 'email', message: 'Email is not valid!', trigger: 'blur' }
        ],
        mobilePhone: [{ required: false, validator: validateMobilePhone, trigger: 'change' }]
      },
      passRules: {
        password: [
          { required: true, message: 'Password is required!', trigger: 'blur' }
        ]
      },
      isRegister: true,
      message: "We couldn't find that email address.",
      LoginLoading: false,
      phoneFormat: {}
    }
  },
  created() {
    this.registerForm.email = this.$route.params.emailAddress
  },
  methods: {
    Register() {
      this.$refs.registerForm.validate(async(valid) => {
        if (valid) {
          const params = {
            email: this.registerForm.email,
            fullName: this.registerForm.fullName,
            mobilePhone: this.phoneFormat.formattedNumber
          }
          await registerApi(params).then(res => {
            const { data } = res
            this.loginForm.userId = data.applicantUserId
            this.loginForm.email = this.registerForm.email
            this.introduction = data.instructions.replace('/n', '<br/>')
            this.isRegister = false
            this.message = data.alertText
          })
        }
      })
    },
    LogIn() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.LoginLoading = true
          this.$store.dispatch('user/login', this.loginForm).then(() => {
            this.$router.push({ path: '/application' })
          })
          this.LoginLoading = false
        }
      })
    },
    update(allValue) {
      this.phoneFormat = allValue
      if (allValue.formatNational) {
        this.$nextTick(() => {
          this.registerForm.mobilePhone = allValue.formatNational
        })
      } else {
        this.$nextTick(() => {
          this.registerForm.mobilePhone = allValue.phoneNumber
        })
      }
      if (!allValue.phoneNumber) {
        this.isValid = true
      } else {
        this.isValid = allValue.isValid
      }
    },

    PhoneNumberBlur(formName) {
      this.$refs[formName].validateField(['mobilePhone'])
    }
  }
}
</script>
<template>
  <div class="RegisterView">
    <el-row type="flex" class="row-bg" justify="center">
      <el-col
        :xs="22" :sm="21" :md="9" :lg="9"
        :xl="9"
      >
        <div class="content-box">
          <div class="logo" />
          <h2 class="text-c">Applicant Portal</h2>
          <!-- <el-tag class="tag text-c" color="#007aad">
            <i class="el-icon-info" />
            <span class="tag-group__title">{{ message }}</span>
          </el-tag> -->
          <div class="tag">
            <i class="el-icon-info" />
            <span style="word-break: break-word;" v-html="message" />
          </div>
          <template v-if="isRegister">
            <h4 class="text-c">
              It looks like you're new here... Please sign up for an account.
            </h4>
            <el-form
              ref="registerForm"
              :model="registerForm"
              label-width="100px"
              class="demo-ruleForm"
              :rules="registerRules"
              label-position="top"
            >
              <el-form-item prop="fullName" label="Full Name">
                <el-input v-model="registerForm.fullName" />
              </el-form-item>
              <el-form-item prop="email" label="Email Address">
                <el-input v-model="registerForm.email" />
              </el-form-item>
              <el-form-item prop="mobilePhone" label="Mobile Phone">
                <VuePhoneNumberInput
                  v-model="registerForm.mobilePhone"
                  :error="error"
                  :valid-color="validColor"
                  :color="color"
                  :no-country-selector="noCountrySelector"
                  :default-country-code="defaultCountryCode"
                  :preferred-countries="preferredCountries"
                  :disabled="disabled"
                  :clearable="clearable"
                  :show-code-on-list="showCodeOnList"
                  :no-flags="noFlags"
                  :border-radius="borderRadius"
                  :no-example="noExample"
                  :translations="translations"
                  @update="update"
                  @phone-number-blur="PhoneNumberBlur('registerForm')"
                />
              </el-form-item>
            </el-form>
            <el-button style="width: 100%" type="primary" @click="Register">Register</el-button>
          </template>
          <template v-else>
            <h4 class="text-c" v-html="introduction" />
            <el-form
              ref="loginForm"
              :model="loginForm"
              label-width="100px"
              class="demo-ruleForm"
              :rules="passRules"
              label-position="top"
            >
              <el-form-item prop="password" label="Password">
                <el-input v-model="loginForm.password" type="password" />
              </el-form-item>
            </el-form>
            <el-button style="width: 100%" :loading="LoginLoading" type="primary" @click="LogIn">Log In</el-button>
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="scss" scoped>
.RegisterView {
  height: 100%;
  background-color: #206baa;
  .content-box {
    max-width: 400px;
    padding: 48px;
    background-color: #fff;
    border-radius: 6px;
    margin: 50px auto 0;
    color: #4f575e;
    .logo {
      background-image: url("@/assets/imgs/login-logo.svg");
      height: 106px;
      width: 197px;
      margin: 0 auto;
    }
    .tag {
      width: 100%;
      color: #fff;
      background-color: #007aad;
      padding:10px 10px;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      align-items: center;
      i {
        font-size: 18px;
        display: block;
      }
      span {
        margin: 10px;
        font-size: 16px;
        display: block;
        word-break:break-all;
      }
    }
  }
}
</style>
