var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "RequiredDeclarationNabView",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, "label-position": "top" },
        },
        [
          _c("el-form-item", { staticStyle: { "margin-top": "20px" } }, [
            _c(
              "div",
              {
                staticStyle: { "font-weight": "bold", "margin-bottom": "20px" },
              },
              [_vm._v("1. Close Personal Relationships")]
            ),
            _c(
              "span",
              [
                _vm._v("Do you have a Close Personal Relationship/s "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "bottom" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "Close Personal Relationship is defined as a regular and ongoing relationship (connection) that is intimate, familial or financial."
                      ),
                      _c("br"),
                      _c("br"),
                      _c("br"),
                      _c("span", { staticStyle: { "font-style": "italic" } }, [
                        _vm._v(
                          " Examples of relationships requiring disclosure include:"
                        ),
                        _c("br"),
                        _vm._v(
                          " • a close relative is a NAB client you may directly service."
                        ),
                        _c("br"),
                        _vm._v(
                          " • your spouse works for NAB in a role that may overlap with your role or prospective role"
                        ),
                        _c("br"),
                        _vm._v(
                          " • your parent works in the same line of business as you/you will be"
                        ),
                        _c("br"),
                        _vm._v(
                          " • any role where a Close Personal Relationship exists which could influence or be perceived to influence employment or business outcomes."
                        ),
                        _c("br"),
                      ]),
                    ]),
                    _c("i", { staticClass: "el-icon-info" }),
                  ]
                ),
                _vm._v(
                  " with another NAB employee, customer, supplier or regulator? "
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.haveClosePersonalRelationship,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "haveClosePersonalRelationship",
                          $$v
                        )
                      },
                      expression: "ruleForm.haveClosePersonalRelationship",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                    _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.ruleForm.haveClosePersonalRelationship
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "Yes – for each situation please provide the name of the other party,\n            connected entity if\n            applicable, nature of relationship and outline your position or prospective role at NAB.",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      autosize: { minRows: 2 },
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.closePersonalRelationshipDetails,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "closePersonalRelationshipDetails",
                          $$v
                        )
                      },
                      expression: "ruleForm.closePersonalRelationshipDetails",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { prop: "haveOutsideBusinessInterests" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-weight": "bold",
                    "margin-bottom": "20px",
                  },
                },
                [_vm._v("2. Outside Business Interests")]
              ),
              _c(
                "span",
                [
                  _vm._v(
                    "Do you have any Outside Business Interests (OBI) which requires disclosure? "
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "bottom" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c(
                            "span",
                            { staticStyle: { "font-style": "italic" } },
                            [
                              _vm._v(" Examples include "),
                              _c("br"),
                              _vm._v(
                                "• Directorships, officeholder (incl Treasurer) of a business or organisation "
                              ),
                              _c("br"),
                              _vm._v(
                                "• Material investments (10%+) in private companies which conduct commercial activities "
                              ),
                              _c("br"),
                              _vm._v(
                                "A holder or an authorised representative of an Australian Financial Services Licence (AFSL) or Australian Credit Licence (ACL) not connected to NAB? "
                              ),
                            ]
                          ),
                          _c("br"),
                          _c("br"),
                          _c("br"),
                          _vm._v("Interests where disclosure not required: "),
                          _c("br"),
                          _vm._v(
                            "Roles at external entities which do not conduct any commercial enterprise and where the only positions within that entity are held by the Employee and members of their family or extended family (eg. SMSF); "
                          ),
                          _c("br"),
                          _vm._v(
                            "Directorships, positions at non-profit companies, or associations engaged in community-based activities (sporting, schools, religious, etc), that do not have a banking/client relationship with NAB; and "
                          ),
                          _c("br"),
                          _vm._v(
                            "Investments in private companies where the investment is nominal or the company is a shelf company, small family company (where the only positions within that company are held by the Employee and members of their family or extended family) or family trust. "
                          ),
                        ]
                      ),
                      _c("i", { staticClass: "el-icon-info" }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.ruleForm.haveOutsideBusinessInterests,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.ruleForm,
                            "haveOutsideBusinessInterests",
                            $$v
                          )
                        },
                        expression: "ruleForm.haveOutsideBusinessInterests",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("Yes"),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("No"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.ruleForm.haveOutsideBusinessInterests
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "Yes - for each OBI please provide the name of the connected entity, your\n            role, nature of the business and outline any actual, potential or perceived conflicts of Interest.",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      autosize: { minRows: 2 },
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.outsideBusinessInterestsDetails,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "outsideBusinessInterestsDetails",
                          $$v
                        )
                      },
                      expression: "ruleForm.outsideBusinessInterestsDetails",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { staticStyle: { "margin-top": "20px" } }, [
            _c(
              "div",
              {
                staticStyle: { "font-weight": "bold", "margin-bottom": "20px" },
              },
              [_vm._v("3. Second Jobs")]
            ),
            _c(
              "span",
              [
                _vm._v("Do you have a Second Job which requires disclosure? "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "bottom" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " You must disclose any role with another organisation where you are receiving remuneration. "
                      ),
                    ]),
                    _c("i", { staticClass: "el-icon-info" }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.haveSecondJobs,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "haveSecondJobs", $$v)
                      },
                      expression: "ruleForm.haveSecondJobs",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                    _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.ruleForm.haveSecondJobs
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "Yes - please provide details including role overview, organisation, hours\n            and remuneration.",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      autosize: { minRows: 2 },
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.secondJobsDetails,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "secondJobsDetails", $$v)
                      },
                      expression: "ruleForm.secondJobsDetails",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { staticStyle: { "margin-top": "20px" } }, [
            _c(
              "div",
              {
                staticStyle: { "font-weight": "bold", "margin-bottom": "20px" },
              },
              [_vm._v("4. Disciplinary Processes")]
            ),
            _c("span", [
              _vm._v(
                "Are you currently or have you been subject to any disciplinary processes by a government regulator (eg. ASIC, APRA) or professional body?"
              ),
            ]),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.haveDisciplinaryProcess,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "haveDisciplinaryProcess", $$v)
                      },
                      expression: "ruleForm.haveDisciplinaryProcess",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                    _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.ruleForm.haveDisciplinaryProcess
            ? _c(
                "el-form-item",
                { attrs: { label: "Yes - please provide details" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      autosize: { minRows: 2 },
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.disciplinaryProcessDetails,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "disciplinaryProcessDetails",
                          $$v
                        )
                      },
                      expression: "ruleForm.disciplinaryProcessDetails",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { staticStyle: { "margin-top": "20px" } }, [
            _c(
              "div",
              {
                staticStyle: { "font-weight": "bold", "margin-bottom": "20px" },
              },
              [_vm._v("5. Government Official")]
            ),
            _c(
              "span",
              [
                _vm._v(
                  "Are you a Government Official or do you have Close Personal Relationship "
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "bottom" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " • An elected member of any government, state or local municipality in Australia or internationally."
                      ),
                      _c("br"),
                      _vm._v(
                        " • An Executive in a prominent public position of a public service of any Government, State or Territory in Australia or internationally. e.g. Commissioner of Australian Taxation Office, Secretary Department of Education."
                      ),
                      _c("br"),
                    ]),
                    _c("i", { staticClass: "el-icon-info" }),
                  ]
                ),
                _vm._v(" with any of the following persons? "),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.haveGovernmentOfficials,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "haveGovernmentOfficials", $$v)
                      },
                      expression: "ruleForm.haveGovernmentOfficials",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                    _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.ruleForm.haveGovernmentOfficials
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "Yes - provide details including the name of the person, their position,\n            name of the government body, location of their position and the nature of your relationship.",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      autosize: { minRows: 2 },
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.governmentOfficialsDetails,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "governmentOfficialsDetails",
                          $$v
                        )
                      },
                      expression: "ruleForm.governmentOfficialsDetails",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { staticStyle: { "margin-top": "20px" } }, [
            _c(
              "div",
              {
                staticStyle: { "font-weight": "bold", "margin-bottom": "20px" },
              },
              [_vm._v("6. Criminal Matters")]
            ),
            _c("span", [_vm._v("Do you have any:")]),
            _c("ul", [
              _c(
                "li",
                {
                  staticStyle: { "margin-top": "10px", "line-height": "25px" },
                },
                [
                  _vm._v("Disclosable Court outcomes "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "bottom" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " A Disclosable Court outcome can include, but is not limited to: "
                          ),
                          _c(
                            "ol",
                            {
                              staticStyle: { "list-style-type": "lower-alpha" },
                            },
                            [
                              _c("li", [_vm._v("court convictions;")]),
                              _c("li", [_vm._v("court appearances;")]),
                              _c("li", [
                                _vm._v("findings of guilt with no conviction;"),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "good behaviour bonds or other court orders;"
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  "pending matters awaiting court hearing;"
                                ),
                              ]),
                              _c("li", [_vm._v("traffic offence history.")]),
                            ]
                          ),
                        ]
                      ),
                      _c("i", { staticClass: "el-icon-info" }),
                    ]
                  ),
                  _vm._v(" ; or "),
                ],
                1
              ),
              _c(
                "li",
                {
                  staticStyle: { "margin-top": "10px", "line-height": "25px" },
                },
                [
                  _vm._v("Being investigated for a Criminal Offence "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: { effect: "dark", placement: "bottom" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " A criminal offence is an offence (or crime) in breach of the law. "
                          ),
                        ]
                      ),
                      _c("i", { staticClass: "el-icon-info" }),
                    ]
                  ),
                  _vm._v(
                    " that is yet to be resolved (including but not limited to charge/s); or "
                  ),
                ],
                1
              ),
              _c(
                "li",
                {
                  staticStyle: { "margin-top": "10px", "line-height": "25px" },
                },
                [
                  _vm._v(
                    "Being investigated by a state or federal government body? "
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.haveCriminalMatters,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "haveCriminalMatters", $$v)
                      },
                      expression: "ruleForm.haveCriminalMatters",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                    _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.ruleForm.haveCriminalMatters
            ? _c(
                "el-form-item",
                { attrs: { label: "Yes - please provide details" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      autosize: { minRows: 2 },
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.criminalMattersDetails,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "criminalMattersDetails", $$v)
                      },
                      expression: "ruleForm.criminalMattersDetails",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { staticStyle: { "margin-top": "20px" } }, [
            _c(
              "div",
              {
                staticStyle: { "font-weight": "bold", "margin-bottom": "20px" },
              },
              [_vm._v("7. Bankruptcy")]
            ),
            _c("span", [
              _vm._v("Are you: "),
              _c("ul", [
                _c(
                  "li",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "line-height": "25px",
                    },
                  },
                  [_vm._v("currently bankrupt?")]
                ),
                _c(
                  "li",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "line-height": "25px",
                    },
                  },
                  [_vm._v("been bankrupt in the last 7 years? or")]
                ),
                _c(
                  "li",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "line-height": "25px",
                    },
                  },
                  [
                    _vm._v(
                      "likely to become bankrupt or currently the subject of bankruptcy proceedings?"
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.haveBankruptcy,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "haveBankruptcy", $$v)
                      },
                      expression: "ruleForm.haveBankruptcy",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: true } }, [_vm._v("Yes")]),
                    _c("el-radio", { attrs: { label: false } }, [_vm._v("No")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm.ruleForm.haveBankruptcy
            ? _c(
                "el-form-item",
                { attrs: { label: "Yes - please provide details" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "1000",
                      autosize: { minRows: 2 },
                      disabled: "",
                    },
                    model: {
                      value: _vm.ruleForm.bankruptcyDetails,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "bankruptcyDetails", $$v)
                      },
                      expression: "ruleForm.bankruptcyDetails",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }