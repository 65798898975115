<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import OnlyUpload from '@/components/OnlyUpload'
import GetAllGeoCountries from '@/components/GetAllGeoCountries'
import { preparationApi, uploadApi, removeDocumentApi, onSelectCertificateTypeApi, getIntPassportDocumentsApi, hasPassportOnChangeApi, isAusCitizenChangeApi, continueApi } from '@/api/EntitlementToWork'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'EntitlementToWorkView',
  components: { OnlyUpload, GetAllGeoCountries },
  inject: ['ContinueSetSession', 'OnApplicantChange'],
  data() {
    return {

      uploadCertificateDocumentTypeId: '',
      orderApplicantDocuments: [],

      ruleForm: {
        isAusCitizen: '',
        selectedCitizenshipCountryCode: '',
        hasPassport: '',
        passportNumber: '',
        selectedPassportCountryCode: '',
        selectedCertificateDocumentType: ''
      },
      rules: {
        isAusCitizen: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        hasPassport: [{ required: true, message: 'Please select a value', trigger: 'change' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    BlockName() {
      return this.preparation.session.current.formSection.blockName
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    CitizenshipShow() {
      return this.ruleForm.isAusCitizen === 'No'
    },
    PassportBirthCertShow() {
      return this.ruleForm.isAusCitizen === 'Yes' || this.ruleForm.selectedCitizenshipCountryCode !== ''
    },
    hasPassportTrueShow() {
      return this.ruleForm.hasPassport === 'Yes'
    },
    hasPassportFalseShow() {
      return this.ruleForm.hasPassport === 'No' && this.ruleForm.selectedCitizenshipCountryCode === 'AU'
    },
    uploadShow() {
      // HasPassport = "Yes" or HasPassport = "No" and SelectedCertificateDocumentType <> ""
      return this.ruleForm.hasPassport !== '' && this.ruleForm.selectedCitizenshipCountryCode !== ''
      // return true
    },
    buttonText() {
      // If(Session.Current.FormSection.Index < (Session.FormSections.Length - 1), "Save and Continue", "Submit")
      return this.Session.current.formSection.index <
        this.Session.formSections.length - 1
        ? 'Save and Continue'
        : 'Submit'
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'EntitlementToWork')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        applicant: this.Applicant
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.orderApplicantDocuments = data.orderApplicantDocuments || []
        this.ruleForm.selectedCitizenshipCountryCode = data.selectedCitizenshipCountryCode
        this.selectedPassportCountryCode = data.selectedPassportCountryCode
        this.ruleForm.isAusCitizen = data.isAusCitizen
        this.ruleForm.hasPassport = data.hasPassport
        this.ruleForm.passportNumber = this.Applicant.passportNumber
        this.ruleForm.selectedCertificateDocumentType = data.selectedCertificateDocumentType
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
      // if (this.orderApplicantDocuments.length > 0) {
      //   this.OnSelectCertificateType()
      // }
    },
    async Document_Upload(file) {
      const formData = new FormData()
      formData.append('UploadCertificateDocumentTypeId', this.uploadCertificateDocumentTypeId)
      formData.append('OrderApplicantId', this.Session.current.orderApplicantId)
      formData.append('BlockName', this.BlockName)
      formData.append('IsAusCitizen', this.ruleForm.isAusCitizen)
      formData.append('HasPassport', this.ruleForm.hasPassport)
      formData.append('file', file)

      await uploadApi(formData).then(res => {
        const { data } = res
        this.orderApplicantDocuments = data.getOrderApplicantDocumentsByOrderApplicantId2
      })
    },

    async Document_Remove(item) {
      const params = {
        session: this.Session,
        orderApplicantDocumentId: item.id
      }
      await removeDocumentApi(params).then(res => {
        this.orderApplicantDocuments = []
      })
    },

    async OnCitizenshipCountryChange(data) {
      this.ruleForm.selectedCitizenshipCountryCode = data.countryCode
      const params = {
        orderApplicantId: this.Session.current.orderApplicantId
      }
      await getIntPassportDocumentsApi(params).then(res => {
        const { data } = res
        this.orderApplicantDocuments = data.orderApplicantDocuments
      })
    },

    async hasPassportOnChange(val) {
      const params = {
        session: this.Session,
        applicant: this.Applicant,
        hasPassport: this.ruleForm.hasPassport,
        isAusCitizen: this.ruleForm.isAusCitizen
      }
      params.applicant.passportCountryCode = this.ruleForm.selectedPassportCountryCode
      if (val === 'Yes') {
        this.ruleForm.selectedCertificateDocumentType = ''
        this.uploadCertificateDocumentTypeId = ''
      }
      if (val === 'Yes' && this.ruleForm.isAusCitizen === 'Yes') {
        params.applicant.passportCountry = 'Australia'
        params.applicant.passportCountryCode = 'AU'
        params.applicant.passportCountryCode3 = 'AUS'
        this.ruleForm.selectedCertificateDocumentType = ''
      }
      await hasPassportOnChangeApi(params).then(res => {
        this.OnApplicantChange(params)
      })
    },

    async isAusCitizenChange() {
      const params = {
        session: this.Session,
        applicant: this.Applicant,
        isAusCitizen: this.ruleForm.isAusCitizen,
        orderApplicantId: this.Session.current.orderApplicantId
      }
      if (this.ruleForm.isAusCitizen === 'Yes') {
        params.applicant.citizenshipCountry = 'Australia'
        this.ruleForm.selectedCitizenshipCountryCode = 'AU'
        params.applicant.citizenshipCountryCode = 'AU'
        params.applicant.citizenshipCountryCode3 = 'AUS'
        this.ruleForm.hasPassport = (params.applicant.passportCountryCode !== 'AU' && params.applicant.passportCountry !== '') ? 'No' : this.ruleForm.hasPassport
      } else {
        this.ruleForm.selectedPassportCountryCode = ''
        this.ruleForm.selectedCitizenshipCountryCode = ''
        params.applicant.citizenshipCountry = ''
        params.applicant.citizenshipCountryCode = 'AU'
        params.applicant.citizenshipCountryCode3 = 'AUS'
      }
      await isAusCitizenChangeApi(params).then(res => {
        this.OnApplicantChange(params)
      })
    },

    OnPassportCountryChange(data) {
      this.ruleForm.selectedPassportCountryCode = data.countryCode
    },

    async OnSelectCertificateType() {
      const params = {
        orderApplicantId: this.Session.current.orderApplicantId,
        selectedCertificateDocumentType: this.ruleForm.selectedCertificateDocumentType
      }
      await onSelectCertificateTypeApi(params).then(res => {
        const { data } = res
        this.uploadCertificateDocumentTypeId = data.uploadCertificateDocumentTypeId
        this.orderApplicantDocuments = data.orderApplicantDocuments
      })
    },

    passportNumberChange: _.debounce(
      function() {
        const params = {
          session: this.Session,
          applicant: this.Applicant
        }
        params.applicant.passportNumber = this.ruleForm.passportNumber
        this.OnApplicantChange(params)
      }, global.debounceTime),

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicant: this.Applicant,
            orderApplicantId: this.Session.current.orderApplicantId,
            hasPassport: this.ruleForm.hasPassport,
            isAusCitizen: this.ruleForm.isAusCitizen,
            selectedCertificateDocumentType: this.ruleForm.selectedCertificateDocumentType,
            selectedPassportCountryCode: this.ruleForm.selectedPassportCountryCode,
            orderApplicantDocuments: this.orderApplicantDocuments
          }
          params.applicant.passportNumber = this.ruleForm.passportNumber
          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    }
  }
}
</script>
<template>
  <div v-loading="allLoading" class="EntitlementToWorkView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="19"
          :xl="19" class="label"
        >
          <span class="color-red">*</span>
          Are you an Australian Citizen?
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="24" :lg="5"
          :xl="5"
        >
          <el-form-item prop="isAusCitizen" style="margin-bottom: 0">
            <el-radio-group v-model="ruleForm.isAusCitizen" @change="isAusCitizenChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="CitizenshipShow" :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item prop="selectedCitizenshipCountryCode">
            <label class="label">* What is your Country of Citizenship?
              <GetAllGeoCountries
                :disabled="allDisabled"
                :defalut-value.sync="ruleForm.selectedCitizenshipCountryCode"
                @getCountryData="OnCitizenshipCountryChange"
              />
            </label>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="PassportBirthCertShow">
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="24" :lg="19"
              :xl="19" class="label"
            >
              <span>* Do you hold a current Australian Passport?</span>
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="24" :lg="5"
              :xl="5"
            >
              <el-form-item prop="hasPassport" style="margin-bottom: 0">
                <el-radio-group v-model="ruleForm.hasPassport" @change="hasPassportOnChange">
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </el-form-item>
            </el-col>
          </label>
        </el-row>
        <el-row v-if="hasPassportTrueShow" :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Passport Number" prop="passportNumber">
              <el-input v-model="ruleForm.passportNumber" @input="passportNumberChange" />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Passport Country" prop="selectedPassportCountryCode">
              <GetAllGeoCountries
                :disabled="allDisabled"
                :defalut-value.sync="ruleForm.selectedPassportCountryCode"
                @getCountryData="OnPassportCountryChange"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item v-if="hasPassportFalseShow" class="f-w-600" label="Please attach a legible copy of your Birth Certificate or Citizenship Certificate" prop="selectedCertificateDocumentType">
          <el-radio-group v-model="ruleForm.selectedCertificateDocumentType" @change="OnSelectCertificateType">
            <el-radio-button label="Birth">Birth Certificate</el-radio-button>
            <el-radio-button label="Citizenship">Citizenship Certificate</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>

      <el-row v-if="uploadShow" :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="24" :lg="24"
          :xl="24" class="label f-w-600"
        >
          Please attach a legible copy of your Australian Passport
        </el-col>
        <el-col>
          <OnlyUpload v-if="orderApplicantDocuments.length<1" @Document_Upload="Document_Upload" />
          <OnlyUpload
            v-for="item in orderApplicantDocuments"
            :key="item.id"
            :img-item="item"
            @Document_Upload="Document_Upload"
            @Document_Remove="Document_Remove"
          />
        </el-col>
      </el-row>

      <el-form-item>
        <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">{{ buttonText }}</el-button>
      </el-form-item>

    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.EntitlementToWorkView {
  .label {
    line-height: 40px;
    font-size: 14px;
    color: #606266;
  }
}
</style>
