<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import OnlyUpload from '@/components/OnlyUpload'
import AddressAutocomplete from '@/components/AddressAutocomplete'
import GetAllGeoCountries from '@/components/GetAllGeoCountries'
import {
  preparationApi,
  uploadApi,
  removeDocumentsApi,
  otherNameOnChangeApi,
  tertiaryQualificationOnChangeApi,
  continueApi,
  saveFormDataApi
} from '@/api/QualificationsTertiary'
import { getAuStatesApi } from '@/api/application'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'QualificationsTertiary',
  components: { OnlyUpload, GetAllGeoCountries, AddressAutocomplete },
  inject: ['SaveFormDataSetSession', 'ContinueSetSession', 'SetApplicant'],
  data() {
    const validateSearch = (rule, value, callback) => {
      if (value !== '') {
        if (value === this.displayLine1) {
          callback()
        } else {
          callback(new Error('Please select an address from the list.'))
        }
      } else {
        callback()
      }
    }
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date(new Date().getFullYear(), 11, 31).getTime()
        }
      },
      otherNames: [],
      selectedOtherName: {},
      isManualAddress: false,
      qualificationOrderApplicantDocuments: [],
      id: 0,
      hasOtherName: '',

      manualAddressEntry: {},
      qualification: {},
      ruleForm: {
        initialAddress: '',
        hasTertiaryQualification: '',
        suburb: '',
        state: '',
        selectedCountryCode: '',
        schoolLocality: '',
        schoolState: '',
        schoolCountry: '',
        dateCommenced: '',
        dateCompleted: '',
        hasOtherName: '',
        selectedOtherNameId: '',
        qualificationName: '',
        dateGraduated: '',
        applicantStudentId: '',
        contactPhone: '',
        contactEmail: ''
      },
      rules: {
        hasTertiaryQualification: [
          { required: true, message: 'Please select a value', trigger: 'change' }
        ],
        schoolName: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        initialAddress: [{ required: false, validator: validateSearch, trigger: 'blur' }],
        suburb: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        dateCommenced: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        dateCompleted: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        hasOtherName: [
          { required: true, message: 'Please select a value', trigger: 'change' }
        ],
        selectedOtherNameId: [
          { required: true, message: 'Please select a value', trigger: 'change' }
        ],
        qualificationName: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        dateGraduated: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        applicantStudentId: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        contactPhone: [
          { required: false, message: 'Required field!', trigger: 'blur' }
        ],
        contactEmail: [
          { required: false, message: 'Required field!', trigger: 'blur' },
          { type: 'email', message: 'Email expected!', trigger: 'blur' }
        ]
      },
      CountryList: [],
      displayLine1: '',
      allLoading: false,
      australianStates: [],
      hasOtherNameShowLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: (state) => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    BlockName() {
      return this.preparation.session.current.formSection.blockName
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    initialAddress() {
      return !this.qualification.schoolLocality ? '' : (this.qualification.schoolLocality + ', ' + this.qualification.schoolState +
        (this.qualification.schoolCountry !== 'Australia' ? ' (' + this.qualification.schoolCountry + ')' : ''))
    },
    HasTertiaryQualificationShow() {
      return this.ruleForm.hasTertiaryQualification === 'Yes'
    },
    StudyingShow() {
      return this.otherNames.length >= 1
    },
    HasOtherNameShow() {
      return this.ruleForm.hasOtherName === 'Yes' && this.otherNames.length >= 1
    },
    AddressShow() {
      return !this.isManualAddress
    },
    uploadShow() {
      // (HasTertiaryQualification = "Yes" and ((not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100) or not QualificationOrderApplicantDocuments.Empty)) or Session.Current.IsAdmin
      return (this.ruleForm.hasTertiaryQualification === 'Yes' &&
      ((!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100) ||
      !this.qualificationOrderApplicantDocuments.length < 1)) || this.Session.current.isAdmin
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    buttonText() {
      // If(Session.Current.FormSection.Index < (Session.FormSections.Length - 1), "Save and Continue", "Submit")
      return this.Session.current.formSection.index <
        this.Session.formSections.length - 1
        ? 'Save and Continue'
        : 'Submit'
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'QualificationsTertiary')
      // }
      this.allLoading = true
      const params = {
        Session: this.Session,
        ApplicantId: this.ApplicantId,
        Applicant: this.Applicant
      }

      await preparationApi(params).then((res) => {
        const { data } = res
        this.isManualAddress = data.isManualAddress
        this.qualificationOrderApplicantDocuments =
          data.qualificationOrderApplicantDocuments
        this.otherNames = data.otherNames
        this.id = data.qualification.id || 0
        this.ruleForm = data.qualification
        this.ruleForm.dateCommenced = data.qualification.dateCommenced === '1900-01-01T00:00:00' ? null : data.qualification.dateCommenced
        this.ruleForm.dateCompleted = data.qualification.dateCompleted === '1900-01-01T00:00:00' ? null : data.qualification.dateCompleted
        this.ruleForm.dateGraduated = data.qualification.dateGraduated === '1900-01-01T00:00:00' ? null : data.qualification.dateGraduated

        this.ruleForm.hasTertiaryQualification = data.hasTertiaryQualification
        this.qualification = data.qualification
        this.manualAddressEntry = data.manualAddressEntry || {}
        this.ruleForm.selectedCountryCode = data.selectedCountryCode
        this.ruleForm.state = data.manualAddressEntry ? data.manualAddressEntry.state : null
        this.ruleForm.suburb = data.manualAddressEntry ? data.manualAddressEntry.suburb : null
        this.ruleForm.hasOtherName = data.hasOtherName
        this.hasOtherName = data.hasOtherName
        this.selectedOtherName = data.selectedOtherName
        this.ruleForm.selectedOtherNameId = data.selectedOtherNameId ? data.selectedOtherNameId : ''
        this.ruleForm.initialAddress = this.initialAddress
        this.displayLine1 = this.initialAddress
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
        if (this.ruleForm.selectedCountryCode === 'AU') {
          this.getAuStates()
        }
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async getAuStates() {
      await getAuStatesApi().then(res => {
        const { data } = res
        this.australianStates = data.australianStates
      })
    },

    AddressAutocompleteOnDetailReady(AddressDetail, displayLine1) {
      this.displayLine1 = displayLine1
      this.qualification.schoolLocality = AddressDetail.Suburb ? AddressDetail.suburb : AddressDetail.addressLine1
      this.qualification.schoolState = AddressDetail.state
      this.qualification.schoolCountry = AddressDetail.country

      this.$refs.ruleForm.validateField('initialAddress')
    },

    async AddressAutocompleteOnManualEntry(Search) {
      this.displayLine1 = ''
      this.isManualAddress = true
      this.manualAddressEntry.suburb = Search
      this.ruleForm.suburb = Search
      this.manualAddressEntry.country = !this.manualAddressEntry.country ? 'Australia' : this.manualAddressEntry.Country
      this.manualAddressEntry.countryCode = !this.manualAddressEntry.countryCode ? 'AU' : this.manualAddressEntry.countryCode
      this.manualAddressEntry.countryCode3 = !this.manualAddressEntry.countryCode3 ? 'AUS' : this.manualAddressEntry.countryCode3
      this.ruleForm.selectedCountryCode = this.manualAddressEntry.countryCode
      this.qualification.schoolLocality = ''
      this.qualification.schoolState = ''
      this.qualification.SchoolCountry = ''
      this.getAuStates()
    },

    AddressAutocompleteOnNoSelectionMade() {
      this.qualification.schoolLocality = ''
      this.qualification.schoolState = ''
      this.qualification.SchoolCountry = ''
    },

    async CloudinaryUpload_UploadResult_Qualification(file) {
      const formData = new FormData()
      formData.append(
        'OrderApplicantId',
        this.Session.current.orderApplicantId
      )
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)
      await uploadApi(formData).then((res) => {
        const { data } = res
        this.qualificationOrderApplicantDocuments =
          data.qualificationOrderApplicantDocuments
      })
    },

    async Document_Remove(item) {
      const params = {
        Session: this.Session,
        OrderApplicantDocumentId: item.id
      }
      await removeDocumentsApi(params).then((res) => {
        this.qualificationOrderApplicantDocuments = []
        this.SaveFormDataSetSession(res.data.session)
      })
    },

    async HasOtherNameOnChange(val) {
      this.hasOtherNameShowLoading = true
      if (this.ruleForm.hasOtherName === 'No') {
        this.qualification.applicantName = ''
      }
      const params = {
        Session: this.Session,
        Applicant: this.Applicant,
        hasOtherName: this.ruleForm.hasOtherName,
        HasTertiaryQualification: this.ruleForm.hasTertiaryQualification
      }
      await otherNameOnChangeApi(params).then((res) => {
        const { data } = res
        this.hasOtherNameShowLoading = false
        if (data) {
          this.otherNames = data.otherNames
          this.selectedOtherName = data.selectedOtherName
          this.selectedOtherNameId = data.selectedOtherNameId
          this.ruleForm.selectedOtherNameId = data.selectedOtherNameId
          this.qualification.applicantName = this.selectedOtherName.label
          this.ruleForm = { ...this.ruleForm }
          // this.$set(this.ruleForm, 'selectedOtherNameId', data.selectedOtherNameId)
          // this.$forceUpdate()
          // this.$refs.ruleForm.validateField(['selectedOtherNameId'])
        } else {
          this.ruleForm.selectedOtherNameId = ''
          this.selectedOtherNameId = ''
          this.selectedOtherName = {}
          this.$forceUpdate()
        }
      })
    },

    async HasTertiaryQualificationOnChange(val) {
      if (val === 'No') {
        this.ruleForm.schoolName = ''
        this.ruleForm.contactPhone = ''
        this.ruleForm.contactEmail = ''
        this.ruleForm.qualificationName = ''
        this.ruleForm.dateCommenced = null
        this.ruleForm.dateGraduated = null
        this.ruleForm.applicantStudentId = ''
        this.ruleForm.dateCompleted = null
        this.qualification.schoolLocality = ''
        this.qualification.schoolState = ''
        this.qualification.SchoolCountry = ''
        this.ruleForm.hasOtherName = this.hasOtherName
        this.ruleForm.selectedOtherNameId = null
        this.ruleForm.initialAddress = ''
      }
      const params = {
        HasTertiaryQualification: val,
        Session: this.Session,
        Applicant: this.Applicant
      }
      await tertiaryQualificationOnChangeApi(params).then((res) => {
        const { data } = res
        this.SetApplicant(data.applicant)
      })
      this.FormDataSave()
    },

    selectedOtherNameIdChange() {
      this.selectedOtherName = this.otherNames.find(item => item.id === this.ruleForm.selectedOtherNameId)
      this.qualification.applicantName = this.selectedOtherName.label
    },

    async OnManualCountryChange(data) {
      this.ruleForm.selectedCountryCode = data.countryCode
      this.manualAddressEntry.country = data.countryName
      this.manualAddressEntry.countryCode = data.countryCode
      this.manualAddressEntry.countryCode3 = data.isoAlpha3

      if (this.ruleForm.selectedCountryCode === 'AU' && this.australianStates.length < 1) {
        this.getAuStates()
      } else {
        this.ruleForm.state = ''
        this.manualAddressEntry.state = ''
      }
    },

    FormDataSave: _.debounce(
      function() {
        this.qualification.schoolName = this.ruleForm.schoolName
        this.qualification.contactPhone = this.ruleForm.contactPhone
        this.qualification.contactEmail = this.ruleForm.contactEmail
        this.qualification.qualificationName = this.ruleForm.qualificationName
        this.qualification.dateCommenced = this.ruleForm.dateCommenced
        this.qualification.dateGraduated = this.ruleForm.dateGraduated
        this.qualification.applicantStudentId = this.ruleForm.applicantStudentId
        this.qualification.dateCompleted = this.ruleForm.dateCompleted
        this.manualAddressEntry.suburb = this.ruleForm.suburb
        this.manualAddressEntry.state = this.ruleForm.state
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId,
          qualification: this.qualification,
          manualAddressEntry: this.manualAddressEntry,
          hasTertiaryQualification: this.ruleForm.hasTertiaryQualification,
          selectedCountryCode: this.ruleForm.selectedCountryCode,
          hasOtherName: this.ruleForm.hasOtherName,
          isManualAddress: this.isManualAddress
        }

        saveFormDataApi(params).then((res) => {
          if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
        })
      }, global.debounceTime),

    async Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.qualification.schoolName = this.ruleForm.schoolName
          this.qualification.contactPhone = this.ruleForm.contactPhone
          this.qualification.contactEmail = this.ruleForm.contactEmail
          this.qualification.qualificationName = this.ruleForm.qualificationName
          this.qualification.dateCommenced = this.ruleForm.dateCommenced
          this.qualification.dateGraduated = this.ruleForm.dateGraduated
          this.qualification.applicantStudentId = this.ruleForm.applicantStudentId
          this.qualification.dateCompleted = this.ruleForm.dateCompleted
          this.qualification.selectedOtherNameId = this.ruleForm.selectedOtherNameId
          this.manualAddressEntry.state = this.ruleForm.state
          this.manualAddressEntry.suburb = this.ruleForm.suburb
          const params = {
            session: this.Session,
            applicant: this.Applicant,
            qualification: this.qualification,
            isManualAddress: this.isManualAddress,
            manualAddressEntry: this.manualAddressEntry,
            qualificationOrderApplicantDocuments: this.qualificationOrderApplicantDocuments,
            hasTertiaryQualification: this.ruleForm.hasTertiaryQualification,
            hasOtherName: this.ruleForm.hasOtherName,
            selectedOtherName: this.selectedOtherName
          }

          await continueApi(params).then((res) => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    getImgcanDelete(item) {
      // (QualificationDocuments.List.Current.UploadedBlockName = Session.Current.FormSection.BlockName
      // and not Session.Current.IsSubmitted) or Session.Current.IsAdmin
      return (item.uploadedBlockName === this.Session.current.formSection.blockName &&
      !this.Session.current.isSubmitted) || this.Session.current.isAdmin
    }
  }
}
</script>
<template>
  <div v-loading="allLoading" class="QualificationsTertiary">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-form-item
        prop="hasTertiaryQualification"
      >
        <label class="label" style="display: flex; flex-direction: column;"><span>* Do you have a Tertiary Qualification?</span>
          <el-radio-group
            v-model="ruleForm.hasTertiaryQualification"
            @change="HasTertiaryQualificationOnChange"
          >
            <el-radio label="Yes" />
            <el-radio label="No" />
          </el-radio-group>
        </label>
      </el-form-item>
      <div v-if="HasTertiaryQualificationShow">
        <p class="label">
          Please provide details of your highest level of COMPLETED tertiary
          education.
        </p>
        <br>
        <el-form-item
          prop="schoolName"
        >
          <label class="label">* University/College/School (Full Name)
            <el-input v-model="ruleForm.schoolName" maxlength="250" @input="FormDataSave" />
          </label>
        </el-form-item>
        <el-form-item v-if="AddressShow" prop="initialAddress">
          <label class="label">School Location
            <AddressAutocomplete
              :initialaddress.sync="ruleForm.initialAddress"
              :placeholder="'Start typing your school\'s suburb'"
              :use-google="true"
              :google-types="'(cities)'"
              from="QualificationsTertiary"
              @OnDetailReady="AddressAutocompleteOnDetailReady"
              @OnManualEntry="AddressAutocompleteOnManualEntry"
              @OnNoSelectionMade="AddressAutocompleteOnNoSelectionMade"
            />
          </label>
        </el-form-item>

        <div v-if="!AddressShow">
          <el-row :gutter="20">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item label="School Suburb" prop="suburb">
                <el-input v-model="ruleForm.suburb" @input="FormDataSave" />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item v-if="ruleForm.selectedCountryCode === 'AU'" label="School State" prop="state">
                <el-select v-model="ruleForm.state" popper-class="dropdownClass" style="width:100%" @change="FormDataSave">
                  <el-option v-for="item in australianStates" :key="item.adminCodes1.ISO3166_2" :label="item.adminCodes1.ISO3166_2" :value="item.adminCodes1.ISO3166_2" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="School Country" prop="selectedCountryCode">
            <GetAllGeoCountries
              :defalut-value.sync="ruleForm.selectedCountryCode"
              @getCountryData="OnManualCountryChange"
            />
          </el-form-item>
        </div>

        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="dateCommenced">
              <label class="label">* Date Commenced
                <el-date-picker
                  v-model="ruleForm.dateCommenced" :picker-options="pickerOptions" format="MM/yyyy" value-format="yyyy-MM-dd"
                  type="month" @change="FormDataSave"
                />
              </label>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="dateCompleted">
              <label class="label">* Date Completed
                <el-date-picker
                  v-model="ruleForm.dateCompleted" :picker-options="pickerOptions" format="MM/yyyy" value-format="yyyy-MM-dd"
                  type="month" @change="FormDataSave"
                />
              </label>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item
          v-if="StudyingShow"
          prop="hasOtherName"
        >
          <label class="label">Were you known under a different name while you were studying?
            <el-radio-group
              v-model="ruleForm.hasOtherName"
              @change="HasOtherNameOnChange"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </label>
        </el-form-item>

        <el-form-item v-if="HasOtherNameShow" v-loading="hasOtherNameShowLoading" prop="selectedOtherNameId">
          <span slot="label">Your name at the time of studying
            <el-tooltip
              class="item"
              effect="dark"
              content="If no options are available, please add other names to the Your Personal Details section."
              placement="top"
            >
              <i class="el-icon-info" />
            </el-tooltip>
          </span>
          <el-select v-model="ruleForm.selectedOtherNameId" popper-class="dropdownClass" @change="selectedOtherNameIdChange">
            <el-option
              v-for="item in otherNames"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item prop="qualificationName">
          <label class="label">* Name of Qualification
            <el-input v-model="ruleForm.qualificationName" maxlength="150" @input="FormDataSave" />
          </label>
        </el-form-item>

        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="dateGraduated">
              <label class="label">* Date Graduated
                <el-date-picker
                  v-model="ruleForm.dateGraduated" :picker-options="pickerOptions" format="MM/yyyy" value-format="yyyy-MM-dd"
                  type="month" @change="FormDataSave"
                />
              </label>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="applicantStudentId">
              <label class="label">Student Number
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="If you are unsure of your Student ID please enter &quot;N/A&quot;"
                  placement="top"
                >
                  <i class="el-icon-info" />
                </el-tooltip>
                <el-input
                  v-model="ruleForm.applicantStudentId"
                  maxlength="60"
                  class="input-with-select"
                  @input="FormDataSave"
                />
              </label>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="contactPhone">
              <label class="label">School Contact Phone
                <el-input v-model="ruleForm.contactPhone" maxlength="20" @input="FormDataSave" />
              </label>
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item prop="contactEmail">
              <label class="label">School Contact Email
                <el-input v-model="ruleForm.contactEmail" maxlength="250" @input="FormDataSave" />
              </label>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div v-if="uploadShow">

        <div style="font-size: 16px; font-weight: 600;">Document Upload</div>
        <p class="label">
          If your education qualification was obtained overseas, please upload a
          certified copy of the certificate, if available.
        </p>
        <OnlyUpload
          v-if="qualificationOrderApplicantDocuments.length < 1"
          @Document_Upload="CloudinaryUpload_UploadResult_Qualification($event)"
        />
        <OnlyUpload
          v-for="item in qualificationOrderApplicantDocuments"
          :key="item.id"
          :img-item="item"
          :can-delete="getImgcanDelete(item)"
          @Document_Upload="CloudinaryUpload_UploadResult_Qualification($event)"
          @Document_Remove="Document_Remove"
        />
      </div>
    </el-form>
    <!-- <el-button type="primary" @click="FormDataSave('ruleForm')">Save form data</el-button> -->
    <el-button
      v-if="ContinueBtn"
      :loading="endAllRequests"
      type="primary"
      style="margin-top:20px"
      @click="Continue('ruleForm')"
    >{{ buttonText }}</el-button>
  </div>
</template>
<style lang="scss" scoped>
.QualificationsTertiary {
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
  }
}
.dropdownClass .el-select-dropdown__item{
  width: 90vw !important;
  max-width: 500px;
}
</style>
