// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/login-logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SessionExpiredView[data-v-64f7d3ef] {\n  height: 100%;\n  background-color: #206baa;\n}\n.SessionExpiredView .content-box[data-v-64f7d3ef] {\n  padding: 48px;\n  background-color: #fff;\n  border-radius: 6px;\n  margin: 180px 0 0 0;\n  color: #4f575e;\n  text-align: center;\n}\n.SessionExpiredView .content-box h2[data-v-64f7d3ef] {\n  margin-bottom: 50px;\n}\n.SessionExpiredView .content-box h4[data-v-64f7d3ef] {\n  margin: 0 auto;\n}\n.SessionExpiredView .content-box .btn[data-v-64f7d3ef] {\n  width: 100%;\n  margin-top: 30px;\n}\n.SessionExpiredView .content-box .logo[data-v-64f7d3ef] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 106px;\n  width: 197px;\n  margin: 0 auto;\n}\n.SessionExpiredView .content-box .tag[data-v-64f7d3ef] {\n  width: 100%;\n  color: #fff;\n}\n.SessionExpiredView .content-box .tag i[data-v-64f7d3ef] {\n  font-size: 18px;\n}\n.SessionExpiredView .content-box .tag span[data-v-64f7d3ef] {\n  margin: 10px;\n  font-size: 16px;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
