import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'

import 'font-awesome/css/font-awesome.min.css'

import './styles/index.scss'

import App from './App.vue'
import router from './router'
import store from './store'

import { isNullDate, isNullIdentifier, isRepeat } from './utils'
import { getPreparation } from './utils/preparation'

import './utils/permission' // permission control

import * as filters from './filters' // global filters

import session_loginout from './utils/session_loginout.js'

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

// set ElementUI lang to EN
Vue.use(ElementUI, { locale })

Vue.prototype.$isNullDate = isNullDate
Vue.prototype.$isNullIdentifier = isNullIdentifier
Vue.prototype.$isRepeat = isRepeat

Vue.prototype.$getPreparation = getPreparation

Vue.use(session_loginout)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
