<script>
import _ from 'lodash'
import global from '@/utils/global'
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import AddressForm from './AddressForm'
import AddressAutocomplete from '@/components/AddressAutocomplete'
import GetAllGeoCountries from '@/components/GetAllGeoCountries'
import { getAuStatesApi } from '@/api/application'
import { preparationApi, continueApi } from '@/api/AddressHistory'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'AddressHistory',
  components: { GetAllGeoCountries, AddressForm, AddressAutocomplete },
  inject: ['ContinueSetSession', 'OnApplicantChange'],
  provide() {
    return {
      GetAllCompliancesList: this.GetAllCompliancesList,
      PreviousAddressChange: this.PreviousAddressChange
    }
  },
  data() {
    const validateSearch = (rule, value, callback) => {
      if (value !== '') {
        if (value === this.displayLine1) {
          callback()
        } else {
          callback(new Error('Please select an address from the list.'))
        }
      } else {
        callback(new Error('Required field!'))
      }
    }
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      isManualAddressEntry: true,
      manualAddressEntry: {},
      australianStates: [],
      targetMinDate: '',
      maxDate: '',
      newPreviousAddress: {},
      actualMinDate: '',
      form: {
        fullAddress: '',
        selectedCountryCode: '',
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        state: '',
        postCode: '',
        currentAddressFromDate: ''
      },
      fullAddress: '',
      previousAddresses: [],
      preAddressList: [],
      rules: {
        fullAddress: [
          { required: true, validator: validateSearch, trigger: 'blur' }
        ],
        addressLine1: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        suburb: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        state: [
          { required: true, validator: this.validate, trigger: 'change' }
        ],
        postCode: [
          { validator: this.validate, trigger: 'blur' }
        ],
        currentAddressFromDate: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ]
      },
      componentList: [],
      passInfo: false,
      displayLine1: '',
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: (state) => state.section.introduction,
      sectionTitle: (state) => state.section.sectionTitle,
      preparation: (state) => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    IsManualAddressEntryShow() {
      return !this.isManualAddressEntry
    },
    MinimumYears() {
      return this.preparation.session.current.formSection.minQty
    },
    StateShow() {
      return this.form.selectedCountryCode === 'AU'
    },
    currentAddressFromDateDisabled() {
      if (this.Session.current.isAdmin) {
        return false
      } else {
        return this.preAddressList[0] && (this.preAddressList[0].fullAddress !== '' || this.preAddressList[0].fromDate)
      }
    },
    PreviousAddressesShow() {
      const arr = this.previousAddresses
      if (arr.length === 0) {
        return false
      } else {
        return true
      }
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    validate(rule, value, callback) {
      if (this.form.selectedCountryCode === 'AU') {
        if (value === '') {
          callback(new Error('Required field!'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'AddressHistory')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        Applicant: this.Applicant,
        minimumYears: 0,
        previousAddress: {}
      }
      await preparationApi(params).then((res) => {
        const { data } = res
        this.isManualAddressEntry = data.isManualAddressEntry
        this.targetMinDate = dayjs(data.targetMinDate).format('YYYY-MM-DD')
        this.maxDate = dayjs(data.maxDate).format('YYYY-MM-DD')
        this.form.currentAddressFromDate =
          this.Applicant.currentAddressFromDate === '1900-01-01T00:00:00'
            ? null
            : this.Applicant.currentAddressFromDate
        this.newPreviousAddress = data.newPreviousAddress
        this.actualMinDate = data.actualMinDate
        this.previousAddresses = data.previousAddresses

        this.form.fullAddress = this.Applicant.fullAddress || ''
        this.displayLine1 = this.form.fullAddress

        this.manualAddressEntry = data.manualAddressEntry || {}
        if (data.manualAddressEntry) {
          this.form.addressLine1 = data.manualAddressEntry.addressLine1 || ''
          this.form.addressLine2 = data.manualAddressEntry.addressLine2 || ''
          this.form.country = data.manualAddressEntry.country || ''
          this.form.countryCode = data.manualAddressEntry.countryCode || ''
          this.form.countryCode3 = data.manualAddressEntry.countryCode3 || ''
          this.form.postCode = data.manualAddressEntry.postCode || ''
          this.form.state = data.manualAddressEntry.state || ''
          this.form.suburb = data.manualAddressEntry.suburb || ''
          this.form.selectedCountryCode =
            data.manualAddressEntry.countryCode || ''
          // const index = data.countries.findIndex(item => item.countryCode === this.form.selectedCountryCode)
          // this.form.country = data.countries[index].countryName
        }
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
      if (this.isManualAddressEntry && this.form.selectedCountryCode === 'AU') {
        this.getAuStates()
      }
    },

    async getAuStates() {
      await getAuStatesApi().then((res) => {
        const { data } = res
        this.australianStates = data.australianStates
      })
    },

    getAddressDetail(val) {
      this.form.fullAddress = val
    },

    AutocompleteAddressOnClear() {
      const data = this.preparation
      this.displayLine1 = ''
      data.applicant.fullAddress = ''
      this.form.fullAddress = ''
      data.applicant.addressLine1 = ''
      data.applicant.addressLine2 = ''
      data.applicant.suburb = ''
      data.applicant.state = ''
      data.applicant.postCode = ''
      data.applicant.country = ''
      data.applicant.countryCode = ''
      data.applicant.longitude = ''
      data.applicant.latitude = ''
      data.applicant.meshblock = ''
      data.applicant.gnaf = ''
      data.applicant.dpid = ''
      data.applicant.parcelId = ''
      this.OnApplicantChange(data)
    },

    AddressAutocompleteOnDetailReady(AddressDetail, displayLine1) {
      this.displayLine1 = displayLine1
      // this.form.fullAddress = AddressDetail.fullAddress

      const data = this.preparation
      data.applicant.fullAddress = AddressDetail.fullAddress
      data.applicant.addressLine1 = AddressDetail.addressLine1
      data.applicant.addressLine2 = AddressDetail.addressLine2
      data.applicant.suburb = AddressDetail.suburb
      data.applicant.state = AddressDetail.state
      data.applicant.postCode = AddressDetail.postcode
      data.applicant.country = AddressDetail.country
      data.applicant.countryCode = AddressDetail.countryCode
      // data.applicant.countryCode3 = GetCountry(AddressDetail.CountryCode).IsoAlpha3
      data.applicant.longitude = AddressDetail.longitude
      data.applicant.latitude = AddressDetail.latitude
      data.applicant.meshblock = AddressDetail.meshblock
      data.applicant.gnaf = AddressDetail.gnaf
      data.applicant.dpid = AddressDetail.dpid
      data.applicant.parcelId = AddressDetail.parcelId
      data.applicant.isAddressManual = false

      this.newPreviousAddress.toDate = this.actualMinDate

      this.OnApplicantChange(data)
      this.$refs.mainForm.validateField('fullAddress')
    },

    async AddressAutocompleteOnManualEntry(Search) {
      this.displayLine1 = ''
      this.isManualAddressEntry = true
      this.manualAddressEntry.country = !this.manualAddressEntry.country
        ? 'Australia'
        : this.manualAddressEntry.country
      this.manualAddressEntry.countryCode = !this.manualAddressEntry.countryCode
        ? 'AU'
        : this.manualAddressEntry.countryCode
      this.manualAddressEntry.addressLine1 = Search

      this.form.selectedCountryCode = !this.manualAddressEntry.countryCode
        ? 'AU'
        : this.manualAddressEntry.countryCode
      this.form.addressLine1 = Search

      this.form.country = this.manualAddressEntry.country
      this.form.countryCode = this.manualAddressEntry.countryCode

      const data = this.preparation
      // data.applicant.isAddressManual = true
      this.OnApplicantChange(data)

      this.getAuStates()
    },

    AddressAutocompleteOnNoSelectionMade() {
      const data = this.preparation
      data.applicant.fullAddress = ''
      data.applicant.addressLine1 = ''
      data.applicant.addressLine2 = ''
      data.applicant.suburb = ''
      data.applicant.state = ''
      data.applicant.postCode = ''
      data.applicant.country = ''
      data.applicant.longitude = ''
      data.applicant.latitude = ''
      data.applicant.meshblock = ''
      data.applicant.gnaf = ''
      data.applicant.dpid = ''
      data.applicant.parcelId = ''
      this.OnApplicantChange(data)
    },

    async OnManualCountryChange(data) {
      const currentAddressFromDateTemp = this.form.currentAddressFromDate
      this.$refs['mainForm'].resetFields()
      this.form.selectedCountryCode = data.countryCode
      this.form.country = data.countryName
      this.form.currentAddressFromDate = currentAddressFromDateTemp
      this.manualAddressEntry.country = data.countryName
      this.manualAddressEntry.countryCode = data.countryCode
      this.manualAddressEntry.countryCode3 = data.isoAlpha3

      if (this.form.selectedCountryCode !== 'AU') {
        this.form.state = ''
        this.manualAddressEntry.state = ''
      } else {
        this.getAuStates()
      }

      const applicant = this.Applicant
      applicant.addressLine1 = this.form.addressLine1
      applicant.addressLine2 = this.form.addressLine2
      applicant.suburb = this.form.suburb
      applicant.state = this.form.state
      applicant.postCode = this.form.postCode
      applicant.country = data.country
      applicant.countryCode = data.countryCode
      applicant.countryCode3 = data.countryCode3
      applicant.isAddressManual = this.isManualAddressEntry

      this.OnApplicantChange({
        applicant: applicant,
        session: this.Session
      })
    },

    CurrentAddressFromDateChange() {
      const applicant = this.Applicant
      applicant.currentAddressFromDate = this.form.currentAddressFromDate
      const isBefore = dayjs(this.form.currentAddressFromDate).isBefore(dayjs(this.targetMinDate))
      const isSame = dayjs(this.form.currentAddressFromDate).isSame(dayjs(this.targetMinDate))

      const passStatus = isBefore || isSame

      console.log(passStatus)
      if (passStatus) {
        this.preAddressList = []
        this.previousAddresses = []
      } else {
        this.previousAddresses = [
          {
            fullAddress: '',
            country: '',
            addressLine1: '',
            addressLine2: '',
            suburb: '',
            state: '',
            postCode: '',
            fromDate: null,
            toDate: this.form.currentAddressFromDate,
            index: 0,
            isManual: false
          }
        ]
      }

      this.OnApplicantChange({
        applicant: applicant,
        session: this.Session
      })
    },

    OnManualAddressChange: _.debounce(
      function() {
        const applicant = this.Applicant
        applicant.addressLine1 = this.form.addressLine1
        applicant.addressLine2 = this.form.addressLine2
        applicant.suburb = this.form.suburb
        applicant.state = this.form.state
        applicant.postCode = this.form.postCode
        applicant.country = this.form.country
        applicant.countryCode = this.form.countryCode
        applicant.countryCode3 = this.form.countryCode3

        if (
          !(
            applicant.addressLine1 === '' ||
          applicant.suburb === '' ||
          (applicant.country === 'Australia' && applicant.state === '') ||
          applicant.postCode === ''
          )
        ) {
          applicant.fullAddress =
          applicant.addressLine1 +
          ' ' +
          (applicant.addressLine2 !== '' ? applicant.addressLine2 + ' ' : '') +
          applicant.suburb +
          ' ' +
          applicant.state +
          ' ' +
          applicant.postCode +
          ' ' +
          applicant.country
          this.form.fullAddress = applicant.fullAddress
          this.OnApplicantChange({
            applicant: applicant,
            session: this.Session
          })
        }
      }, global.debounceTime),

    getPassinfo(val) {
      this.passInfo = val
    },

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (!valid) {
          return false
        } else {
          const isBefore = dayjs(this.form.currentAddressFromDate).isBefore(
            dayjs(this.targetMinDate)
          )
          const isSame = dayjs(this.form.currentAddressFromDate).isSame(
            dayjs(this.targetMinDate)
          )

          // console.log('isBefore', isBefore)
          // console.log('isSame', isSame)

          const passStatus = isBefore || isSame

          if (!passStatus && this.preAddressList.length >= 1) {
            await this.$refs.AddressFormRef.onlyValidate()
            await this.$refs.AddressFormRef.ValidateForm()
          }

          // console.log('passStatus', passStatus)
          // console.log('passInfo', this.passInfo)

          if (!passStatus && !this.passInfo) {
            if (this.preAddressList.length < 1) {
              this.previousAddresses = [
                {
                  fullAddress: '',
                  country: '',
                  addressLine1: '',
                  addressLine2: '',
                  suburb: '',
                  state: '',
                  postCode: '',
                  fromDate: null,
                  toDate: this.form.currentAddressFromDate,
                  index: 0,
                  isManual: false
                }
              ]
            }
            this.$message.error(
              'Please provide enough Previous Addresses before continuing.'
            )
            return
          }

          this.manualAddressEntry.addressLine1 = this.form.addressLine1
          this.manualAddressEntry.addressLine2 = this.form.addressLine2
          this.manualAddressEntry.postCode = this.form.postCode
          this.manualAddressEntry.state = this.form.state
          this.manualAddressEntry.suburb = this.form.suburb

          const params = {
            session: this.Session,
            ApplicantId: this.ApplicantId,
            Applicant: this.Applicant,
            targetMinDate: this.targetMinDate,
            isManualAddressEntry: this.isManualAddressEntry,
            manualAddressEntry: this.manualAddressEntry,
            newPreviousAddress: {},
            previousAddresses: []
          }
          this.preAddressList.forEach((element) => {
            params.previousAddresses.push({
              ...element,
              ApplicantId: this.ApplicantId
            })
          })
          // console.log('continue')

          await continueApi(params).then((res) => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    GetAllCompliancesList(data) {
      // console.log('得到所有的数据', data)
      this.preAddressList = data
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="ComplianceReference">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <p class="bold">Current Address</p>
    <el-form
      ref="mainForm"
      :model="form"
      :rules="rules"
      label-width="80px"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-row v-if="IsManualAddressEntryShow">
        <el-col :xs="24" :sm="24" :lg="24" :xl="24">
          <el-form-item prop="fullAddress">
            <label class="label">Full Address
              <AddressAutocomplete
                :initialaddress.sync="form.fullAddress"
                :placeholder="'Start typing and pause to select the address'"
                :use-kleber="true"
                :use-google="true"
                from="AddressHistory"
                :search-after-characters="5"
                @OnDetailReady="AddressAutocompleteOnDetailReady"
                @OnClear="AutocompleteAddressOnClear"
                @OnManualEntry="AddressAutocompleteOnManualEntry"
                @OnNoSelectionMade="AddressAutocompleteOnNoSelectionMade"
              />
            </label>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="!IsManualAddressEntryShow">
        <el-row>
          <el-col :xs="24" :sm="24" :lg="24" :xl="24">
            <el-form-item prop="selectedCountryCode" label="Country">
              <GetAllGeoCountries
                :defalut-value.sync="form.selectedCountryCode"
                @getCountryData="OnManualCountryChange"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :xs="24" :sm="24" :lg="24" :xl="24">
            <el-form-item prop="addressLine1" label="Address Line 1">
              <el-input
                v-model="form.addressLine1"
                @input="OnManualAddressChange"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :xs="24" :sm="24" :lg="24" :xl="24">
            <el-form-item prop="addressLine2" label="Address Line 2">
              <el-input
                v-model="form.addressLine2"
                @input="OnManualAddressChange"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :lg="12" :xl="12">
            <el-form-item prop="suburb" label="Suburb">
              <el-input v-model="form.suburb" @input="OnManualAddressChange" />
            </el-form-item>
          </el-col>

          <el-col
            v-if="StateShow" :xs="24" :sm="6" :lg="6"
            :xl="6"
          >
            <el-form-item prop="state" label="State">
              <el-select
                v-model="form.state"
                popper-class="dropdownClass"
                style="width: 100%"
                @change="OnManualAddressChange"
              >
                <el-option
                  v-for="item in australianStates"
                  :key="item.adminCodes1.ISO3166_2"
                  :label="item.adminCodes1.ISO3166_2"
                  :value="item.adminCodes1.ISO3166_2"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="6" :lg="6" :xl="6">
            <el-form-item prop="postCode" label="Postcode">
              <el-input v-model="form.postCode" @input="OnManualAddressChange" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <el-row>
        <el-col :xs="24" :sm="12" :lg="12" :xl="12">
          <el-form-item prop="currentAddressFromDate">
            <label class="label">*From Date
              <el-date-picker
                v-model="form.currentAddressFromDate"
                :picker-options="pickerOptions"
                :disabled="currentAddressFromDateDisabled"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                placeholder="DD/MM/YYYY"
                type="date"
                @change="CurrentAddressFromDateChange"
              />
            </label>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div v-if="PreviousAddressesShow">
      <p class="bold">Previous Addresses</p>
      <p style="color: #c92a50; font-weight: bold">
        Your address history must include all your residential addresses over
        the last {{ MinimumYears }} years.
      </p>
      <AddressForm
        ref="AddressFormRef"
        :current-address-from-date.sync="form.currentAddressFromDate"
        :minimum-years="MinimumYears"
        :target-min-date="targetMinDate"
        :australian-states="australianStates"
        :previous-addresses="previousAddresses"
        @getPassinfo="getPassinfo"
      />
    </div>

    <!-- <el-button type="primary" @click="submitForm()">Save form data</el-button> -->
    <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('mainForm')">Save and Continue</el-button>
  </div>
</template>
<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
.selectWidth {
  display: block;
}

.ComplianceReference {
  ::v-deep .el-form-item {
    margin-bottom: 20px;
  }
}
.dropdownClass .el-select-dropdown__item{
  width: 90vw !important;
  max-width: 500px;
}
</style>
