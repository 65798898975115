<script>
export default {
  name: 'PersonalDetailsItem',
  props: {
    otherNames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formConfig: {
        type: '',
        nameChangeDate: null,
        firstName: '',
        lastName: '',
        middleNames: ''
      },
      nameList: []
    }
  },
  created() {
    this.nameList = this.otherNames
  }
}
</script>

<template>
  <div class="PersonalDetailsItem">
    <div v-for="item in nameList" :key="item.index">
      <div class="history-box">
        <p>{{ item.type }}: {{ item.label }} <span v-if="$isNullDate(item.nameChangeDate, '<>')">({{ item.nameChangeDate | parseDefaultTime("{d}/{m}/{y}") }})</span> </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.action-box {
  display: flex;
  justify-content: space-between;
  color: #206caa;
  .action-item {
    cursor: pointer;
  }
  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }
  .action-value {
    margin-left: 10px;
  }
}
</style>
