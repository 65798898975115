<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import EmploymentHistoryItem from './AddForm/index.vue'
import OnlyUpload from '@/components/OnlyUpload'
import { preparationApi, uploadApi, removeDocumentApi, saveFormDataApi, continueApi } from '@/api/EmploymentHistory'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'EmploymentHistoryView',
  components: { EmploymentHistoryItem, OnlyUpload },
  inject: ['ContinueSetSession', 'SaveFormDataSetSession'],
  provide() {
    return {
      GetAllCompliancesList: this.GetAllCompliancesList,
      removeItem: this.removeItem,
      SaveFormData: this.SaveFormData
    }
  },
  data() {
    return {
      maxDate: '',
      targetMinDate: '',
      showDate: '',
      showDateList: [],
      minYear: 0,
      documentTypeId: '',
      confirmationOfEmploymentDocumentTypeId: 0,
      statementOfServiceDocumentTypeId: 0,
      employmentOrderApplicantDocuments: {},
      selectedCertificateDocumentType: '',
      currentFormSection: {},
      showDocuments: false,
      employments: [],
      editItemIndex: 0,
      reopenNotes: '',
      monthList: [
        {
          title: 'Jan',
          mobile: 'J',
          index: '01',
          check: 'yellow'
        },
        {
          title: 'Feb',
          mobile: 'F',
          index: '02',
          check: 'yellow'
        },
        {
          title: 'Mar',
          mobile: 'M',
          index: '03',
          check: 'yellow'
        },
        {
          title: 'Apr',
          mobile: 'A',
          index: '04',
          check: 'yellow'
        },
        {
          title: 'May',
          mobile: 'M',
          index: '05',
          check: 'yellow'
        },
        {
          title: 'Jun',
          mobile: 'J',
          index: '06',
          check: 'yellow'
        },
        {
          title: 'Jul',
          mobile: 'J',
          index: '07',
          check: 'yellow'
        },
        {
          title: 'Aug',
          mobile: 'A',
          index: '08',
          check: 'yellow'
        },
        {
          title: 'Sep',
          mobile: 'S',
          index: '09',
          check: 'yellow'
        },
        {
          title: 'Oct',
          mobile: 'O',
          index: '10',
          check: 'yellow'
        },
        {
          title: 'Nov',
          mobile: 'N',
          index: '11',
          check: 'yellow'
        },
        {
          title: 'Dec',
          mobile: 'D',
          index: '12',
          check: 'yellow'
        }
      ],
      index: 1,
      componentList: [],
      fileLoding: false,
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device,
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.preparation.session
    },
    BlockName() {
      return this.preparation.session.current.formSection.blockName
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    imgcanDelete() {
      // (not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100) or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100) || this.Session.current.isAdmin
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'EmploymentHistory')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId
      }
      await preparationApi(params).then((res) => {
        const { data } = res
        this.employmentOrderApplicantDocuments =
          data.employmentOrderApplicantDocuments
        this.currentFormSection = data.currentFormSection
        this.showDocuments = data.showDocuments
        this.documentTypeId = data.documentTypeId
        this.confirmationOfEmploymentDocumentTypeId = data.confirmationOfEmploymentDocumentTypeId
        this.statementOfServiceDocumentTypeId = data.statementOfServiceDocumentTypeId
        this.maxDate = data.maxDate
        this.targetMinDate = data.targetMinDate
        this.minYear = data.minYear
        this.employments = data.employments
        this.editItemIndex = data.editItemIndex

        const targetMinYear = this.targetMinDate.split('-')[0]
        const targetMinMonth = this.targetMinDate.split('-')[1]
        // const maxYear = this.maxDate.split('-')[0]
        const maxMonth = this.maxDate.split('-')[1]

        const showDateObj = this.monthList.find(
          (item) => item.index === targetMinMonth
        )
        this.showDate = showDateObj.title + ' ' + targetMinYear
        for (let i = 0; i <= this.minYear; i++) {
          this.showDateList.push({ year: Number(this.targetMinDate.split('-')[0]) + i, mobileYear: Number(this.targetMinDate.split('-')[0]) + i - 2000, months: [...this.monthList] })
        }
        this.showDateList[0].months = this.showDateList[0].months.slice(Number(targetMinMonth) - 1)
        this.showDateList[this.minYear].months = this.showDateList[this.minYear].months.slice(0, Number(maxMonth))
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async CloudinaryUpload_UploadResult_Employment(file) {
      this.fileLoding = true
      const formData = new FormData()
      formData.append(
        'OrderApplicantId',
        this.Session.current.orderApplicantId
      )
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)
      formData.append('documentTypeId', this.documentTypeId)

      await uploadApi(formData).then((res) => {
        const { data } = res
        this.employmentOrderApplicantDocuments = data.employmentOrderApplicantDocuments
        this.fileLoding = false
        this.SaveFormData('', 0)
      })
    },
    async Document_Remove(index, item) {
      const params = {
        orderApplicantDocumentId: item.id,
        session: this.Session
      }
      await removeDocumentApi(params).then(res => {
        this.employmentOrderApplicantDocuments.splice(index, 1)
        this.SaveFormDataSetSession(res.data.session)
      })
    },

    async GetAllCompliancesList(data, removeItem) {
      // console.log('get all data', data)
      this.componentList = JSON.parse(JSON.stringify(data))
      this.showDateList.forEach(item => {
        item.months.forEach(_item => {
          _item.check = 'yellow'
        })
      })
      // const maxYear = Number(this.maxDate.split('-')[0])
      // const maxMonth = Number(this.maxDate.split('-')[1])
      // const targetMinYear = Number(this.targetMinDate.split('-')[0])
      // const targetMinMonth = Number(this.targetMinDate.split('-')[1])

      const tempData = JSON.parse(JSON.stringify(this.showDateList))
      this.componentList.forEach(item => {
        if (item.dateCommenced && item.dateEnded) {
          const startYear = Number(item.dateCommenced.split('-')[0])
          const endYear = Number(item.dateEnded.split('-')[0])
          const startMonth = Number(item.dateCommenced.split('-')[1])
          const endMonth = Number(item.dateEnded.split('-')[1])

          if (startYear === endYear) {
            for (let i = 0; i < tempData.length; i++) {
              if (startYear === Number(tempData[i].year)) {
                tempData[i].months.forEach(__item => {
                  if (__item.index >= startMonth && __item.index <= endMonth) {
                    __item.check = item.employmentOrGap === 'Gap' ? 'green' : 'blue'
                  }
                })
                this.showDateList = tempData
                break
              }
            }
          } else {
            const differenceValue = endYear - startYear

            for (let i = 0; i < tempData.length; i++) {
              if (startYear === Number(tempData[i].year)) {
                tempData[i].months.forEach(__item => {
                  if (__item.index >= startMonth) {
                    __item.check = item.employmentOrGap === 'Gap' ? 'green' : 'blue'
                  }
                })
              }
              if (endYear === Number(tempData[i].year)) {
                tempData[i].months.forEach(__item => {
                  if (__item.index <= endMonth) {
                    __item.check = item.employmentOrGap === 'Gap' ? 'green' : 'blue'
                  }
                })
              }
              if (differenceValue > 1 && (startYear < Number(tempData[i].year) && Number(tempData[i].year) < endYear)) {
                tempData[i].months.forEach(__item => {
                  __item.check = item.employmentOrGap === 'Gap' ? 'green' : 'blue'
                })
              }
              this.showDateList = tempData
            }
          }
        }
      })
    },

    removeItem(removeItem, editItemIndex) {
      this.SaveFormData(removeItem, editItemIndex)
    },

    SaveFormData: _.debounce(
      function(removeItem, editItemIndex) {
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId,
          formData: {
            editItemIndex: editItemIndex,
            employments: [],
            removedEmployments: []
          }
        }
        this.componentList.forEach(item => {
          const data = {
            canContactEmployer: item.canContactEmployer,
            employment: {
              isGap: item.employmentOrGap === 'Gap',
              applicantId: this.ApplicantId,
              ...item
            },
            employmentOrGap: item.employmentOrGap,
            isEmploymentPaid: item.isEmploymentPaid,
            isPaidByRecruitmentAgency: item.isPaidByRecruitmentAgency,
            isPayrollHREmailUnknown: item.isPayrollHREmailUnknown,
            isRecruitmentAgencyEmailUnknown: item.isRecruitmentAgencyEmailUnknown,
            isStillEmployed: item.isStillEmployed,
            isStillInBusiness: item.isStillInBusiness
          }
          if (data.employment.isStillEmployed === 'Yes') {
            data.employment.dateEnded = null
          }
          if (!data.employment.id && data.employment.id !== 0) {
            delete data.employment.id
          }
          data.employment.canContactEmployer = item.canContactEmployer === 'Yes'
          data.employment.isStillEmployed = item.isStillEmployed === 'Yes'
          data.employment.recruitmentAgencyPhone = data.employment.recruitmentAgencyPhone ? data.employment.recruitmentAgencyPhone + '' : ''
          data.employment.employerPhone = data.employment.employerPhone ? data.employment.employerPhone + '' : ''
          data.employment.recruitmentAgencyEmail = item.isRecruitmentAgencyEmailUnknown ? '' : data.employment.recruitmentAgencyEmail
          data.employment.payrollHrEmail = item.isPayrollHREmailUnknown ? '' : data.employment.payrollHrEmail

          params.formData.employments.push(data)
        })
        if (removeItem) {
          const removeObj = {
            isGap: removeItem.employmentOrGap === 'Gap',
            applicantId: this.ApplicantId,
            ...removeItem
          }
          removeObj.canContactEmployer = removeItem.canContactEmployer === 'Yes'
          removeObj.isStillEmployed = removeItem.isStillEmployed === 'Yes'
          removeObj.recruitmentAgencyPhone = removeObj.recruitmentAgencyPhone ? removeObj.recruitmentAgencyPhone + '' : ''
          removeObj.employerPhone = removeObj.employerPhone ? removeObj.employerPhone + '' : ''
          params.formData.removedEmployments.push(removeObj)
        }
        saveFormDataApi(params).then(res => {
          if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
        })
      }, global.debounceTime),

    Validate(type) {
      const GapRules = ['gapReason', 'dateCommenced', 'dateEnded'] // 类型为gap时 必填的字段
      const EmploymentRules = ['businessfullAddress', 'businessFullName', 'typeOfEmployment',
        'isEmploymentPaid', 'isStillEmployed', 'isStillInBusiness', 'canContactEmployer',
        'employerPhone', 'positionTitle', 'employeeId', 'reasonForLeaving',
        'isPaidByRecruitmentAgency', 'recruitmentAgencyName', 'recruitmentAgencyPhone',
        'dateCommenced', 'dateEnded', 'payrollHrEmail', 'recruitmentAgencyEmail'] // 类型为employment时 必填的字段
      for (let i = 0; i < this.componentList.length; i++) {
        // 防止用户没有填完表单就点击了提交
        // console.log(this.componentList[i])
        if (this.componentList[i].employmentOrGap === '') {
          type !== 'pass' && this.$refs.EmploymentHistoryItemRef.ValidateForm(
            this.componentList[i].index
          )
          console.log(321)
          return false
        }
        if (this.componentList[i].employmentOrGap === 'Gap') {
          for (let j = 0; j < GapRules.length; j++) {
            if (!this.componentList[i][GapRules[j]]) {
              type !== 'pass' && this.$refs.EmploymentHistoryItemRef.ValidateForm(
                this.componentList[i].index
              )
              return false
            }
          }
        }
        if (this.componentList[i].employmentOrGap === 'Employment') {
          for (let k = 0; k < EmploymentRules.length; k++) {
            if (!this.componentList[i][EmploymentRules[k]]) {
              type !== 'pass' && this.$refs.EmploymentHistoryItemRef.ValidateForm(
                this.componentList[i].index
              )

              if (EmploymentRules[k] === 'payrollHrEmail') {
                type !== 'pass' && this.$refs.EmploymentHistoryItemRef.ValidateForm(
                  this.componentList[i].index
                )
                if (!this.componentList[i].isPayrollHREmailUnknown) {
                  console.log(347)
                  return false
                }
              }
              if (EmploymentRules[k] === 'isStillInBusiness') {
                type !== 'pass' && this.$refs.EmploymentHistoryItemRef.ValidateForm(
                  this.componentList[i].index
                )
                if (this.componentList[i].isStillEmployed === 'No') {
                  console.log(356)
                  return false
                }
              }
              if (EmploymentRules[k] === 'canContactEmployer') {
                type !== 'pass' && this.$refs.EmploymentHistoryItemRef.ValidateForm(
                  this.componentList[i].index
                )
                if (this.componentList[i].isStillEmployed === 'Yes') {
                  console.log(365)
                  return false
                }
              }
              if (EmploymentRules[k] === 'recruitmentAgencyName' || EmploymentRules[k] === 'recruitmentAgencyPhone') {
                if (this.componentList[i].isPaidByRecruitmentAgency === 'Yes') {
                  console.log(372)
                  return false
                }
              }
              if (EmploymentRules[k] === 'recruitmentAgencyEmail') {
                type !== 'pass' && this.$refs.EmploymentHistoryItemRef.ValidateForm(
                  this.componentList[i].index
                )
                if (!this.componentList[i].isRecruitmentAgencyEmailUnknown && this.componentList[i].isPaidByRecruitmentAgency === 'Yes') {
                  console.log(381)
                  return false
                }
              }

              if (EmploymentRules[k] === 'reasonForLeaving') {
                if (this.componentList[i].isStillEmployed === 'No') {
                  console.log(387)
                  return false
                }
              }

              if (EmploymentRules[k] !== 'payrollHrEmail' &&
              EmploymentRules[k] !== 'recruitmentAgencyEmail' &&
              EmploymentRules[k] !== 'isStillInBusiness' &&
              EmploymentRules[k] !== 'canContactEmployer' &&
              EmploymentRules[k] !== 'recruitmentAgencyName' &&
              EmploymentRules[k] !== 'recruitmentAgencyPhone' &&
              EmploymentRules[k] !== 'recruitmentAgencyEmail' &&
              EmploymentRules[k] !== 'reasonForLeaving') {
                console.log(EmploymentRules[k])
                return false
              }
            }
          }
        }
      }
      return true
    },

    async Continue() {
      // let state = false
      // for (let i = 0; i < this.showDateList.length; i++) {
      //   for (let k = 0; k < this.showDateList[i].months.length; k++) {
      //     if (this.showDateList[i].months[k].check === 'yellow') {
      //       state = true
      //     }
      //   }
      // }

      // if (state) { // 不满足条件 验证表单
      if (!this.Validate()) {
        return false
      }
      // }

      for (let i = 0; i < this.showDateList.length; i++) {
        for (let k = 0; k < this.showDateList[i].months.length; k++) {
          if (this.showDateList[i].months[k].check === 'yellow') {
            this.$refs.EmploymentHistoryItemRef.handleAdd('', 2)
            if (i === 0 && k === 0) {
              this.reopenNotes = 'start'
            } else {
              this.reopenNotes = 'end'
            }
            return
          }
        }
      }

      // if (!this.Validate('pass')) { // 满足年份条件后，表单验证是否有未填写完的表单 有的话去掉
      //   console.log(441)
      //   this.componentList.pop()
      // }

      // console.log('continue')

      const params = {
        session: this.Session,
        targetMinDate: this.targetMinDate,
        applicant: this.Applicant,
        currentFormSection: this.currentFormSection,
        employments: [],
        showDocuments: this.showDocuments
      }

      this.componentList.forEach(item => {
        const data = {
          canContactEmployer: item.canContactEmployer,
          employment: {
            isGap: item.employmentOrGap === 'Gap',
            applicantId: this.ApplicantId,
            ...item
          },
          employmentOrGap: item.employmentOrGap,
          isEmploymentPaid: item.isEmploymentPaid,
          isPaidByRecruitmentAgency: item.isPaidByRecruitmentAgency,
          isPayrollHREmailUnknown: item.isPayrollHREmailUnknown,
          isRecruitmentAgencyEmailUnknown: item.isRecruitmentAgencyEmailUnknown,
          isStillEmployed: item.isStillEmployed,
          isStillInBusiness: item.isStillInBusiness
        }
        if (data.employment.isStillEmployed === 'Yes') {
          data.employment.dateEnded = null
        }
        if (!data.employment.id && data.employment.id !== 0) {
          delete data.employment.id
        }
        data.employment.isStillEmployed = item.isStillEmployed === 'Yes'
        data.employment.canContactEmployer = item.canContactEmployer === 'Yes'
        data.employment.recruitmentAgencyPhone = data.employment.recruitmentAgencyPhone ? data.employment.recruitmentAgencyPhone + '' : ''
        data.employment.employerPhone = data.employment.employerPhone ? data.employment.employerPhone + '' : ''
        data.employment.isPayrollHREmailUnknown = item.isPayrollHREmailUnknown
        console.log(item.isRecruitmentAgencyEmailUnknown)
        data.employment.isRecruitmentAgencyEmailUnknown = item.isRecruitmentAgencyEmailUnknown
        data.employment.recruitmentAgencyEmail = item.isRecruitmentAgencyEmailUnknown ? '' : data.employment.recruitmentAgencyEmail
        data.employment.payrollHrEmail = item.isPayrollHREmailUnknown ? '' : data.employment.payrollHrEmail

        params.employments.push(data)
      })
      await continueApi(params).then(res => {
        const { data } = res
        if (data.showDocuments) {
          this.showDocuments = data.showDocuments
        } else {
          this.ContinueSetSession(res.data.session)
        }
      })
    },

    getImgcanDelete(item) {
      // DocumentsList.List.Current.UploadedBlockName = Session.Current.FormSection.BlockName and
      //  (not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100) or Session.Current.IsAdmin
      return (item.uploadedBlockName === this.Session.current.formSection.blockName &&
      (!this.Session.current.isSubmitted || this.Session.current.formSection.completePercent < 100)) || this.Session.current.isAdmin
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="EmploymentHistoryView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <p class="label">
      For this check, we need to know your work history for the last {{ minYear }} years
      (from {{ showDate }})
    </p>
    <div class="year-box">
      <div class="year-label">
        <el-row v-for="v in showDateList" :key="v.year">
          <el-col
            class="year" :xs="2" :sm="2" :md="2"
            :lg="2" :xl="2"
          >
            {{ isMobile?v.mobileYear:v.year }}
          </el-col>
          <el-col
            :xs="22" :sm="22" :md="22" :lg="22"
            :xl="22"
          >
            <el-row :gutter="10" class="test">
              <el-col
                v-for="k in v.months"
                :key="k.index"
                :xs="2"
                :sm="2"
                :md="2"
                :lg="2"
                :xl="2"
                class="month"
              >
                <div :class="[{ 'bg-yellow': k.check==='yellow' },{ 'bg-green': k.check==='green' },{ 'bg-blue': k.check==='blue' }]">{{ isMobile?k.mobile:k.title }}</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-divider />
    <EmploymentHistoryItem
      v-if="employments.length>0"
      ref="EmploymentHistoryItemRef"
      :notes.sync="reopenNotes"
      :target-min-date="targetMinDate"
      :max-date="maxDate"
      :edit-item-index="editItemIndex"
      :employments="employments"
    />
    <div v-if="showDocuments">
      <h4>Document Upload</h4>
      <p class="f-s-14">
        Please upload any Statement of Service (SOS) / Confirmation of Employment
        letters, if available.
      </p>
      <el-radio-group v-model="documentTypeId" :disabled="!ContinueBtn">
        <el-radio-button :label="statementOfServiceDocumentTypeId">Statement of Service</el-radio-button>
        <el-radio-button :label="confirmationOfEmploymentDocumentTypeId">Confirmation of Employment</el-radio-button>
      </el-radio-group>

      <div v-if="documentTypeId">
        <OnlyUpload
          v-if="imgcanDelete"
          :file-loding.sync="fileLoding"
          @Document_Upload="CloudinaryUpload_UploadResult_Employment"
        />
        <div class="file-boxs">
          <OnlyUpload
            v-for="(item,index) in employmentOrderApplicantDocuments"
            :key="item.id"
            :img-item="item"
            :can-delete="getImgcanDelete(item)"
            @Document_Upload="CloudinaryUpload_UploadResult_Employment"
            @Document_Remove="Document_Remove(index,$event)"
          />
        </div>
      </div>

    </div>

    <div>
      <el-button
        v-if="ContinueBtn" :loading="endAllRequests" class="mt-10" type="primary"
        @click="Continue"
      >Save and Continue</el-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.EmploymentHistoryView {
  .file-boxs{
    display: flex;
    flex-wrap: wrap;
  }
  .label {
    line-height: 40px;
    font-size: 14px;
  }
  .year {
    line-height: 40px;
    font-weight: bold;
  }
  .year-label :first-child .test{
    display: flex;
    justify-content: flex-end;
  }

  .month {
    color: #fff;
    font-weight: bold;
    line-height: 40px;
    padding: 0 !important;
    margin-bottom: 10px;

    div {
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
  .bg-green {
    background: #29823b !important;
  }
  .bg-yellow{
    background: #e9a100;
    color: #212121;
  }
  .bg-blue{
    background: #206caa;
  }
  .visibility {
    visibility: hidden;
  }
}
</style>
