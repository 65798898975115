var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RefereesItemView" },
    [
      _vm._l(_vm.refereesList, function (item) {
        return _c("div", { key: item.index, staticClass: "mb-20" }, [
          item.index != _vm.openIndex
            ? _c(
                "div",
                {
                  staticClass: "history-box",
                  on: {
                    click: function ($event) {
                      return _vm.open(item.index)
                    },
                  },
                },
                [_c("p", [_vm._v(" " + _vm._s(item.name) + " ")])]
              )
            : _vm._e(),
          item.index == _vm.openIndex
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: `addForm${item.index}`,
                      refInFor: true,
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: item,
                        rules: _vm.rules,
                        "label-position": "top",
                        disabled: _vm.allDisabled,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Contact Person", prop: "name" } },
                        [
                          _c("el-input", {
                            model: {
                              value: item.name,
                              callback: function ($$v) {
                                _vm.$set(item, "name", $$v)
                              },
                              expression: "item.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "label",
                              attrs: { xs: 24, sm: 15, md: 15, lg: 15, xl: 15 },
                            },
                            [
                              _c("span", { staticClass: "color-red" }, [
                                _vm._v("*"),
                              ]),
                              _vm._v(
                                " Can this person be contacted immediately? "
                              ),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 9, md: 9, lg: 9, xl: 9 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { prop: "canContactImmediately" },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: item.canContactImmediately,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "canContactImmediately",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.canContactImmediately",
                                      },
                                    },
                                    [
                                      _c("el-radio", {
                                        attrs: { label: "Yes" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "No" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Employer Name",
                            prop: "employerName",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: item.employerName,
                              callback: function ($$v) {
                                _vm.$set(item, "employerName", $$v)
                              },
                              expression: "item.employerName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Position Held",
                            prop: "positionHeld",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: item.positionHeld,
                              callback: function ($$v) {
                                _vm.$set(item, "positionHeld", $$v)
                              },
                              expression: "item.positionHeld",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Relationship to You",
                            prop: "relationshipToApplicant",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: item.relationshipToApplicant,
                              callback: function ($$v) {
                                _vm.$set(item, "relationshipToApplicant", $$v)
                              },
                              expression: "item.relationshipToApplicant",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Contact Phone Mobile",
                            prop: "contactPhoneMobile",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "20" },
                            model: {
                              value: item.contactPhoneMobile,
                              callback: function ($$v) {
                                _vm.$set(item, "contactPhoneMobile", $$v)
                              },
                              expression: "item.contactPhoneMobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Contact Phone Business",
                            prop: "contactPhoneBusiness",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "20" },
                            model: {
                              value: item.contactPhoneBusiness,
                              callback: function ($$v) {
                                _vm.$set(item, "contactPhoneBusiness", $$v)
                              },
                              expression: "item.contactPhoneBusiness",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Contact Email",
                            prop: "contactEmail",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: item.contactEmail,
                              callback: function ($$v) {
                                _vm.$set(item, "contactEmail", $$v)
                              },
                              expression: "item.contactEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      !_vm.allDisabled
                        ? _c("el-form-item", [
                            _c("div", { staticClass: "action-box" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdd(
                                        `addForm${item.index}`,
                                        1,
                                        item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Add New Referee"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item.index, item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete-solid",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Remove Referee"),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      _vm.outAddBtnShow && !_vm.allDisabled
        ? _c(
            "div",
            {
              staticClass: "action-box",
              on: {
                click: function ($event) {
                  return _vm.handleAdd("", 2)
                },
              },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _c("br"),
      _vm.continueBtn
        ? _c(
            "el-button",
            {
              attrs: { loading: _vm.endAllRequests, type: "primary" },
              on: { click: _vm.handleSure },
            },
            [_vm._v("Save and Continue")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "action-item" }, [
      _c("i", { staticClass: "el-icon-circle-plus" }),
      _c("span", { staticClass: "action-value f-s-14" }, [
        _vm._v("Add New Referee"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }