<script>
import AddForm from './AddForm/index.vue'
import { complianceReferenceApi } from '@/api/pattern'
export default {
  name: 'ComplianceReference',
  components: { AddForm },
  props: {
    applicantId: {
      type: Number,
      default: null
    },
    applicant: {
      type: Object,
      default: () => {
        return {
          wasAuthorisedRepresentative: null
        }
      }
    },
    minimumQuantity: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      getCompliancesByApplicantId: [],
      HasBeenAuthorisedRepresentative: '',
      moduleLoading: false
    }
  },
  mounted() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      await complianceReferenceApi({ applicantId: this.applicantId }).then(res => {
        // console.log('complianceReferenceApi===', res)
        this.HasBeenAuthorisedRepresentative = res.data.hasBeenAuthorisedRepresentative
        this.getCompliancesByApplicantId = res.data.getCompliancesByApplicantId
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>
<template>
  <div v-loading="moduleLoading" class="ComplianceReference">
    <el-form
      ref="topForm"
      label-width="80px"
      label-position="top"
    >
      <el-form-item prop="hasBeenAuthorisedRepresentative">
        <el-row>
          <el-col :xs="24" :sm="16" :lg="16" :xl="16">
            <span class="label">In the last {{ minimumQuantity }} years have you been
              an Authorised Representative?</span>
          </el-col>
          <el-col :xs="24" :sm="8" :lg="8" :xl="8">
            <el-radio-group
              v-model="HasBeenAuthorisedRepresentative"
              disabled
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>

    <div v-if="getCompliancesByApplicantId.length">
      <AddForm
        ref="AddForm"
        :compliances="getCompliancesByApplicantId"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ComplianceReference {
  ::v-deep .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep .el-collapse-item__header{
    padding:0;
    background-color:rgba(255, 255, 255, 0);
  }
  ::v-deep .el-collapse-item__content{
    background-color:rgba(255, 255, 255, 0);
  }
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
    line-height: 1.5;
  }
}
</style>
