<script>
import { mapState } from 'vuex'
import { preparationApi, continueApi } from '@/api/VulnerablePeople'
export default {
  name: 'VulnerablePeopleView',
  inject: ['ContinueSetSession'],
  data() {
    return {
      ApplicantForm: {
        varEmploymentType: '',
        employmentLocation: '',
        varVulnerablePeopleClass: ''
      },
      rules: {
        varEmploymentType: [
          { required: true, message: 'Please select a value', trigger: 'change' }
        ],
        employmentLocation: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        varVulnerablePeopleClass: [
          { required: true, message: 'Please select a value', trigger: 'change' }
        ]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    buttonText() {
      // If(Session.Current.FormSection.Index < (Session.FormSections.Length - 1), "Save and Continue", "Submit")
      return this.Session.current.formSection.index <
        this.Session.formSections.length - 1
        ? 'Save and Continue'
        : 'Submit'
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      this.allLoading = true
      const params = {
        session: this.Session,
        Applicant: this.Applicant
      }
      await preparationApi(params).then((res) => {
        const { data } = res
        this.ApplicantForm.varEmploymentType = data.varEmploymentType
        this.ApplicantForm.employmentLocation = this.Applicant.employmentLocation
        this.ApplicantForm.varVulnerablePeopleClass =
          data.varVulnerablePeopleClass
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },
    Continue() {
      this.$refs['ApplicantForm'].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            Applicant: this.Applicant,
            varEmploymentType: this.ApplicantForm.varEmploymentType,
            varVulnerablePeopleClass:
              this.ApplicantForm.varVulnerablePeopleClass
          }
          params.Applicant.employmentLocation = this.ApplicantForm.employmentLocation
          params.Applicant.vulnerablePeopleClass = this.ApplicantForm.varVulnerablePeopleClass
          await continueApi(params).then((res) => {
            this.ContinueSetSession(res.data.session)
            const data = this.preparation
            data.applicant.employmentLocation = this.ApplicantForm.employmentLocation
            this.$store.dispatch('preparation/setPreparation', data)
          })
        }
      })
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="VulnerablePeopleView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ApplicantForm"
      :model="ApplicantForm"
      :rules="rules"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item prop="varEmploymentType">
        <label class="label" style="display: flex; flex-direction: column;">
          <span>* Type of Employment</span>
          <el-radio-group v-model="ApplicantForm.varEmploymentType">
            <el-radio-button label="Paid">Paid Employment</el-radio-button>
            <el-radio-button label="Volunteer">Volunteer Work</el-radio-button>
          </el-radio-group>
        </label>
      </el-form-item>
      <el-form-item
        prop="employmentLocation"
      >
        <label class="label">* Employment Location (Town / State)
          <el-input v-model="ApplicantForm.employmentLocation" />
        </label>
      </el-form-item>

      <template v-if="ApplicantForm.varVulnerablePeopleClass">
        <el-row :gutter="20" class="f-s-14">
          <el-col
            :xs="22" :sm="22" :md="22" :lg="22"
            :xl="22"
          >
            <p class="label"><span class="color-red">*</span> Select the statement that best describes the role:</p>
          </el-col>
          <el-col
            :xs="1" :sm="1" :md="1" :lg="1"
            :xl="1"
          >
            <el-popover
              placement="left"
              width="400"
              trigger="hover"
            >
              <span class="f-w-600">Definition of Working with Vulnerable People</span>
              <el-divider />
              <div class="text item">
                <p class="f-w-600">Vulnerable groups mean:</p>
                <p>
                  a child an adult who is disadvantaged or in need of special
                  care, support or protection because of age, disability or risk
                  of abuse or neglect.
                </p>
                <p>
                  <span class="f-w-600">Contact means</span> direct or indirect
                  face-to-face contact, phone contact or any type of communication
                  over the internet.
                </p>
                <p>
                  <span class="f-w-600">Supervision means</span> in the presence
                  of an adult who is responsible for the safety or care of the
                  vulnerable person.
                </p>
                <p>
                  <span class="f-w-600">Unsupervised means</span> you will not be
                  in the presense of an adult who is responsible for the safety or
                  care of the vulnerable person.
                </p>
              </div>
              <i slot="reference" class="fa fa-info" />
            </el-popover>

          </el-col>
        </el-row>

        <el-form-item prop="varVulnerablePeopleClass">
          <el-radio-group v-model="ApplicantForm.varVulnerablePeopleClass">
            <div class="mt-10">
              <el-radio label="Supervised">
                <span class="f-w-600">Supervised contact</span> with vulnerable
                people
              </el-radio>
            </div>
            <div class="mt-10">
              <el-radio label="Unsupervised">
                <span class="f-w-600">Unsupervised contact</span> with vulnerable
                people
              </el-radio>
            </div>
            <div class="mt-10">
              <el-radio label="No contact">
                <span class="f-w-600">No contact</span> with vulnerable people
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </template>

      <el-form-item>
        <el-button
          v-if="ContinueBtn"
          :loading="endAllRequests"
          type="primary"
          @click="Continue('ruleForm')"
        >{{ buttonText }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.label {
    margin: 0;
    font-size: 14px;
    color: #606266;
  }
</style>
