var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "EntitlementToWorkView",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "label-width": "50%",
            "label-position": "top",
            disabled: "",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Citizenship Country",
                prop: "selectedCitizenshipCountryCode",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.ruleForm.citizenshipCountry,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "citizenshipCountry", $$v)
                  },
                  expression: "ruleForm.citizenshipCountry",
                },
              }),
            ],
            1
          ),
          _vm.ruleForm.selectedCertificateDocumentType === ""
            ? _c("div", [
                _vm.hasPassportTrueShow
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  xs: 24,
                                  sm: 24,
                                  md: 12,
                                  lg: 12,
                                  xl: 12,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Passport Number",
                                      prop: "passportNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.ruleForm.passportNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "passportNumber",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.passportNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: {
                                  xs: 24,
                                  sm: 24,
                                  md: 12,
                                  lg: 12,
                                  xl: 12,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Passport Country",
                                      prop: "selectedPassportCountryCode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.ruleForm.passportCountry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "passportCountry",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.passportCountry",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("div", [
                      _c("p", { staticStyle: { margin: "10px 0" } }, [
                        _vm._v("Passport"),
                      ]),
                      _c("p", { staticClass: "f-w-600" }, [
                        _vm._v("No Passport"),
                      ]),
                    ]),
              ])
            : _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "f-w-600",
                      attrs: {
                        label:
                          "Please attach a legible copy of your Birth Certificate or Citizenship Certificate",
                        prop: "selectedCertificateDocumentType",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.ruleForm.selectedCertificateDocumentType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "selectedCertificateDocumentType",
                                $$v
                              )
                            },
                            expression:
                              "ruleForm.selectedCertificateDocumentType",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "Birth" } }, [
                            _vm._v("Birth Certificate"),
                          ]),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "Citizenship" } },
                            [_vm._v("Citizenship Certificate")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c("ImageShow", {
            attrs: {
              "img-list": _vm.getOrderApplicantDocumentsByDocumentTypeId,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }