import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/professional-memberships' : '/proxy/api/applicant/professional-memberships'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}
export function continueApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}
export function saveFormDataApi(data) {
  return service({
    url: `${api}/save-form-data`,
    method: 'post',
    data: data
  })
}
export function onRadioHasMembershipChangeApi(data) {
  return service({
    url: `${api}/on-radio-has-membership-change`,
    method: 'post',
    data: data
  })
}
