<script>
import { entitlementToWorkApi } from '@/api/pattern'
import ImageShow from '../components/ImageShow/index.vue'
export default {
  name: 'EntitlementToWorkView',
  components: { ImageShow },
  props: {
    applicantId: {
      type: Number,
      default: 0
    },
    sectionId: {
      type: Number,
      default: 0
    },
    applicant: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {},
      getOrderApplicantDocumentsByDocumentTypeId: [],
      moduleLoading: false
    }
  },
  computed: {
    hasPassportTrueShow() {
      return this.ruleForm.passportNumber || this.ruleForm.passportCountry
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId,
        sectionId: this.sectionId
      }
      await entitlementToWorkApi(params).then((res) => {
        const { data } = res
        this.ruleForm = this.applicant
        this.ruleForm.selectedCertificateDocumentType = data.selectedCertificateDocumentType
        this.getOrderApplicantDocumentsByDocumentTypeId = data.getOrderApplicantDocumentsByDocumentTypeId
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>
<template>
  <div v-loading="moduleLoading" class="EntitlementToWorkView">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
      disabled
    >
      <el-form-item
        label="Citizenship Country"
        prop="selectedCitizenshipCountryCode"
      >
        <el-input v-model="ruleForm.citizenshipCountry" disabled />
      </el-form-item>

      <div v-if="ruleForm.selectedCertificateDocumentType === ''">
        <div v-if="hasPassportTrueShow">
          <el-row :gutter="20">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item label="Passport Number" prop="passportNumber">
                <el-input v-model="ruleForm.passportNumber" disabled />
              </el-form-item>
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-form-item
                label="Passport Country"
                prop="selectedPassportCountryCode"
              >
                <el-input v-model="ruleForm.passportCountry" disabled />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <p style="margin:10px 0">Passport</p>
          <p class="f-w-600">No Passport</p>
        </div>
      </div>

      <div v-else>
        <el-form-item
          class="f-w-600"
          label="Please attach a legible copy of your Birth Certificate or Citizenship Certificate"
          prop="selectedCertificateDocumentType"
        >
          <el-radio-group
            v-model="ruleForm.selectedCertificateDocumentType"
            disabled
          >
            <el-radio-button label="Birth">Birth Certificate</el-radio-button>
            <el-radio-button label="Citizenship">Citizenship Certificate</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>

      <ImageShow :img-list="getOrderApplicantDocumentsByDocumentTypeId" />

    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.EntitlementToWorkView {
  .f-w-600{
    font-weight: 600;
  }
  .label {
    line-height: 40px;
    font-size: 14px;
    color: #606266;
  }
   ::v-deep .el-radio-button__orig-radio:disabled + .el-radio-button__inner{
    border-color: #206caa;
    color:#206caa;
  }
  ::v-deep .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner{
    color: #FFFFFF;
    background-color: #206caa;
  }
}
</style>
