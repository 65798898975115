<script>
import { qualificationsTradeApi } from '@/api/pattern'
export default {
  name: 'QualificationsTrade',
  props: {
    applicantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      manualAddressEntry: {},
      isManualAddress: false,
      AustralianStates: {},
      Countries: {},
      selectedCountryCode: '',
      qualificationOrderApplicantDocuments: [],
      FilePondUploadConfig: {},
      NewCloudinaryResource: {},
      CurrentFormSection: {},
      FormData_Qualifications: {},
      qualification: {},
      ruleForm: {
        initialAddress: '',
        selectedCountryCode: '',
        schoolLocality: '',
        contactPhone: '',
        qualificationName: '',
        dateCompleted: '',
        state: '',
        suburb: '',
        name: '',
        schoolName: ''
      },
      CountryList: [],
      moduleLoading: false
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }
      await qualificationsTradeApi(params).then((res) => {
        const { data } = res
        this.ruleForm = data.qualifications[0]
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="QualificationsTrade">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item label="Organisation Name" prop="schoolName">
        <el-input v-model="ruleForm.schoolName" disabled />
      </el-form-item>
      <el-form-item label="School Location" prop="initialAddress">
        <div class="box">
          <p>
            {{ ruleForm.schoolLocality }} {{ ruleForm.schoolState }} {{ ruleForm.schoolCountry }}
          </p>
        </div>
      </el-form-item>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Contact Phone" prop="contactPhone">
            <el-input v-model="ruleForm.contactPhone" disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
        label="Name of Qualification/Trade"
        prop="qualificationName"
      >
        <el-input v-model="ruleForm.qualificationName" disabled />
      </el-form-item>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Date Completed" prop="dateCompleted">
            <el-date-picker
              v-model="ruleForm.dateCompleted"
              type="date"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
  margin: 10px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: no-drop;
  p {
    font-size: 14px;
    margin: 0;
    line-height: 40px;
  }
}
</style>
