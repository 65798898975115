<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import {
  preparationApi,
  continueApi,
  saveFormDataApi,
  onRadioHasMembershipChangeApi
} from '@/api/ProfessionalMemberships'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'ProfessionalMembershipsView',
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  data() {
    return {
      id: 0,
      ruleForm: {
        hasProfessionalMembership: '',
        institutionName: '',
        locality: '',
        state: '',
        country: '',
        contactPhone: '',
        type: '',
        referenceNo: ''
      },
      rules: {
        hasProfessionalMembership: [
          { required: true, message: 'Please select a value', trigger: 'change' }
        ],
        institutionName: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        locality: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        state: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        country: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        contactPhone: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        type: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        referenceNo: [{ required: true, message: 'Required field!', trigger: 'blur' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Intro() {
      return this.preparation.intro
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Session() {
      return this.preparation.session
    },
    Applicant() {
      return this.preparation.applicant
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
    // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
       this.Session.current.formSection.completePercent < 100 ||
       this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'ProfessionalMemberships')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        Applicant: this.Applicant
      }
      await preparationApi(params).then((res) => {
        const { data } = res
        this.id = data.professionalMembership ? data.professionalMembership.id : null
        this.ruleForm = data.professionalMembership || {}
        this.ruleForm.hasProfessionalMembership = data.hasProfessionalMembership
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },
    Continue() {
      this.$refs['ruleForm'].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            hasProfessionalMembership: this.ruleForm.hasProfessionalMembership
          }
          params['professionalMembership'] = Object.assign({}, this.ruleForm)
          params['professionalMembership'].id = this.id || 0
          params['professionalMembership'].applicantId = this.ApplicantId
          delete params['professionalMembership'].hasProfessionalMembership
          await continueApi(params).then((res) => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    SaveFormTempData: _.debounce(
      function() {
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId,
          hasProfessionalMembership: this.ruleForm.hasProfessionalMembership
        }
        params['professionalMembership'] = Object.assign({}, this.ruleForm)
        params['professionalMembership'].id = this.id || 0
        params['professionalMembership'].applicantId = this.ApplicantId

        delete params['professionalMembership'].hasProfessionalMembership

        saveFormDataApi(params).then((res) => {
          if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
        })
      }, global.debounceTime),

    onRadioHasMembershipChange() {
      const params = {
        session: this.Session,
        applicantId: this.ApplicantId,
        hasProfessionalMembership: this.ruleForm.hasProfessionalMembership
      }
      if (this.ruleForm.hasProfessionalMembership === 'No') {
        this.ruleForm.institutionName = null
        this.ruleForm.locality = null
        this.ruleForm.state = null
        this.ruleForm.country = null
        this.ruleForm.contactPhone = null
        this.ruleForm.type = null
        this.ruleForm.referenceNo = null
        params['professionalMembership'] = Object.assign({}, this.ruleForm)
        delete params['professionalMembership'].hasProfessionalMembership
        onRadioHasMembershipChangeApi(params).then(res => {
          if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
        })
      } else {
        this.SaveFormTempData()
      }
    }
  }
}
</script>

<template>
  <div v-loading="allLoading" class="ProfessionalMembershipsView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-form-item
        prop="hasProfessionalMembership"
      >
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="24" :lg="16"
            :xl="16" class="label"
          >
            Do you hold a Professional Membership?
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="24" :lg="8"
            :xl="8"
          >
            <el-radio-group v-model="ruleForm.hasProfessionalMembership" @change="onRadioHasMembershipChange">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <template v-if="ruleForm.hasProfessionalMembership === 'Yes'">
        <div style="font-size: 16px; font-weight: 600;">Please provide your most relevant Professional Membership</div>
        <el-form-item prop="institutionName">
          <label class="label">* Institution Name
            <el-input v-model="ruleForm.institutionName" maxlength="250" @input="SaveFormTempData" />
          </label>
        </el-form-item>
        <el-form-item prop="locality">
          <label class="label">* City / Suburb
            <el-input v-model="ruleForm.locality" @input="SaveFormTempData" />
          </label>
        </el-form-item>
        <el-form-item prop="state">
          <label class="label">* State
            <el-input v-model="ruleForm.state" @input="SaveFormTempData" />
          </label>
        </el-form-item>
        <el-form-item prop="country">
          <label class="label">* Country
            <el-input v-model="ruleForm.country" @input="SaveFormTempData" />
          </label>
        </el-form-item>
        <el-form-item prop="contactPhone">
          <label class="label">* Contact Phone Number
            <el-input v-model="ruleForm.contactPhone" maxlength="20" @input="SaveFormTempData" />
          </label>
        </el-form-item>
        <el-form-item
          prop="type"
        >
          <label class="label">* Membership Type (Name or Title e.g. Associate or Affiliate)
            <el-input v-model="ruleForm.type" maxlength="100" @input="SaveFormTempData" />
          </label>
        </el-form-item>
        <el-form-item prop="referenceNo">
          <label class="label">* Membership Reference Number
            <el-input v-model="ruleForm.referenceNo" maxlength="50" @input="SaveFormTempData" />
          </label>
        </el-form-item>
      </template>
      <el-form-item>
        <!-- <el-button type="primary" @click="SaveFormTempData()">Save form data</el-button> -->
        <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue">Save and Continue</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.ProfessionalMembershipsView {
  .label {
    line-height: 40px;
    font-size: 14px;
    color: #606266;
  }
  .el-form-item__label {
    line-height: 40px;
  }
}
</style>
