var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.compliancesList.length === 0
        ? _c("p", { staticClass: "f-s-14" }, [
            _vm._v('please select "Add New Item" to begin'),
          ])
        : _vm._e(),
      _vm._l(_vm.compliancesList, function (item) {
        return _c("div", { key: item.index }, [
          item.index != _vm.openIndex
            ? _c(
                "div",
                {
                  staticClass: "history-box",
                  on: {
                    click: function ($event) {
                      return _vm.open(item.index)
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(item.jobTitle) +
                        " (" +
                        _vm._s(
                          _vm._f("parseDefaultTime")(
                            item.dateCommenced,
                            "{g} {y}"
                          )
                        ) +
                        " - " +
                        _vm._s(
                          _vm._f("parseDefaultTime")(item.dateEnded, "{g} {y}")
                        ) +
                        ") "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          item.index == _vm.openIndex
            ? _c(
                "div",
                [
                  _c("h4", [
                    _vm._v(_vm._s(item.id ? "Editing" : "New") + " Job Title"),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: `addForm${item.index}`,
                      refInFor: true,
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: item,
                        rules: _vm.rules,
                        "label-width": "50%",
                        "label-position": "top",
                        disabled: _vm.allDisabled,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Job Title", prop: "jobTitle" } },
                        [
                          _c("el-input", {
                            on: { input: _vm.Question_DataChange },
                            model: {
                              value: item.jobTitle,
                              callback: function ($$v) {
                                _vm.$set(item, "jobTitle", $$v)
                              },
                              expression: "item.jobTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "When did the employment commence?",
                                    prop: "dateCommenced",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd",
                                      format: "MM/yyyy",
                                      type: "month",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: { change: _vm.Question_DataChange },
                                    model: {
                                      value: item.dateCommenced,
                                      callback: function ($$v) {
                                        _vm.$set(item, "dateCommenced", $$v)
                                      },
                                      expression: "item.dateCommenced",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "When did the employment conclude?",
                                    prop: "dateEnded",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      "value-format": "yyyy-MM-dd",
                                      format: "MM/yyyy",
                                      type: "month",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: { change: _vm.Question_DataChange },
                                    model: {
                                      value: item.dateEnded,
                                      callback: function ($$v) {
                                        _vm.$set(item, "dateEnded", $$v)
                                      },
                                      expression: "item.dateEnded",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.allDisabled
                        ? _c("el-form-item", [
                            _c("div", { staticClass: "action-box" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdd(
                                        `addForm${item.index}`,
                                        1,
                                        item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Add New Item"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item.index, item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete-solid",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Remove Item"),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      !_vm.outAddBtnShow && !_vm.allDisabled ? _c("el-divider") : _vm._e(),
      _vm.outAddBtnShow
        ? _c(
            "div",
            {
              staticClass: "action-box",
              on: {
                click: function ($event) {
                  return _vm.handleAdd("", 2)
                },
              },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "action-item" }, [
      _c("i", { staticClass: "el-icon-circle-plus" }),
      _c("span", { staticClass: "action-value f-s-14" }, [
        _vm._v("Add New Item"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }