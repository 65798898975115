<script>
import { mapState } from 'vuex'
import { coiDeclarationNabApi } from '@/api/pattern'
export default {
  name: 'CoIDeclarationNABView',
  props: {
    applicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      ruleForm: {
        hasRelWIthNABEmployee: '',
        colHasRelWithNabEmployeeDel: '',
        hasRelWithOfficial: '',
        colHasRelWithOfficialDetail: '',
        hasBizOrg: '',
        colHasBizOrgInAuDetail: '',
        criminalInterestOrInvestigatedByState: '',
        colBeingInvestigatedDetail: ''
      },
      moduleLoading: false
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      await coiDeclarationNabApi({ applicantId: this.applicantId }).then(res => {
        // console.log('coiDeclarationNabApi===', res)
        this.ruleForm = res.data.getApplicantDeclarationByApplicantId || {}
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="MedicalDeclarationView">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-form-item>
        <span slot="label">
          1. Do you have any close personal or financial relationships with another employee of NAB?
        </span>
        <el-radio-group v-model="ruleForm.colHasRelWithNabEmployee" disabled>
          <el-radio :label="true">Yes</el-radio>
          <el-radio :label="false">No</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.colHasRelWithNabEmployee"
        label="If yes, provide the name of the NAB employee(s) and the nature of each relationship."
      >
        <el-input
          v-model="ruleForm.colHasRelWithNabEmployeeDel"
          type="textarea"
          maxlength="1000"
          disabled
        />
      </el-form-item>

      <el-form-item prop="hasRelWithOfficial">
        <span slot="label">
          2. Do you have a close personal or financial relationship with any of the below persons?
          <br>• An executive member of any public department of any
          Government, State or Territories in Australia or internationally.
          <br>• An elected member of any government, state or local
          municipality in Australia or internationally.
        </span>
        <el-radio-group v-model="ruleForm.colHasRelWithOfficial" disabled>
          <el-radio :label="true">Yes</el-radio>
          <el-radio :label="false">No</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.colHasRelWithOfficial"
        label="If yes, provide details including the name of the NAB employee(s) and the nature of your relationship."
      >
        <el-input
          v-model="ruleForm.colHasRelWithOfficialDetail"
          type="textarea"
          maxlength="1000"
          disabled
        />
      </el-form-item>

      <el-form-item>
        <span slot="label">
          3. In relation to any business or other organisation in Australia and internationally, do you:
          <br>• hold an official directorship/officeholder position
          <br>• have any financial interest
          <br>• have influence, including over the board or any committee or associated group thereof
          <br>• provide advisory or other services
          <br>Includes competitors of and suppliers to NAB as well as other interests external to NAB.
        </span>
        <el-radio-group v-model="ruleForm.colHasBizOrgInAu" disabled>
          <el-radio :label="true">Yes</el-radio>
          <el-radio :label="false">No</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.colHasBizOrgInAu"
        label="If yes, provide details including the name of the NAB employee(s) and the nature of your relationship."
      >
        <el-input
          v-model="ruleForm.colHasBizOrgInAuDetail"
          type="textarea"
          maxlength="1000"
          disabled
        />
      </el-form-item>

      <el-form-item>
        <span slot="label">
          4. Are you the person of interest in an ongoing criminal matter before the Courts or being investigated by a state or federal government body?
        </span>
        <el-radio-group
          v-model="ruleForm.colBeingInvestigated"
          disabled
        >
          <el-radio :label="true">Yes</el-radio>
          <el-radio :label="false">No</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.colBeingInvestigated"
        label="If yes, provide details of the offence, your involvement/interest in the criminal investigation."
      >
        <el-input
          v-model="ruleForm.colBeingInvestigatedDetail"
          type="textarea"
          maxlength="1000"
          disabled
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss">
.MedicalDeclarationView {
  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
