var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddressForm" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        _vm._l(_vm.compliances, function (item) {
          return _c(
            "el-collapse-item",
            {
              key: item.index,
              attrs: {
                title: `${item.licenseeName}(${item.licenseeNumber}) - ${item.complianceTeamMemberName}`,
                name: item.index,
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: `addForm${item.index}`,
                      refInFor: true,
                      attrs: {
                        model: item,
                        "label-width": "80px",
                        "label-position": "top",
                        disabled: "",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Licencee Name" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "150" },
                            model: {
                              value: item.licenseeName,
                              callback: function ($$v) {
                                _vm.$set(item, "licenseeName", $$v)
                              },
                              expression: "item.licenseeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Licencee Number" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "50" },
                            model: {
                              value: item.licenseeNumber,
                              callback: function ($$v) {
                                _vm.$set(item, "licenseeNumber", $$v)
                              },
                              expression: "item.licenseeNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              "Full Name of Compliance Team Member at Licencee",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "150" },
                            model: {
                              value: item.complianceTeamMemberName,
                              callback: function ($$v) {
                                _vm.$set(item, "complianceTeamMemberName", $$v)
                              },
                              expression: "item.complianceTeamMemberName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Position held by Compliance Team Member",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "150" },
                            model: {
                              value: item.complianceTeamMemberPosition,
                              callback: function ($$v) {
                                _vm.$set(
                                  item,
                                  "complianceTeamMemberPosition",
                                  $$v
                                )
                              },
                              expression: "item.complianceTeamMemberPosition",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Contact Phone Number" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "20" },
                            model: {
                              value: item.contactPhone,
                              callback: function ($$v) {
                                _vm.$set(item, "contactPhone", $$v)
                              },
                              expression: "item.contactPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Work Email Address" } },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "250" },
                            model: {
                              value: item.workEmail,
                              callback: function ($$v) {
                                _vm.$set(item, "workEmail", $$v)
                              },
                              expression: "item.workEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Can contact?" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: "" },
                              model: {
                                value: item.cancontact,
                                callback: function ($$v) {
                                  _vm.$set(item, "cancontact", $$v)
                                },
                                expression: "item.cancontact",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("Yes"),
                              ]),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("No"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }