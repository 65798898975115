var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SelectView" },
    [
      _c("h3", [_vm._v("Your Background Check Applications")]),
      _c("p", [
        _vm._v("We found the following Background Check Applications for you."),
      ]),
      _c("el-divider"),
      _c(
        "div",
        [
          _vm._l(_vm.openOrderApplicants, function (item) {
            return _c("div", { key: item.id, staticClass: "order-box" }, [
              _c("div", { staticClass: "text-l" }, [
                _c("p", { staticClass: "f-w-600" }, [
                  _vm._v("PBS Ref# " + _vm._s(item.referenceNumber)),
                ]),
                _c("p", [_vm._v(_vm._s(item.stage))]),
              ]),
              _c("div", { staticClass: "text-l" }, [
                _c("p", { staticClass: "f-w-600" }, [
                  _vm._v(_vm._s(item.jobPosition)),
                ]),
                _c("p", [_vm._v(_vm._s(item.organisationName))]),
              ]),
              _c(
                "div",
                {
                  class: [
                    "text-r",
                    _vm.device === "mobile" ? "" : "primary-button",
                  ],
                },
                [
                  item.policeReportAvailableFrom
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.reportLoading },
                          on: {
                            click: function ($event) {
                              return _vm.ViewReport(item)
                            },
                          },
                        },
                        [_vm._v("View Report")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      class: [
                        _vm.device === "mobile" ? "" : "primary-button",
                        item.buttonText === "Review" ? "color-green" : "",
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.start(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.buttonText))]
                  ),
                ],
                1
              ),
            ])
          }),
          _vm._l(_vm.closedOrderApplicants, function (item) {
            return _c("div", { key: item.id, staticClass: "order-box" }, [
              _c("div", { staticClass: "text-l" }, [
                _c("p", { staticClass: "f-w-600" }, [
                  _vm._v("PBS Ref# " + _vm._s(item.referenceNumber)),
                ]),
                _c("p", [_vm._v(_vm._s(item.stage))]),
              ]),
              _c("div", { staticClass: "text-l" }, [
                _c("p", { staticClass: "f-w-600" }, [
                  _vm._v(_vm._s(item.jobPosition)),
                ]),
                _c("p", [_vm._v(_vm._s(item.organisationName))]),
              ]),
              _c(
                "div",
                { class: ["text-r", _vm.device === "mobile" ? "width40" : ""] },
                [
                  item.policeReportAvailableFrom
                    ? _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.reportLoading },
                          on: {
                            click: function ($event) {
                              return _vm.ViewReport(item)
                            },
                          },
                        },
                        [_vm._v("View Report")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }