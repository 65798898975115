var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AuthorisedRepresentative" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, "label-position": "top", disabled: "" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "isRepresentative" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { xs: 24, sm: 16, lg: 16, xl: 16 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "Do you currently or have you previously had an AFS Representative Number?"
                      ),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 8, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.isRepresentative,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "isRepresentative", $$v)
                            },
                            expression: "ruleForm.isRepresentative",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "Yes" } }),
                          _c("el-radio", { attrs: { label: "No" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showItem
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "afsRepresentativeNumber",
                    label: "Please provide your AFS Representative Number",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 14, lg: 14, xl: 14 } },
                        [
                          _c("el-input", {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.ruleForm.afsRepresentativeNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "afsRepresentativeNumber",
                                  $$v
                                )
                              },
                              expression: "ruleForm.afsRepresentativeNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }