<script>
import { entitlementToWork2Api } from '@/api/pattern'
import ImageShow from '../components/ImageShow/index.vue'
export default {
  name: 'EntitlementToWorkView',
  components: { ImageShow },
  props: {
    applicantId: {
      type: Number,
      default: 0
    },
    sectionId: {
      type: Number,
      default: 0
    },
    applicant: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ruleForm: {},
      getOrderApplicantDocumentsByDocumentTypeId: [],
      getChangeOfNameOrderApplicantDocuments: [],
      moduleLoading: false
    }
  },
  computed: {
    hasPassportTrueShow() {
      return this.ruleForm.passportNumber !== '' || this.ruleForm.passportCountry !== ''
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId,
        sectionId: this.sectionId
      }

      await entitlementToWork2Api(params).then((res) => {
        const { data } = res
        this.ruleForm = Object.assign({}, this.applicant)
        this.ruleForm.selectedCertificateDocumentType = data.selectedCertificateDocumentType
        this.getOrderApplicantDocumentsByDocumentTypeId = data.getOrderApplicantDocumentsByDocumentTypeId
        this.getChangeOfNameOrderApplicantDocuments = data.getChangeOfNameOrderApplicantDocuments
        this.ruleForm.dualCitizenshipCountry = this.ruleForm.isDualcitizenship
          ? (this.ruleForm.citizenshipDualCountry ? this.ruleForm.citizenshipDualCountry : this.ruleForm.citizenShipDualCountry) : 'Not Dual Citizen'
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="EntitlementToWork2ViewRN">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
      disabled
    >
      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Country of Citizenship"
            prop="selectedCitizenshipCountryCode"
          >
            <el-input v-model="ruleForm.citizenshipCountry" />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Second Country of Citizenship"
            prop="selectedCitizenshipDualCountryCode"
          >
            <el-input v-model="ruleForm.dualCitizenshipCountry" />
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="ruleForm.selectedCertificateDocumentType === ''">
        <el-row v-if="hasPassportTrueShow" :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Passport Number" prop="passportNumber">
              <el-input v-model="ruleForm.passportNumber" />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item
              label="Passport Country"
              prop="selectedPassportCountryCode"
            >
              <el-input v-model="ruleForm.passportCountry" />
            </el-form-item>
          </el-col>
        </el-row>
        <p v-else>No Passport</p>
      </div>

      <div v-else>
        <el-form-item
          class="f-w-600"
          label="Please attach a legible copy of your Birth Certificate or Citizenship Certificate"
          prop="selectedCertificateDocumentType"
        >
          <el-radio-group v-model="ruleForm.selectedCertificateDocumentType">
            <el-radio-button label="Birth">Birth Certificate</el-radio-button>
            <el-radio-button label="Citizenship">Citizenship Certificate</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>

      <ImageShow v-if="getOrderApplicantDocumentsByDocumentTypeId.length>0" :img-list="getOrderApplicantDocumentsByDocumentTypeId" />

      <div v-if="getChangeOfNameOrderApplicantDocuments.length>0">
        <h3>Proof of Name Change Document</h3>
        <ImageShow :img-list="getChangeOfNameOrderApplicantDocuments" />
      </div>

    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.EntitlementToWork2ViewRN {
  .label {
    line-height: 40px;
    font-size: 14px;
    color: #606266;
  }
  ::v-deep .el-radio-button__orig-radio:disabled + .el-radio-button__inner{
    border-color: #206caa;
    color:#206caa;
  }
  ::v-deep .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner{
    color: #FFFFFF;
    background-color: #206caa;
  }
}
</style>
