import router from '@/router'
import store from '@/store'

export default function() {
  let setInterval = null// 定时器
  const timeOutPrompt = 1800 * 1000 // 设置超时弹出提示: 60秒钟 *30
  const timeOut = 3600 * 1000 // 设置超时退出时间: 60秒钟 *60

  // console.log("开始", new Date().getTime())
  // 初次向sessionStorage存入操作时间
  window.sessionStorage.setItem('lastTime', new Date().getTime())
  // 每次操作页面，更新sessionStorage存入的操作时间
  window.onload = function() {
    window.document.onmousedown = function() {
      // console.log("开始", new Date().getTime())
      window.sessionStorage.setItem('lastTime', new Date().getTime())
    }
  }
  function checkTimeout() {
    const currentTime = new Date().getTime() // 当前时间
    const lastTime = window.sessionStorage.getItem('lastTime')// 上次操作的时间

    // 判断是否超时弹出提示
    if (currentTime - lastTime >= timeOutPrompt) {
      if (!store.getters.session_timeout) {
        store.dispatch('setting/set_session_timeout', true)
      }
    }

    // 判断是否超时
    if (currentTime - lastTime >= timeOut) {
      // console.log("结束", new Date().getTime())
      // 清除定时器
      window.clearInterval(setInterval)
      // 清除sessionStorage
      window.sessionStorage.clear('lastTime')
      // 跳到登陆页
      if (router.history.current.fullPath !== '/' &&
      router.history.current.fullPath !== '/login' &&
      router.history.current.fullPath !== '/register' &&
      router.history.current.fullPath !== '/sessionExpired') {
        router.push('/login')
        if (store.getters.session_timeout) {
          store.dispatch('setting/set_session_timeout', false)
        }
        store.dispatch('user/resetToken')
        store.dispatch('preparation/resetPreparation')
        store.dispatch('section/reset_section')
        window.location.reload()
      }
    }
  }
  /* 定时器 间隔2秒检测是否长时间未操作页面 */
  setInterval = window.setInterval(checkTimeout, 2000)
}
