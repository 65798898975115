var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "employmentOrGapForm" },
    [
      _vm._l(_vm.compliancesList, function (item) {
        return _c("div", { key: item.index }, [
          item.index != _vm.openIndex
            ? _c(
                "div",
                {
                  staticClass: "history-box",
                  on: {
                    click: function ($event) {
                      return _vm.open(item.index)
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(item.employmentOrGap) +
                        ": " +
                        _vm._s(
                          item.employmentOrGap === "Gap"
                            ? item.gapReason
                            : item.businessFullName
                        ) +
                        " ( " +
                        _vm._s(
                          _vm._f("parseDefaultTime")(
                            item.dateCommenced,
                            "{g} {y}"
                          )
                        ) +
                        " - "
                    ),
                    item.employmentOrGap !== "Gap" &&
                    item.isStillEmployed === "Yes"
                      ? _c("span", [_vm._v("Present")])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("parseDefaultTime")(
                                item.dateEnded,
                                "{g} {y}"
                              )
                            )
                          ),
                        ]),
                    _vm._v(" ) "),
                  ]),
                ]
              )
            : _vm._e(),
          item.index == _vm.openIndex
            ? _c(
                "div",
                [
                  _vm.ReopenNotes
                    ? _c(
                        "div",
                        {
                          staticClass: "ReopenNotes",
                          staticStyle: {
                            color: "#0e1011",
                            "font-weight": "bolder",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "expression-left-box",
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                color: "#0e1011",
                                "font-weight": "bolder",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning",
                                staticStyle: { "margin-left": "10px" },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    color: "#0e1011",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Please provide your employment history up to " +
                                      _vm._s(
                                        _vm._f("parseDefaultTime")(
                                          _vm.ReopenNotes === "start"
                                            ? _vm.targetMinDate
                                            : _vm.maxDate,
                                          "{g} {y}"
                                        )
                                      )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c("i", {
                            staticClass: "el-icon-close",
                            on: {
                              click: function ($event) {
                                _vm.ReopenNotes = ""
                              },
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "section-header" }, [
                    _vm._v(
                      _vm._s(item.status === "edit" ? "Editing" : "New") +
                        " " +
                        _vm._s(item.employmentOrGap || "Item") +
                        " "
                    ),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: `addForm${item.index}`,
                      refInFor: true,
                      attrs: {
                        model: item,
                        rules: _vm.rules,
                        "label-width": "80px",
                        "label-position": "top",
                        disabled: _vm.allDisabled,
                      },
                    },
                    [
                      item.status !== "edit"
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { label: "", prop: "employmentOrGap" },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "label" },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-bottom": "5px" } },
                                    [
                                      _vm._v(
                                        "* What are you adding to your Employment History?"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.SaveFormData(
                                            "",
                                            item.index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.employmentOrGap,
                                        callback: function ($$v) {
                                          _vm.$set(item, "employmentOrGap", $$v)
                                        },
                                        expression: "item.employmentOrGap",
                                      },
                                    },
                                    [
                                      _c("el-radio", {
                                        attrs: { label: "Employment" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "Gap" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      item.employmentOrGap == "Employment"
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "businessFullName" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _vm._v(
                                      "* Employer / Business Name (Full Name) "
                                    ),
                                    _c("el-input", {
                                      on: {
                                        input: function ($event) {
                                          return _vm.SaveFormData(
                                            "",
                                            item.index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.businessFullName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "businessFullName",
                                            $$v
                                          )
                                        },
                                        expression: "item.businessFullName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "0" },
                                attrs: { prop: "typeOfEmployment" },
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [_vm._v("* Type of Employment:")]
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "typeEmp",
                                        on: {
                                          change: function ($event) {
                                            return _vm.SaveFormData(
                                              "",
                                              item.index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.typeOfEmployment,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "typeOfEmployment",
                                              $$v
                                            )
                                          },
                                          expression: "item.typeOfEmployment",
                                        },
                                      },
                                      [
                                        _c("el-radio", {
                                          attrs: { label: "Employment" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "Volunteer" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "Traineeship" },
                                        }),
                                        _c("el-radio", {
                                          attrs: {
                                            label: "Internship / Cadet",
                                          },
                                        }),
                                        _c("el-radio", {
                                          attrs: {
                                            label:
                                              "Placement / Work Experience",
                                          },
                                        }),
                                        _vm.isMobile ? _c("br") : _vm._e(),
                                        _c("el-radio", {
                                          attrs: { label: "Self-Employed" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("br"),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "isEmploymentPaid" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [_vm._v("* Was the Employment Paid?")]
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.SaveFormData(
                                              "",
                                              item.index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.isEmploymentPaid,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "isEmploymentPaid",
                                              $$v
                                            )
                                          },
                                          expression: "item.isEmploymentPaid",
                                        },
                                      },
                                      [
                                        _c("el-radio", {
                                          attrs: { label: "Yes" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "No" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "isStillEmployed" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "* Are you still employed at this company?"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.isStillEmployedChange(
                                              item.index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.isStillEmployed,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "isStillEmployed",
                                              $$v
                                            )
                                          },
                                          expression: "item.isStillEmployed",
                                        },
                                      },
                                      [
                                        _c("el-radio", {
                                          attrs: { label: "Yes" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "No" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 12,
                                      lg: 12,
                                      xl: 12,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "dateCommenced" } },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "label" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "* When did you start working for this employer?"
                                                ),
                                              ]
                                            ),
                                            _c("el-date-picker", {
                                              attrs: {
                                                "picker-options":
                                                  _vm.pickerOptions,
                                                type: "month",
                                                format: "MM/yyyy",
                                                "value-format": "yyyy-MM-dd",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.SaveFormData(
                                                    "",
                                                    item.index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.dateCommenced,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "dateCommenced",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.dateCommenced",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                item.isStillEmployed !== "Yes"
                                  ? _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 12,
                                          lg: 12,
                                          xl: 12,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { prop: "dateEnded" } },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "label" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "5px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "* Are you still employed at this company?"
                                                    ),
                                                  ]
                                                ),
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    type: "month",
                                                    "picker-options":
                                                      _vm.pickerOptions,
                                                    format: "MM/yyyy",
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.SaveFormData(
                                                        "",
                                                        item.index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.dateEnded,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "dateEnded",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.dateEnded",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            item.isStillEmployed === "No"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "isStillInBusiness" } },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "label" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "* Is the Employer still in business?"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-radio-group",
                                              {
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.SaveFormData(
                                                      "",
                                                      item.index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.isStillInBusiness,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "isStillInBusiness",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.isStillInBusiness",
                                                },
                                              },
                                              [
                                                _c("el-radio", {
                                                  attrs: { label: "Yes" },
                                                }),
                                                _c("el-radio", {
                                                  attrs: { label: "No" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.isStillEmployed === "Yes"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "canContactEmployer" } },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "label" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "* Can we contact this Employer to verify your Employment?"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-radio-group",
                                              {
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.SaveFormData(
                                                      "",
                                                      item.index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    item.canContactEmployer,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "canContactEmployer",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.canContactEmployer",
                                                },
                                              },
                                              [
                                                _c("el-radio", {
                                                  attrs: { label: "Yes" },
                                                }),
                                                _c("el-radio", {
                                                  attrs: { label: "No" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("el-divider"),
                            _c("div", { staticClass: "section-header" }, [
                              _vm._v("Employer / Business Address Details"),
                            ]),
                            !item.isBusinessAddressManual
                              ? _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 12,
                                          lg: 12,
                                          xl: 12,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "businessfullAddress",
                                            },
                                          },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "label" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "5px",
                                                    },
                                                  },
                                                  [_vm._v("* City / Suburb")]
                                                ),
                                                _c("AddressAutocomplete", {
                                                  attrs: {
                                                    initialaddress:
                                                      item.businessfullAddress,
                                                    placeholder:
                                                      "Start typing and pause to select from the list",
                                                    "use-google": true,
                                                    "google-types": "(cities)",
                                                    from: "EmploymentHistory",
                                                  },
                                                  on: {
                                                    "update:initialaddress":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          item,
                                                          "businessfullAddress",
                                                          $event
                                                        )
                                                      },
                                                    OnDetailReady:
                                                      _vm.AddressAutocompleteOnDetailReady,
                                                    OnClear:
                                                      _vm.AddressAutocompleteOnClear,
                                                    OnManualEntry:
                                                      _vm.AddressAutocompleteOnManualEntry,
                                                    OnNoSelectionMade:
                                                      _vm.AddressAutocompleteOnNoSelectionMade,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 20 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              lg: 12,
                                              xl: 12,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "businessLocality",
                                                },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "label" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "* City / Suburb"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("el-input", {
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.SaveFormData(
                                                            "",
                                                            item.index
                                                          )
                                                        },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.refreshBusinessFullAddress(
                                                            item.index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.businessLocality,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "businessLocality",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.businessLocality",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              lg: 12,
                                              xl: 12,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "businessState",
                                                },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "label" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                      },
                                                      [_vm._v("* State")]
                                                    ),
                                                    _c("el-input", {
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.SaveFormData(
                                                            "",
                                                            item.index
                                                          )
                                                        },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.refreshBusinessFullAddress(
                                                            item.index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.businessState,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "businessState",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.businessState",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 20 } },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              lg: 12,
                                              xl: 12,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  rop: "businessCountry",
                                                },
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "label" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "* Employer / Business Country"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-select",
                                                      {
                                                        attrs: {
                                                          "popper-class":
                                                            "dropdownClass",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.refreshBusinessFullAddress(
                                                              item.index
                                                            )
                                                          },
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.SaveFormData(
                                                              "",
                                                              item.index
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            item.businessCountry,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "businessCountry",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.businessCountry",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.countrySelectionOptions,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                value:
                                                                  item.countryName,
                                                                label:
                                                                  item.countryName,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "employerPhone" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "* Employer Phone Number (Business, landline or mobile) "
                                        ),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                'If you are unsure of your Employer Phone Number please enter "N/A"',
                                              placement: _vm.isMobile
                                                ? "top"
                                                : "right",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-info",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-input", {
                                      attrs: { maxlength: "20" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.SaveFormData(
                                            "",
                                            item.index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.employerPhone,
                                        callback: function ($$v) {
                                          _vm.$set(item, "employerPhone", $$v)
                                        },
                                        expression: "item.employerPhone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "payrollHrEmail" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [
                                        !item.isPayrollHREmailUnknown
                                          ? _c(
                                              "span",
                                              { staticClass: "color-red" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          " Email Address (Payroll / Human Resources) "
                                        ),
                                      ]
                                    ),
                                    _c("el-input", {
                                      attrs: {
                                        disabled: item.isPayrollHREmailUnknown,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.SaveFormData(
                                            "",
                                            item.index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.payrollHrEmail,
                                        callback: function ($$v) {
                                          _vm.$set(item, "payrollHrEmail", $$v)
                                        },
                                        expression: "item.payrollHrEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "email-check" }, [
                              _c(
                                "label",
                                { staticClass: "label" },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.isPayrollHREmailUnknownChange(
                                            item.index,
                                            $event
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.isPayrollHREmailUnknown,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "isPayrollHREmailUnknown",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.isPayrollHREmailUnknown",
                                      },
                                    },
                                    [_vm._v("Email Unknown? ")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "positionTitle" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "* What was the title of your position under your Employer? "
                                        ),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                'If you are unsure of your position please enter "N/A"',
                                              placement: _vm.isMobile
                                                ? "top"
                                                : "right",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-info",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-input", {
                                      attrs: { maxlength: "150" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.SaveFormData(
                                            "",
                                            item.index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.positionTitle,
                                        callback: function ($$v) {
                                          _vm.$set(item, "positionTitle", $$v)
                                        },
                                        expression: "item.positionTitle",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "employeeId" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "* " +
                                            _vm._s(
                                              item.typeOfEmployment !==
                                                "Self-Employed"
                                                ? "What " +
                                                    (item.isStillEmployed ===
                                                    "Yes"
                                                      ? "is "
                                                      : "was ") +
                                                    "your Employee Number / ID?"
                                                : "What is the ABN of your business?"
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                'If you are unsure of your Employee Number/ID please enter "N/A"',
                                              placement: _vm.isMobile
                                                ? "top"
                                                : "right",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-info",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-input", {
                                      attrs: { maxlength: 50 },
                                      on: {
                                        input: function ($event) {
                                          return _vm.SaveFormData(
                                            "",
                                            item.index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.employeeId,
                                        callback: function ($$v) {
                                          _vm.$set(item, "employeeId", $$v)
                                        },
                                        expression: "item.employeeId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            item.isStillEmployed !== "Yes"
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: { prop: "reasonForLeaving" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "label" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "* Please select your reason for leaving:"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio-group",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.SaveFormData(
                                                  "",
                                                  item.index
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.reasonForLeaving,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "reasonForLeaving",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.reasonForLeaving",
                                            },
                                          },
                                          [
                                            _c("el-radio", {
                                              attrs: {
                                                label: "Contract Ended",
                                              },
                                            }),
                                            _c("el-radio", {
                                              attrs: { label: "Resignation" },
                                            }),
                                            _c("el-radio", {
                                              attrs: { label: "Dismissal" },
                                            }),
                                            _c("el-radio", {
                                              attrs: { label: "Redundancy" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("el-divider"),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "isPaidByRecruitmentAgency" } },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          "* Were you paid by a Recruitment Agency?"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.isPaidByRecruitmentAgencyChange(
                                              item.index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.isPaidByRecruitmentAgency,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "isPaidByRecruitmentAgency",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.isPaidByRecruitmentAgency",
                                        },
                                      },
                                      [
                                        _c("el-radio", {
                                          attrs: { label: "Yes" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "No" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            item.isPaidByRecruitmentAgency === "Yes"
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { prop: "recruitmentAgencyName" },
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "label" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "5px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "* Recruitment Agency Name"
                                              ),
                                            ]
                                          ),
                                          _c("el-input", {
                                            on: {
                                              input: function ($event) {
                                                return _vm.SaveFormData(
                                                  "",
                                                  item.index
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.recruitmentAgencyName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "recruitmentAgencyName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.recruitmentAgencyName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 20 } },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 24,
                                            md: 12,
                                            lg: 12,
                                            xl: 12,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "recruitmentAgencyPhone",
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "label" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-bottom": "5px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "* Recruitment Agency Phone"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("el-input", {
                                                    attrs: { maxlength: "20" },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.SaveFormData(
                                                          "",
                                                          item.index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        item.recruitmentAgencyPhone,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "recruitmentAgencyPhone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.recruitmentAgencyPhone",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 24,
                                            md: 12,
                                            lg: 12,
                                            xl: 12,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "recruitmentAgencyEmail",
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "label" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-bottom": "5px",
                                                      },
                                                    },
                                                    [
                                                      !item.isRecruitmentAgencyEmailUnknown
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "color-red",
                                                            },
                                                            [_vm._v("*")]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " Recruitment Agency Email "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled:
                                                        item.isRecruitmentAgencyEmailUnknown,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.SaveFormData(
                                                          "",
                                                          item.index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        item.recruitmentAgencyEmail,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "recruitmentAgencyEmail",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.recruitmentAgencyEmail",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    {
                                      attrs: {
                                        gutter: 20,
                                        type: "flex",
                                        justify: "end",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          attrs: {
                                            xs: 24,
                                            sm: 24,
                                            md: 12,
                                            lg: 12,
                                            xl: 12,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "email-check" },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "label" },
                                                [
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.isRecruitmentAgencyEmailUnknownChange(
                                                            item.index,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.isRecruitmentAgencyEmailUnknown,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "isRecruitmentAgencyEmailUnknown",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.isRecruitmentAgencyEmailUnknown",
                                                      },
                                                    },
                                                    [_vm._v(" Email Unknown? ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      item.employmentOrGap == "Gap"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "0" },
                                attrs: { prop: "gapReason" },
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "label" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "5px" },
                                      },
                                      [_vm._v("* Explanation for the gap:")]
                                    ),
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "typeEmp",
                                        on: {
                                          change: function ($event) {
                                            return _vm.SaveFormData(
                                              "",
                                              item.index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.gapReason,
                                          callback: function ($$v) {
                                            _vm.$set(item, "gapReason", $$v)
                                          },
                                          expression: "item.gapReason",
                                        },
                                      },
                                      [
                                        _c("el-radio", {
                                          attrs: { label: "Parental Leave" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "Career Break" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "Travel" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "Unemployed" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "Caring for Family" },
                                        }),
                                        _c("el-radio", {
                                          attrs: { label: "Study" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("br"),
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 12,
                                      lg: 12,
                                      xl: 12,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "dateCommenced" } },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "label" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "* When did your gap commence?"
                                                ),
                                              ]
                                            ),
                                            _c("el-date-picker", {
                                              attrs: {
                                                "picker-options":
                                                  _vm.pickerOptions,
                                                type: "month",
                                                format: "MM/yyyy",
                                                "value-format": "yyyy-MM-dd",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.SaveFormData(
                                                    "",
                                                    item.index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.dateCommenced,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "dateCommenced",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.dateCommenced",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 12,
                                      lg: 12,
                                      xl: 12,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "dateEnded" } },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "label" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "* When did your gap conclude?"
                                                ),
                                              ]
                                            ),
                                            _c("el-date-picker", {
                                              attrs: {
                                                "picker-options":
                                                  _vm.pickerOptions,
                                                type: "month",
                                                format: "MM/yyyy",
                                                "value-format": "yyyy-MM-dd",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.SaveFormData(
                                                    "",
                                                    item.index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.dateEnded,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "dateEnded",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.dateEnded",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      !_vm.allDisabled
                        ? _c("el-form-item", [
                            _c("div", { staticClass: "action-box" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdd(
                                        `addForm${item.index}`,
                                        1,
                                        item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Add New Item"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item.index, item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete-solid",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v(
                                      "Remove " +
                                        _vm._s(item.employmentOrGap || "Item")
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      _vm.compliancesList.length === 0
        ? _c("p", [_vm._v('Please select "Add New Item" to begin')])
        : _vm._e(),
      _vm.outAddBtnShow && !_vm.allDisabled
        ? _c(
            "div",
            {
              staticClass: "action-box",
              on: {
                click: function ($event) {
                  return _vm.handleAdd("", 2)
                },
              },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "action-item" }, [
      _c("i", { staticClass: "el-icon-circle-plus" }),
      _c("span", { staticClass: "action-value f-s-14" }, [
        _vm._v("Add New Item"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }