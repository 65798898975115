<script>
import { professionalMembershipsApi } from '@/api/pattern'
export default {
  name: 'ProfessionalMembershipsView',
  props: {
    applicantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: {},
      moduleLoading: false
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      const params = {
        applicantId: this.applicantId
      }

      await professionalMembershipsApi(params).then((res) => {
        const { data } = res
        if (data.getProfessionalMembershipsByApplicantId.length !== 0) {
          this.ruleForm = data.getProfessionalMembershipsByApplicantId[data.getProfessionalMembershipsByApplicantId.length - 1]
        }
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
        this.ruleForm.hasMembership = data.hasMembership
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>

<template>
  <div v-loading="moduleLoading" class="ProfessionalMembershipsView">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      class="demo-ruleForm"
      label-position="top"
      disabled
    >
      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="16" :md="16" :lg="16"
          :xl="16" class="label"
        >
          Do you hold a Professional Membership?
        </el-col>
        <el-col
          :xs="24" :sm="8" :md="8" :lg="8"
          :xl="8"
        >
          <el-form-item
            prop="hasMembership"
            style="margin-bottom: 0"
          >
            <el-radio-group
              v-model="ruleForm.hasMembership"
              disabled
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="ruleForm.hasMembership === 'Yes'">
        <el-form-item label="Institution Name" prop="institutionName">
          <el-input v-model="ruleForm.institutionName" disabled />
        </el-form-item>
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="12" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Locality" prop="locality">
              <el-input v-model="ruleForm.locality" disabled />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="12" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="State" prop="state">
              <el-input v-model="ruleForm.state" disabled />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Country" prop="country">
          <el-input v-model="ruleForm.country" disabled />
        </el-form-item>
        <el-form-item label="Contact Phone Number" prop="contactPhone">
          <el-input v-model="ruleForm.contactPhone" disabled />
        </el-form-item>
        <el-form-item
          label="Membership Type"
          prop="type"
        >
          <el-input v-model="ruleForm.type" disabled />
        </el-form-item>
        <el-form-item label="Reference No" prop="referenceNo">
          <el-input v-model="ruleForm.referenceNo" disabled />
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.ProfessionalMembershipsView {
  .label {
    line-height: 40px;
    font-size: 14px;
    color: #606266;
  }
  .el-form-item__label {
    line-height: 40px;
  }
}
</style>
