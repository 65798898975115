var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "QualificationsNAB",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Name of Qualification",
                prop: "qualificationName",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.ruleForm.qualificationName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "qualificationName", $$v)
                  },
                  expression: "ruleForm.qualificationName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "University/College/School (Full Name)",
                prop: "schoolName",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.ruleForm.schoolName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "schoolName", $$v)
                  },
                  expression: "ruleForm.schoolName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "School Location", prop: "initialAddress" } },
            [
              _c("div", { staticClass: "box" }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.ruleForm.schoolLocality) +
                      " " +
                      _vm._s(_vm.ruleForm.schoolState) +
                      " " +
                      _vm._s(_vm.ruleForm.schoolCountry) +
                      " "
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Date Commenced", prop: "dateCommenced" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          format: "MM/yyyy",
                          type: "date",
                          disabled: "",
                        },
                        model: {
                          value: _vm.ruleForm.dateCommenced,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "dateCommenced", $$v)
                          },
                          expression: "ruleForm.dateCommenced",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Date Graduated", prop: "dateGraduated" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          format: "MM/yyyy",
                          type: "date",
                          disabled: "",
                        },
                        model: {
                          value: _vm.ruleForm.dateGraduated,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "dateGraduated", $$v)
                          },
                          expression: "ruleForm.dateGraduated",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Student Number", prop: "applicantStudentId" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.ruleForm.applicantStudentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "applicantStudentId", $$v)
                  },
                  expression: "ruleForm.applicantStudentId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "Were you known under a different name while you were studying?",
                prop: "hasOtherName",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.hasOtherName,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "hasOtherName", $$v)
                    },
                    expression: "ruleForm.hasOtherName",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Yes" } }),
                  _c("el-radio", { attrs: { label: "No" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.HasOtherNameShow
            ? _c(
                "el-form-item",
                { attrs: { label: "Your name at the time of studying" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.ruleForm.applicantName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "applicantName", $$v)
                      },
                      expression: "ruleForm.applicantName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }