<script>
import EYQuestionG13Item from './AddForm/index.vue'
import { eyQuestionG13Api } from '@/api/pattern'
export default {
  name: 'EYQuestionG13View',
  components: { EYQuestionG13Item },
  provide() {
    return {
      OnItemAddNew: this.OnItemAddNew,
      OnItemRemoved: this.OnItemRemoved,
      getAddFormList: this.getAddFormList
    }
  },
  props: {
    applicantId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      listOf8: [
        {
          key: 'q7_A',
          details: 'q7_A_Details',
          title:
            'a) you continue to be a partner, principal, shareholder or professional employee of Ernst & Young after your engagement by NAB begins?'
        },
        {
          key: 'q7_B',
          details: 'q7_B_Details',
          title:
            'b) you have actual ongoing influence over or participation in Ernst & Young’s business?'
        },
        {
          key: 'q7_C',
          details: 'q7_C_Details',
          title:
            'c) there be a perception that you have ongoing influence over or participation in Ernst & Young’s business, even if in fact you have no such influence?'
        },
        {
          key: 'q7_D',
          details: 'q7_D_Details',
          title: 'd) you have capital balances in Ernst & Young?'
        },
        {
          key: 'q7_E',
          details: 'q7_E_Details',
          title:
            'e) you have an on-going financial arrangement with Ernst & Young?'
        }
      ],
      listAfter8: [
        {
          key: 'q8_A',
          periodStart: 'q8_A_PeriodStart',
          periodConclude: 'q8_A_PeriodConclude',
          title:
            '9. Is your spouse, spousal equivalent, parent, child (dependent or not) or sibling (a Close Family Member) currently, or have they been at any time since 1 October 2004, a partner, principal, shareholder or employee of Ernst & Young?'
        },
        {
          key: 'q8_B',
          periodStart: 'q8_B_PeriodStart',
          periodConclude: 'q8_B_PeriodConclude',
          title:
            'is (or was) the relevant Close Family Member on the Ernst & Young NAB audit engagement team?'
        },
        {
          key: 'q8_C',
          periodStart: 'q8_C_PeriodStart',
          periodConclude: 'q8_C_PeriodConclude',
          title:
            'is (or was) the relevant Close Family Member a person who supervises or has direct management responsibility for the NAB audit, or who evaluates the performance or recommends the compensation of the audit engagement partner, or who provides quality control or other oversight of NAB’s audit?'
        },
        {
          key: 'q8_D',
          periodStart: 'q8_D_PeriodStart',
          periodConclude: 'q8_D_PeriodConclude',
          title:
            'is (or was) the relevant Close Family Member a partner, principal shareholder or managerial employee of Ernst & Young who provided (or expects to provide) 10 or more hours of non-audit services to NAB during a financial year?'
        }
      ],
      ruleForm: {
        q1: '',
        q2: '',
        q3: '',
        q4: '',
        q5: '',
        q5_Details: '',
        q5_Q1: '',
        q5_Q1_Details: '',
        q6: '',
        q6_Details: '',
        q7_A: '',
        q7_A_Details: '',
        q7_B: '',
        q7_B_Details: '',
        q7_C: '',
        q7_C_Details: '',
        q7_D: '',
        q7_D_Details: '',
        q7_E: '',
        q7_E_Details: '',
        q8_A: '',
        q8_A_FamilyMemberType: '',
        q8_A_JobTitle: '',
        q8_A_PeriodStart: null,
        q8_A_PeriodConclude: null,
        q8_B: '',
        q8_B_PeriodStart: null,
        q8_B_PeriodConclude: null,
        q8_C: '',
        q8_C_PeriodStart: null,
        q8_C_PeriodConclude: null,
        q8_D: '',
        q8_D_PeriodStart: null,
        q8_D_PeriodConclude: null,
        q8_E: '',
        q8_E_PeriodStart: null,
        q8_E_PeriodConclude: null
      },
      rules: {},
      currentFormSection: {},
      editItemIndex: null,
      eyQuestionG1_3_Id: null,
      jobsList: [],
      jobs: [],
      status: false,
      activeName: '1',
      moduleLoading: false
    }
  },
  created() {
    if (this.applicantId) {
      this.Preparation()
    }
  },
  methods: {
    async Preparation() {
      this.moduleLoading = true
      await eyQuestionG13Api({ applicantId: this.applicantId }).then(res => {
        // console.log('eyQuestionG13Api===', res)
        this.ruleForm = res.data.question
        this.jobs = res.data.jobs
        this.moduleLoading = false
      }).catch(() => {
        this.moduleLoading = false
      })
    }
  }
}
</script>
<template>
  <div v-loading="moduleLoading" class="EYQuestionG13View">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-width="50%"
      label-position="top"
    >
      <el-collapse v-model="activeName">
        <el-collapse-item title="Part 1" name="1">
          <el-form-item
            prop="q1"
            label="1. Are you applying for an Accounting or Financial Reporting Oversight Role,
       a secretary role on any NAB Group company board, or a role on the Executive Committee AND have you worked for
        EY (NAB external auditor) at any time since 1 October 2004?"
          >
            <el-radio-group v-model="ruleForm.q1" disabled>
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="q2"
            label="2. In past three years, has your spouse, spousal equivalent, parent,
         child (dependent or otherwise) or sibling been a partner, principal,
          shareholder or professional employee of Ernst & Young (NAB's external auditor) AND have they worked on the NAB Audit?"
          >
            <el-radio-group v-model="ruleForm.q2" disabled>
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item title="Part 2" name="2">
          <template v-if="ruleForm.q1 === 'Yes' || ruleForm.q2 === 'Yes'">
            <el-form-item
              prop="q3"
              label="3. Have you been a professional employee, partner, principal or shareholder of Ernst & Young at any time since 1 October 2004?"
            >
              <el-radio-group v-model="ruleForm.q3" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <div v-if="ruleForm.q3 === 'Yes'">
              <p class="label mb-10">
                If yes, what was your job title and during what time period(s)?
                (If you had more than one role at Ernst & Young, please list
                each job title and period in that role separately.
              </p>

              <EYQuestionG13Item ref="EYQuestionG13Item" :jobs="jobs" />
            </div>

            <el-form-item
              label="4. Please provide a brief description of your role and responsibilities at Ernst & Young:"
              prop="q4"
            >
              <el-input v-model="ruleForm.q4" type="textarea" disabled />
            </el-form-item>

            <el-form-item
              label="5. During your time at Ernst & Young, were you ever a member of the NAB Group Audit Engagement Team?"
              prop="q5"
            >
              <el-radio-group v-model="ruleForm.q5" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <el-form-item
              v-if="ruleForm.q5 === 'Yes'"
              label="If yes, please list the financial years you were a member of the NAB Group Audit Engagement Team."
              prop="q5_Details"
            >
              <el-input
                v-model="ruleForm.q5_Details"
                type="textarea"
                disabled
              />
            </el-form-item>

            <el-form-item
              label="6. During your time at Ernst & Young, were you ever involved in providing non-audit services to the NAB Group?"
              prop="q5_Q1"
            >
              <el-radio-group v-model="ruleForm.q5_Q1" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <el-form-item
              v-if="ruleForm.q5_Q1 === 'Yes'"
              label="If yes, please list what services you provided and during which financial year/s"
              prop="q5_Q1_Details"
            >
              <el-input
                v-model="ruleForm.q5_Q1_Details"
                type="textarea"
                disabled
              />
            </el-form-item>

            <el-form-item prop="q6">
              <span slot="label">7. During your time at Ernst & Young did you serve in a
                professional capacity in a Prudential Audit
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    “Prudential Audit” means an audit in relation <br>
                    to the Banking Act 1959 (Cth) or in <br>relation to the
                    Prudential Standards <br>
                    or Reporting Standards within the <br>
                    meaning of Prudential Standard CPS 510 of <br>
                    the Australian Prudential Regulation Authority)
                  </div>
                  <i class="el-icon-info" /> </el-tooltip>of NAB?
              </span>
              <el-radio-group v-model="ruleForm.q6" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <el-form-item
              v-if="ruleForm.q6 === 'Yes'"
              label="If yes, please list the financial years you served in a professional capacity in a Prudential Audit of NAB."
              prop="name"
            >
              <el-input
                v-model="ruleForm.q6_Details"
                type="textarea"
                disabled
              />
            </el-form-item>

            <p class="label">8. If you accept this position with NAB, will:</p>

            <div v-for="(item, index) in listOf8" :key="index">
              <el-form-item :label="item.title" :prop="item.key">
                <el-radio-group v-model="ruleForm[item.key]" disabled>
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </el-form-item>

              <el-form-item
                v-if="ruleForm[item.key] === 'Yes'"
                label="If yes, please provide details"
                :prop="item.details"
              >
                <el-input
                  v-model="ruleForm[item.details]"
                  type="textarea"
                  disabled
                />
              </el-form-item>
            </div>

            <div v-for="(item, index) in listAfter8" :key="'9' + index">
              <el-form-item :label="item.title" :prop="item.key">
                <el-radio-group v-model="ruleForm[item.key]" disabled>
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </el-form-item>

              <el-form-item
                v-if="ruleForm[item.key] === 'Yes' && item.key === 'q8_A'"
                label="Type of Close Family Member (i.e. spouse, sibling etc)"
                prop="q8_A_FamilyMemberType"
              >
                <el-input v-model="ruleForm.q8_A_FamilyMemberType" disabled />
              </el-form-item>

              <el-form-item
                v-if="ruleForm[item.key] === 'Yes' && item.key === 'q8_A'"
                label="Job Title"
                prop="q8_A_JobTitle"
              >
                <el-input v-model="ruleForm.q8_A_JobTitle" disabled />
              </el-form-item>

              <div v-if="ruleForm[item.key] === 'Yes'">
                <p class="label">If yes, please provide period worked</p>
                <el-row :gutter="20">
                  <el-col
                    :xs="24" :sm="24" :md="12" :lg="12"
                    :xl="12"
                  >
                    <el-form-item
                      label="Period start?"
                      :prop="item.periodStart"
                    >
                      <el-date-picker
                        v-model="ruleForm[item.periodStart]"
                        disabled
                        value-format="yyyy-MM-dd"
                        format="MM/yyyy"
                        type="month"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="24" :sm="24" :md="12" :lg="12"
                    :xl="12"
                  >
                    <el-form-item
                      label="Period conclude?"
                      :prop="item.periodConclude"
                    >
                      <el-date-picker
                        v-model="ruleForm[item.periodConclude]"
                        disabled
                        value-format="yyyy-MM-dd"
                        format="MM/yyyy"
                        type="month"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>

            <el-form-item prop="q8_E">
              <span slot="label">is (or was) the relevant Close Family Member a partner,
                principal or shareholder from an Ernst & Young “office”

                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    In this question, “office” means a distinct <br>
                    sub-group within Ernst & Young, whether <br>
                    distinguished along geographic or practice lines.
                  </div>
                  <i class="el-icon-info" />
                </el-tooltip>
                where the lead Ernst & Young NAB audit engagement partner
                primarily practices?
              </span>
              <el-radio-group v-model="ruleForm.q8_E" disabled>
                <el-radio label="Yes" />
                <el-radio label="No" />
              </el-radio-group>
            </el-form-item>

            <div v-if="ruleForm.q8_E === 'Yes'">
              <p class="label">If yes, please provide period worked</p>
              <el-row :gutter="20">
                <el-col
                  :xs="24" :sm="24" :md="12" :lg="12"
                  :xl="12"
                >
                  <el-form-item label="Period start?" prop="q8_E_PeriodStart">
                    <el-date-picker
                      v-model="ruleForm.q8_E_PeriodStart"
                      disabled
                      value-format="yyyy-MM-dd"
                      format="MM/yyyy"
                      type="month"
                    />
                  </el-form-item>
                </el-col>
                <el-col
                  :xs="24" :sm="24" :md="12" :lg="12"
                  :xl="12"
                >
                  <el-form-item
                    label="Period conclude?"
                    prop="q8_E_PeriodConclude"
                  >
                    <el-date-picker
                      v-model="ruleForm.q8_E_PeriodConclude"
                      disabled
                      value-format="yyyy-MM-dd"
                      format="MM/yyyy"
                      type="month"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </template>
          <p v-else>No Part 2 questions required.</p>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>
<style lang="scss" scoped>
.EYQuestionG13View {
  .label {
    margin: 0;
    font-size: 14px;
    color: #606266;
  }
  ::v-deep .el-collapse-item__wrap {
  background-color: transparent !important;
}
::v-deep .el-collapse-item__header {
  padding: 0;
  background-color: transparent !important;
}
}
</style>
