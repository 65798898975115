var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "welcome" },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c("el-divider"),
      _c("p", [_vm._v("This feature is not implemented yet.")]),
      _c("p", [_vm._v("Please continue to the next section...")]),
      _vm.ContinueBtn
        ? _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.Continue } },
            [_vm._v("Continue")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }