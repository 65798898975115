var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "Identity100Points",
    },
    [
      _vm.orderApplicantDocuments.length === 0
        ? _c("div", [_vm._v(" Proof of address document already provided. ")])
        : _c("div", [
            _c(
              "div",
              { staticClass: "CVUpload-box" },
              [
                _c("ImageShow", {
                  attrs: { "img-list": _vm.orderApplicantDocuments },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }