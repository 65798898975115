var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AdobeSign" },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _vm.expressionShow && _vm.show
        ? _c("div", { staticClass: "expression" }, [
            _c("div", { staticClass: "expression-left-box" }, [
              _c("i", { staticClass: "el-icon-warning" }),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.validationMessage ||
                        "There was an error preparing your documents for signing."
                    )
                ),
              ]),
            ]),
            _c("i", {
              staticClass: "el-icon-close",
              on: {
                click: function ($event) {
                  _vm.expressionShow = !_vm.expressionShow
                },
              },
            }),
          ])
        : _vm._e(),
      !_vm.show
        ? _c("div", [
            _vm.IsWaitShow
              ? _c("p", [
                  _c("i", { staticClass: "fa fa-spinner fa-pulse" }),
                  _vm._v(
                    " Please wait while we prepare your documents for signing..."
                  ),
                ])
              : _c("div", {
                  staticClass: "mt-10 mb-10",
                  domProps: { innerHTML: _vm._s(_vm.introduction) },
                }),
            _vm.AgreementSigningURLShow
              ? _c("div", [
                  !_vm.loading && !_vm.isSubmitted
                    ? _c("div", { staticClass: "waitingBox" }, [
                        _c("i", {
                          staticClass: "fa fa-spin fa-circle-o-notch",
                        }),
                        _c("span", [
                          _vm._v(
                            "Please follow the instructions and submit your signature below. The system is awaiting for your signature."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticStyle: {
                        "margin-top": "10px",
                        padding: "5px",
                        height: "630px",
                        border: "#206caa dashed 2px",
                        "border-radius": "5px",
                      },
                    },
                    [
                      _vm._m(0),
                      _c("iframe", {
                        ref: "Iframe",
                        staticStyle: {
                          height: "580px",
                          "margin-top": "5px",
                          "border-radius": "5px",
                        },
                        attrs: {
                          id: "iframe",
                          src: _vm.agreementSigningURL,
                          width: "100%",
                          frameborder: "0",
                        },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "btn-bottom" },
        [
          _vm.show
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.endAllRequests },
                  on: { click: _vm.Continue },
                },
                [_vm._v("Continue")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("SubmittingModal", {
        attrs: { show: _vm.isSubmitting },
        on: {
          "update:show": function ($event) {
            _vm.isSubmitting = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "instruction" }, [
      _c("span", [
        _vm._v(
          "Please scroll down to complete your signature. Zoom out to check the document if you're using a mobile screen."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }