import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/proof-of-identity' : '/proxy/api/applicant/proof-of-identity'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}

export function uploadCommencementApi(data) {
  return service({
    url: `${api}/upload-commencement`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadOtherNameApi(data) {
  return service({
    url: `${api}/upload-other-name`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadPrimaryApi(data) {
  return service({
    url: `${api}/upload-primary`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadSecondary1Api(data) {
  return service({
    url: `${api}/upload-secondary1`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadSecondary2Api(data) {
  return service({
    url: `${api}/upload-secondary2`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function uploadSelfieApi(data) {
  return service({
    url: `${api}/upload-selfie`,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function removeDocumentApi(data) {
  return service({
    url: `${api}/remove-document`,
    method: 'post',
    data: data
  })
}

export function onCommencementComboChangetApi(data) {
  return service({
    url: `${api}/on-commencement-combo-change`,
    method: 'post',
    data: data
  })
}

export function onOtherNameDocumentTypeChangetApi(data) {
  return service({
    url: `${api}/on-other-name-document-type-change`,
    method: 'post',
    data: data
  })
}

export function onPrimaryComboChangetApi(data) {
  return service({
    url: `${api}/on-primary-combo-change`,
    method: 'post',
    data: data
  })
}

export function onSecondary1ComboChangetApi(data) {
  return service({
    url: `${api}/on-secondary1-combo-change`,
    method: 'post',
    data: data
  })
}

export function onSecondary2ComboChangetApi(data) {
  return service({
    url: `${api}/on-secondary2-combo-change`,
    method: 'post',
    data: data
  })
}

export function otherNameRadioButtonOnChangeApi(data) {
  return service({
    url: `${api}/other-name-radio-button-on-change`,
    method: 'post',
    data: data
  })
}

export function continueApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}
