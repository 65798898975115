import service from '@/utils/request'
const api = process.env.NODE_ENV !== 'development' ? '/api/applicant/adobe-sign' : '/proxy/api/applicant/adobe-sign'

export function preparationApi(data) {
  return service({
    url: `${api}/preparation`,
    method: 'post',
    data: data
  })
}

export function submitAgreementApi(data) {
  return service({
    url: `${api}/submit-agreement`,
    method: 'post',
    data: data
  })
}
export function attemptSubmitAgreementApi(data) {
  return service({
    url: `${api}/attempt-submit-agreement`,
    method: 'post',
    data: data
  })
}
export function downloadAdobeDoc(data) {
  return service({
    url: `${api}/download-adobe-doc`,
    method: 'post',
    data: data
  })
}

export function createAgreementApi(data) {
  return service({
    url: `${api}/create-agreement`,
    method: 'post',
    data: data
  })
}

export function createButtonApi(data) {
  return service({
    url: `${api}/create-button`,
    method: 'post',
    data: data
  })
}

export function continueApi(data) {
  return service({
    url: `${api}/continue`,
    method: 'post',
    data: data
  })
}
