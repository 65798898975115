<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
export default {
  name: 'AddForm',
  inject: ['Continue', 'NewComplianceReference', 'OnComplianceRemoved', 'EditCompliance', 'GetAllCompliancesList'],
  props: {
    compliances: {
      type: Array,
      default: () => []
    },
    continueBtn: {
      type: Boolean,
      default: true
    },
    completePercent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {},
      formConfig: {
        licenseeName: '',
        licenseeNumber: '',
        complianceTeamMemberName: '',
        complianceTeamMemberPosition: '',
        contactPhone: '',
        workEmail: '',
        local_CanContact: ''
      },
      rules: {
        licenseeName: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        licenseeNumber: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        complianceTeamMemberName: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        complianceTeamMemberPosition: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        workEmail: [
          { required: true, message: 'Required field!', trigger: 'blur' },
          { type: 'email', message: 'Email expected!', trigger: 'blur' }
        ],
        local_CanContact: [
          {
            required: true,
            message: 'Please select a value.',
            trigger: 'change'
          }
        ]
      },
      compliancesList: [
        {
          licenseeName: '',
          licenseeNumber: '',
          complianceTeamMemberName: '',
          complianceTeamMemberPosition: '',
          contactPhone: '',
          workEmail: '',
          local_CanContact: '',
          index: 0
        }
      ],
      openIndex: 0, // 当前打开的index
      index: 0 // 持续递增的index
    }
  },
  computed: {
    ...mapState({
      preparation: (state) => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    outAddBtnShow() {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === this.openIndex
      )
      return spliceIndex === -1
    }
  },
  watch: {
    compliancesList: {
      handler(data) {
        if (data) {
          this.GetAllCompliancesList(this.compliancesList)
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    if (this.compliances.length > 0) {
      this.compliancesList = []
      this.compliances.forEach(item => {
        const obj = item.compliance
        obj.local_CanContact = item.local_CanContact
        this.compliancesList.push(item.compliance)
      })
      this.compliancesList = this.$isRepeat(this.compliancesList)
      this.index = Math.max.apply(Math, this.compliancesList.map(item => { return item.index }))
      this.index = this.index ? this.index : 0
      if (this.completePercent) { // continue后 不展开列表
        this.openIndex = null
      } else {
        this.openIndex = (this.index && this.index !== 0) ? this.index : 0
      }
    }
  },
  methods: {
    handleRemove(itemIndex, item) {
      const spliceIndex = this.compliancesList.findIndex(
        (data) => data.index === itemIndex
      )
      if (this.compliancesList.length > 1) {
        this.compliancesList.splice(spliceIndex, 1)
        this.OnComplianceRemoved(item, this.compliancesList)
      } else {
        this.compliancesList = [
          {
            licenseeName: '',
            licenseeNumber: '',
            complianceTeamMemberName: '',
            complianceTeamMemberPosition: '',
            contactPhone: '',
            workEmail: '',
            local_CanContact: '',
            index: 0
          }
        ]
        this.openIndex = 0
        this.OnComplianceRemoved(item, [])
      }
    },
    addItem() {
      this.NewComplianceReference(this.compliancesList)
      this.compliancesList.push(
        JSON.parse(
          JSON.stringify({
            ...this.formConfig,
            index: ++this.index
          })
        )
      )
      this.openIndex = this.index
    },
    handleAdd(formName, type, item) {
      // console.log(item)
      if (type === 1) {
        // 表单内
        this.$refs[formName][0].validate(async(valid) => {
          if (valid) {
            this.addItem()
          }
        })
      } else {
        // 全局下
        if (
          this.$refs[`addForm${this.index}`] &&
          this.$refs[`addForm${this.index}`][0]
        ) {
          // 当前存在打开的表单
          this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
            if (valid) {
              this.addItem()
            }
          })
        } else {
          //  不存在打开的表单
          this.addItem()
        }
      }
    },

    open(itemIndex) {
      if (
        this.$refs[`addForm${this.index}`] &&
        this.$refs[`addForm${this.index}`][0]
      ) {
        this.$refs[`addForm${this.index}`][0].validate(async(valid) => {
          if (valid) {
            this.openIndex = itemIndex
          }
        })
      } else {
        this.openIndex = itemIndex
      }
    },

    item_change: _.debounce(
      function() {
        this.EditCompliance(this.compliancesList)
        this.$forceUpdate()
      }, global.debounceTime),

    handleSure() {
      if (this.$refs[`addForm${this.openIndex}`] && this.$refs[`addForm${this.openIndex}`][0]) {
        this.$refs[`addForm${this.openIndex}`][0].validate(async(valid) => {
          if (valid) {
            this.Continue(this.compliancesList)
          }
        })
      } else {
        this.Continue(this.compliancesList)
      }
    }
  }
}
</script>

<template>
  <div class="AddressForm">
    <div v-for="item in compliancesList" :key="item.index" class="allForm">
      <div
        v-if="item.index != openIndex"
        class="history-box"
        @click="open(item.index)"
      >
        <p>
          {{ item.complianceTeamMemberName }} ({{
            item.complianceTeamMemberPosition
          }})
        </p>
      </div>
      <div v-if="item.index == openIndex">
        <el-form
          :ref="`addForm${item.index}`"
          :model="item"
          :rules="rules"
          label-width="80px"
          label-position="top"
          :disabled="allDisabled"
        >
          <el-form-item prop="licenseeName" label="Licencee Name">
            <el-input v-model="item.licenseeName" maxlength="150" @input="item_change" />
          </el-form-item>
          <el-form-item prop="licenseeNumber" label="Licencee Number">
            <el-input v-model="item.licenseeNumber" maxlength="50" @input="item_change" />
          </el-form-item>
          <el-form-item
            prop="complianceTeamMemberName"
            label="Full Name of Compliance Team Member at Licencee"
          >
            <el-input v-model="item.complianceTeamMemberName" maxlength="150" @input="item_change" />
          </el-form-item>
          <el-form-item
            prop="complianceTeamMemberPosition"
            label="Position held by Compliance Team Member"
          >
            <el-input
              v-model="item.complianceTeamMemberPosition"
              maxlength="150"
              @input="item_change"
            />
          </el-form-item>
          <el-form-item prop="contactPhone" label="Contact Phone Number">
            <el-input v-model="item.contactPhone" maxlength="20" @input="item_change" />
          </el-form-item>
          <el-form-item prop="workEmail" label="Work Email Address">
            <el-input v-model="item.workEmail" maxlength="250" @input="item_change" />
          </el-form-item>

          <el-form-item prop="local_CanContact">
            <el-row>
              <el-col :xs="24" :sm="15" :lg="15" :xl="15">
                <span class="label"><span class="color-red">*</span> Can we contact the
                  Licencee(s) for the Compliance Reference Check?</span>
              </el-col>
              <el-col :xs="24" :sm="9" :lg="9" :xl="9">
                <el-radio-group v-model="item.local_CanContact" @change="item_change">
                  <el-radio label="Yes" />
                  <el-radio label="No" />
                </el-radio-group>
              </el-col>
            </el-row>
          </el-form-item>

          <el-form-item v-if="!allDisabled">
            <div class="action-box">
              <div
                class="action-item"
                @click="handleAdd(`addForm${item.index}`, 1,item)"
              >
                <i class="el-icon-circle-plus" />
                <span class="action-value">New Compliance Reference</span>
              </div>
              <div class="action-item" @click="handleRemove(item.index,item)">
                <i class="el-icon-delete-solid" />
                <span class="action-value">Remove Compliance Reference</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="outAddBtnShow && !allDisabled" class="action-box" @click="handleAdd('', 2)">
      <div class="action-item">
        <i class="el-icon-circle-plus" />
        <span class="action-value f-s-14">New Compliance Reference</span>
      </div>
    </div>
    <el-button
      v-if="continueBtn" :loading="endAllRequests" class="mt-10" type="primary"
      @click="handleSure"
    >Save and Continue</el-button>
  </div>
</template>

<style lang="scss" scoped>
.allForm{
  margin-bottom: 30px;
}
.selectWidth {
  display: block;
}
.action-box {
  display: flex;
  justify-content: space-between;
  color: #206caa;
  .action-item {
    cursor: pointer;
  }
  .el-icon-circle-plus,
  .el-icon-delete-solid {
    font-size: 40px;
    vertical-align: middle;
  }
  .action-value {
    margin-left: 10px;
  }
}
.label {
  margin: 0;
  font-size: 14px;
  color: #606266;
}
</style>
