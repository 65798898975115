var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.allLoading,
          expression: "allLoading",
        },
      ],
      staticClass: "EmploymentHistoryView",
    },
    [
      _c("div", { staticClass: "section-header" }, [
        _vm._v(_vm._s(_vm.sectionTitle)),
      ]),
      _c("div", {
        staticClass: "mb-10 f-s-14",
        domProps: { innerHTML: _vm._s(_vm.introduction) },
      }),
      _c("p", { staticClass: "label" }, [
        _vm._v(
          " For this check, we need to know your work history for the last " +
            _vm._s(_vm.minYear) +
            " years (from " +
            _vm._s(_vm.showDate) +
            ") "
        ),
      ]),
      _c("div", { staticClass: "year-box" }, [
        _c(
          "div",
          { staticClass: "year-label" },
          _vm._l(_vm.showDateList, function (v) {
            return _c(
              "el-row",
              { key: v.year },
              [
                _c(
                  "el-col",
                  {
                    staticClass: "year",
                    attrs: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.isMobile ? v.mobileYear : v.year) + " "
                    ),
                  ]
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 22, sm: 22, md: 22, lg: 22, xl: 22 } },
                  [
                    _c(
                      "el-row",
                      { staticClass: "test", attrs: { gutter: 10 } },
                      _vm._l(v.months, function (k) {
                        return _c(
                          "el-col",
                          {
                            key: k.index,
                            staticClass: "month",
                            attrs: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
                          },
                          [
                            _c(
                              "div",
                              {
                                class: [
                                  { "bg-yellow": k.check === "yellow" },
                                  { "bg-green": k.check === "green" },
                                  { "bg-blue": k.check === "blue" },
                                ],
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.isMobile ? k.mobile : k.title)
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
      ]),
      _c("el-divider"),
      _vm.employments.length > 0
        ? _c("EmploymentHistoryItem", {
            ref: "EmploymentHistoryItemRef",
            attrs: {
              notes: _vm.reopenNotes,
              "target-min-date": _vm.targetMinDate,
              "max-date": _vm.maxDate,
              "edit-item-index": _vm.editItemIndex,
              employments: _vm.employments,
            },
            on: {
              "update:notes": function ($event) {
                _vm.reopenNotes = $event
              },
            },
          })
        : _vm._e(),
      _vm.showDocuments
        ? _c(
            "div",
            [
              _c("h4", [_vm._v("Document Upload")]),
              _c("p", { staticClass: "f-s-14" }, [
                _vm._v(
                  " Please upload any Statement of Service (SOS) / Confirmation of Employment letters, if available. "
                ),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: !_vm.ContinueBtn },
                  model: {
                    value: _vm.documentTypeId,
                    callback: function ($$v) {
                      _vm.documentTypeId = $$v
                    },
                    expression: "documentTypeId",
                  },
                },
                [
                  _c(
                    "el-radio-button",
                    { attrs: { label: _vm.statementOfServiceDocumentTypeId } },
                    [_vm._v("Statement of Service")]
                  ),
                  _c(
                    "el-radio-button",
                    {
                      attrs: {
                        label: _vm.confirmationOfEmploymentDocumentTypeId,
                      },
                    },
                    [_vm._v("Confirmation of Employment")]
                  ),
                ],
                1
              ),
              _vm.documentTypeId
                ? _c(
                    "div",
                    [
                      _vm.imgcanDelete
                        ? _c("OnlyUpload", {
                            attrs: { "file-loding": _vm.fileLoding },
                            on: {
                              "update:fileLoding": function ($event) {
                                _vm.fileLoding = $event
                              },
                              "update:file-loding": function ($event) {
                                _vm.fileLoding = $event
                              },
                              Document_Upload:
                                _vm.CloudinaryUpload_UploadResult_Employment,
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "file-boxs" },
                        _vm._l(
                          _vm.employmentOrderApplicantDocuments,
                          function (item, index) {
                            return _c("OnlyUpload", {
                              key: item.id,
                              attrs: {
                                "img-item": item,
                                "can-delete": _vm.getImgcanDelete(item),
                              },
                              on: {
                                Document_Upload:
                                  _vm.CloudinaryUpload_UploadResult_Employment,
                                Document_Remove: function ($event) {
                                  return _vm.Document_Remove(index, $event)
                                },
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _vm.ContinueBtn
            ? _c(
                "el-button",
                {
                  staticClass: "mt-10",
                  attrs: { loading: _vm.endAllRequests, type: "primary" },
                  on: { click: _vm.Continue },
                },
                [_vm._v("Save and Continue")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }