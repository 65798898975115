<script>
import driverLicenceFig1 from '@/assets/driverInfo/driver-licence-fig-1.jpg'
import driverLicenceFig2 from '@/assets/driverInfo/driver-licence-fig-2.jpg'
import driverLicenceFig3 from '@/assets/driverInfo/driver-licence-fig-3.jpg'
import driverLicenceFig4 from '@/assets/driverInfo/driver-licence-fig-4.jpg'
import driverLicenceFig5 from '@/assets/driverInfo/driver-licence-fig-5.jpg'
import driverLicenceFig6 from '@/assets/driverInfo/driver-licence-fig-6.jpg'
import driverLicenceFig7 from '@/assets/driverInfo/driver-licence-fig-7.jpg'
import driverLicenceFig8 from '@/assets/driverInfo/driver-licence-fig-8.jpg'
import driverLicenceFig9 from '@/assets/driverInfo/driver-licence-fig-9.jpg'
import driverLicenceFig10 from '@/assets/driverInfo/driver-licence-fig-10.jpg'
import driverLicenceFig11 from '@/assets/driverInfo/driver-licence-fig-11.jpg'
import driverLicenceFig12 from '@/assets/driverInfo/driver-licence-fig-12.jpg'
import driverLicenceFig13 from '@/assets/driverInfo/driver-licence-fig-13.jpg'
import driverLicenceFig14 from '@/assets/driverInfo/driver-licence-fig-14.jpg'
import driverLicenceFig15 from '@/assets/driverInfo/driver-licence-fig-15.jpg'
import driverLicenceFig16 from '@/assets/driverInfo/driver-licence-fig-16.jpg'
import driverLicenceFig17 from '@/assets/driverInfo/driver-licence-fig-17.jpg'
import driverLicenceFig18 from '@/assets/driverInfo/driver-licence-fig-18.jpg'
import driverLicenceFig19 from '@/assets/driverInfo/driver-licence-fig-19.jpg'
import driverLicenceFig20A from '@/assets/driverInfo/driver-licence-fig-20A.jpg'
import driverLicenceFig20B from '@/assets/driverInfo/driver-licence-fig-20B.jpg'
import driverLicenceFig21 from '@/assets/driverInfo/driver-licence-fig-21.jpg'
import driverLicenceFig22 from '@/assets/driverInfo/driver-licence-fig-22.jpg'
import driverLicenceFig23 from '@/assets/driverInfo/driver-licence-fig-23.jpg'
import driverLicenceFig24 from '@/assets/driverInfo/driver-licence-fig-24.jpg'
import { mapState } from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    driversLicenceState: {
      type: String,
      default: () => 'ACT'
    }
  },
  data() {
    return {
      currentIndex: 0,
      dialogTitle: '',
      metaInfo: [
        {
          id: 1,
          state: 'ACT',
          sections:
            [
              {
                title: 'ACT',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of card, located on LHS underneath Date of Birth.'
                  },
                  {
                    title: 'Card Number',
                    content: 'Front of card, runs vertically alongside the photo.'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'Australian Capital Territory Driver Licence Sample - Front',
                    src: driverLicenceFig1
                  },
                  {
                    id: 2,
                    title: 'Australian Capital Territory Driver Licence Sample - Back',
                    src: driverLicenceFig2
                  }
                ]
              }
            ]
        },
        {
          id: 2,
          state: 'NT',
          sections:
            [
              {
                title: 'NT: Post November 2020',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of Card, located in middle'
                  },
                  {
                    title: 'Card Number',
                    content: 'Back of card, located at bottom'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'Northern Territory Driver Licence - post 1 November 2020 Sample - Front',
                    src: driverLicenceFig3
                  },
                  {
                    id: 2,
                    title: 'Northern Territory Driver Licence - post 1 November 2020 Sample - Back',
                    src: driverLicenceFig4
                  }
                ]
              },
              {
                title: 'NT: Pre-November 2020',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of Card, located top RHS'
                  },
                  {
                    title: 'Card Number',
                    content: 'Back of card, located on bottom left'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'Northern Territory Driver Licence - pre 1 November 2020 Sample - Front',
                    src: driverLicenceFig5
                  },
                  {
                    id: 2,
                    title: 'Northern Territory Driver Licence - pre 1 November 2020 Sample - Back',
                    src: driverLicenceFig6
                  }
                ]
              }
            ]
        },
        {
          id: 3,
          state: 'QLD',
          sections:
            [
              {
                title: 'QLD',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of Card, Top RHS'
                  },
                  {
                    title: 'Card Number',
                    content: 'Front of Card, located in middle bottom (Post 12 June 2019) OR Back of Card, located on bottom right (Pre June 2019)'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'Queensland Driver Licence - post 12 June 2019 Sample - Front',
                    src: driverLicenceFig7
                  },
                  {
                    id: 2,
                    title: 'Queensland Driver Licence - pre 12 June 2019 Sample - Front',
                    src: driverLicenceFig8
                  },
                  {
                    id: 3,
                    title: 'Queensland Driver Sample - Back',
                    src: driverLicenceFig9
                  }
                ]
              }
            ]
        },
        {
          id: 4,
          state: 'NSW',
          sections:
            [
              {
                title: 'NSW: Physical Licence',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of Card, located in Middle'
                  },
                  {
                    title: 'Card Number',
                    content: 'Front of Card, located top RHS'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'New South Wales Driver Licence Sample - Front',
                    src: driverLicenceFig10
                  },
                  {
                    id: 2,
                    title: 'New South Wales Driver Licence Sample - Back',
                    src: driverLicenceFig11
                  }
                ]
              },
              {
                title: 'NSW: Digital Licence',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Located on LHS, Underneath First Name & Last Name'
                  },
                  {
                    title: 'Card Number',
                    content: 'Located at bottom of screen, next to NSW Government Logo'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'New South Wales Digital Driver Licence Sample',
                    src: driverLicenceFig12
                  }
                ]
              }
            ]
        },
        {
          id: 5,
          state: 'SA',
          sections:
            [
              {
                title: 'SA: Physical Licence',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of Card, located top LHS'
                  },
                  {
                    title: 'Card Number',
                    content: 'Back of Card, located top RHS'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'South Australia Driver Licence Sample - Front',
                    src: driverLicenceFig13
                  },
                  {
                    id: 2,
                    title: 'South Australia Driver Licence Sample - Back',
                    src: driverLicenceFig14
                  }
                ]
              },
              {
                title: 'SA: Digital Licence',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Located LHS, Underneath Overview'
                  },
                  {
                    title: 'Card Number',
                    content: 'Located bottom of screen, on top of Demerit Points Accumulated'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'South Australia Digital Driver Licence Sample - Overview Tab',
                    src: driverLicenceFig15
                  },
                  {
                    id: 2,
                    title: 'South Australia Digital Driver Licence Sample - Details Tab',
                    src: driverLicenceFig16
                  }
                ]
              }
            ]
        },
        {
          id: 6,
          state: 'TAS',
          sections:
            [
              {
                title: 'TAS',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of Card, located top LHS'
                  },
                  {
                    title: 'Card Number',
                    content: 'Back of Card, located top RHS'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'Tasmania Driver Licence Sample - Front',
                    src: driverLicenceFig17
                  },
                  {
                    id: 2,
                    title: 'Tasmania Driver Licence Sample - Back',
                    src: driverLicenceFig18
                  }
                ]
              }
            ]
        },
        {
          id: 7,
          state: 'VIC',
          sections:
            [
              {
                title: 'VIC: Post 7 November 2022',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of Card, located top RHS'
                  },
                  {
                    title: 'Card Number',
                    content: 'Back of Card, located top RHS OR Address Label attached to Back of Card'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'Victoria Driver Licence - post 7 November 2022 Sample - Front',
                    src: driverLicenceFig19
                  },
                  {
                    id: 2,
                    title: 'Victoria Driver Licence - post 7 November 2022 Sample - Back',
                    src: driverLicenceFig20A
                  },
                  {
                    id: 3,
                    title: '',
                    src: driverLicenceFig20B
                  }
                ]
              },
              {
                title: 'VIC: Pre-7 November 2022',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of Card, located top RHS'
                  },
                  {
                    title: 'Card Number',
                    content: 'Back of Card, located underneath DOB (MM-YY)'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'Victoria Driver Licence - pre 7 November 2022 Sample - Front',
                    src: driverLicenceFig21
                  },
                  {
                    id: 2,
                    title: 'Victoria Driver Licence - pre 7 November 2022 Sample - Back',
                    src: driverLicenceFig22
                  }
                ]
              }
            ]
        },
        {
          id: 8,
          state: 'WA',
          sections:
            [
              {
                title: 'WA',
                messageList: [
                  {
                    title: 'Licence Number',
                    content: 'Front of Card, located top RHS'
                  },
                  {
                    title: 'Card Number',
                    content: 'Back of Card, located under DOB (MM-YY)'
                  }
                ],
                imageList: [
                  {
                    id: 1,
                    title: 'Western Australia Driver Licence Sample - Front',
                    src: driverLicenceFig23
                  },
                  {
                    id: 2,
                    title: 'Western Australia Driver Licence Sample - Back',
                    src: driverLicenceFig24
                  }
                ]
              }
            ]
        }
      ]
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.setting.device
    }),
    visible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  watch: {
    driversLicenceState: {
      handler(data) {
        this.currentIndex = this.metaInfo.findIndex(t => t.state === data)
        this.dialogTitle = 'Driver Licence Number and Card Number (' + data + ')'
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<template>
  <el-dialog
    ref="dialogEntity"
    class="dialog"
    :lock-scroll="false"
    :append-to-body="true"
    :visible.sync="visible"
    :width="device === 'mobile'?'90%':'800px'"
  >
    <div slot="title" class="dialog-title">{{ dialogTitle }}</div>
    <div v-if="show">
      <div v-for="section of metaInfo[currentIndex].sections" :key="section.title">
        <div class="section-title">{{ section.title }}</div>
        <div v-for="item of section.messageList" :key="item.title">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
        <div class="imageList">
          <div v-for="item of section.imageList" :key="item.id" class="imageListItem">
            <div class="title">{{ item.title }}</div>
            <img class="popup-img" :src="item.src">
          </div>
        </div>
        <br>
      </div>
    </div>
    <div class="dialog-footer">
      <el-button @click="visible = false">Close</el-button>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
.dialog-title {
  font-weight: bold;
  font-size: large;
  color: #206BAA;
}
.section-title {
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word;
}
.title {
  font-size: 15px;
  margin-top: 10px;
  word-wrap: normal;
  font-weight: bold;
}
.content {
  margin-bottom: 20px;
  word-wrap: normal;
}
.popup-img {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}
.imageListItem {
  min-width: 300px;
}
</style>
