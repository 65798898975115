var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.moduleLoading,
          expression: "moduleLoading",
        },
      ],
      staticClass: "PersonalDetailsView",
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "label-width": "50%",
            "label-position": "top",
            disabled: "",
          },
        },
        [
          _c("div", { staticClass: "mt-10" }, [
            _c(
              "div",
              { staticClass: "label" },
              [
                _c("el-checkbox", {
                  on: {
                    change: function ($event) {
                      return _vm.isSingleNameOnlyChange($event)
                    },
                  },
                  model: {
                    value: _vm.ruleForm.isSingleNameOnly,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "isSingleNameOnly", $$v)
                    },
                    expression: "ruleForm.isSingleNameOnly",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ml-4" }, [
                  _vm._v(" I am legally known by single name only"),
                ]),
              ],
              1
            ),
          ]),
          !_vm.ruleForm.isSingleNameOnly
            ? _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Given Name (current legal name)",
                            prop: "firstName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: 100 },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.ruleForm.firstName =
                                  _vm.ruleForm.firstName.replace(
                                    /[^a-zA-Z\s'-]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.firstName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "firstName", $$v)
                              },
                              expression: "ruleForm.firstName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Family Name (current legal name)",
                            prop: "lastName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: 100 },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.ruleForm.lastName =
                                  _vm.ruleForm.lastName.replace(
                                    /[^a-zA-Z\s'-]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.lastName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "lastName", $$v)
                              },
                              expression: "ruleForm.lastName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Current legal name",
                            prop: "lastName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: 100 },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.ruleForm.lastName =
                                  _vm.ruleForm.lastName.replace(
                                    /[^a-zA-Z\s'-]/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.ruleForm.lastName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "lastName", $$v)
                              },
                              expression: "ruleForm.lastName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          !_vm.ruleForm.isSingleNameOnly
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Middle Name(s) (current legal names)",
                    prop: "middleNames",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 100 },
                    nativeOn: {
                      keyup: function ($event) {
                        _vm.ruleForm.middleNames =
                          _vm.ruleForm.middleNames.replace(/[^a-zA-Z\s'-]/g, "")
                      },
                    },
                    model: {
                      value: _vm.ruleForm.middleNames,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "middleNames", $$v)
                      },
                      expression: "ruleForm.middleNames",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email", prop: "email" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "email", $$v)
                          },
                          expression: "ruleForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Contact Phone Number", prop: "phone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "phone", $$v)
                          },
                          expression: "ruleForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Date of Birth", prop: "dateOfBirth" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          format: "dd/MM/yyyy",
                          type: "date",
                        },
                        model: {
                          value: _vm.ruleForm.dateOfBirth,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "dateOfBirth", $$v)
                          },
                          expression: "ruleForm.dateOfBirth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Place of Birth", prop: "placeOfBirth" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.placeOfBirth,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "placeOfBirth", $$v)
                          },
                          expression: "ruleForm.placeOfBirth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Gender", prop: "gender" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.gender,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "gender", $$v)
                  },
                  expression: "ruleForm.gender",
                },
              }),
            ],
            1
          ),
          _c("el-divider"),
          _c("h3", [_vm._v("Australian Driver's Licence")]),
          _vm.ruleForm.driversLicenceNumber !== ""
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Driver's Licence State",
                              prop: "driversLicenceState",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.driversLicenceState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "driversLicenceState",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.driversLicenceState",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Driver's Licence Number",
                              prop: "driversLicenceNumber",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.driversLicenceNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "driversLicenceNumber",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.driversLicenceNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.showDriverLicenseAdditionalInfo
                      ? _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Driver's Licence Card Number",
                                  prop: "driversLicenceCardNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value:
                                      _vm.ruleForm.driversLicenceCardNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "driversLicenceCardNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.driversLicenceCardNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : [_c("p", [_vm._v("No Driver's Licence")])],
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                [
                  _vm.listFilter_DriversLicence.length
                    ? _c(
                        "div",
                        { staticClass: "PersonalDetails-box" },
                        [
                          _c("ImageShow", {
                            attrs: {
                              "img-list": _vm.listFilter_DriversLicence,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm.showDriverLicenseAdditionalInfo
                ? _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _vm.listFilter_DriversLicenceBack.length
                        ? _c(
                            "div",
                            { staticClass: "PersonalDetails-box" },
                            [
                              _c("ImageShow", {
                                attrs: {
                                  "img-list": _vm.listFilter_DriversLicenceBack,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("h3", [_vm._v("Current Passport")]),
          _vm.ruleForm.passportNumber !== ""
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Passport Number",
                              prop: "passportNumber",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.passportNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "passportNumber", $$v)
                                },
                                expression: "ruleForm.passportNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Passport Country",
                              prop: "passportCountryCode",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.passportCountry,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "passportCountry", $$v)
                                },
                                expression: "ruleForm.passportCountry",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [_c("p", [_vm._v("No Passport")])],
          _vm.listFilter_Passport.length
            ? _c(
                "div",
                { staticClass: "PersonalDetails-box" },
                [
                  _c("ImageShow", {
                    attrs: { "img-list": _vm.listFilter_Passport },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("h3", [_vm._v("Australian Firearms Licence ")]),
          _vm.ruleForm.firearmsLicenceNumber !== ""
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Firearms Licence Number",
                              prop: "firearmsLicenceNumber",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.firearmsLicenceNumber,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "firearmsLicenceNumber",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.firearmsLicenceNumber",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "Firearms Licence State",
                              prop: "firearmsLicenceState",
                            },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.ruleForm.firearmsLicenceState,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "firearmsLicenceState",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.firearmsLicenceState",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [_c("p", [_vm._v("No Firearms Licence")])],
          _vm.listFilter_FirearmsLicence.length
            ? _c(
                "div",
                { staticClass: "PersonalDetails-box" },
                [
                  _c("ImageShow", {
                    attrs: { "img-list": _vm.listFilter_FirearmsLicence },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("h3", [_vm._v("Other Names ")]),
          _vm.getOtherNamesByApplicantId.length
            ? _c("PersonalDetailsItem", {
                ref: "PersonalDetailsItem",
                attrs: { "other-names": _vm.getOtherNamesByApplicantId },
              })
            : _c("p", [_vm._v("No other names provided")]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }