<script>
import { getNameApi } from '@/api/documentTypes'
export default {
  name: 'OnlyUpload',
  props: {
    imgList: {
      type: Array,
      default: () => []
    },
    imgItem: {
      type: Object,
      default: () => {}
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    caption: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'green'
    },
    fileLoding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      source: null, // cnacel upload
      fileName: '',
      loadingCover: false,
      typeList: ['png', 'jpeg', 'jpg', 'pdf', 'heic'],
      fileTypePass: true,
      notPassFileName: '',
      notPassFileSize: '',
      removeLoding: false,
      processLen: 0,
      maxFileSize: 20971520
    }
  },
  computed: {
    defaultImg: {
      get() {
        return this.fileLoding
      },
      set(val) {
        this.$emit('update:fileLoding', val)
      }
    }
  },
  watch: {
    'imgItem.thumbUrl': {
      handler(data) {
        if (data) {
          this.defaultImg = ''
        }
      },
      immediate: true,
      deep: true
    },
    defaultImg(newValue, oldValue) {
      if (!newValue) {
        // false  close loading
        this.loadingCover = ''
      }
    }
  },
  created() {
    this.getDocumentName()
  },
  methods: {
    uploadChange(res, file) {
      if (!(file[0].size > this.maxFileSize) && !this.fileTypeVerdict(file[0])) {
        this.loadingCover = URL.createObjectURL(res.raw)
      }
    },
    uploadFile(file) {
      this.notPassFileName = file.file.name
      if (file.file.size > this.maxFileSize) {
        this.fileTypePass = false
      } else {
        this.notPassFileSize = (file.file.size / 1024).toFixed(2)
        if (this.fileTypeVerdict(file.file)) {
          this.fileTypePass = false
        } else {
          const interval = setInterval(() => {
            if (this.processLen >= 99) {
              clearInterval(interval)
              return
            }
            this.processLen += 1
          }, 80)
          this.$emit('Document_Upload', file.file)
        }
      }
    },

    fileTypeVerdict(file) { // false--file type not allowed to upload
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      const isSuffix = this.typeList.indexOf(fileSuffix) === -1
      return isSuffix
    },

    removeDocuments(imgItem) {
      this.removeLoding = true
      this.$emit('Document_Remove', imgItem)
    },
    cancelUpload() {
      if (this.source) {
        this.source.cancel('cnacel upload')
        this.show = false
      }
    },
    openImg(url) {
      window.open(url)
    },
    async getDocumentName() {
      if (!this.caption) {
        this.imgItem && await getNameApi({ id: this.imgItem.documentTypeId }).then(res => {
          this.fileName = res.data.name
        })
      } else {
        this.fileName = this.caption
      }
    },
    uploadProgress(event) {
      console.log(event)
    }
  }
}
</script>

<template>
  <div class="documnetGalleryUpload">
    <div v-if="imgItem" class="DocumentGalleryBox">
      <div v-loading="removeLoding" class="DocumentGalleryItem">
        <div class="img">
          <el-image
            alt="document"
            style="width: 140px; height: 200px"
            :src="imgItem.thumbUrl"
            fit="cover"
            @click="openImg(imgItem.docurl)"
          />
          <!-- <img
            :src="imgItem.docurl" alt="" width="140px" height="200px"
          > -->
          <i
            v-if="canDelete"
            class="el-icon-error"
            :style="{color:iconColor==='red'?'red':iconColor}"
            @click="removeDocuments(imgItem)"
          />
        </div>
        <div class="msg">{{ fileName }}</div>
      </div>
    </div>

    <template v-else>
      <!-- <div v-if="defaultImg || loadingCover" class="DocumentGalleryBox">
        <div class="DocumentGalleryItem"> -->
      <!-- <div
            v-loading="(defaultImg ||loadingCover) ? true : false"
            class="img"
            element-loading-text="Uploading"
          >
            <img :src="loadingCover" alt="" width="140px" height="200px">
          </div> -->
      <el-row v-if="defaultImg || loadingCover">
        <el-col>
          <div v-if="loadingCover" class="img-box">
            <img
              :src="loadingCover" alt="" style="max-width: 200px"
            >
            <div class="upload-test">
              <div>
                <p class="color-white">{{ notPassFileName }}</p>
                <p class="color-white">{{ notPassFileSize }}KB</p>
              </div>
              <div>
                <p class="color-white">Uploading {{ processLen }}% <i class="fa fa-circle-o-notch fa-pulse" /></p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <el-row v-else>
        <!-- accept=" .tiff, .pjp, .jfif, .bmp, .gif, .svg, .png, .xbm, .dib, .jxl, .jpeg, .svgz, .jpg, .webp, .ico, .tif, .pjpeg, .avif, .pdf" -->
        <el-col>
          <el-upload
            v-if="fileTypePass"
            ref="upload"
            class="upload-demo"
            drag
            action="#"
            :http-request="uploadFile"
            :before-remove="cancelUpload"
            :show-file-list="false"
            :on-change="uploadChange"
            :on-progress="uploadProgress"
          >
            <div style="display: flex;flex-direction: column;height: 80px;justify-content: center;">
              <span style="height: 20px; line-height: 20px; font-size: 13px; font-weight: bold;">Drag & Drop your files or<span class="browse" style="margin-left: 5px;">Browser</span></span>
              <span style="height: 20px; line-height: 18px; font-size: 13px;">Max file size: 20MB. Acceptable formats: jpeg, jpg, png, pdf, or heic.</span>
            </div>
          </el-upload>

          <div v-else>
            <div class="not-pass-box">
              <div class="not-pass-content">
                <div class="content1"><i class="el-icon-error" @click="fileTypePass = !fileTypePass" /></div>
                <div class="content2">
                  <!--                  <p class="color-white">{{ notPassFileName }}</p>-->
                  <!--                  <p class="color-pink">{{ notPassFileSize }}KB</p>-->
                </div>
                <div class="content3">
                  <p class="color-white">File size exceed 20MB or file type is invalid</p>
                  <p class="color-pink">Expects jpeg, jpg, png, pdf, or heic</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.img-box {
  border-radius: 5px;
  width: 95%;
  background: #dee2e6;
  padding: 4px;
  display: flex;
  justify-content: center;
  position: relative;
  .upload-test{
    border-radius: 5px;
    position: absolute;
    width: 100%;
    top: 0;
    left:0;
    padding: 4px 0;
    background:linear-gradient(black,transparent);
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    p {
      font-size: 12px;
      line-height: 1.5;
      margin: 0;
      padding: 0 4px;
    }
  }
}
.not-pass-box{
  width: 100%;
  margin: 10px 0;
  height: 80px;
  line-height: 80px;
  background-color: #f1f0ef;
  border-radius: 4px;
  padding: 10px 8px;
  box-sizing: border-box;
  .not-pass-content{
  background-color: #c44f47;
  border-radius: 4px;
  height: 60px;
  padding: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p{
    line-height: 1.5;
    margin: 0;
  }
  .content1{
    font-size: 30px;
    .el-icon-error{
      cursor: pointer;
    }
  }
  .content2{
    width: 20%;
    p{
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .content3{
    text-align: right;
  }
  }
}
.color-white{
  color: #fff;
}
.color-pink{
  color: #db918c;
}
.documnetGalleryUpload {
  ::v-deep .el-upload {
    width: 100%;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 80px;
    border: 1px solid #d9d9d9;
    background: #f1f0ef;
  }
}

.upload-demo {
  margin: 10px 0;
  height: 80px;
  line-height: 80px;
  font-size: 14px;
  .browse {
    text-decoration: underline;
  }
}
.DocumentGalleryBox {
  display: flex;
  flex-wrap: wrap;
}
.DocumentGalleryItem {
  width: 156px;
  height: 242px;
  background: #fff;
  margin: 10px 20px 10px 0;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.img {
  width: 140px;
  height: 205px;
  padding: 8px;
  position: relative;
  cursor: pointer;
  .el-icon-error {
    font-size: 25px;
    position: absolute;
    right: 8px;
  }
}
.msg {
  padding:0 8px;
  line-height: 1.5;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  text-overflow:ellipsis;
	white-space:nowrap;
}
.upload {
  width: 140px;
  padding: 10px;
  outline: 1px solid red;
  text-align: center;
  .hight-light {
    color: #2d74af;
  }
  .action {
    font-size: 12px;
    color: #272b30;
    margin: 10px auto 0;
  }
}
</style>
