var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      ref: "dialogEntity",
      staticClass: "dialog",
      attrs: {
        "lock-scroll": false,
        "append-to-body": true,
        visible: _vm.visible,
        width: _vm.device === "mobile" ? "90%" : "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v("What is legally known by single name only?")]
      ),
      _c("div", { staticClass: "modal-content" }, [
        _c("p", [
          _c("b", [_vm._v("A single name only")]),
          _vm._v(" is when your name comprises:"),
        ]),
        _c("ul", [
          _c("li", [_vm._v("only one word, or")]),
          _c("li", [
            _vm._v(
              "several words not using Western naming conventions (first name, middle name/s and family name)."
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "div",
          { staticStyle: { "margin-bottom": "20px" } },
          [
            _c("el-switch", {
              attrs: { disabled: _vm.allDisabled, "off-text": "" },
              model: {
                value: _vm.enableSingleNameOption,
                callback: function ($$v) {
                  _vm.enableSingleNameOption = $$v
                },
                expression: "enableSingleNameOption",
              },
            }),
            _c(
              "span",
              { staticClass: "ml-4", staticStyle: { "font-weight": "bold" } },
              [_vm._v(" I am legally known by single name only")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "20px" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.saveSingleNameOption },
              },
              [_vm._v("Confirm")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }