<script>
import { tokenLoginApi } from '@/api/user'
import { setToken, setTokenTime } from '@/utils/auth'
export default {
  data() {
    return {
      token: null
    }
  },
  mounted() {
    const hash = location.href
    if (hash.indexOf('?token=') !== -1) {
      const index = hash.indexOf('?token=')
      this.token = hash.substr(index + 1, hash.length).replace('token=', '')
    } else {
      this.$router.push({ path: '/login' })
    }
    this.token && this.tokenLogIn()
  },
  methods: {
    async tokenLogIn() {
      setTokenTime(new Date().getTime())
      await tokenLoginApi({ token: this.token }).then(async res => {
        const { data } = res
        setToken(data.accessToken)
        this.$store.dispatch('user/setToken', data.accessToken)
        this.$store.dispatch('preparation/setPreparation', { session: data.session })
        this.$router.push({ path: '/application' })
      })
    }
  }
}
</script>

<template>
  <div />
</template>
