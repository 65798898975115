<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import OnlyUpload from '@/components/OnlyUpload'
import AddressAutocomplete from '@/components/AddressAutocomplete'
import GetAllGeoCountries from '@/components/GetAllGeoCountries'
import {
  preparationApi,
  uploadApi,
  removeDocumentsApi,
  otherNameOnChangeApi,
  continueApi,
  saveFormDataApi
} from '@/api/QualificationsNAB'
import { getAuStatesApi } from '@/api/application'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'
export default {
  name: 'QualificationsNABView',
  components: { OnlyUpload, GetAllGeoCountries, AddressAutocomplete },
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  data() {
    const validateSearch = (rule, value, callback) => {
      if (value !== '') {
        if (value === this.displayLine1) {
          callback()
        } else {
          callback(new Error('Please select an address from the list.'))
        }
      } else {
        callback()
      }
    }
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date(new Date().getFullYear(), 11, 31).getTime()
        }
      },
      pickerOptionsAfter5years: {
        disabledDate(time) {
          return time.getTime() > new Date(new Date().getFullYear() + 5, 11, 31).getTime()
        }
      },
      otherNames: [],
      isManualAddress: false,
      qualificationOrderApplicantDocuments: [],
      selectedOtherName: null,
      // initialAddress: '',
      manualAddressEntry: {},
      qualification: {},
      id: 0,
      ruleForm: {
        initialAddress: '',
        schoolName: '',
        schoolLocality: '',
        suburb: '',
        state: '',
        selectedCountryCode: '',
        contactPhone: '',
        contactEmail: '',
        qualificationName: '',
        dateCommenced: '',
        dateGraduated: '',
        hasOtherName: '',
        selectedOtherNameId: null,
        applicantStudentId: ''
      },
      rules: {
        schoolName: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        initialAddress: [{ required: false, validator: validateSearch, trigger: 'blur' }],
        suburb: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        contactPhone: [
          { required: false, message: 'Required field!', trigger: 'blur' }
        ],
        contactEmail: [
          { required: false, message: 'Required field!', trigger: 'blur' }
        ],
        qualificationName: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        dateCommenced: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        dateGraduated: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ],
        hasOtherName: [
          { required: true, message: 'Please select a value', trigger: 'blur' }
        ],
        selectedOtherNameId: [
          { required: true, message: 'Please select a value', trigger: 'change' }
        ],
        applicantStudentId: [
          { required: true, message: 'Required field!', trigger: 'blur' }
        ]
      },
      displayLine1: '',
      allLoading: false,
      australianStates: []
    }
  },
  computed: {
    ...mapState({
      introduction: (state) => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    Session() {
      return this.preparation.session
    },
    BlockName() {
      return this.preparation.session.current.formSection.blockName
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    Applicant() {
      return this.preparation.applicant
    },
    initialAddress() {
      return !this.qualification.schoolLocality ? '' : (this.qualification.schoolLocality + ', ' + this.qualification.schoolState +
        (this.qualification.schoolCountry !== 'Australia' ? ' (' + this.qualification.schoolCountry + ')' : ''))
    },
    HasOtherNameShow() {
      return this.ruleForm.hasOtherName === 'Yes'
    },
    AddressShow() {
      return !this.isManualAddress
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    buttonText() {
      // If(Session.Current.FormSection.Index < (Session.FormSections.Length - 1), "Save and Continue", "Submit")
      return this.Session.current.formSection.index <
        this.Session.formSections.length - 1
        ? 'Save and Continue'
        : 'Submit'
    }

  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'QualificationsNAB')
      // }
      this.allLoading = true
      const params = {
        session: this.Session,
        ApplicantId: this.ApplicantId,
        Applicant: this.Applicant
      }

      await preparationApi(params).then((res) => {
        const { data } = res
        this.qualificationOrderApplicantDocuments =
          data.qualificationOrderApplicantDocuments
        this.isManualAddress = data.isManualAddress
        this.otherNames = data.otherNames
        this.selectedOtherName = data.selectedOtherName || {}
        this.id = data.qualification.id || 0
        this.ruleForm = data.qualification
        this.ruleForm.dateCommenced = data.qualification.dateCommenced === '1900-01-01T00:00:00' ? null : data.qualification.dateCommenced
        this.ruleForm.dateGraduated = data.qualification.dateGraduated === '1900-01-01T00:00:00' ? null : data.qualification.dateGraduated

        this.qualification = data.qualification
        this.manualAddressEntry = data.manualAddressEntry || {}
        this.ruleForm.selectedCountryCode = data.selectedCountryCode
        this.ruleForm.state = data.manualAddressEntry ? data.manualAddressEntry.state : null
        this.ruleForm.suburb = data.manualAddressEntry ? data.manualAddressEntry.suburb : null
        this.ruleForm.hasOtherName = data.hasOtherName

        this.ruleForm.selectedOtherNameId = data.selectedOtherNameId ? data.selectedOtherNameId : ''

        this.ruleForm.initialAddress = this.initialAddress
        this.displayLine1 = this.initialAddress
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
        if (this.ruleForm.selectedCountryCode === 'AU') {
          this.getAuStates()
        }
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    async getAuStates() {
      await getAuStatesApi().then(res => {
        const { data } = res
        this.australianStates = data.australianStates
      })
    },

    AddressAutocompleteOnDetailReady(AddressDetail, displayLine1) {
      this.displayLine1 = AddressDetail.fullAddress
      this.qualification.schoolLocality = AddressDetail.suburb
      this.qualification.schoolState = AddressDetail.state
      this.qualification.SchoolCountry = AddressDetail.country
      this.ruleForm.initialAddress = AddressDetail.fullAddress

      this.$refs.ruleForm.validateField('initialAddress')
    },

    async AddressAutocompleteOnManualEntry(Search) {
      this.displayLine1 = ''
      this.isManualAddress = true
      this.manualAddressEntry.suburb = Search
      this.ruleForm.suburb = Search
      this.manualAddressEntry.country = !this.manualAddressEntry.country ? 'Australia' : this.manualAddressEntry.Country
      this.manualAddressEntry.countryCode = !this.manualAddressEntry.countryCode ? 'AU' : this.manualAddressEntry.countryCode
      this.manualAddressEntry.countryCode3 = !this.manualAddressEntry.countryCode3 ? 'AUS' : this.manualAddressEntry.countryCode3
      this.ruleForm.selectedCountryCode = this.manualAddressEntry.countryCode
      this.qualification.schoolLocality = ''
      this.qualification.schoolState = ''
      this.qualification.SchoolCountry = ''
      this.getAuStates()
    },

    AddressAutocompleteOnNoSelectionMade() {
      this.qualification.schoolLocality = ''
      this.qualification.schoolState = ''
      this.qualification.SchoolCountry = ''
    },
    AutocompleteAddressOnClear() {},

    async CloudinaryUpload_UploadResult_Qualification(file) {
      const formData = new FormData()
      formData.append(
        'OrderApplicantId',
        this.Session.current.orderApplicantId
      )
      formData.append('BlockName', this.BlockName)
      formData.append('file', file)
      await uploadApi(formData).then((res) => {
        const { data } = res
        this.qualificationOrderApplicantDocuments =
          data.qualificationOrderApplicantDocuments
      })
    },

    async Document_Remove(item) {
      const params = {
        Session: this.Session,
        OrderApplicantDocumentId: item.id
      }
      await removeDocumentsApi(params).then((res) => {
        this.qualificationOrderApplicantDocuments = []
        this.SaveFormDataSetSession(res.data.session)
      })
    },

    async HasOtherNameOnChange(val) {
      if (this.ruleForm.hasOtherName === 'No') {
        this.qualification.applicantName = ''
      }
      const params = {
        Session: this.Session,
        Applicant: this.Applicant,
        hasOtherName: this.ruleForm.hasOtherName
      }
      await otherNameOnChangeApi(params).then((res) => {
        const { data } = res
        if (data) {
          this.otherNames = data ? data.otherNames : []
        } else {
          this.ruleForm.selectedOtherNameId = null
          this.selectedOtherNameId = null
          this.selectedOtherName = {}
          this.$forceUpdate()
        }
      })
    },

    async OnManualCountryChange(data) {
      this.ruleForm.selectedCountryCode = data.countryCode
      this.manualAddressEntry.country = data.countryName
      this.manualAddressEntry.countryCode = data.countryCode
      this.manualAddressEntry.countryCode3 = data.isoAlpha3

      if (this.ruleForm.selectedCountryCode === 'AU' && this.australianStates.length < 1) {
        this.getAuStates()
      } else {
        this.ruleForm.state = ''
        this.manualAddressEntry.state = ''
      }
    },

    selectedOtherNameIdChange() {
      this.selectedOtherName = this.otherNames.find(item => item.id === this.ruleForm.selectedOtherNameId)
      this.qualification.applicantName = this.selectedOtherName.label
    },

    SaveFormTempData: _.debounce(
      function() {
        this.qualification.schoolName = this.ruleForm.schoolName
        this.qualification.contactPhone = this.ruleForm.contactPhone
        this.qualification.contactEmail = this.ruleForm.contactEmail
        this.qualification.qualificationName = this.ruleForm.qualificationName
        this.qualification.dateCommenced = this.ruleForm.dateCommenced
        this.qualification.dateGraduated = this.ruleForm.dateGraduated
        this.qualification.applicantStudentId = this.ruleForm.applicantStudentId
        this.manualAddressEntry.suburb = this.ruleForm.suburb
        this.manualAddressEntry.state = this.ruleForm.state
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId,
          qualification: this.qualification,
          manualAddressEntry: this.manualAddressEntry,
          selectedCountryCode: this.ruleForm.selectedCountryCode,
          hasOtherName: this.ruleForm.hasOtherName,
          isManualAddress: this.isManualAddress
        }
        saveFormDataApi(params).then((res) => {
          if (res.data && res.data.session) { this.SaveFormDataSetSession(res.data.session) }
        })
      }, global.debounceTime),

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.qualification.schoolName = this.ruleForm.schoolName
          this.qualification.contactPhone = this.ruleForm.contactPhone
          this.qualification.contactEmail = this.ruleForm.contactEmail
          this.qualification.qualificationName = this.ruleForm.qualificationName
          this.qualification.dateCommenced = this.ruleForm.dateCommenced
          this.qualification.dateGraduated = this.ruleForm.dateGraduated
          this.qualification.selectedOtherNameId = this.ruleForm.selectedOtherNameId
          this.qualification.applicantStudentId = this.ruleForm.applicantStudentId
          this.manualAddressEntry.state = this.ruleForm.state
          this.manualAddressEntry.suburb = this.ruleForm.suburb
          const params = {
            session: this.Session,
            applicant: this.Applicant,
            qualification: this.qualification,
            isManualAddress: this.isManualAddress,
            manualAddressEntry: this.manualAddressEntry,
            orderApplicantDocuments: this.orderApplicantDocuments,
            hasOtherName: this.ruleForm.hasOtherName,
            selectedOtherName: this.selectedOtherName
          }
          // params.applicant.suburb = this.ruleForm.suburb
          // params.applicant.state = this.ruleForm.state

          await continueApi(params).then((res) => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    getImgcanDelete(item) {
      // (QualificationDocuments.List.Current.UploadedBlockName = Session.Current.FormSection.BlockName
      // and not Session.Current.IsSubmitted) or Session.Current.IsAdmin
      return (item.uploadedBlockName === this.Session.current.formSection.blockName &&
      !this.Session.current.isSubmitted) || this.Session.current.isAdmin
    }
  }
}
</script>

<template>
  <div v-loading="allLoading">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <p>
      Please list details for the tertiary qualification you have completed/are
      completing for the NAB Graduate Program
    </p>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-position="top"
      :validate-on-rule-change="false"
      :disabled="allDisabled"
    >
      <el-form-item
        label="University/College/School (Full Name)"
        prop="schoolName"
      >
        <el-input v-model="ruleForm.schoolName" maxlength="250" @input="SaveFormTempData" />
      </el-form-item>

      <el-form-item v-if="AddressShow" label="School Location" prop="initialAddress">
        <AddressAutocomplete
          :initialaddress.sync="ruleForm.initialAddress"
          :placeholder="'Start typing your school\'s suburb'"
          :use-google="true"
          :google-types="'(cities)'"
          from="QualificationsNAB"
          @OnDetailReady="AddressAutocompleteOnDetailReady"
          @OnManualEntry="AddressAutocompleteOnManualEntry"
          @OnNoSelectionMade="AddressAutocompleteOnNoSelectionMade"
        />
      </el-form-item>
      <div v-if="!AddressShow">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="12" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item label="Place of Birth Suburb" prop="suburb">
              <el-input v-model="ruleForm.suburb" @input="SaveFormTempData" />
            </el-form-item>
          </el-col>
          <el-col
            :xs="24" :sm="12" :md="12" :lg="12"
            :xl="12"
          >
            <el-form-item v-if="ruleForm.selectedCountryCode === 'AU'" label="Place of Birth State" prop="state">
              <el-select v-model="ruleForm.state" popper-class="dropdownClass" style="width:100%" @change="SaveFormTempData">
                <el-option v-for="item in australianStates" :key="item.adminCodes1.ISO3166_2" :label="item.adminCodes1.ISO3166_2" :value="item.adminCodes1.ISO3166_2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Place of Birth Country" prop="selectedCountryCode">
          <GetAllGeoCountries
            :defalut-value.sync="ruleForm.selectedCountryCode"
            @getCountryData="OnManualCountryChange"
          />
        </el-form-item>
      </div>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="12" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="School Contact Phone" prop="contactPhone">
            <el-input v-model="ruleForm.contactPhone" maxlength="20" @input="SaveFormTempData" />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="12" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="School Contact Email" prop="contactEmail">
            <el-input v-model="ruleForm.contactEmail" maxlength="250" @input="SaveFormTempData" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="Name of Qualification" prop="qualificationName">
        <el-input v-model="ruleForm.qualificationName" maxlength="150" @input="SaveFormTempData" />
      </el-form-item>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="12" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Date Commenced" prop="dateCommenced">
            <el-date-picker
              v-model="ruleForm.dateCommenced" :picker-options="pickerOptions" format="MM/yyyy" value-format="yyyy-MM-dd"
              type="month" @change="SaveFormTempData"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="12" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item
            label="Date Graduated / Due to Graduate"
            prop="dateGraduated"
          >
            <el-date-picker
              v-model="ruleForm.dateGraduated" :picker-options="pickerOptionsAfter5years" format="MM/yyyy" value-format="yyyy-MM-dd"
              type="month" @change="SaveFormTempData"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item
        label="Were you known under a different name while you were studying?"
        prop="hasOtherName"
      >
        <el-radio-group
          v-model="ruleForm.hasOtherName"
          @change="HasOtherNameOnChange"
        >
          <el-radio label="Yes">Yes</el-radio>
          <el-radio label="No">No</el-radio>
        </el-radio-group>
      </el-form-item>

      <template v-if="HasOtherNameShow">
        <el-form-item
          label="Your name at the time of studying"
          prop="selectedOtherNameId"
        >
          <el-select v-model="ruleForm.selectedOtherNameId" popper-class="dropdownClass" @change="selectedOtherNameIdChange">
            <el-option
              v-for="item in otherNames"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </template>

      <el-form-item label="Student Number" prop="applicantStudentId">
        <el-input v-model="ruleForm.applicantStudentId" maxlength="60" />
      </el-form-item>

      <h4>Document Upload</h4>
      <p>
        If your education qualification was obtained overseas, please upload a
        certified copy of the certificate, if available.
      </p>
      <OnlyUpload
        v-if="qualificationOrderApplicantDocuments.length < 1"
        @Document_Upload="CloudinaryUpload_UploadResult_Qualification($event)"
      />
      <OnlyUpload
        v-for="item in qualificationOrderApplicantDocuments"
        :key="item.id"
        :img-item="item"
        :can-delete="getImgcanDelete(item)"
        @Document_Upload="CloudinaryUpload_UploadResult_Qualification($event)"
        @Document_Remove="Document_Remove"
      />
      <el-form-item>
        <!-- <el-button type="primary" @click="SaveFormTempData('ruleForm')">Save form data</el-button> -->
        <el-button
          v-if="ContinueBtn"
          :loading="endAllRequests"
          type="primary"
          @click="Continue('ruleForm')"
        >{{ buttonText }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.dropdownClass .el-select-dropdown__item{
  width: 90vw !important;
  max-width: 500px;
}
</style>
