<script>
import _ from 'lodash'
import global from '@/utils/global'
import { mapState } from 'vuex'
import { preparationApi, saveFormDataApi, continueApi } from '@/api/CoIDeclarationNAB'
// import PubSubHub from '@/views/Edit/PubSubHub.vue'

export default {
  name: 'CoIDeclarationNABView',
  inject: ['SaveFormDataSetSession', 'ContinueSetSession'],
  data() {
    return {
      applicantDeclaration: {},
      currentFormSection: {},
      ruleForm: {
        hasRelWIthNABEmployee: '',
        colHasRelWithNabEmployeeDel: '',
        hasRelWithOfficial: '',
        colHasRelWithOfficialDetail: '',
        hasBizOrg: '',
        colHasBizOrgInAuDetail: '',
        criminalInterestOrInvestigatedByState: '',
        colBeingInvestigatedDetail: ''
      },
      id: 0,
      criminalInterestOrInvestigatedByStateValid: false,
      rules: {
        hasRelWIthNABEmployee: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        colHasRelWithNabEmployeeDel: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        hasRelWithOfficial: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        colHasRelWithOfficialDetail: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        hasBizOrg: [{ required: true, message: 'Please select a value', trigger: 'change' }],
        colHasBizOrgInAuDetail: [{ required: true, message: 'Required field!', trigger: 'blur' }],
        criminalInterestOrInvestigatedByState: [{
          required: true,
          message: 'Please select a value',
          trigger: 'change'
        }],
        colBeingInvestigatedDetail: [{ required: true, message: 'Required field!', trigger: 'blur' }]
      },
      allLoading: false
    }
  },
  computed: {
    ...mapState({
      device: state => state.setting.device,
      introduction: state => state.section.introduction,
      sectionTitle: state => state.section.sectionTitle,
      preparation: state => state.preparation.preparation,
      endAllRequests: state => state.portCount.endAllRequests
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    Session() {
      return this.preparation.session
    },
    ApplicantId() {
      return this.preparation.session.current.applicantId
    },
    allDisabled() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return !(
        !this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin
      )
    },
    ContinueBtn() {
      // not Session.Current.IsSubmitted or Session.Current.FormSection.CompletePercent < 100 or Session.Current.IsAdmin
      return (!this.Session.current.isSubmitted ||
        this.Session.current.formSection.completePercent < 100 ||
        this.Session.current.isAdmin)
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      // if (PubSubHub !== null && PubSubHub.client.state === 'Connected') {
      //   await PubSubHub.client.send('UpdateApplicantWebblock', 'CoIDeclarationNAB')
      // }
      this.allLoading = true
      const params = {
        Session: this.Session,
        ApplicantId: this.ApplicantId
      }
      await preparationApi(params).then(res => {
        const { data } = res
        this.currentFormSection = data.currentFormSection
        this.criminalInterestOrInvestigatedByStateValid = data.criminalInterestOrInvestigatedByStateValid !== ''
        this.ruleForm.hasRelWIthNABEmployee = data.hasRelWIthNABEmployee
        this.ruleForm.hasRelWithOfficial = data.hasRelWithOfficial
        this.ruleForm.hasBizOrg = data.hasBizOrg
        this.ruleForm.criminalInterestOrInvestigatedByState = data.criminalInterestOrInvestigatedByState

        this.applicantDeclaration = data.applicantDeclaration || {}
        this.id = data.applicantDeclaration ? data.applicantDeclaration.id : 0
        this.ruleForm.colHasRelWithNabEmployeeDel = data.applicantDeclaration ? this.applicantDeclaration.colHasRelWithNabEmployeeDel : ''
        this.ruleForm.colHasRelWithOfficialDetail = data.applicantDeclaration ? this.applicantDeclaration.colHasRelWithOfficialDetail : ''
        this.ruleForm.colHasBizOrgInAuDetail = data.applicantDeclaration ? this.applicantDeclaration.colHasBizOrgInAuDetail : ''
        this.ruleForm.colBeingInvestigatedDetail = data.applicantDeclaration ? this.applicantDeclaration.colBeingInvestigatedDetail : ''
        this.allLoading = false
      }).catch(() => {
        this.allLoading = false
      })
    },

    Change(val, type) {
      val === 'No' ? this.ruleForm[type] = '' : ''
      this.SaveFormData()
    },

    Continue(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const params = {
            session: this.Session,
            applicantId: this.ApplicantId,
            currentFormSection: this.currentFormSection
          }
          params.criminalInterestOrInvestigatedByStateValid = this.criminalInterestOrInvestigatedByStateValid

          params.applicantDeclaration = this.applicantDeclaration
          params.applicantDeclaration.id = this.id

          params.hasRelWIthNABEmployee = this.ruleForm.hasRelWIthNABEmployee
          params.applicantDeclaration.colHasRelWithNabEmployee = this.ruleForm.hasRelWIthNABEmployee === 'Yes'
          params.applicantDeclaration.colHasRelWithNabEmployeeDel = this.ruleForm.colHasRelWithNabEmployeeDel

          params.hasRelWithOfficial = this.ruleForm.hasRelWithOfficial
          params.applicantDeclaration.colHasRelWithOfficial = this.ruleForm.hasRelWithOfficial === 'Yes'
          params.applicantDeclaration.colHasRelWithOfficialDetail = this.ruleForm.colHasRelWithOfficialDetail

          params.hasBizOrg = this.ruleForm.hasBizOrg
          params.applicantDeclaration.colHasBizorginau = this.ruleForm.hasBizOrg === 'Yes'
          params.applicantDeclaration.colHasBizOrgInAuDetail = this.ruleForm.colHasBizOrgInAuDetail

          params.criminalInterestOrInvestigatedByState = this.ruleForm.criminalInterestOrInvestigatedByState
          params.applicantDeclaration.colBeingInvestigated = this.ruleForm.criminalInterestOrInvestigatedByState === 'Yes'
          params.applicantDeclaration.colBeingInvestigatedDetail = this.ruleForm.colBeingInvestigatedDetail

          await continueApi(params).then(res => {
            this.ContinueSetSession(res.data.session)
          })
        }
      })
    },

    SaveFormData: _.debounce(
      function() {
        const params = {
          session: this.Session,
          applicantId: this.ApplicantId
        }
        params.applicantDeclaration = this.applicantDeclaration

        params.hasRelationshipWIthNABEmployee = this.ruleForm.hasRelWIthNABEmployee
        params.applicantDeclaration.colHasRelWithNabEmployee = this.ruleForm.hasRelWIthNABEmployee === 'Yes'
        params.applicantDeclaration.colHasRelWithNabEmployeeDel = this.ruleForm.colHasRelWithNabEmployeeDel

        params.hasRelationshipWithOfficial = this.ruleForm.hasRelWithOfficial
        params.applicantDeclaration.colHasRelWithOfficial = this.ruleForm.hasRelWithOfficial === 'Yes'
        params.applicantDeclaration.colHasRelWithOfficialDetail = this.ruleForm.colHasRelWithOfficialDetail

        params.hasBizOrg = this.ruleForm.hasBizOrg
        params.applicantDeclaration.colHasBizorginau = this.ruleForm.hasBizOrg === 'Yes'
        params.applicantDeclaration.colHasBizOrgInAuDetail = this.ruleForm.colHasBizOrgInAuDetail

        params.criminalInterestOrInvestigatedByState = this.ruleForm.criminalInterestOrInvestigatedByState
        params.applicantDeclaration.colBeingInvestigated = this.ruleForm.criminalInterestOrInvestigatedByState === 'Yes'
        params.applicantDeclaration.colBeingInvestigatedDetail = this.ruleForm.colBeingInvestigatedDetail

        saveFormDataApi(params).then(res => {
          if (res.data && res.data.session) {
            this.SaveFormDataSetSession(res.data.session)
          }
        })
      }, global.debounceTime)
  }
}
</script>

<template>
  <div v-loading="allLoading" class="MedicalDeclarationView">
    <div class="section-header">{{ sectionTitle }}</div>
    <div class="mb-10 f-s-14" v-html="introduction" />
    <el-divider />
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="demo-ruleForm"
      label-position="top"
      :disabled="allDisabled"
    >
      <el-form-item prop="hasRelWIthNABEmployee">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span class="color-red">*</span> <span>1. Do you have any close personal or financial relationships
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">A close personal relationship may include:<br>
                  • A regular and ongoing relationship with someone who might be an intimate friend, partner or a family member.<br>
                  Financial relationship includes:<br>
                  • A person with whom you have an interest in a business, investment or other financial liability/commitment or obligation.<br>
                  • A person with whom you are regularly involved in monetary transactions with.
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
              with another employee of NAB?
            </span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group
              v-model="ruleForm.hasRelWIthNABEmployee"
              @change="Change($event,'colHasRelWithNabEmployeeDel')"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.hasRelWIthNABEmployee==='Yes'"
        prop="colHasRelWithNabEmployeeDel"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              <span class="color-red">*</span> If yes, provide the name of the NAB employee(s) and the nature of each
              relationship.
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.colHasRelWithNabEmployeeDel"
                type="textarea"
                maxlength="1000"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item prop="hasRelWithOfficial">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span class="color-red">*</span> <span>2. Do you have a close personal or financial relationship
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">A close personal relationship may include:
                  <br>• A regular and ongoing relationship with someone who might be an intimate friend, partner or a family member.
                  <br>Financial relationship includes:
                  <br>• A person with whom you have an interest in a business, investment or other financial liability/commitment or obligation.
                  <br>• A person with whom you are regularly involved in monetary transactions with.
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
              with any of the below persons?
              <br>• An executive member of any public department of any Government, State or Territories in Australia or internationally.
              <br>• An elected member of any government, state or local municipality in Australia or internationally.
            </span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group
              v-model="ruleForm.hasRelWithOfficial"
              @change="Change($event,'colHasRelWithOfficialDetail')"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.hasRelWithOfficial==='Yes'"
        prop="colHasRelWithOfficialDetail"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              <span class="color-red">*</span> If yes, provide details including the name of the person, their position,
              name of the government body, location of their position and the nature of your relationship.
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.colHasRelWithOfficialDetail"
                type="textarea"
                maxlength="1000"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item prop="hasBizOrg">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span class="color-red">*</span> <span>3. In relation to any business or other organisation
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  Business or organisation include all incorporated and unincorporated government and non-government organisations, businesses, companies, partnerships, trusts, funds, charitable organisations, as well as committees and working groups thereof.
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
              in Australia and internationally, do you:
              <br><span>• hold an official directorship/officeholder position<el-tooltip
                class="item" effect="dark"
                placement="bottom"
              >
                <div slot="content">
                  Official position includes but is not limited to executive and non-executive board positions, advisory positions on committees, trusteeships and any other role conferring responsibility.
                </div>
                <i class="el-icon-info" />
              </el-tooltip></span>

              <br>• have any financial interest
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  Financial interest includes a significant shareholding, majority shareholding and other beneficial interest.
                </div>
                <i class="el-icon-info" />
              </el-tooltip>
              <br>• have influence, including over the board or any committee or associated group thereof
              <br>• provide advisory or other services
              <br>Includes competitors of and suppliers to NAB as well as other interests external to NAB.
            </span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group v-model="ruleForm.hasBizOrg" @change="Change($event,'colHasBizOrgInAuDetail')">
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.hasBizOrg==='Yes'"
        prop="colHasBizOrgInAuDetail"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              <span class="color-red">*</span> If yes, provide details of the company/organisation, your
              involvement/interest and the country in which it is primarily registered in.
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.colHasBizOrgInAuDetail"
                type="textarea"
                maxlength="1000"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <el-form-item prop="criminalInterestOrInvestigatedByState">
        <el-row :gutter="20">
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12" class="label"
          >
            <span class="color-red">*</span> <span>4. Are you the person of interest
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">A 'Person of interest' is a person who is suspected by police or other law enforcement agencies of committing a crime, or a person who is a subject of a criminal investigation but who has yet to be charged with the crime.</div>
                <i class="el-icon-info" />
              </el-tooltip>
              in an ongoing criminal matter
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">A criminal matter is an offence (or crime) against the state. It is often called ‘breaking the law’ and if you are accused of a criminal offence, you will have a charge sheet that states the offence you have been charged with.</div>
                <i class="el-icon-info" />
              </el-tooltip>
              before the Courts or being investigated by a state or federal government body?
            </span>
          </el-col>
          <el-col
            :xs="24" :sm="24" :md="12" :lg="12"
            :xl="12"
          >
            <el-radio-group
              v-model="ruleForm.criminalInterestOrInvestigatedByState"
              @change="Change($event,'colBeingInvestigatedDetail')"
            >
              <el-radio label="Yes" />
              <el-radio label="No" />
            </el-radio-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        v-if="ruleForm.criminalInterestOrInvestigatedByState==='Yes'"
        prop="colBeingInvestigatedDetail"
      >
        <el-row :gutter="20">
          <label class="label">
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12" class="label"
            >
              <span class="color-red">*</span> If yes, provide details of the offence, your involvement/interest in the
              criminal investigation.
            </el-col>
            <el-col
              :xs="24" :sm="24" :md="12" :lg="12"
              :xl="12"
            >
              <el-input
                v-model="ruleForm.colBeingInvestigatedDetail"
                type="textarea"
                maxlength="1000"
                @input="SaveFormData"
              />
            </el-col>
          </label>
        </el-row>
      </el-form-item>

      <!-- <el-button type="primary" @click="SaveFormData('ruleForm')">Save form data</el-button> -->
      <el-button v-if="ContinueBtn" :loading="endAllRequests" type="primary" @click="Continue('ruleForm')">Save and
        Continue
      </el-button>
    </el-form>
  </div>
</template>

<style lang="scss">
.MedicalDeclarationView {
  .label {
    line-height: 1.5;
    font-size: 14px;
    color: #606266;
  }

  .el-form-item__label {
    line-height: 18px;
  }
}
</style>
