var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AddressForm" },
    [
      _vm._l(_vm.compliancesList, function (item) {
        return _c("div", { key: item.index, staticClass: "allForm" }, [
          item.index != _vm.openIndex
            ? _c(
                "div",
                {
                  staticClass: "history-box",
                  on: {
                    click: function ($event) {
                      return _vm.open(item.index)
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(item.complianceTeamMemberName) +
                        " (" +
                        _vm._s(item.complianceTeamMemberPosition) +
                        ") "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          item.index == _vm.openIndex
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: `addForm${item.index}`,
                      refInFor: true,
                      attrs: {
                        model: item,
                        rules: _vm.rules,
                        "label-width": "80px",
                        "label-position": "top",
                        disabled: _vm.allDisabled,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "licenseeName",
                            label: "Licencee Name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "150" },
                            on: { input: _vm.item_change },
                            model: {
                              value: item.licenseeName,
                              callback: function ($$v) {
                                _vm.$set(item, "licenseeName", $$v)
                              },
                              expression: "item.licenseeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "licenseeNumber",
                            label: "Licencee Number",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "50" },
                            on: { input: _vm.item_change },
                            model: {
                              value: item.licenseeNumber,
                              callback: function ($$v) {
                                _vm.$set(item, "licenseeNumber", $$v)
                              },
                              expression: "item.licenseeNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "complianceTeamMemberName",
                            label:
                              "Full Name of Compliance Team Member at Licencee",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "150" },
                            on: { input: _vm.item_change },
                            model: {
                              value: item.complianceTeamMemberName,
                              callback: function ($$v) {
                                _vm.$set(item, "complianceTeamMemberName", $$v)
                              },
                              expression: "item.complianceTeamMemberName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "complianceTeamMemberPosition",
                            label: "Position held by Compliance Team Member",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "150" },
                            on: { input: _vm.item_change },
                            model: {
                              value: item.complianceTeamMemberPosition,
                              callback: function ($$v) {
                                _vm.$set(
                                  item,
                                  "complianceTeamMemberPosition",
                                  $$v
                                )
                              },
                              expression: "item.complianceTeamMemberPosition",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "contactPhone",
                            label: "Contact Phone Number",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "20" },
                            on: { input: _vm.item_change },
                            model: {
                              value: item.contactPhone,
                              callback: function ($$v) {
                                _vm.$set(item, "contactPhone", $$v)
                              },
                              expression: "item.contactPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "workEmail",
                            label: "Work Email Address",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "250" },
                            on: { input: _vm.item_change },
                            model: {
                              value: item.workEmail,
                              callback: function ($$v) {
                                _vm.$set(item, "workEmail", $$v)
                              },
                              expression: "item.workEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "local_CanContact" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { xs: 24, sm: 15, lg: 15, xl: 15 } },
                                [
                                  _c("span", { staticClass: "label" }, [
                                    _c("span", { staticClass: "color-red" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v(
                                      " Can we contact the Licencee(s) for the Compliance Reference Check?"
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { xs: 24, sm: 9, lg: 9, xl: 9 } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.item_change },
                                      model: {
                                        value: item.local_CanContact,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "local_CanContact",
                                            $$v
                                          )
                                        },
                                        expression: "item.local_CanContact",
                                      },
                                    },
                                    [
                                      _c("el-radio", {
                                        attrs: { label: "Yes" },
                                      }),
                                      _c("el-radio", {
                                        attrs: { label: "No" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.allDisabled
                        ? _c("el-form-item", [
                            _c("div", { staticClass: "action-box" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAdd(
                                        `addForm${item.index}`,
                                        1,
                                        item
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-plus",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("New Compliance Reference"),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "action-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item.index, item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete-solid",
                                  }),
                                  _c("span", { staticClass: "action-value" }, [
                                    _vm._v("Remove Compliance Reference"),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      _vm.outAddBtnShow && !_vm.allDisabled
        ? _c(
            "div",
            {
              staticClass: "action-box",
              on: {
                click: function ($event) {
                  return _vm.handleAdd("", 2)
                },
              },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm.continueBtn
        ? _c(
            "el-button",
            {
              staticClass: "mt-10",
              attrs: { loading: _vm.endAllRequests, type: "primary" },
              on: { click: _vm.handleSure },
            },
            [_vm._v("Save and Continue")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "action-item" }, [
      _c("i", { staticClass: "el-icon-circle-plus" }),
      _c("span", { staticClass: "action-value f-s-14" }, [
        _vm._v("New Compliance Reference"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }