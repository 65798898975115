<script>
export default {
  name: 'VulnerablePeopleView',
  props: {
    applicant: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ApplicantForm: {
        employmentType: '',
        employmentLocation: '',
        vulnerablePeopleClass: ''
      }
    }
  },
  created() {
    this.Preparation()
  },
  methods: {
    async Preparation() {
      this.ApplicantForm = JSON.parse(JSON.stringify(this.applicant))
      // If(Applicant.EmploymentType = "Paid", "Paid (Standard)", Applicant.EmploymentType)
      this.ApplicantForm.employmentType = this.ApplicantForm.employmentType === 'Paid' ? 'Paid (Standard)' : this.ApplicantForm.employmentType
    }
  }
}
</script>

<template>
  <div>
    <el-form
      ref="ApplicantForm"
      :model="ApplicantForm"
      class="demo-ruleForm"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Type of Employment" prop="employmentType">
            <el-input v-model="ApplicantForm.employmentType" disabled />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        />
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        >
          <el-form-item label="Employment Location" prop="employmentLocation">
            <el-input v-model="ApplicantForm.employmentLocation" disabled />
          </el-form-item>
        </el-col>
        <el-col
          :xs="24" :sm="24" :md="12" :lg="12"
          :xl="12"
        />
      </el-row>

      <el-form-item v-if="ApplicantForm.vulnerablePeopleClass" label="Vulnerable People Class" prop="vulnerablePeopleClass">
        <el-input v-model="ApplicantForm.vulnerablePeopleClass" disabled />
      </el-form-item>

    </el-form>
  </div>
</template>

<style lang="scss" scoped></style>
